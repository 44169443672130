import React, { useEffect, useState } from "react";
import CompletionBar from "../Components/CompletionBar";
import SidebarRight from "../Components/SidebarRight";
import InfoTips from "../Components/InfoTips/InfoTips";
import { useSelector, useDispatch } from "react-redux";
import {activeUser, getDefaultPrompt, getProjectDefaultPrompt} from "../reducers/user";
import { AI_OUTLINE_PATH, USER_CLIENT, USER_PROOFREADER } from "../common/consts";
import {
    getOutlineProgressStatus,
    getCurrentArticle,
    getWriterMode,
    resetAndGetOutline,
    setArticleEditorLoading,
    setBothEditorsLoading,
    setShowNewArticleModal,
    getShowNewArticleModal,
} from '../reducers/articles';
import { useEditorModeCheck } from "../utils/useEditorModeCheck";
import TipTapEditor from "../Components/TipTapEditor/TipTapEditor";
// import EditorMenuBottom from "../Components/TipTapEditor/EditorMenuBottom";
import WriterLeftSidebar from '../Components/WriterLeftSidebar/WriterLeftSidebar';
import { getContentArticleRequest, contentArticleData, getCurrentArticleId,  applyWriterMode } from '../reducers/articles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getGlobalState } from '../globals';
import Competition from "../Components/Competition";
import TryAgainModal from "../Shared/TryAgainModal";
import {getSearchKeywords, requestTopicDetails, searchTopic} from "../reducers/searchTopic";
import {getAllDataFetched} from "../reducers/articleDataFlags";
import NewArticleModal from "../Components/NewArticleModal/NewArticleModal";
import ArticlesSidebar from "../Components/ArticlesSidebar/ArticlesSidebar";
import {setNoDataResults} from '../reducers/dashboard'
import DefaultSettingsSidebar from "../Components/DefaultSettingsSidebar/DefaultSettingsSidebar";
import { getShowArticleSidebar, setShowArticleSidebar } from '../reducers/applicationMode'
import {getCampaignWritingStyleOptions, setShowSettingsSidebar, showSettingsSidebar} from "../reducers/articleSettings";

const ArticleMode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(activeUser);
  const currentArticle = useSelector(getCurrentArticle);
  const articleData = useSelector(contentArticleData);
  const topicSearched = useSelector(getSearchKeywords);
  const allDataFetched = useSelector(getAllDataFetched);
  const outlineProgressStatus = useSelector(getOutlineProgressStatus);
  const showAddArticleModal = useSelector(getShowNewArticleModal);
  const defaultPrompt = useSelector(getProjectDefaultPrompt);
  const showArticlesSidebar = useSelector(getShowArticleSidebar)
  const showSidebarType = useSelector(showSettingsSidebar)

  const topicSearchString = () => topicSearched || (currentArticle && currentArticle.name);

  const currentArticleId = useSelector(getCurrentArticleId);
  const siteId = getGlobalState('site_id');
  const sharedToken = getGlobalState('sharedToken');

  const isReadOnlyMode = useEditorModeCheck();

  const [firstLoad, setFirstLoad] = useState(true);
  const [userType, setCurrentUserType] = useState(null);
  const [article, setArticle] = useState(null);
  const [needsOutline, setNeedsOutline] = useState(null);

  const search = useLocation().search;
  const siteIdParams = new URLSearchParams(search).get('site_id');
  const {id} = useParams();
  

  useEffect(() => {
    if (user) {
      setCurrentUserType(user.role[0]);
    }
  }, [user]);

  useEffect(() => {
    if (currentArticle) {
      if(!sharedToken) {
          dispatch(getDefaultPrompt(siteIdParams))
          dispatch(getCampaignWritingStyleOptions(siteIdParams))
      }

      setArticle(currentArticle);
    }

  }, [currentArticle]);

  const switchToArticle = () => {
      if(!sharedToken)
        history.push(`/article/${id}?site_id=${siteId || siteIdParams}`);

      dispatch(applyWriterMode('article'));
  }

  const switchToOutline = () => {
      if(!sharedToken)
        history.push(`/article/${id}/outline?site_id=${siteId || siteIdParams}`);

      dispatch(applyWriterMode('outline'));
  }

    // useEffect(() => { //there is a chance that this isn't used anymore because of the flow that has changed so allDataFetched is always false
    //     if(needsOutline && id) {
    //         dispatch(resetAndGetOutline(id)).then(response => {})
    //     }
    // }, [allDataFetched, needsOutline, id])

  useEffect(() => {
    if (showAddArticleModal && outlineProgressStatus < 0) {
        dispatch(setNoDataResults(true));
        history.push(`/dashboard?site_id=${id}`);
    }
  }, [showAddArticleModal]);

  useEffect(() => {

  if(outlineProgressStatus == 9) {
      dispatch(getContentArticleRequest(id))
        .then((response) => {
              if (id) {
                  // if (!!response.content && response.content !== "<h1></h1>")
                  //     switchToArticle();
                  //
                  if(firstLoad) setFirstLoad(false)

                  // switchToOutline();

                  if (!response.outline) {
                      setNeedsOutline(true);
                      setArticleEditorLoading(false);
                  }
                  // }
              }
          })
          .catch()
          .finally(() => {
              setBothEditorsLoading(false);
          });
  }
  }, [outlineProgressStatus])

    const writerMode = useSelector(getWriterMode)

    const setWriterMode = (mode) => {
        dispatch(applyWriterMode(mode));
        if (mode !== 'undefined' && mode === 'article') {
          // history.push(`/article/${currentArticleId}?site_id=${siteId || siteIdParams}`);
          switchToArticle();
        } else {
          // history.push(`/article/${currentArticleId}/outline?site_id=${siteId || siteIdParams}`);
          switchToOutline();
        }
    }

  // const showCompletionBar = !window.location.pathname.includes(AI_OUTLINE_PATH)

    const [showCompetition, setShowCompetition] = useState(false);

    const updateShowCompetition = () => setShowCompetition(true);
    const closeCompetition = () => setShowCompetition(false);

    const toggleShowAddArticleModal = () => dispatch(setShowNewArticleModal(!showAddArticleModal));
    const toggleShowArticlesSidebar = () => dispatch(setShowArticleSidebar(!showArticlesSidebar));

    const updateShowSettingsSidebar = (type) => dispatch(setShowSettingsSidebar(type))

  return (
    <>
        {showCompetition && (
            <Competition closeCompetition={closeCompetition} />
        )}
        {!sharedToken && (
            <ArticlesSidebar close={toggleShowArticlesSidebar} open={showArticlesSidebar}/>
        )}
        {!!showSidebarType && <DefaultSettingsSidebar />}
        {/*{showArticlesSidebar && (*/}
        {/*)}*/}
        {showAddArticleModal && (
            <NewArticleModal close={toggleShowAddArticleModal} noArticles={false} />
        )}
      {/* {showCompletionBa2wqsazr && <CompletionBar />} */}
      <div className="homepage-content">
        {/* <SidebarRight /> */}
        <WriterLeftSidebar
            setWriterMode={setWriterMode}
            writerMode={writerMode}
            updateShowCompetition={updateShowCompetition}
            showAddArticleModal={toggleShowAddArticleModal}
            showArticlesSidebar={toggleShowArticlesSidebar}
            openDefaultSettings={updateShowSettingsSidebar}
        />
        <div className="homepage-editor">

          {/* {showInfoTips && <InfoTips />} */}
          {<TipTapEditor
              isReadOnly={false}
              writerMode={writerMode}
              switchToArticle={switchToArticle}
              firstLoad={firstLoad}
          />}
          {/* {article && <EditorMenuBottom />} */}
        </div>
      </div>
    </>
  );
};

export default ArticleMode;
