import "./Input.css";
import {PlusIcon, SpinnerIcon} from "../UI_utils/SVGIcons";

const Input = ({
    loading,
    showIcon,
    inputValue,
    onEnter,
    onChange,
    placeholder,
    className,
    label,
    disabled,
    IconToShow = null
}) => {

  const onEnterHandler = (event) => {
    if(onEnter) onEnter()
  }

  const enterHandler = (event) => {
    if (event.key === 'Enter') onEnterHandler(event)
  }

  const handleInputChange = (event) => onChange(event.target.value)

  return (
      <div className={`input__wrapper ${loading ? 'input__wrapper--loading' : ''} ${showIcon ? 'has-icon' : ''}`}>
        {label && (<div className="input__label">{ label }</div>)}
        <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={enterHandler}
            className={`input ${className}`}
            placeholder={placeholder}
            disabled={disabled}
        />
        {showIcon && (
          <div className="input__action" onClick={onEnterHandler}>
            {!loading && (
                <>
                    {IconToShow && <IconToShow /> || <PlusIcon />}
                </>
            )}
            {loading && (
                <SpinnerIcon />
            )}
          </div>
        )}
      </div>
  );
};

export default Input;
