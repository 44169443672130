import axios from "axios";
import { BASE_URL } from "../common/consts";

const userProfile = "api/user-profile";
const login = "api/login";
const logout = "api/logout";
const register = "api/register";
const industries = "api/industries";
const reset = "api/forgot-password";
const changePass = "api/reset";
const multistepRegister = "api/register-clients";
const checkTokenPath = "api/check-token"
const userSyncPath = "api/user-sync"
const saveProjectDefaultPromptPath = "api/project/save-prompt"
const getProjectDefaultPromptPath = "api/project/get-prompt"
const getUserFlagsPath = "api/flags"
const getTopicSuggestionsPath = "api/project/topic-suggestions";
const getTopicSuggestionsTrafficPath = "api/project/topic-suggestions-traffic"

const credits = "api/credit/limit";

// user profile
export const getUserProfile = (token) =>
  axios
    .get(`${BASE_URL}/${userProfile}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// login
export const loginRequest = (user) =>
  axios
    .post(`${BASE_URL}/${login}`, user)
    .then(({ data: { token } }) => token)
    .catch((error) => Promise.reject(error.response));

// logout
export const logoutRequest = () =>
  axios
    .get(`${BASE_URL}/${logout}`)
    .then(({ data: { token } }) => token)
    .catch((error) => Promise.reject(error.response));

// register
export const registerRequest = (user) =>
  axios
    .post(`${BASE_URL}/${register}`, user)
    .then(({ status }) => status)
    .catch((error) => Promise.reject(error.response));

// multistep register
export const multistepRegisterRequest = (multistepPayload) =>
  axios
    .post(`${BASE_URL}/${multistepRegister}`, multistepPayload)
    .then(({ status }) => status)
    .catch((error) => Promise.reject(error.response));

// we need industries for registration
export const getIndustries = () =>
  axios
    .get(`${BASE_URL}/${industries}`)
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error));

// reset password
export const resetPassRequest = (data) =>
  axios
    .post(`${BASE_URL}/${reset}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// change password
export const changePassRequest = (data) =>
  axios
    .post(`${BASE_URL}/${changePass}`, data)
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// user credits
export const getUserCredits = (token) =>
    axios
        .get(`${BASE_URL}/${credits}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));

// check user token for seomonitor platforms
export const checkToken = () =>
    axios
        .get(`${BASE_URL}/${checkTokenPath}`, {
            withCredentials: true, // Include cookies in the request
        })
        .then(({data}) => data)
        .catch((error) => ({ error: 'Failed to check token' }));

// sync user for seomonitor platforms
export const syncUser = () =>
    axios
        .get(`${BASE_URL}/${userSyncPath}`, {
            withCredentials: true, // Include cookies in the request
        })
        .then(({data}) => {return data})
        .catch((error) => ({ error: 'Failed to check token' }));

// get default prompt for project
export const getProjectDefaultPrompt = (siteId, token) =>
        // .get(`${BASE_URL}/${getProjectDefaultPromptPath}`, {
        //     withCredentials: true, // Include cookies in the request
        // })
    axios
        .post(`${BASE_URL}/${getProjectDefaultPromptPath}`, {id: siteId}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({data}) => {return data.data})
        .catch((error) => ({ error: 'Failed to get default prompt' }));


// save default prompt for project
export const saveProjectDefaultPrompt = (siteId, prompt, token) =>
    axios
        .post(`${BASE_URL}/${saveProjectDefaultPromptPath}`, {id: siteId, prompt}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({data}) => {return data})
        .catch((error) => ({ error: 'Failed to save default prompt' }));

// get user flags
export const getUserFlagsRequest = (site_id, token) =>
    axios
        .post(`${BASE_URL}/${getUserFlagsPath}`, {site_id}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => ({ error: 'Failed to save default prompt' }));

export const getTopicSuggestionsRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${getTopicSuggestionsPath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => ({ error: 'Failed to get suggested topics' }));


export const getTopicSuggestionsTrafficRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${getTopicSuggestionsTrafficPath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => ({ error: 'Failed to get traffic for suggested topics' }));
