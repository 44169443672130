// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-competition-card {
  display: flex;
  flex-direction: column;
  border-top: solid 1px #D7E1EA;
  padding: 20px 10px;
  box-sizing: border-box;
  width: 100%;
}

.skeleton-competition-card-info {
  display: flex;
  align-items: center;
  width: 100%;
}

.skeleton-competition-card-circle {
  display: flex;
  align-items: center;
  padding-right: 30px;
}

.skeleton-competition-bars {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.skeleton-competition-bars-top {
  display: flex;
  justify-content: space-between;
}

.skeleton-competition-bars-bottom {
  display: flex;
  flex-direction: column;
}

.skeleton-competition-analyze {
  margin-top: 10px;
  padding-left: 78px;
}

.skeleton-competition-card .react-loading-skeleton {
  border-radius: 25px;
  line-height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SkeletonCardCompetition.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".skeleton-competition-card {\n  display: flex;\n  flex-direction: column;\n  border-top: solid 1px #D7E1EA;\n  padding: 20px 10px;\n  box-sizing: border-box;\n  width: 100%;\n}\n\n.skeleton-competition-card-info {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.skeleton-competition-card-circle {\n  display: flex;\n  align-items: center;\n  padding-right: 30px;\n}\n\n.skeleton-competition-bars {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.skeleton-competition-bars-top {\n  display: flex;\n  justify-content: space-between;\n}\n\n.skeleton-competition-bars-bottom {\n  display: flex;\n  flex-direction: column;\n}\n\n.skeleton-competition-analyze {\n  margin-top: 10px;\n  padding-left: 78px;\n}\n\n.skeleton-competition-card .react-loading-skeleton {\n  border-radius: 25px;\n  line-height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
