import React, { useCallback, useRef, useState } from 'react';
import { BubbleMenu } from '@tiptap/react';
import LinkEditorPanel from './LinkEditorPanel';
import './LinkBubbleMenu.css';

const LinkBubbleMenu = ({ editor }) => {
  const containerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);

  const shouldShow = useCallback(() => {
    if (!editor) return false;
    
    const isActive = editor.isActive('link');
    const href = editor.getAttributes('link').href;
    return isActive && href;
  }, [editor]);

  const removeLink = useCallback(() => {
    editor.chain().focus().unsetLink().run();
  }, [editor]);

  const setLink = useCallback((url, openInNewTab) => {
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url, target: openInNewTab ? '_blank' : null })
      .run();
    
    setIsEditing(false);
  }, [editor]);

  if (!editor) {
    return null;
  }

  const href = editor.getAttributes('link').href;

  return (
    <div ref={containerRef}>
      <BubbleMenu
        editor={editor}
        shouldShow={shouldShow}
        tippyOptions={{
          placement: 'bottom',
          duration: [300, 200],
          animation: 'shift-away',
          appendTo: () => containerRef.current,
        }}
      >
        {isEditing ? (
          <LinkEditorPanel 
            initialUrl={href}
            onSetLink={setLink}
            onCancel={() => setIsEditing(false)}
          />
        ) : (
          <div className="link-bubble-menu">
            <a 
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              className="link-url"
            >
              {href}
            </a>
            <div className="link-actions">
              <button 
                onClick={() => setIsEditing(true)}
                className="link-action-button edit"
                title="Edit link"
              >
                Edit
              </button>
              <button 
                onClick={removeLink}
                className="link-action-button delete"
                title="Remove link"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </BubbleMenu>
    </div>
  );
};

export default LinkBubbleMenu;
