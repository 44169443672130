import React from "react";
import CustomSvgIcon from "./CustomSvgIcon";

import "./MenuItemTop.css";

const MenuItemTop = ({
  iconLabel,
  Icon,
  icon,
  title,
  action,
  disabled,
  isActive = null,
  isDisabled = null,
  isComponent,
  Component,
}) => {
  if (isComponent) {
    // if we defined a custom component we just render it here
    return Component;
  } else {
    return (
        <button
            className={`menu-item${isDisabled && isDisabled() ? ' is-disabled' : ''} ${isActive && isActive() ? ' is-active' : ''} left-item`}
            onClick={action}
            title={title}
            disabled={disabled ?? false}
            >
          {Icon && <Icon />}
          {icon && <span dangerouslySetInnerHTML={{ __html: icon }}/>}
          {iconLabel && <span className="menu-item icon-label">{iconLabel}</span>}
        </button>
    );
  }
};

export default MenuItemTop;
