// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-keywords-table-group-loading .loading-block {
    border-radius: 12px;
}

.article-keywords-table-group-loading--row {
    display: flex;
    align-items: center;
}

.article-keywords-table-group-loading--row .flex-grow {
    flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/ArticleKeywordsTable/ArticleKeywordsTableGroupLoading.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".article-keywords-table-group-loading .loading-block {\n    border-radius: 12px;\n}\n\n.article-keywords-table-group-loading--row {\n    display: flex;\n    align-items: center;\n}\n\n.article-keywords-table-group-loading--row .flex-grow {\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
