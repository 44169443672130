// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-prompt-sidebar-sub-header {
    padding-inline: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Components/DefaultSettingsSidebar/DefaultSettingsSidebarSubHeader.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB","sourcesContent":[".default-prompt-sidebar-sub-header {\n    padding-inline: 24px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
