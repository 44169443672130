import { IN_TIME, DEADLINE_CLOSE, DEADLINE_PASSED } from "../common/consts";
import {
  differenceInHours,
  getHours,
  getMinutes,
  getSeconds,
  format,
} from "date-fns";

/* getting time from a date Object, used to display the last autosave information in editor  */
export const getTimeFromDate = (dateObject) => {
  const newDate = new Date(dateObject);
  const hours = String(getHours(newDate)).padStart(2, "0");
  const minutes = String(getMinutes(newDate)).padStart(2, "0");
  const seconds = String(getSeconds(newDate)).padStart(2, "0");

  const time = `${hours}:${minutes}:${seconds}`;

  return time;
};


// compare dates with date-fns
export const compareDates = (deadline) => {
  const difference = differenceInHours(new Date(deadline), new Date());

  if (difference < 0) {
    return DEADLINE_PASSED;
  }

  if (difference <= 24) {
    return DEADLINE_CLOSE;
  }

  return IN_TIME;
};

/* format for Safari - __ -
we need to transform the date when receiving it from server because Safari doesn't work well with this format MM-dd-yyyy */
export const formatDateForStore = (date) => new Date(date.replace(/-/g, "/"));

/* transform the date back into shape accepted by server  */
export const formatDateForServer = (dateObject) =>
  format(new Date(dateObject), "MM-dd-yyyy");

export const formatDateToYYYYMMDD = (dateObject) => {
    if (!dateObject) return null;

    const date = new Date(dateObject);
    if (isNaN(date.getTime())) return null;

    return format(date, 'yyyy-MM-dd');
};

export const shortFormatDate = (dateString) => {
  if (!dateString) return null;

  let date;

  // Check if the string is in ISO format
  if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(dateString)) {
    date = new Date(dateString); // ISO string format
  } else if (/^\d{2}-\d{2}-\d{4}$/.test(dateString)) {
    // MM-DD-YYYY format: rearrange to 'YYYY-MM-DD' for compatibility
    const [month, day, year] = dateString.split('-');
    date = new Date(`${year}-${month}-${day}`);
  } else {
    // If it's a natural language date (e.g., "Mon Oct 07 2024 00:00:00 GMT+0300"), parse it directly
    date = new Date(dateString);
  }

  // Validate the date
  if (isNaN(date.getTime())) return null;

  const currentYear = new Date().getFullYear();
  const options = date.getFullYear() === currentYear
      ? { day: 'numeric', month: 'short' } // Current year: show day and month only
      : { day: 'numeric', month: 'short', year: 'numeric' }; // Different year: show day, month, and year

  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

export const formatPublishDate = (dateString) => {
  if (!dateString) return null;

  const date = new Date(dateString);
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Check if the date is more than 12 months old
  const twelveMonthsAgo = new Date();
  twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);

  if (date < twelveMonthsAgo) {
    return `${month} ${day}, ${year}`;
  }

  return `${month} ${day}`;
};
