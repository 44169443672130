// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-content-info__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
}

.article-actions {
    display: flex;
    align-items: center;
    gap: 6px;
    flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/EditorContentInfo/EditorContentInfoHeader/EditorContentInfoHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,YAAY;AAChB","sourcesContent":[".editor-content-info__header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 8px 0;\n}\n\n.article-actions {\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
