// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-article-modal {
    width: 100%;
    max-width: 960px;
    background: var(--default-surfaces-base);
    border-radius: 12px;
    padding: 36px 36px 12px;
    z-index: 1;
    position: relative;
    margin: auto;
}

.new-article-modal hr {
    margin: 24px 0;
    border-color: var(--default-surfaces-light, rgba(23, 23, 23, 0.05));
    border-width: 1px 0 0 0;
}
.new-article-modal__content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
}
.modal__content--left {
    padding-right: 16px;
    max-width: 420px;
    width: 100%;
}
.new-article-modal__content.new-article-modal__content--new-user > * {
    max-width: 50%;
    width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewArticleModal/NewArticleBox.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,wCAAwC;IACxC,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,mEAAmE;IACnE,uBAAuB;AAC3B;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;AACf;AACA;IACI,cAAc;IACd,UAAU;AACd","sourcesContent":[".new-article-modal {\n    width: 100%;\n    max-width: 960px;\n    background: var(--default-surfaces-base);\n    border-radius: 12px;\n    padding: 36px 36px 12px;\n    z-index: 1;\n    position: relative;\n    margin: auto;\n}\n\n.new-article-modal hr {\n    margin: 24px 0;\n    border-color: var(--default-surfaces-light, rgba(23, 23, 23, 0.05));\n    border-width: 1px 0 0 0;\n}\n.new-article-modal__content {\n    display: flex;\n    align-items: flex-start;\n    margin-bottom: 16px;\n}\n.modal__content--left {\n    padding-right: 16px;\n    max-width: 420px;\n    width: 100%;\n}\n.new-article-modal__content.new-article-modal__content--new-user > * {\n    max-width: 50%;\n    width: 50%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
