import React, { useRef, useState, useCallback } from "react";
import { PenIcon, PlusCircle } from "../../UI_utils/SVGIcons";
import Input from "../../Shared/Input";

const ArticleUrlInput = ({
     initialUrl = "",
     onSubmit,
     loading = false,
     error = "",
     setError,
     disabled = false,
     onChange,
 }) => {
    const [url, setUrl] = useState(initialUrl);
    const [showEditIcon, setShowEditIcon] = useState(!!initialUrl);
    const inputRef = useRef(null);

    const handleIconToShow = useCallback(() => (showEditIcon ? PenIcon : PlusCircle), [showEditIcon]);

    const handleInputChange = useCallback((newValue) => {
        setUrl(newValue);
        setError("");
        if(onChange) onChange(newValue);
    }, [setError, onChange]);

    const iconClickHandler = useCallback((event) => {
        if (showEditIcon) {
            setShowEditIcon(false);
            setTimeout(() => inputRef.current?.focus());
            return;
        }
        onSubmit(url);
    }, [showEditIcon, url, onSubmit]);

    return (
        <Input
            ref={inputRef}
            type="text"
            element="input"
            label="Article URL"
            placeholder="E.g. https://website.com/article-sample"
            inputValue={url}
            showIcon={!error}
            loading={loading}
            IconToShow={handleIconToShow()}
            disabled={disabled || showEditIcon}
            error={error}
            onChange={handleInputChange}
            onEnter={() => onSubmit(url)}
            iconClick={iconClickHandler}
        />
    );
};

export default ArticleUrlInput;
