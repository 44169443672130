import React, {useEffect, useState} from "react";
import "./ArticleProgressBox.css";
// import {
//     getOutlineProgressStatus
// } from "../../reducers/articles";
import {useDispatch, useSelector} from "react-redux";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {CheckIcon, CountdownIcon, SpinnerIcon, WriterIcon} from "../../UI_utils/SVGIcons";
import LoadingBlock from '../LoadingBlock/LoadingBlock';
import {
    getArticlePrevProgressStatus, getGenerateImages,
    getOutlinePrevProgressStatus,
    setArticlePrevProgressStatus, setOutlinePrevProgressStatus
} from "../../reducers/articles";
import {getUserHasImageAccess} from "../../reducers/user";
import {getMaxImages} from "../../reducers/images";
const ArticleProgressBox = ({outlineProgressStatus, isOutline, articleProgressStatus, completedHeadings, totalHeadings, articleCompilationStatus, selectedTypeOption}) => {
    const dispatch = useDispatch();
    const imageAccess = useSelector(getUserHasImageAccess);
    const generateImages = useSelector(getGenerateImages);
    const maxImages = useSelector(getMaxImages);

    const outlineSteps = () => [
        { name: 'Analyzing top 10 ranking articles', time: 90 },
        { name: 'Identifying keywords within the topic', time: 123 },
        { name: 'Generating brief and outline', time: 5 },
        { name: 'Calculating score', time: 80 },
        // { name: 'Generating outline content', time: 60 },
    ]

    const SECONDS_PER_HEADING = 20;

    const articleSteps = () => {
        const steps = [
            {
                name: `Crafting sections (${completedHeadings}/${totalHeadings}) by building content for each heading`,
                time: SECONDS_PER_HEADING * totalHeadings
            },
            {name: 'Assembling full draft into a cohesive article', time: 60},
            {name: 'Enhancing language with refined style and improved readability', time: 60},
            {name: 'Optimizing structure for perfect flow and transitions', time: 60},
            {name: 'Processing and integrating internal links', time: 60},
            {name: 'Polishing intro & conclusion to strengthen impact', time: 60},
            {name: 'Performing final review for quality and consistency', time: 60}
        ]

        if(imageAccess && generateImages) {
            steps.push({
                name: 'Acquiring visuals through AI generation and selective importing',
                time: 20 * maxImages
            })
        }

        return steps;
    }

    const returnSteps = () => isOutline ? outlineSteps() : articleSteps();

    const outlineTimeExplanation = 'To create the most accurate outline we analyze top 10 articles in SERP, single out the most relevant keywords, calculate search volumes and organize them by positioning in title, headings and text to make your article effective.';
    const articleTimeExplanation = 'Not only do we generate articles utilizing AI, but we also incorporate an additional step to verify the text for potential duplications and inaccuracies. This meticulous process ensures the creation of cohesive, impactful article when this process is completed.';

    const whyItTakesTime = () => isOutline ? outlineTimeExplanation : articleTimeExplanation;

    const outlinePrevProgressStatus = useSelector(getOutlinePrevProgressStatus);
    const articlePrevProgressStatus = useSelector(getArticlePrevProgressStatus);

    const activePrevProgressStatus = () => isOutline ?
        outlinePrevProgressStatus === 4 ?
            3 : outlinePrevProgressStatus
        : articlePrevProgressStatus;

    // Calculate total time based on step times
    const totalTime = returnSteps().reduce((acc, step) => acc + step.time, 0);

    // Function to calculate remaining time based on active step
    const remainingSeconds = () => {
        try {
             let seconds = 0;
            for (let i = activePrevProgressStatus(); i < returnSteps().length; i++) {
                seconds += returnSteps()[i].time;
            }

            if(!isOutline && activePrevProgressStatus() === 0) seconds = seconds - (completedHeadings * SECONDS_PER_HEADING)

            return Math.max(seconds, 0); // Ensure positive value
        } catch (e) {
            console.log(e)
        }
       
    };

    // Remaining time to display
    const progressScore = () => Math.round((totalTime - remainingSeconds()) * 100 / totalTime) || 5;

    // Function to format remaining time
    const formatTime = () => {
        const seconds = remainingSeconds();
        if(isNaN(seconds)) return `0`
        if (seconds >= 60) {
            const minutes = Math.ceil(seconds / 60);
            return `${minutes} minute${minutes > 1 ? 's' : ''}`;
        } else {
            return `${Math.ceil(seconds)} seconds`;
        }
    };

    const returnStateId = (index) => {
        if(activePrevProgressStatus() < index ) return 0
        if(activePrevProgressStatus() === index) return 1
        if(activePrevProgressStatus() > index ) return 2
    }

    const returnStateClass = (index) => {
        const stateId = returnStateId(index);
        if(stateId === 0) return 'progress-step--loading'

        if(stateId === 1) return 'progress-step--active'

        if(stateId === 2) return 'progress-step--loaded'
    }

    // Check for jumps in steps and update previous progress status
    useEffect(() => {
        if(outlineProgressStatus !== null) {
            if (outlineProgressStatus > outlinePrevProgressStatus) {
                const interval = setInterval(() => {
                    const nextStep = outlinePrevProgressStatus + 1;
                    if (nextStep <= outlineProgressStatus) {
                        dispatch(setOutlinePrevProgressStatus(nextStep))
                    } else {
                        clearInterval(interval);
                    }
                }, 3000);
                return () => clearInterval(interval);
            }
            dispatch(setOutlinePrevProgressStatus(outlineProgressStatus))
        }
    }, [outlineProgressStatus, outlinePrevProgressStatus]);

    useEffect(() => {
        if(!articleProgressStatus) {
            if(completedHeadings == totalHeadings) {
                if(articlePrevProgressStatus === 0) dispatch(setArticlePrevProgressStatus(1));
                // if(articleCompilationStatus < 6) {
                //     const nextStep = articlePrevProgressStatus + 1
                //     dispatch(setArticlePrevProgressStatus(nextStep));
                // }


                if (articleCompilationStatus > articlePrevProgressStatus) {
                    const interval = setInterval(() => {
                        const nextStep = articlePrevProgressStatus + 1;
                        if (nextStep <= articleCompilationStatus) {
                            dispatch(setArticlePrevProgressStatus(nextStep))
                        } else {
                            clearInterval(interval);
                        }
                    }, 3000);
                    return () => clearInterval(interval);
                }
            } else {
                dispatch(setArticlePrevProgressStatus(0));
            }
        }
    }, [articleProgressStatus, articleCompilationStatus, completedHeadings, totalHeadings]);

    if (
        (
            isOutline && (
                outlineProgressStatus === null ||
                outlineProgressStatus === -1 ||
                outlineProgressStatus > 4
            )
        ) || (
            !isOutline && (// is article
                completedHeadings === null || ( //article was never generated
                    articleProgressStatus && completedHeadings == totalHeadings && articleCompilationStatus > 6 //article was generated and completed
                ) ||
                articleProgressStatus ||
                selectedTypeOption === 'new' ||
                selectedTypeOption === 'current'
            )
        )
    ) return (<div></div>)

    return (
        <div className="article-progress-box">
            <div className="article-progress-box-pie">
                <div className="progress-pie__wrapper">
                    <div className="progress-pie">
                        <CircularProgressbarWithChildren
                            value={!!remainingSeconds() && progressScore()}
                            strokeWidth={16}
                            styles={buildStyles({
                                pathTransitionDuration: 0.5,
                                pathColor: isOutline? '#745ECA' : 'rgba(64, 226, 70, 1)',
                                strokeLinecap: 'butt',
                                backgroundColor: 'transparent',
                            })} />
                    </div>
                </div>
                {!Math.ceil(remainingSeconds()) ?
                <div>
                    <LoadingBlock height={'24px'} width={'164px'}/>
                    <div>
                        <LoadingBlock margin={'4px 0 0 0'} height={'18px'} width={'130px'}/>
                    </div>
                </div>
                :  <div className="progress-time-remaining">
                        <div className="counter">{formatTime()}</div>
                        <div className="counter-label">Estimated time left</div>
                    </div>
                }
            </div>
            <div className="article-progress-box-steps">
                {returnSteps().map((step, index) => (
                    <div
                        key={`progress-step-${index}`}
                        className={`progress-step ${returnStateClass(index)} ${isOutline ? 'outline-check' :'article-check'}`}
                    >
                        <div className="progress-step-icon">
                            {returnStateId(index) === 2 && <CheckIcon fill={isOutline ? '#745ECA' : 'rgba(64, 226, 70, 1)' } />}
                            {returnStateId(index) === 1 && <SpinnerIcon />}
                            {returnStateId(index) === 0 && <CountdownIcon />}
                        </div>
                        <div className="progress-step-description">{step.name}</div>

                    </div>
                ))}
            </div>
            {
                <div className="article-progress-box-icon">
                    <WriterIcon/>
                </div>
            }
        </div>
    );
};

export default ArticleProgressBox;
