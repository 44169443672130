// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-head-competition {
  display: flex;
  flex-direction: column;
  padding: 10px 0 30px;
}

.skeleton-head-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.skeletion-head-results {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 15px;
}

.skeletion-head-details {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.skeleton-head-competition .react-loading-skeleton {
  border-radius: 10px;
  line-height: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SkeletonHeadCompetition.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;EACnB,cAAc;AAChB","sourcesContent":[".skeleton-head-competition {\n  display: flex;\n  flex-direction: column;\n  padding: 10px 0 30px;\n}\n\n.skeleton-head-top {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 100%;\n}\n\n.skeletion-head-results {\n  display: flex;\n  width: 100%;\n  justify-content: space-evenly;\n  margin-top: 15px;\n}\n\n.skeletion-head-details {\n  display: flex;\n  width: 100%;\n  justify-content: space-evenly;\n}\n\n.skeleton-head-competition .react-loading-skeleton {\n  border-radius: 10px;\n  line-height: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
