import React, { Fragment, useEffect, useMemo, useState, useCallback } from "react";
import MenuItemTop from "./MenuItemTop";

import "./EditorMenuTop.css";

import ImageUploader from "./ImageUploader";
import Hyperlink from "./Hyperlink";
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import { getImageLimitError, setLimitImagesError } from "../../reducers/images";
import { menuIconsLeft, menuIconsRight } from './EditorMenuTopIcons';
const EditorMenuTop = ({ editor, writerMode }) => {
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState('');
    const imageLimitError = useSelector(getImageLimitError);

    useEffect(() => {
        if (imageLimitError) {
            setIsOpen(true);
            setError(imageLimitError);
        }
    }, [imageLimitError]);

    const items = useMemo(
        () => [
            // {
            //     icon: "h-1",
            //     title: "Heading 1",
            //     action: () => true,
            //     isActive: () => editor && editor.isActive("heading", { level: 1 }),
            //     disabled: true
            // },
            {
                icon: menuIconsLeft.h2,
                title: 'Heading 2',
                action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
                isActive: () => editor && editor.isActive('heading', { level: 2 }),
            },
            {
                icon: menuIconsLeft.h3,
                title: 'Heading 3',
                action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
                isActive: () => editor && editor.isActive('heading', { level: 3 }),
            },
            {
                icon: menuIconsLeft.h4,
                title: 'Heading 4',
                action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
                isActive: () => editor && editor.isActive('heading', { level: 4 }),
            },
            // {
            //   icon: "h-5",
            //   title: "Heading 5",
            //   action: () => editor.chain().focus().toggleHeading({ level: 5 }).run(),
            //   isActive: () => editor && editor.isActive("heading", { level: 5 }),
            // },
            {
                icon: menuIconsLeft.paragraph,
                title: 'Paragraph',
                action: () => editor.chain().focus().setParagraph().run(),
                isActive: () => editor && editor.isActive('paragraph'),
            },
            // {
            //   type: "divider",
            // },
            {
                icon: menuIconsLeft.bold,
                title: 'Bold',
                action: () => editor.chain().focus().toggleBold().run(),
                isActive: () => editor && editor.isActive('bold'),
            },
            {
                icon: menuIconsLeft.italics,
                title: 'Italic',
                action: () => editor.chain().focus().toggleItalic().run(),
                isActive: () => editor && editor.isActive('italic'),
            },

            {
                icon: menuIconsLeft.image,
                title: 'Add Image',
                isComponent: true,
                Component: (
                  <ImageUploader
                      icon={menuIconsLeft.image}
                      title="Add Image"
                      onUploadProgres={setProgress}
                      onUploadError={setError}
                      onUploadStart={() => setIsOpen(true)}
                      onUploadSuccess={() => setIsOpen(false)}
                  />
                ),
            },
            {
                icon: menuIconsLeft.link,
                title: 'Link',
                  isComponent: true,
                  Component: <Hyperlink icon={menuIconsLeft.link} title="Link" />,
            },
            // {
            //   type: "divider",
            // },
            // {
            {
                icon: menuIconsLeft.lefAlign,
                title: 'Align Left',
                action: () => editor.chain().focus().setTextAlign('left').run(),
                isActive: () => editor && editor.isActive({ textAlign: 'left' }),
            },
            {
                icon: menuIconsLeft.centerAlign,
                title: 'Align Center',
                action: () => editor.chain().focus().setTextAlign('center').run(),
                isActive: () => editor && editor.isActive({ textAlign: 'center' }),
            },
            {
                icon: menuIconsLeft.rightAlign,
                title: 'Align Right',
                action: () => editor.chain().focus().setTextAlign('right').run(),
                isActive: () => editor && editor.isActive({ textAlign: 'right' }),
            },
            {
                icon: menuIconsLeft.justifyAlign,
                title: 'Align Justified',
                action: () => editor.chain().focus().setTextAlign('justify').run(),
                isActive: () => editor && editor.isActive({ textAlign: 'justify' }),
            },
            {
                icon: menuIconsLeft.unOrdered,
                title: 'Bullet List',
                action: () => editor.chain().focus().toggleBulletList().run(),
                isActive: () => editor && editor.isActive('bulletList'),
            },
            {
                icon: menuIconsLeft.ordered,
                title: 'Ordered List',
                action: () => editor.chain().focus().toggleOrderedList().run(),
                isActive: () => editor && editor.isActive('orderedList'),
            },
            // {
            //   icon: "list-check-2",
            //   title: "Task List",
            //   action: () => editor.chain().focus().toggleTaskList().run(),
            //   isActive: () => editor && editor.isActive("taskList"),
            // },
            {
                icon: menuIconsLeft.codeBlock,
                title: 'Code Block',
                action: () => editor.chain().focus().toggleCodeBlock().run(),
                isActive: () => editor && editor.isActive('codeBlock'),
            },
            // {
            //   type: "divider",
            // },
            // {
            //   icon: "double-quotes-l",
            //   title: "Blockquote",
            //   action: () => editor.chain().focus().toggleBlockquote().run(),
            //   isActive: () => editor && editor.isActive("blockquote"),
            // },
            {
                icon: menuIconsLeft.separator,
                title: 'Horizontal Rule',
                action: () => editor && editor.chain().focus().setHorizontalRule().run(),
            },
            // {
            //   type: "divider",
            // },
            {
                icon: menuIconsRight.reverse,
                title: 'Undo',
                action: () => editor && editor.chain().focus().undo().run(),
            },
            {
                icon: menuIconsRight.foward,
                title: 'Redo',
                action: () => editor && editor.chain().focus().redo().run(),
            },
        ],
        [editor]
    );

    const onClosePopup = () => {
        dispatch(setLimitImagesError(null));
        setError('');
        setIsOpen(false);
    };

    return (
        <>
            <div className="editor__header">
                {writerMode === 'article' && (
                    <div className="edit__header-left-container">
                        <div className="editor__header-left">
                            {items.map(
                                (item) =>
                                    ['Heading 2', 'Heading 3', 'Heading 4', 'Paragraph'].includes(item.title) && (
                                        <MenuItemTop {...item} key={item.title} />
                                    )
                            )}
                        </div>
                        <div className="editor__header-left">
                            {items.map(
                                (item) => ['Bold', 'Italic', 'Add Image', 'Link'].includes(item.title) && <MenuItemTop {...item} key={item.title} />
                            )}
                        </div>
                        <div className="editor__header-left">
                            {items.map(
                                (item) =>
                                    ['Align Left', 'Align Center', 'Align Right', 'Align Justified'].includes(item.title) && (
                                        <MenuItemTop {...item} key={item.title} />
                                    )
                            )}
                        </div>
                        <div className="editor__header-left">
                            {items.map(
                                (item) =>
                                    ['Ordered List', 'Bullet List', 'Code Block', 'Horizontal Rule'].includes(item.title) && (
                                        <MenuItemTop {...item} key={item.title} />
                                    )
                            )}
                        </div>
                    </div>
                )}

                {writerMode === 'outline' && (
                    <div className="edit__header-left-container">
                        <div className="editor__header-left">
                            {items.map(
                                (item) =>
                                    ['Heading 2', 'Heading 3', 'Heading 4', 'Paragraph'].includes(item.title) && (
                                        <MenuItemTop {...item} key={item.title} />
                                    )
                            )}
                        </div>
                        <div className="editor__header-left">
                            {items.map(
                                (item) =>
                                    ['Ordered List', 'Bullet List'].includes(item.title) && (
                                        <MenuItemTop {...item} key={item.title} />
                                    )
                            )}
                        </div>
                    </div>
                )}

                <div className="editor__header-right">
                    {items.map((item, index) => ['Undo', 'Redo'].includes(item.title) &&
                        <MenuItemTop {...item} key={item.title}/>)}
                </div>
            </div>
            <Popup open={isOpen} onClose={() => onClosePopup()} closeOnDocumentClick={true}>
                <div className="upload-progress-wrapper">
                    {!error && <p className="upload-progress-message">UPLOADING IMAGE...</p>}
                    {error && <p className="upload-progress-error">{error}</p>}
                    {!imageLimitError && (
                        <div className="upload-progress-bar">
                            <div
                                style={{
                                    width: `${progress}%`,
                                    backgroundColor: `#afe9cb`,
                                    height: '14px',
                                    borderRadius: '9px',
                                }}></div>
                        </div>
                    )}
                </div>
            </Popup>
        </>
    );
};

export default EditorMenuTop;
