/** @format */

import React, {useEffect, useRef, useState} from 'react';
import {useEditor, EditorContent} from '@tiptap/react';
import { TEXTS } from "../../common/consts";
import Document from '@tiptap/extension-document';
import DragHandle from '@tiptap-pro/extension-drag-handle';
import StarterKit from '@tiptap/starter-kit';
import CharacterCount from '@tiptap/extension-character-count';
import Placeholder from '@tiptap/extension-placeholder';
import Image from '@tiptap/extension-image';
import {Collaboration} from "@tiptap/extension-collaboration";
import {CollaborationCursor} from '@tiptap/extension-collaboration-cursor'
import * as Y from 'yjs';
import { TiptapCollabProvider } from '@hocuspocus/provider';
// we use our custom extension from extensions folder
import { Link } from './extensions/link/CustomLink';
import { CustomImage } from './extensions/image/CustomImage';
import TextAlign from '@tiptap/extension-text-align';
// import Underline from '@tiptap/extension-underline';
import TextStyle from '@tiptap/extension-text-style';
// import { Color } from '@tiptap/extension-color';
// import Highlight from '@tiptap/extension-highlight';
import { BubbleMenu } from '@tiptap/extension-bubble-menu';
import { debounce } from 'lodash';
import './TipTapEditor.css';
import { getParsedData, resetEditorData, saveEditorDataToStore, setEditorInstance } from '../../reducers/textEditor';
import { setHighTopicCoverage, setLowTopicCoverage, setTopicCoverageData, updateMatchingWords } from '../../reducers/topicCoverage';
import { useDispatch, useSelector } from 'react-redux';
import { setWordCount } from '../../reducers/counters';
import { DEFAULT_ARTICLE_ID } from '../../common/consts';
import {
    getCurrentArticle,
    getCurrentArticleId,
    saveArticle,
    setArticle,
    setArticleId,
    setArticlesCollection,
    contentArticleData,
    outlineData,
    getArticleEditorLoadingState,
    getOutlineEditorLoadingState,
    saveOutlineToDB,
    getNewTitleForOutline,
    getOutlineData,
    getOutlineProgressStatus,
    setArticleAltered,
    checkIfArticleIsEditable,
    getArticleJustFinishedGenerating,
    getArticleCompleted,
    getArticleCompilationStatus,
    getCompletedHeadings,
    getTotalHeadings,
    getCurrentWebsiteArticle,
    getLastGeneratedArticle,
    getLoadingCurrentWebsiteArticle,
    getContentArticleData,
    applyWriterMode,
    getRegenerateCount,
    getArticleScore,
    getOutlineScore,
    getGeneratedScore,
    getDefaultTabSelected, contentArticleDataIsEmpty, getCheckingLiveUrl
} from '../../reducers/articles';
import { isDemoApplication } from '../../reducers/applicationMode';
import { checkTitleOptimization } from '../../reducers/titleOptimization';
import { checkHeadingsOptimization } from '../../reducers/headingsOptimization';
import { checkTopicDensity } from '../../reducers/topicDensity';
import { checkCurrentImages, transformContentOnPaste } from '../../reducers/images';

import useUnmount from '../../utils/useUnmount';
import { useApplicationType } from '../../utils/useApplicationType';
import EditorMenuTop from './EditorMenuTop';
import { loadingStep2Data } from '../../reducers/searchTopic';
import { useLocation } from 'react-router-dom';
import EditorHeader from './EditorHeader';
import AiCard from './AiCard/AiCard';
import {
    DraftIcon,
    DragIcon,
    RefreshIcon,
    RobotIcon,
    UserIcon,
    WriterMagicPen,
    WrittenFileIcon
} from '../../UI_utils/SVGIcons';
import Scrollbars from 'react-custom-scrollbars';
import ArticleProgressBox from './ArticleProgressBox';
import {getGlobalState} from "../../globals";
import EditorContentInfo from "./EditorContentInfo/EditorContentInfo";
import {getUserHasWritingStyles} from "../../reducers/user";
import ArticleLoadingBlocks from "./ArticleLoadingBlocks";
import FeedbackBox from "./FeedbackBox";
import EditorSwitcher from "./EditorSwitcher";
import { EditorMenuWrapper } from './EditorMenuWrapper';
import MenuManager from './MenuManager';
import { DragHandleContainer } from './DragHandleContainer';
import { TextMenuContainer } from './TextMenuContainer';
import { Ai, getHTMLContentBetween } from '@tiptap-pro/extension-ai';
import LinkBubbleMenuContainer from './LinkBubbleMenuContainer';

const CustomDocument = Document.extend({
    content: 'heading block*',
});

const parseEditorData = debounce((dispatch, data) => {
    dispatch(saveEditorDataToStore(data));
}, 500);

const trackTopicCoverage = debounce((dispatch) => {
    dispatch(updateMatchingWords());
}, 600);

const sendDataToServer = debounce((dispatch, articleType) => {
    if(!articleType.viewOnly) {
        dispatch(saveArticle(articleType.type, 'article'));
    }
}, 500);

const sendOutlineDataToServer = debounce((dispatch, outline) => {
    dispatch(saveOutlineToDB(outline));
}, 500);

const trackTitle = debounce((dispatch) => {
    dispatch(checkTitleOptimization());
}, 900);

const trackHeadings = debounce((dispatch) => {
    dispatch(checkHeadingsOptimization());
}, 2800);

const trackTopicDensity = debounce((dispatch) => {
    dispatch(checkTopicDensity());
}, 2900);

const trackImages = debounce((dispatch) => {
    dispatch(checkCurrentImages());
}, 3000);

const TipTapEditor2 = ({ writerMode, switchToArticle, firstLoad, setShowPublishModal, tiptapToken, tiptapAIToken, documentName, initialContent }) => {
    const articleScore = useSelector(getArticleScore);
    const outlineScore = useSelector(getOutlineScore);
    const generatedScore = useSelector(getGeneratedScore);

    const dispatch = useDispatch();
    const location = useLocation();
    const isDemoApp = useSelector(isDemoApplication);
    const styleAccess = useSelector(getUserHasWritingStyles)
    const currentArticleId = useSelector(getCurrentArticleId);
    const outlineProgressStatus = useSelector(getOutlineProgressStatus);
    const articleEditorIsEditable = useSelector(checkIfArticleIsEditable);
    const articleJustFinishedGenerating = useSelector(getArticleJustFinishedGenerating);

    const applicationType = useApplicationType();
    const articleLoading = useSelector(getArticleEditorLoadingState);
    const outlineLoading = useSelector(getOutlineEditorLoadingState);
    const articleData = useSelector(contentArticleData);
    const outline = useSelector(outlineData);
    const hasSharedToken = getGlobalState('sharedToken');

    const parsedData = useSelector(getParsedData);

    const lastGeneratedArticle = useSelector(getLastGeneratedArticle);
    const currentWebsiteArticle = useSelector(getCurrentWebsiteArticle);
    const loadingCurrentWebsiteArticle = useSelector(getLoadingCurrentWebsiteArticle);
    const regenerateCount = useSelector(getRegenerateCount);
    const defaultTabSelected = useSelector(getDefaultTabSelected);
    const checkingLiveUrl = useSelector(getCheckingLiveUrl);

    const [firstTiptapLoad, setFirstTiptapLoaded] = React.useState(true);
    const [firstDraftTiptapLoad, setFirstDraftTiptapLoaded] = React.useState(true);
    const [firstOutlineTiptapLoad, setFirstOutlineTiptapLoaded] = React.useState(true);
    const [outlineSynced, setOutlineSynced] = React.useState(false);
    const [draftSynced, setDraftSynced] = React.useState(false);
    const [isOutline, setIsOutline] = React.useState(false);
    const [headingIsEmpty, setHeadingIsEmpty] = React.useState(true);
    const [generating, setGenerating] = React.useState(false);
    const [aiMode, setAiMode] = React.useState({ label: 'Open AI', value: 'openai' });
    const [selectedTestPrompt, setSelectedTestPrompt] = React.useState({ label: 'Default', value: false });
    const [selectedTypeOption, setSelectedTypeOption] = useState(defaultTabSelected)
    const [typeOptions, setTypeOptions] = useState([]);
    const [newTypeOptions, setNewTypeOptions] = useState([]);
    const [activeConfigurationStep, setActiveConfigurationStep] = useState(0)
    const [promptType, setPromptType] = useState('article')

    const articleCompilationStatus = useSelector(getArticleCompilationStatus);
    const articleCompleted = useSelector(getArticleCompleted);
    const completedHeadings = useSelector(getCompletedHeadings);
    const totalHeadings = useSelector(getTotalHeadings);

    const draftIsEmpty = useSelector(contentArticleDataIsEmpty);

    const editorLoading = () => {
        return writerMode === 'outline' ? outlineLoading : selectedTypeOption === 'generated' && articleLoading;
    };

    useEffect(() => {
        const outlineTooltip = TEXTS.OUTLINE_TAB_TOOLTOP;
        const generatedTooltip = TEXTS.GENERATED_TAB_TOOLTOP;
        const draftTooltip = TEXTS.DRAFT_TAB_TOOLTOP;

        const loading = outlineLoading || articleLoading;

        // const newDefaultTabs = [
        //     {label: 'Outline', key: 'outline', icon: WrittenFileIcon, score: outlineScore, tooltip: outlineTooltip},
        //     {label: 'Working Draft', key:'new', icon: DraftIcon, score: articleScore, tooltip: draftTooltip, disabled: (outlineLoading && draftIsEmpty) || articleLoading}
        // ];
        const newGeneratedTabs = [
            {label: 'Outline', key: 'outline', icon: WrittenFileIcon, score: outlineScore, tooltip: outlineTooltip},
            {label: 'AI Version', key: 'generated', tooltip: generatedTooltip, icon: WriterMagicPen, score: generatedScore},
            {label: 'Working Draft', key:'new', icon: DraftIcon, score: articleScore, tooltip: draftTooltip, disabled: (outlineLoading && draftIsEmpty) || articleLoading}
        ];

        // if(articleCompleted === null) {
        //     setNewTypeOptions(newDefaultTabs);
        // } else {
            setNewTypeOptions(newGeneratedTabs);
        // }
    }, [lastGeneratedArticle, articleScore, outlineScore, generatedScore, articleCompleted, outlineLoading, articleLoading]);

    useEffect(() => {
        if(defaultTabSelected !== selectedTypeOption) {
            setSelectedTypeOption(defaultTabSelected)
            dispatch(applyWriterMode(defaultTabSelected === 'outline' ? 'outline' : 'article'))
        }
    }, [defaultTabSelected])

    // ate a random user color
    const getRandomColor = () => {
        const colors = ['#ffa500', '#ff0000', '#0000ff', '#008000', '#800080']
        return colors[Math.floor(Math.random() * colors.length)]
    }
    // Collaborative editing setup
    const doc = useRef(new Y.Doc()).current;
    const [provider, setProvider] = useState(null);
    const previousMode = useRef(writerMode);
    const showUncompiledArticleRef = useRef(false);

    useEffect(() => {
        showUncompiledArticleRef.current = false;
    }, []);

    useEffect(() => {
        if (previousMode.current !== writerMode) {
            try {
                // Clean up menus before mode change
                MenuManager.cleanup();
            } catch (e) {
                console.warn('Error during menu cleanup on mode change:', e);
            }
            
            if (provider) {
                provider.destroy();
            }
            previousMode.current = writerMode;
        }

        const documentName = `document-${currentArticleId}-${writerMode}`;

        const newProvider = new TiptapCollabProvider({
            name: documentName,
            appId: 'XK2O4YGM',
            token: tiptapToken,
            document: doc,
            onSynced: () => {

                const tiptapContent = editor?.getHTML();
                const content = writerMode === 'outline' ? outline : articleData;

                if(writerMode === 'outline' && !outlineSynced) {
                    setOutlineSynced(true);
                }else if(writerMode !== 'outline' && !draftSynced) {
                    setDraftSynced(true);
                }
                editor?.commands.setContent(content)
            },
            onMessage: () => {
              // console.log('onMessage outline => ', outline)
            },
            onConnect: () => {
                const awareness = newProvider.awareness;
                awareness.setLocalStateField('user', {
                    name: 'User',
                    color: '#' + Math.floor(Math.random()*16777215).toString(16),
                });

                // Only set content if we're the first user
//                 if (awareness.getStates().size <= 1) {
//                     const content = writerMode === 'outline' ? outline : articleData;
// console.log('connect content => ', content)
//                     if (content) {
//                         if(!firstTiptapLoad) {
//                             // if(writerMode === 'outline' && outlineSynced) {
//                             //     editor?.commands.setContent(content);
//                             // }else if(writerMode !== 'outline' && draftSynced) {
//                             //     editor?.commands.setContent(content);
//                             // }
//                         }
//                         // if(!firstDraftTiptapLoad && writerMode !== 'outline') editor?.commands.setContent(articleData);
//                         // if(!firstOutlineTiptapLoad && writerMode !== 'outline') editor?.commands.setContent(outline);
//                     }
//                 }
            },
            onDisconnect: () => {
                console.log('disconnect')

            },
            onError: (error) => {
                console.error('Error for:', documentName, error);
            }
        });

        // Add observer for Y.js document changes
        const observer = () => {
            if (editor) {
                // Force a ProseMirror state update
                const state = editor.state;
                const transaction = state.tr;
                editor.view.dispatch(transaction);
                
                dispatch(setEditorInstance(editor));

                // Trigger your normal update handlers
                if (editor.isEditable) {
                    trackDataDetails(editor);
                }
            }
        };

        // Observe changes to the document
        doc.on('update', observer);

        setProvider(newProvider);

        // Cleanup on unmount or mode change
        return () => {
            if (newProvider) {
                newProvider.destroy();
            }
            // Remove the observer
            doc.off('update', observer);
        };
    }, [writerMode, currentArticleId, tiptapToken, articleLoading, outlineLoading]);

    // Cleanup on unmount
    useUnmount(() => {
        dispatch(setArticle(null));
        dispatch(setArticleId(null));
        dispatch(setArticlesCollection([]));
        dispatch(setEditorInstance(null));
        dispatch(setTopicCoverageData([]));
        dispatch(setHighTopicCoverage([]));
        dispatch(setLowTopicCoverage([]));
        if (editor) {
            editor.destroy();
        }
        if (provider) {
            provider.destroy();
        }
        if (doc) {
            doc.destroy();
        }
    });

    const isOutlineEditorDisabled = () => {
        if (isOutline && articleCompleted === false && parseInt(completedHeadings) != totalHeadings) {
            return false;
        }
        return true;
    };

    const isEditorEditable = () => {
        const isOutline = writerMode === 'outline';
        const isArticle = writerMode === 'article';

        if (isOutline) {
            return isOutlineEditorDisabled() && articleEditorIsEditable;
        } else if(isArticle && selectedTypeOption === 'new'){
            return articleEditorIsEditable;
        }
    };


    const AiExtended = Ai.extend({
        addCommands() {
            return {
                ...this.parent?.(),

                aiCustomTextCommand:
                    (attributes) =>
                        ({ editor, state }) => {
                            const { from, to } = state.selection
                            let selectedText = getHTMLContentBetween(editor, from, to)
                            const prompt = attributes?.prompt

                            // If the selection includes a ul/ol tag, extract only the list items
                            if (selectedText.startsWith('<ul>') || selectedText.startsWith('<ol>')) {
                                const tempDiv = document.createElement('div')
                                tempDiv.innerHTML = selectedText
                                const listItems = tempDiv.querySelectorAll('li')
                                selectedText = Array.from(listItems)
                                    .map(li => li.outerHTML)
                                    .join('')
                            }

                            return editor.commands.aiTextPrompt({
                                text: `${prompt}: ${selectedText}`,
                                collapseToEnd: true,
                                format: "rich-text",
                                html: true,
                                modelName: 'gpt-4o',
                                stream:true
                            })
                        },
            }
        },
    })
    const editor = useEditor({
        editorProps: {
            transformPastedHTML: (html) => dispatch(transformContentOnPaste(html)),
        },
        editable: isEditorEditable(),
        extensions: [
            Collaboration.configure({
                document: doc,
            }),
            StarterKit.configure({
                document: false, // Disable the default Document extension
                bold: true,
                italic: true,
                strike: true,
                code: true,
            }),
            CustomDocument,
            BubbleMenu.configure({
                updateDelay: 0,
            }),
            CharacterCount,
            CustomImage,
            Link.configure({
                openOnClick: false,
                linkOnPaste: true,
                HTMLAttributes: {
                    target: '_blank',
                    rel: 'canonical',
                    class: null,
                },
            }),
            Placeholder.configure({
                placeholder: ({ node }) => {
                    if (node.type.name === 'heading') {
                        return 'Insert title here...';
                    }
                    return '';
                },
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            TextStyle,
            AiExtended.configure({
                appId: '0k3pejdm',
                token: tiptapAIToken,
            }),
        ],
        content: initialContent,
        onUpdate: ({ editor }) => {
            // console.log('onUpdate outline => ', outline)
            // console.log('on update trackDataDetails')
            trackDataDetails(editor);
            // send data to API, autosave debounced
            if (!isDemoApp && (currentArticleId !== DEFAULT_ARTICLE_ID || !!hasSharedToken)) {
                if (writerModeRef.current === 'article' && selectedTypeOption === 'new') {
                    if (sendOutlineDataToServer) sendOutlineDataToServer.cancel();
                    dispatch(setArticleAltered(true));
                    sendDataToServer(dispatch, {type: applicationType, viewOnly: selectedTypeOption !== 'new'});
                } else if (writerModeRef.current === 'outline' && selectedTypeOption === 'outline') {
                    if (sendDataToServer) sendDataToServer.cancel();
                    sendOutlineDataToServer(dispatch, editor.getHTML());
                }
            }
        },
    });

    // Watch for token changes and update AI extension configuration
    useEffect(() => {
        if (editor && tiptapAIToken) {
            // Update just the AI extension configuration
            editor.extensionManager.extensions.find(extension => extension.name === 'ai')?.configure({
                appId: '0k3pejdm',
                token: tiptapAIToken,
            });
        }
    }, [tiptapAIToken]);

    useEffect(() => {
        if (!editor) return;

        return () => {
            // Cleanup
        };
    }, [editor]);

    const wrapPlainTextWithParagraphs = (htmlContent) => {
        if(!htmlContent) return '';

        // First clean up any excessive whitespace and newlines
        let cleanContent = htmlContent.replace(/>\s+</g, '><').replace(/\n+/g, ' ').trim();

        // Convert <b> to <strong> and <i> to <em>
        cleanContent = cleanContent
            .replace(/<b\b[^>]*>/g, '<strong>')
            .replace(/<\/b>/g, '</strong>')
            .replace(/<i\b[^>]*>/g, '<em>')
            .replace(/<\/i>/g, '</em>');

        const parser = new DOMParser();
        const doc = parser.parseFromString(cleanContent, 'text/html');

        // Get all top-level text and inline elements
        const topLevelNodes = [...doc.body.childNodes];
        const newBody = doc.createElement('body');
        let currentP = null;

        topLevelNodes.forEach(node => {
            const isInlineElement = node.nodeType === Node.ELEMENT_NODE &&
                ['strong', 'em', 'span', 'a', 'code', 'mark'].includes(node.tagName.toLowerCase());
            const isTextNode = node.nodeType === Node.TEXT_NODE;

            // Skip empty text nodes
            if (isTextNode && !node.textContent.trim()) return;

            // If it's a block element, add it directly
            if (node.nodeType === Node.ELEMENT_NODE && !isInlineElement) {
                if (currentP && currentP.hasChildNodes()) {
                    newBody.appendChild(currentP);
                }
                currentP = null;
                newBody.appendChild(node.cloneNode(true));
                return;
            }

            // Handle inline elements and text nodes
            if (!currentP) {
                currentP = doc.createElement('p');
            }

            if (isTextNode) {
                currentP.appendChild(doc.createTextNode(node.textContent.trim()));
            } else if (isInlineElement) {
                currentP.appendChild(node.cloneNode(true));
            }
        });

        // Append the last paragraph if it exists
        if (currentP && currentP.hasChildNodes()) {
            newBody.appendChild(currentP);
        }

        // Process paragraphs to add spaces around inline elements
        newBody.querySelectorAll('p').forEach(p => {
            const html = p.innerHTML;
            // Add space before inline elements if needed
            let processed = html.replace(/(\S)(<(?:strong|em)\b[^>]*>)/g, '$1 $2');

            // Add space after inline elements only if not followed by punctuation
            processed = processed.replace(/(<\/(?:strong|em)>)([^.,!?:;\s])/g, '$1 $2');

            // Clean up any double spaces
            p.innerHTML = processed.replace(/\s{2,}/g, ' ');
        });

        return newBody.innerHTML;
    };

    const restoreGenerated = () => {
        const newContent = selectedTypeOption === 'generated' ? lastGeneratedArticle : currentWebsiteArticle;

        setSelectedTypeOption('new');

        dispatch(getContentArticleData(newContent));
        editor.commands.setContent(newContent);
        sendDataToServer(dispatch, {type: applicationType, viewOnly: false})
    }
    const onSwitch = (option) => {
        if(option === 'outline') {
            dispatch(applyWriterMode('outline'));
        } else {
            switchToArticle()
            dispatch(applyWriterMode('article'));
        }

        if(option === 'generated' && articleCompleted === null) {
            setPromptType('article')
            setActiveConfigurationStep(1);
        }

        setSelectedTypeOption(option);
    }

    // Update writerModeRef when writerMode changes
    useEffect(() => {
        setHeadingIsEmpty(false);

        writerModeRef.current = writerMode;

        // if(writerMode === 'outline' && selectedTypeOption !== 'outline' ) {
        //     dispatch(applyWriterMode('article'));
        // } else if (writerMode === 'article' && selectedTypeOption === 'outline') {
        //     dispatch(applyWriterMode('outline'));
        // }
    }, [writerMode]);

    useEffect(() => {
        checkAndSetHeadingIsEmpty();
    }, [parsedData]);

    const checkAndSetHeadingIsEmpty = () => {
        setHeadingIsEmpty(!parsedData.headings1.trim());
    };

    const addContentToEditor = (editor) => {
        let content;
        if(writerMode === 'article') {
            if(selectedTypeOption === 'current' && currentWebsiteArticle !== null) {
                content = currentWebsiteArticle;
            } else if(selectedTypeOption === 'generated') {
                content = lastGeneratedArticle;
            } else {
                content = articleData
            }
        } else {
            content = outline || ''
        }

        editor.commands.setContent(content);
        trackDataDetails(editor);
    };


    const updateEditor = (addContent = false) => {
        if ((articleData || outline) && editor) {
            // console.log('updateEditor')
            trackDataDetails(editor);

            if (writerModeRef.current === 'article') {
                if (sendOutlineDataToServer) sendOutlineDataToServer.cancel();
                if (isEditorEditable()) {
                    if (!sendDataToServer) {
                        sendDataToServer(dispatch, {type: applicationType, viewOnly: selectedTypeOption !== 'new'});
                    }
                } else {
                    if (sendDataToServer) sendDataToServer.cancel();
                    if (sendOutlineDataToServer) sendOutlineDataToServer.cancel();
                }
            } else {
                if (sendDataToServer) sendDataToServer.cancel();
                if (!sendOutlineDataToServer) {
                    sendOutlineDataToServer(dispatch, editor.getHTML());
                }
            }
        }
    };

    const showLoadingArticle = () => {
        return  checkingLiveUrl && selectedTypeOption === 'current'
    }

    useEffect(() => {
        // console.log('articleData effect')
        if (writerMode === 'article') updateEditor(!articleEditorIsEditable || articleJustFinishedGenerating);
    }, [articleData]);

    useEffect(() => {
        updateEditor(true);
        // console.log('articleLoading, outlineLoading, generating')
    }, [articleLoading, outlineLoading, generating]);

    useEffect(() => {
        if (writerMode === 'outline') {
            updateEditor();
            // editor?.commands.setContent(outline);
        }
    }, [outline]);

    useEffect(() => {
        // if(!articleLoading && editor && writerMode === 'article' && selectedTypeOption === 'new' && !draftSynced) {
        if(!articleLoading && editor && writerMode === 'article' && selectedTypeOption === 'new') {
            editor?.commands.setContent(articleData);
        }
    }, [articleLoading]);

    useEffect(() => {
        // if(!outlineLoading && editor && writerMode === 'outline' && !outlineSynced) {
        if(!outlineLoading && editor && writerMode === 'outline') {
            editor?.commands.setContent(outline);
        }
    }, [outlineLoading]);

    useEffect(() => {
        // if (writerMode === 'article') updateEditor(true);
    }, [currentWebsiteArticle, selectedTypeOption]);

    const trackDataDetails = (editor) => {
        const data = editor.getHTML();
        // console.log('$$$$$ data => ', data)
        const wordCount = editor.storage.characterCount.words();
        dispatch(setWordCount(wordCount));
        // parsed all data - debounced
        // console.log('$$$$$ data 2 => ', data)
        parseEditorData(dispatch, data);
        // track words from the content editor - debounced
        trackTopicCoverage(dispatch);
        // track title from content editor - debounced
        trackTitle(dispatch);
        // track headings from content editor - debounced
        trackHeadings(dispatch);
        // track topic density from content editor
        // we use raw data for this one - debounced
        trackTopicDensity(dispatch);
        // track images from content editor - debounce
        trackImages(dispatch);
    };
    
    // Use useRef to create a mutable object that holds the latest value of writerMode
    const writerModeRef = useRef(writerMode);

    const CustomFloatingButton = ({ editor }) => {
        const handleClick = () => {
            setGenerating(true);
            // Handle button click logic

            dispatch(getNewTitleForOutline({ id: currentArticleId, testPrompt: selectedTestPrompt.value }))
                .then((response) => {
                    if (!response.error) {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(outline, 'text/html');
                        const h1Element = doc.querySelector('h1');

                        h1Element.innerHTML = response;

                        const updatedString = doc.body.innerHTML;

                        dispatch(getOutlineData(updatedString));
                    }

                    setGenerating(false);
                })
                .catch((err) => {
                    setGenerating(false);
                });
        };

        const buttonText = () => (generating ? 'Regenerating...' : 'Regenerate');


        return (
            <>
                {writerMode === 'outline' && (
                    <div onClick={isOutlineEditorDisabled() ? handleClick : null} className={isOutlineEditorDisabled() ? "refresh-title" : "refresh-title regenerate-disabled"}>
                        <RefreshIcon /> {buttonText()}
                    </div>
                )}
            </>
        );
    };

    const viewOnlyContent = selectedTypeOption === 'generated' ? lastGeneratedArticle : currentWebsiteArticle;
    const processedContent = wrapPlainTextWithParagraphs(viewOnlyContent);

    return (
        <>
            <div className={writerMode === 'outline' ? 'editor-wrapper outline-mode' : 'editor-wrapper'}>
                <EditorHeader {...{ writerMode, setShowPublishModal, selectedTypeOption }} />
                {/*<EditorMenuTop writerMode={writerMode} editor={editor} />*/}
                <EditorSwitcher
                    currentWebsiteArticle={currentWebsiteArticle}
                    loadingCurrentWebsiteArticle={loadingCurrentWebsiteArticle}
                    selectedTypeOption={selectedTypeOption}
                    setSelectedTypeOption={setSelectedTypeOption}
                    typeOptions={newTypeOptions}
                    setTypeOptions={setNewTypeOptions}
                    restoreGenerated={restoreGenerated}
                    onSwitch={onSwitch}
                />
                <Scrollbars>
                <div className="editor-content-container">
                    <ArticleProgressBox
                        isOutline={writerMode === 'outline'}
                        outlineProgressStatus={outlineProgressStatus}
                        articleProgressStatus={articleCompleted}
                        articleCompilationStatus={articleCompilationStatus}
                        totalHeadings={totalHeadings}
                        completedHeadings={completedHeadings}
                        selectedTypeOption={selectedTypeOption}
                    />
                    {articleCompleted === false && selectedTypeOption === 'generated' && (
                        <AiCard
                            isOutline={writerMode === 'outline'}
                            switchToArticle={onSwitch}
                            generating={editorLoading()}
                            firstLoad={firstLoad}
                            outlineProgressStatus={outlineProgressStatus}
                            aiMode={aiMode}
                            setAiMode={setAiMode}
                            selectedTestPrompt={selectedTestPrompt}
                            setSelectedTestPrompt={setSelectedTestPrompt}
                            activeConfigurationStep={activeConfigurationStep}
                            setActiveConfigurationStep={setActiveConfigurationStep}
                            promptType={promptType}
                            setPromptType={setPromptType}
                            regenerateCount={regenerateCount}
                            selectedTypeOption={selectedTypeOption}
                        />
                    )}
                    {!editorLoading() && (writerMode === 'outline' || articleCompleted !== false || selectedTypeOption !== 'generated' || true) &&
                        (
                        <>
                            <div className="editor-content__inner-wrapper">
                                {styleAccess && (
                                    <EditorContentInfo
                                        disableActions={showLoadingArticle() || (selectedTypeOption === 'generated' && !articleCompleted)}
                                        currentWebsiteArticle={currentWebsiteArticle}
                                        loadingCurrentWebsiteArticle={loadingCurrentWebsiteArticle}
                                        selectedTypeOption={selectedTypeOption}
                                        setSelectedTypeOption={setSelectedTypeOption}
                                        typeOptions={typeOptions}
                                        setTypeOptions={setTypeOptions}
                                        restoreGenerated={restoreGenerated}
                                        activeConfigurationStep={activeConfigurationStep}
                                        setActiveConfigurationStep={setActiveConfigurationStep}
                                        setPromptType={setPromptType}
                                        regenerateCount={regenerateCount}
                                        setShowPublishModal={setShowPublishModal}
                                    />
                                )}
                                <AiCard
                                    isOutline={writerMode === 'outline'}
                                    switchToArticle={onSwitch}
                                    generating={editorLoading()}
                                    firstLoad={firstLoad}
                                    outlineProgressStatus={outlineProgressStatus}
                                    aiMode={aiMode}
                                    setAiMode={setAiMode}
                                    selectedTestPrompt={selectedTestPrompt}
                                    setSelectedTestPrompt={setSelectedTestPrompt}
                                    activeConfigurationStep={activeConfigurationStep}
                                    setActiveConfigurationStep={setActiveConfigurationStep}
                                    promptType={promptType}
                                    setPromptType={setPromptType}
                                    regenerateCount={regenerateCount}
                                    selectedTypeOption={selectedTypeOption}
                                    hideBackButton={activeConfigurationStep === 1 && selectedTypeOption === 'generated' && !articleCompleted}
                                />
                                {(selectedTypeOption !== 'generated' || articleCompleted) && (
                                    <div className="title-label__wrapper">
                                        <div className="title-label">Title</div>
                                        {!hasSharedToken && <CustomFloatingButton editor={editor}/>}
                                    </div>
                                )}
                                {(showLoadingArticle() && (
                                    <ArticleLoadingBlocks />
                                )) || (selectedTypeOption !== 'new' && writerModeRef.current === 'article' && (
                                    <div className={`ProseMirror view-only ${loadingCurrentWebsiteArticle && selectedTypeOption === 'current' ? 'loading' : ''}`} dangerouslySetInnerHTML={{ __html: processedContent }}/>
                                )) || (
                                    <>
                                    <EditorContent editor={editor} className={`editor-content ${headingIsEmpty ? 'heading-is-empty' : ''}`} />
                                    <DragHandleContainer editor={editor} writerMode={writerMode} />
                                    <TextMenuContainer editor={editor} writerMode={writerMode} />
                                    <LinkBubbleMenuContainer editor={editor} />
                                    </>
                                )}
                            </div>
                            {!hasSharedToken && selectedTypeOption === 'generated' && !!lastGeneratedArticle && (
                                <FeedbackBox writerMode={writerMode} />
                            )}
                        </>
                    )}
                </div>
                </Scrollbars>
            </div>
        </>
    );
};

export default TipTapEditor2;
