// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-box {
    max-width: 800px;
    margin: 16px auto 0;
    border: 2px solid var(--default-borders-light);
    padding: 12px 34px;
    border-radius: 8px;
    color: var(--default-symbols-primary);
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.feedback-box__actions {
    display: flex;
    align-items: center;
    gap: 4px;
}
.feedback-box__actions button {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer
}
.feedback-box__actions button.is-active {
    background-color: var(--default-surfaces-light-hv);
}
.feedback-box__actions svg {
    width: 14px;
    height: 14px;
}

.feedback-box__actions path {
    fill: var(--default-symbols-primary);
}
`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/FeedbackBox.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,8CAA8C;IAC9C,kBAAkB;IAClB,kBAAkB;IAClB,qCAAqC;IACrC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,aAAa;IACb;AACJ;AACA;IACI,kDAAkD;AACtD;AACA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".feedback-box {\n    max-width: 800px;\n    margin: 16px auto 0;\n    border: 2px solid var(--default-borders-light);\n    padding: 12px 34px;\n    border-radius: 8px;\n    color: var(--default-symbols-primary);\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 16px;\n    letter-spacing: -0.01em;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n.feedback-box__actions {\n    display: flex;\n    align-items: center;\n    gap: 4px;\n}\n.feedback-box__actions button {\n    padding: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 6px;\n    border: none;\n    background: transparent;\n    outline: none;\n    cursor: pointer\n}\n.feedback-box__actions button.is-active {\n    background-color: var(--default-surfaces-light-hv);\n}\n.feedback-box__actions svg {\n    width: 14px;\n    height: 14px;\n}\n\n.feedback-box__actions path {\n    fill: var(--default-symbols-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
