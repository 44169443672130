import React from 'react';
import PropTypes from 'prop-types';
import { WhiteTooltipAuto } from "../UITooltips/UITooltip";
import { CheckIcon, CloseIcon, InfoCircleLightIcon } from "../../UI_utils/SVGIcons";
import { TEXTS } from "../../common/consts"

export const TrackingInfo = ({ total_kw, has_gsc, has_analytics, connect, external }) => (
    <>
        {external && (
            <div className="external-article">{TEXTS.EXTERNAL_ARTICLE}</div>
        )}
        <ul>
            <li>
                <span className="tracking-info__details">
                    <span className="tracking-info__status">
                        <CheckIcon/>{total_kw} keywords tracked
                    </span>
                    <WhiteTooltipAuto
                        placement="right"
                        title={<span dangerouslySetInnerHTML={{ __html: "Keywords from your SEO brief that will be monitored<br />for ranking performance" }} />}
                    >
                        <span className="info-tooltip"><InfoCircleLightIcon /></span>
                    </WhiteTooltipAuto>
                </span>
            </li>
            <li>
                <span className="tracking-info__details">
                    <span className="tracking-info__status">
                        {has_gsc ? <CheckIcon/> : <CloseIcon />}
                        GSC {!has_gsc && 'not '}connected: impressions and clicks will be tracked
                    </span>
                    <WhiteTooltipAuto
                        placement="right"
                        title={<span dangerouslySetInnerHTML={{ __html: "GSC connection helps monitor your article's<br />performance through impressions and clicks data." }} />}
                    >
                        <span className="info-tooltip"><InfoCircleLightIcon /></span>
                    </WhiteTooltipAuto>
                </span>
                {!has_gsc && (
                    <span className="tracking-info__actions">
                        <button onClick={() => connect('gsc')}>Connect GSC</button>
                    </span>
                )}
            </li>
            <li>
                <span className="tracking-info__details">
                    <span className="tracking-info__status">
                        {has_analytics ? <CheckIcon/> : <CloseIcon />}
                        Analytics {!has_analytics && 'not '}connected: sessions and revenue not tracked.
                    </span>
                    <WhiteTooltipAuto
                        placement="right"
                        title={<span dangerouslySetInnerHTML={{ __html: "GA connection helps measure your content's impact<br />through sessions and revenue." }} />}
                    >
                        <span className="info-tooltip"><InfoCircleLightIcon /></span>
                    </WhiteTooltipAuto>
                </span>
                {!has_analytics && (
                    <span className="tracking-info__actions">
                        <WhiteTooltipAuto
                            placement="bottom"
                            title={has_gsc ? '' : 'Google Analytics can be connected only when GSC is connected.'}>
                            <button
                                onClick={() => connect('ga')}
                                className={has_gsc ? '' : 'disabled'}>
                                Connect GA
                            </button>
                        </WhiteTooltipAuto>
                    </span>
                )}
            </li>
        </ul>
    </>
);

TrackingInfo.propTypes = {
    total_kw: PropTypes.number.isRequired,
    has_gsc: PropTypes.bool.isRequired,
    has_analytics: PropTypes.bool.isRequired,
    connect: PropTypes.func.isRequired
};
