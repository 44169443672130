import React from 'react';
import {WhiteTooltipAuto} from "../../UITooltips/UITooltip";
import {TEXTS} from "../../../common/consts";

const AiCardOptionsDefault = ({
    showPromptInput,
    regenerateCount,
    hasArticlesLeft
}) => {
    return (<>
        <WhiteTooltipAuto
            placement="bottom-end"
            title={hasArticlesLeft ? '' : TEXTS.NO_ARTICLES_LEFT}
        >
            <div className="ai-card-button ai-card-button--reset" onClick={() => showPromptInput('outline')}>
                Regenerate Outline
            </div>
        </WhiteTooltipAuto>

        <WhiteTooltipAuto
            placement="bottom-end"
            title={hasArticlesLeft ? '' : TEXTS.NO_ARTICLES_LEFT}
        >
            <div className="ai-card-button ai-card-button--show-prompt" onClick={() => showPromptInput('article')}>
                {`${regenerateCount === 0 ? 'Generate' : 'Regenerate'}`} Article
            </div>
        </WhiteTooltipAuto>
    </>)
};

export default AiCardOptionsDefault;