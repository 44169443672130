const MenuManager = {
    tippyInstances: new Set(),

    registerInstance(instance) {
        this.tippyInstances.add(instance);
    },

    unregisterInstance(instance) {
        this.tippyInstances.delete(instance);
    },

    cleanup() {
        // First try to clean up registered instances
        this.tippyInstances.forEach(instance => {
            try {
                if (instance && instance.destroy) {
                    instance.destroy();
                }
            } catch (e) {
                console.warn('Error cleaning up tippy instance:', e);
            }
        });
        this.tippyInstances.clear();

        // Then clean up any remaining tippy elements in the DOM
        try {
            const tippyRoots = document.querySelectorAll('[data-tippy-root]');
            tippyRoots.forEach(root => {
                try {
                    root.remove();
                } catch (e) {
                    console.warn('Error removing tippy root:', e);
                }
            });

            // Also clean up any orphaned popper elements
            const popperElements = document.querySelectorAll('.tippy-box');
            popperElements.forEach(el => {
                try {
                    el.remove();
                } catch (e) {
                    console.warn('Error removing popper element:', e);
                }
            });
        } catch (e) {
            console.warn('Error in DOM cleanup:', e);
        }
    }
};

export default MenuManager;
