import React, { useCallback, useEffect, useState } from "react";
import "./FeedbackBox.css";
import { WhiteTooltipAuto } from "../UITooltips/UITooltip";
import { ThumbDownIcon, ThumbUpIcon } from "../../UI_utils/SVGIcons";
import { useDispatch, useSelector } from "react-redux";
import {
    articleFeedback,
    getArticleCompleted,
    getCurrentArticleId,
    outlineFeedback,
    sendArticleFeedback,
} from "../../reducers/articles";
import FeedbackModal from "../FeedbackModal/FeedbackModal";

const FeedbackBox = ({ writerMode }) => {
    const dispatch = useDispatch();
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);

    const feedback = useSelector(articleFeedback);
    const feedbackOutline = useSelector(outlineFeedback);
    const articleCompleted = useSelector(getArticleCompleted);
    const articleId = useSelector(getCurrentArticleId);

    const activeFeedback = writerMode === "article" ? feedback : feedbackOutline;

    const handleUserFeedback = (newFeedback) => {
        const updatedFeedback = newFeedback === activeFeedback ? 0 : newFeedback;
        dispatch(sendArticleFeedback(updatedFeedback, writerMode));
        
        // Show feedback modal only for negative feedback
        if (newFeedback === -1 && activeFeedback !== -1) {
            setShowFeedbackModal(true);
        }
    };

    const openIntercom = () => {
        window.parent.postMessage({ trigger: "intercom" }, "*");
    };

    useEffect(() => {
        const feedbackLink = document.getElementById("trigger-intercom");

        if (feedbackLink) {
            feedbackLink.addEventListener("click", openIntercom);
            return () => feedbackLink.removeEventListener("click", openIntercom);
        }
    }, []);

    const renderTooltipContent = (isUseful) => {
        const message = isUseful
            ? "This article is useful."
            : "This article is not useful.";
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: `${message} <b class="cursor-pointer" id="trigger-intercom">Share feedback.</b>`,
                }}
            />
        );
    };

    const renderButton = (isPositive) => {
        const feedbackValue = isPositive ? 1 : -1;
        const isActive = activeFeedback === feedbackValue && articleCompleted;
        const tooltipContent = renderTooltipContent(isPositive);

        return (
            <WhiteTooltipAuto
                placement="top-end"
                interactive
                title={articleCompleted && articleId ? tooltipContent : ""}
            >
                <button
                    className={isActive ? "is-active" : ""}
                    onClick={() => handleUserFeedback(feedbackValue)}
                    disabled={!articleCompleted || !articleId}
                >
                    {isPositive ? <ThumbUpIcon /> : <ThumbDownIcon />}
                </button>
            </WhiteTooltipAuto>
        );
    };

    return (
        <>
            <div className="feedback-box">
                <div className="feedback-box__label">
                    Do you like the outcome of this {`${writerMode === 'outline' ? 'outline' : 'article'}`}?
                </div>

                <div className="feedback-box__actions">
                    {renderButton(true)}
                    {renderButton(false)}
                </div>
            </div>
            {showFeedbackModal && (
                <FeedbackModal onClose={() => setShowFeedbackModal(false)} />
            )}
        </>
    );
};

export default FeedbackBox;
