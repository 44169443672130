// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-audit-box {
    color: var(--default-symbols-primary);
    border-radius: 12px;
    cursor: pointer;
    padding: 8px;
    display: grid;
    gap: 4px;
}

.content-audit-box:not(.disabled):hover {
    background: var(--default-surfaces-ghost-hv);
}
.content-audit-box.questions-active{
    border: 3px solid rgba(64, 226, 70, 1)
}

.content-audit-box.disabled {
    cursor: not-allowed;
}
.content-audit-box.disabled > * {
    opacity: 0.5;
}

.content-audit-box_title {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
}
.content-audit-box-count{
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/NewArticleModal/ContentAuditBox.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,4CAA4C;AAChD;AACA;IACI;AACJ;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".content-audit-box {\n    color: var(--default-symbols-primary);\n    border-radius: 12px;\n    cursor: pointer;\n    padding: 8px;\n    display: grid;\n    gap: 4px;\n}\n\n.content-audit-box:not(.disabled):hover {\n    background: var(--default-surfaces-ghost-hv);\n}\n.content-audit-box.questions-active{\n    border: 3px solid rgba(64, 226, 70, 1)\n}\n\n.content-audit-box.disabled {\n    cursor: not-allowed;\n}\n.content-audit-box.disabled > * {\n    opacity: 0.5;\n}\n\n.content-audit-box_title {\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 16px;\n    letter-spacing: -0.01em;\n}\n.content-audit-box-count{\n    font-size: 11px;\n    font-weight: 700;\n    line-height: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
