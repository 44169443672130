// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.previous-article {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.previous-article__container{
    color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.5));
    margin-top: 24px;
    display: flex;
}

.previous-article__container svg{
    height:18px;
    width: 18px;
    margin-left: 4px;
}

.previous-article__container svg path{
    fill-opacity : 1;
    fill: var(--default-symbols-secondary);
}

/*@tibi does this need to be removed?*/
.previous-article__container .single-text{
    color: var(--default-symbols-primary);
    font-weight: 500
}

.previous-article__container p{
    color: var(--default-symbols-secondary);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewArticleModal/PreviousArticle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,8DAA8D;IAC9D,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,sCAAsC;AAC1C;;AAEA,sCAAsC;AACtC;IACI,qCAAqC;IACrC;AACJ;;AAEA;IACI,uCAAuC;IACvC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".previous-article {\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n.previous-article__container{\n    color: var(--default-symbols-secondary, rgba(23, 23, 23, 0.5));\n    margin-top: 24px;\n    display: flex;\n}\n\n.previous-article__container svg{\n    height:18px;\n    width: 18px;\n    margin-left: 4px;\n}\n\n.previous-article__container svg path{\n    fill-opacity : 1;\n    fill: var(--default-symbols-secondary);\n}\n\n/*@tibi does this need to be removed?*/\n.previous-article__container .single-text{\n    color: var(--default-symbols-primary);\n    font-weight: 500\n}\n\n.previous-article__container p{\n    color: var(--default-symbols-secondary);\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 24px;\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
