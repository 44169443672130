import React, { useState, useEffect, useRef, useCallback } from 'react';
import { ContentItemMenu } from './ContentItemMenu';

export const DragHandleContainer = ({ editor, writerMode }) => {
    const [shouldRender, setShouldRender] = useState(false);
    const cleanupTimeoutRef = useRef(null);

    const cleanup = useCallback(() => {
        const dragHandle = document.getElementById(`drag-handle-${writerMode}`);
        if (dragHandle) {
            // Remove event listeners
            const existingHandlers = dragHandle._tippy_handlers;
            if (existingHandlers) {
                Object.entries(existingHandlers).forEach(([event, handler]) => {
                    dragHandle.removeEventListener(event, handler);
                });
                delete dragHandle._tippy_handlers;
            }

            // Cleanup tippy instances
            const tippyInstances = dragHandle.querySelectorAll('[data-tippy-root]');
            tippyInstances.forEach(instance => {
                const tippy = instance._tippy;
                if (tippy) {
                    tippy.destroy();
                }
            });
        }
    }, [writerMode]);

    useEffect(() => {
        // On mount or mode change, delay rendering to ensure clean DOM
        setShouldRender(false);
        
        // Clear any existing cleanup timeout
        if (cleanupTimeoutRef.current) {
            clearTimeout(cleanupTimeoutRef.current);
        }

        // Immediate cleanup
        cleanup();
        
        const renderTimer = setTimeout(() => {
            setShouldRender(true);
        }, 100);

        return () => {
            clearTimeout(renderTimer);
            if (cleanupTimeoutRef.current) {
                clearTimeout(cleanupTimeoutRef.current);
            }
            
            // Schedule cleanup for next tick to ensure all events are handled
            cleanupTimeoutRef.current = setTimeout(() => {
                cleanup();
                setShouldRender(false);
            }, 0);
        };
    }, [writerMode, cleanup]);

    if (!shouldRender || !editor) {
        return null;
    }

    return (
        <div id={`drag-handle-${writerMode}`} className="drag-handle-container">
            <div className="drag-handle-wrapper">
                <ContentItemMenu editor={editor} />
            </div>
        </div>
    );
};
