import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import {getGlobalState} from "../../globals";
import "./EditorHeader.css";
import SaveOptions from './SaveOptions'
import { getTopicInfo, getSearchKeywords } from '../../reducers/searchTopic';
import {getArticleIsComprehensive, getCurrentArticle, getGeneratedWithFreshPerspective} from '../../reducers/articles';
import {BooksIcon, FreshArticleIcon, GlobeIcon, ExportIcon} from "../../UI_utils/SVGIcons";
import {WhiteTooltipAuto} from "../UITooltips/UITooltip";
import {TEXTS} from "../../common/consts";
import PublishButton from "./PublishButton";
import {getUserIsSeo, getUserPublishFlow} from "../../reducers/user";
const EditorHeader = ({ writerMode, setShowPublishModal }) => {
  const dispatch = useDispatch();
    const history = useHistory();
    const searchVolume = useSelector((state) => state.searchtopic.topicData?.searchVolume);
    const topicName = useSelector((state) => state.searchtopic.searchKeywords);
    const [showDownloaOptions, setDownloadOptions] = useState(false)
    const currentArticle = useSelector(getCurrentArticle);
    const topicSearched = useSelector(getSearchKeywords);
    const freshPerspectiveMarket = useSelector(getGeneratedWithFreshPerspective)
    const isComprehensive = useSelector(getArticleIsComprehensive)
    const publishFlow = useSelector(getUserPublishFlow)

    const topicSearchString = topicSearched || (currentArticle && currentArticle.name);
    const handleOnClick = () => {
        const siteId = getGlobalState('site_id');
        const siteIdParam = siteId ? `?site_id=${siteId}` : '';
        const path = `/dashboard${siteIdParam}`;
        history.push(path);
    };

    const toggleOptions = (event) => {
        event.stopPropagation();
        setDownloadOptions(!showDownloaOptions);
    }

    const openPublishModal = () => setShowPublishModal(true)


    const articleTypeTooltip = () => {
        if(!!freshPerspectiveMarket) return TEXTS.FRESH_PERSPECTIVE_TOOLTIP;
        if(isComprehensive) return TEXTS.COMPREHENSIVE_ARTICLE_TOOLTIP;
        return '';
    }

    return (
        <>
            <div className="editor-header">
                <div className="edit-header__left">
                    <div className="all-keywords-button">{topicName} {articleTypeTooltip() && (
                        <WhiteTooltipAuto
                            placement="bottom-end"
                            interactive={true}
                            title={<span dangerouslySetInnerHTML={{ __html: articleTypeTooltip() }} />}
                        >
                            <span className={"market-icon"}>
                                {freshPerspectiveMarket && (
                                    <>
                                        <GlobeIcon />
                                        {freshPerspectiveMarket}
                                    </>
                                ) || <BooksIcon />}
                            </span>
                        </WhiteTooltipAuto>
                    )}</div>
                </div>
                <div className="edit-header__right">
                    {/* <div className="right-wrapper">
                        {topicInfoData && topicInfoData.loaded && (
                            <>
                                <div className="right-header__search_volume" onClick={toggleComponent}>
                                    potential traffic:
                                    <span className="header-search_volume"> {shortenNumbers(topicInfoData.opportunity)}/mo </span>
                                </div>
                                {topicInfoDataIsVisible && (
                                    <div ref={topicInfoDataWrapperRef}>
                                        <TrafficDropdown topicInfo={topicInfoData} />
                                    </div>
                                )}
                            </>
                        )}
                    </div> */}
                    <div className={`action-section`}>
                        <div className="header-download_action" onClick={toggleOptions}>
                            <ExportIcon />
                        </div>
                        {showDownloaOptions && <SaveOptions setDownloadOptions={setDownloadOptions} writerMode={writerMode} />}
                    </div>

                    {publishFlow && <PublishButton onClick={openPublishModal} />}
                </div>
            </div>
        </>
    );
};

export default EditorHeader;
