// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-editor-panel {
  padding: 12px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.link-input-group {
  margin-bottom: 12px;
}

.link-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 8px;
}

.link-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
}

.link-checkbox input[type="checkbox"] {
  margin-right: 4px;
}

.link-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.link-button {
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.link-button.cancel {
  background: #f5f5f5;
  color: #666;
}

.link-button.cancel:hover {
  background: #eee;
}

.link-button.apply {
  background: #0066cc;
  color: white;
}

.link-button.apply:hover {
  background: #0052a3;
}

.link-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/LinkEditorPanel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,eAAe;EACf,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,QAAQ;AACV;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".link-editor-panel {\n  padding: 12px;\n  background: white;\n  border-radius: 4px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.link-input-group {\n  margin-bottom: 12px;\n}\n\n.link-input {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n  margin-bottom: 8px;\n}\n\n.link-checkbox {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  font-size: 14px;\n  color: #666;\n}\n\n.link-checkbox input[type=\"checkbox\"] {\n  margin-right: 4px;\n}\n\n.link-actions {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n\n.link-button {\n  padding: 6px 12px;\n  border-radius: 4px;\n  border: none;\n  cursor: pointer;\n  font-size: 14px;\n}\n\n.link-button.cancel {\n  background: #f5f5f5;\n  color: #666;\n}\n\n.link-button.cancel:hover {\n  background: #eee;\n}\n\n.link-button.apply {\n  background: #0066cc;\n  color: white;\n}\n\n.link-button.apply:hover {\n  background: #0052a3;\n}\n\n.link-button:disabled {\n  opacity: 0.5;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
