import React from "react";
import './ConfirmationModal.css';
import {CheckCircle} from "../UI_utils/SVGIcons";
import AppModal from "./AppModal";
import {useSelector, useDispatch} from "react-redux";
import {
    getGenericConfirmationText,
    getGenericConfirmationHandler,
    getOncloseHandler,
    setOncloseHandler,
    getSecondaryConfirmationHandler
} from "../reducers/confirmationModal";

const ConfirmationModal = ({}) => {
    const dispatch = useDispatch();
    const genericHandler = useSelector(getGenericConfirmationHandler);
    const modalContentData = useSelector(getGenericConfirmationText);
    const secondaryGenericHandler = useSelector(getSecondaryConfirmationHandler);
    const onCloseHandler = useSelector(getOncloseHandler)

    const handler = () => {
        if(genericHandler) return genericHandler();
    }

    const closeModal = () => {
        onCloseHandler()
        dispatch(setOncloseHandler(null))
    }

    const secondaryHandler = () => {
        if(secondaryGenericHandler) return secondaryGenericHandler();
    }

    return (
        <AppModal
            className={`confirmation-modal ${modalContentData?.className}`}
            icon={modalContentData?.icon || CheckCircle}
            buttonIcon={modalContentData?.buttonIcon || null}
            title={modalContentData?.title || "Success"}
            text={modalContentData?.description || ""}
            infoText={modalContentData?.infoText || ""}
            buttonText={modalContentData?.buttonText || ""}
            secondaryButtonText={modalContentData?.secondaryButtonText || 'Got it'}
            buttonAction={handler}
            secondaryButtonAction={secondaryHandler}
            onCloseAction={onCloseHandler && closeModal}

        />
    );
};

export default ConfirmationModal;
