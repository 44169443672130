// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hiwb {
    display: flex;
    align-items: flex-start;
}

.hiwb_step {
    font-size: 18px;
    font-weight: 800;
    background: var(--night-symbols-primary);
    border-radius: 50px;
    width: 30px;
    min-width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--default-surfaces-base);
    margin-right: 8px;
    margin-top: 6px;
}

.hiwb_title {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
}

.hiwb_text {
    color: var(--default-symbols-primary);
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
}

.hiwb_text span {
    font-weight: 700;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/NewArticleModal/HowItWorksBox.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,wCAAwC;IACxC,mBAAmB;IACnB,WAAW;IACX,eAAe;IACf,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mCAAmC;IACnC,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,qCAAqC;AACzC;;AAEA;IACI,qCAAqC;IACrC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".hiwb {\n    display: flex;\n    align-items: flex-start;\n}\n\n.hiwb_step {\n    font-size: 18px;\n    font-weight: 800;\n    background: var(--night-symbols-primary);\n    border-radius: 50px;\n    width: 30px;\n    min-width: 30px;\n    height: 30px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: var(--default-surfaces-base);\n    margin-right: 8px;\n    margin-top: 6px;\n}\n\n.hiwb_title {\n    font-size: 13px;\n    font-weight: 700;\n    line-height: 20px;\n    letter-spacing: -0.01em;\n    color: var(--default-symbols-primary);\n}\n\n.hiwb_text {\n    color: var(--default-symbols-primary);\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 20px;\n    letter-spacing: -0.01em;\n}\n\n.hiwb_text span {\n    font-weight: 700;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
