
import React, { useState, useRef, useEffect } from 'react';
import TrafficDropdown from '../TipTapEditor/TrafficDropdown';
import { shortenNumbers } from '../../utils/helpers';
import './TrafficInfoButton.css';

const TrafficInfoButton = ({ topicInfoData, content=''}) => {
    const [isVisible, setIsVisible] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="traffic-info-wrapper">
            {topicInfoData && topicInfoData.loaded && (
                <>
                    <div className="traffic-info__search-volume" onClick={toggleVisibility}>
                        {content ? content : `${shortenNumbers(topicInfoData.opportunity)}/mo potential traffic`}
                    </div>
                    {isVisible && (
                        <div ref={wrapperRef}>
                            <TrafficDropdown topicInfo={topicInfoData} />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default TrafficInfoButton;