import React, {useEffect, useRef, useState} from 'react';
import './DefaultSettingsSidebarHeader.css'
import {CrossIcon} from "../../UI_utils/SVGIcons";

const DefaultSettingsSidebarHeader = ({ close = () => void(0) }) => {
    return (
        <div className="default-settings-sidebar-header">
            <div className="default-settings-sidebar-title__wrapper">
                <div className="default-settings-sidebar-title">Content Writer settings</div>
                <div className="default-settings-sidebar-subtitle">Settings will be prompted in the AI content generation process for all articles generated in this campaign.</div>
            </div>
            <div className="default-settings-sidebar-close" onClick={close}><CrossIcon /></div>
        </div>
    );
};

export default DefaultSettingsSidebarHeader