import { getUserToken } from "../utils/userStatus";
import {
  setNewArticle,
  saveArticleApi,
  getArticles,
  articleById,
  saveArticleStats,
  getArticleStats,
  uploadCSV,
  exportToPdf,
  updateTechnicalFeedback,
  rateArticle,
  getArticlePlagiarismScore,
  updatePlagiarismScore,
  uploadImage,
  articleContent,
  generateArticleFromOutlineRequest,
  generateNewTitleForOutlineRequest,
  resetOutlineRequest,
  saveOutlineRequest,
  deleteArticleFromDashboard,
  stopGeneratingArticleRequest,
  getStopWordsRequest,
  deleteKeywordRequest,
  getCoverageDataRequest,
  checkTrackedKeywordsRequest,
  getShareHashRequest,
  addInternalLinkRequest,
  getInternalLinksRequest,
  deleteInternalLinkRequest,
  subscribeUserRequest,
  checkLandingPageRequest,
  getCurrentArticleRequest,
  checkLandingPageDataRequest,
  publishArticleRequest,
  getAllScores,
  updateFeedbackRequest, detectLiveUrlRequest, checkLiveUrlRequest
} from '../api/articles';

import { formatDateForStore, getTimeFromDate } from "../utils/dateUtility";
import {getGlobalState} from "../globals";

export const createNewArticle = async (keyword, articleType, projectId) => {
  const token = getUserToken();

  const { id } = await setNewArticle(
    {
      name: keyword,
      content: "Article content",
      type: articleType,
      project: projectId || 1,
    },
    token
  );

  return id;
};

export const saveArticleData = async (payload) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();

  if(sharedToken) {
    payload.shareHash = sharedToken
    delete payload.id
  }

  const result = await saveArticleApi({ ...payload }, token);
  return result;
};


export const getArticleById = async (id, sharedArticleToken) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken()
  const payload = {};

  if(sharedToken)
    payload.shareHash = sharedToken
  else if(id)
    payload.id = id

  try {
    const currentArticle = await articleById(payload, token);
    return currentArticle;
  } catch (error) {
    return {
      ...error.data,
      status: error.status,
      error: true,
    };
  }
};

export const saveStats = async (id, section = '', score = null) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();
  const payload = {section};

  if(sharedToken)
    payload.shareHash = sharedToken
  else if(id)
    payload.id = id

  if(score !== null) {
    if(section === 'outline') payload.outlineScore = score
    if(section === 'article') payload.articleScore = score
  }

  const saved = await saveArticleStats(payload, token);
  return saved;
};

export const getStats = async (id, section = '') => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();
  const payload = {};

  if(sharedToken)
    payload.shareHash = sharedToken
  else if(id)
    payload.id = id

  if(section !== '') payload.section = section;

  try {
    const articleStats = await getArticleStats(payload, token);

    return articleStats;
  } catch (error) {
    return [];
  }
};

export const uploadCSVArticles = async (file) => {
  const token = getUserToken();

  let formData = new FormData();
  formData.append("file", file);

  try {
    const uploaded = await uploadCSV(formData, token);
    return uploaded;
  } catch (data) {
    return {
      ...data,
      error: true,
    };
  }
};

export const uploadImageToEditor = async (file, setUploadProgress) => {
  const token = getUserToken();

  let formData = new FormData();
  formData.append("file", file);

  try {
    const uploaded = await uploadImage(formData, setUploadProgress, token);
    return uploaded;
  } catch (data) {
    return {
      ...data,
      error: true,
    };
  }
};

export const exportArticle = async (id, type) => {
  const token = getUserToken();
  await exportToPdf({ id, type }, token);
};

export const saveTechnicalFeedback = async (payload) => {
  const token = getUserToken();

  try {
    const result = await updateTechnicalFeedback(payload, token);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};


export const checkPlagiarismScore = async (id) => {
  const token = getUserToken();
  const payload = {
    id,
  };

  const result = await getArticlePlagiarismScore(payload, token);
  return result;
};

export const checkPlagiarismAndUpdate = async (id) => {
  const token = getUserToken();
  const payload = {
    id,
  };

  const result = await updatePlagiarismScore(payload, token);
  return result;
};

export const contentArticle = async (id) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();
  const payload = {};

  if(sharedToken)
    payload.shareHash = sharedToken
  else if(id)
    payload.id = id

  const result = await articleContent(payload, token);
  return result
}

export const resetOutline = async (articleId, testPrompts, prompt, force, type = {}) => {
  const token = getUserToken();
  const payload = {
      id: articleId,
      test_prompts: testPrompts,
      prompt: prompt,
      force: force,
      comprehensive: !!type.comprehensive,
      fresh_market: !!type.fresh_market ? type.fresh_market : null,
  };

  const result = await resetOutlineRequest(payload, token);
  return result
}

export const saveOutline = async (payload) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();

  if(sharedToken) {
    payload.shareHash = sharedToken
    delete payload.id
  }

  const result = await saveOutlineRequest(payload, token);
  return result
}

export const generateArticleFromOutline = async (payload) => {
  const token = getUserToken();

  const result = await generateArticleFromOutlineRequest(payload, token);
  return result
}


export const generateNewTitleForOutline = async ({ id, testPrompt }) => {
  const token = getUserToken();

  const result = await generateNewTitleForOutlineRequest({ id: id, test_prompts : testPrompt}, token);
  return result
}


export const stopGenerating = async (articleId) => {
  const token = getUserToken();

  const result = await stopGeneratingArticleRequest({id: articleId}, token);
  return result
}

export const getStopWordsArray = async (articleId) => {
  const token = getUserToken();

  const result = await getStopWordsRequest({id: articleId}, token);
  return result
}

export const deleteKeywordFromArticle = async (payload) => {
  const token = getUserToken();

  const result = await deleteKeywordRequest(payload, token);
  return result
}

export const getCoverageData = async (payload) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();

  if(sharedToken) {
    payload.shareHash = sharedToken
    delete payload.id
  }

  const result = await getCoverageDataRequest(payload, token);
  return result
}

export const deleteArticle = async ( payload ) => {
  const token = getUserToken();
  const result = await deleteArticleFromDashboard(payload, token);

  return result
}

export const checkTrackedKeywords = async ( payload ) => {
  const token = getUserToken();
  const result = await checkTrackedKeywordsRequest(payload, token);

  return result
}

export const generateAndGetShareHash = async ( payload ) => {
  const token = getUserToken();
  const result = await getShareHashRequest(payload, token);

  return result
}

export const getInternalLinks = async ( payload ) => {
  const token = getUserToken();
  const result = await getInternalLinksRequest(payload, token);

  return result
}

export const addInternalLink = async ( payload ) => {
  const token = getUserToken();
  const result = await addInternalLinkRequest(payload, token);

  return result
}

export const deleteInternalLink = async ( payload ) => {
  const token = getUserToken();
  const result = await deleteInternalLinkRequest(payload, token);

  return result
}

export const subscribeUser = async ( payload ) => {
  const token = getUserToken();
  const result = await subscribeUserRequest(payload, token);

  return result
}

export const updateFeedback = async ( payload ) => {
  const token = getUserToken();
  const result = await updateFeedbackRequest(payload, token);

  return result
}

export const checkLandingPageData = async (payload) => {
  const token = getUserToken();
  try {
    const response = await checkLandingPageDataRequest(payload, token);
    return {
      kwTracked: response.tracked,
      totalKw: response.all,
      gsc: response.gsc_connected,
      analytics: response.ga_connected
    };
  } catch (error) {
    return {
      error: error?.data?.message || 'Failed to check landing page data'
    };
  }
};

export const publishArticle = async (payload) => {
  const token = getUserToken();
  try {
    const response = await publishArticleRequest(payload, token);
    return {
      date: response.publishedDate,
      author: response.user
    };
  } catch (error) {
    return {
      error: error?.data?.message || 'Failed to publish article'
    };
  }
};


export const checkLandingPage = async ( payload ) => {
  const token = getUserToken();
  const result = await checkLandingPageRequest(payload, token);

  return result
}


export const getArticleFromLandingPage = async ( payload ) => {
  const token = getUserToken();
  const result = await getCurrentArticleRequest(payload, token);

  return result
}


export const getAllScoresForArticle = async ( payload ) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();

  if(sharedToken) {
    payload.shareHash = sharedToken
    delete payload.id
  }

  const result = await getAllScores(payload, token);

  return result
}

export const detectLiveUrl = async ( payload ) => {
  const token = getUserToken();
  const result = await detectLiveUrlRequest(payload, token);

  return result
}

export const checkLiveUrl = async ( payload ) => {
  const token = getUserToken();
  const result = await checkLiveUrlRequest(payload, token);

  return result
}
