// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-block {
	 color: transparent;
	 background: var(--default-surfaces-light);
	 opacity: 1;
	 display: inline-block;
	 vertical-align: middle;
	 animation: pulse 3s infinite ease;
}
 .loading-block--rounded {
	 border-radius: 32px;
}
 @keyframes pulse {
	 0% {
		 opacity: 1;
	}
	 50% {
		 opacity: 0.5;
	}
	 100% {
		 opacity: 1;
	}
}
 `, "",{"version":3,"sources":["webpack://./src/Components/LoadingBlock/loadingBlock.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,yCAAyC;EACzC,UAAU;EACV,qBAAqB;EACrB,sBAAsB;EACtB,iCAAiC;AACnC;CACC;EACC,mBAAmB;AACrB;CACC;EACC;GACC,UAAU;CACZ;EACC;GACC,YAAY;CACd;EACC;GACC,UAAU;CACZ;AACD","sourcesContent":[".loading-block {\n\t color: transparent;\n\t background: var(--default-surfaces-light);\n\t opacity: 1;\n\t display: inline-block;\n\t vertical-align: middle;\n\t animation: pulse 3s infinite ease;\n}\n .loading-block--rounded {\n\t border-radius: 32px;\n}\n @keyframes pulse {\n\t 0% {\n\t\t opacity: 1;\n\t}\n\t 50% {\n\t\t opacity: 0.5;\n\t}\n\t 100% {\n\t\t opacity: 1;\n\t}\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
