import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import HomeContent from "../Components/HomeContent";
import { isDemoApplication } from "../reducers/applicationMode";
import { useSelector } from "react-redux";
import {getUserToken} from "../utils/userStatus";
import {getGlobalState} from "../globals";
import AppLoadingScreen from "../Shared/AppLoadingScreen";

const Home = () => {
  return <HomeContent />
};

export default Home;
