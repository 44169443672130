import React from 'react';
import './ArticlesSidebarItem.css'
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {getGlobalState} from "../../globals";
import {WhiteTooltip} from "../UITooltips/UITooltip";
import {CalendarIcon, FlashIcon, FreshArticleIcon, GlobeIcon, RefreshIcon, TrendUpIcon} from "../../UI_utils/SVGIcons";
import {TEXTS} from "../../common/consts";

const seomonitorInterfaceUrl = process.env.REACT_APP_SEOMONITOR_INTERFACE || 'https://app.seomonitor.com/';

const ArticlesSidebarItem = ({topic, addTraffic, title, date, updatedDate, author,  articleId, showArticleScore = false, outlineScore, articleScore, freshMarket}) => {
    const siteId = getGlobalState('site_id');

    const goToArticle = () => {
        const path = `/article/${articleId}?site_id=${siteId}`;
        window.location.href = path;
    }

    const returnArticlePath = () => {
        return `${seomonitorInterfaceUrl}v2/${siteId}/content/writer/article/${articleId}`;
    }

    const clickPrevented = (event) => {
        event.preventDefault();

        return goToArticle()
    }

    const getScoreToShow = () => {
        if(articleScore){
            return articleScore
        }else{
            if(outlineScore){
                return outlineScore
            }
        }
    }

    return (
        <div className="articles-sidebar-item__wrapper">
            {/*<div className="articles-sidebar-item" onClick={goToArticle}>*/}
            <a className="articles-sidebar-item" href={returnArticlePath()} onClick={clickPrevented}>
                <div className="article-sidebar-item-info">
                    <div className="article-item-topic_wrapper">
                        <div className="article-item-topic">{topic}</div>
                    </div>
                    <div className={`article-item-title ${title ? '' : 'empty'}`} dangerouslySetInnerHTML={{ __html: title ? title : '(missing title)' }}/>
                    <div className="article-item-details">
                        <WhiteTooltip
                            placement="bottom-start"
                            title={!!freshMarket ? TEXTS.FRESH_PERSPECTIVE_TOOLTIP : ''}
                        >
                            <span className="article-item-fresh">
                            {freshMarket && (
                                <>
                                    <GlobeIcon />
                                    <span>{freshMarket}</span>
                                </>
                            ) || <FlashIcon />}
                            </span>
                        </WhiteTooltip>
                        <div className="article-item-date"><CalendarIcon /><span>{date}</span></div>
                        {updatedDate && <div className="article-item-date"><RefreshIcon /><span>{updatedDate}</span></div>}
                        <div className="article-item-add-traffic"><TrendUpIcon /><span>{addTraffic}/mo</span></div>
                        <div className="article-item-author"><span>{author}</span></div>
                    </div>
                </div>
                <div className="article-sidebar-item-score">
                    
                        <CircularProgressbarWithChildren
                        value={getScoreToShow() || 0}
                        strokeWidth={9}
                        styles={buildStyles({
                            pathTransitionDuration: 0.5,
                            pathColor: showArticleScore ? '#40E246' : '#745ECA',
                            strokeLinecap: 'butt',
                            backgroundColor: 'transparent',
                        })}>
                        <p className="article-sidebar-item-score_value">{getScoreToShow() || 0}</p>
                        </CircularProgressbarWithChildren>
            
                </div>
            </a>
            {/*</div>*/}
        </div>
    );
};

export default ArticlesSidebarItem