import React, {useEffect, useState} from 'react';
import './SpellbindingSuggestions.css'
import SpellbindingSuggestionsItem from "./SpellbindingSuggestionsItem";
import {PlusIcon, SpinnerIcon} from "../../UI_utils/SVGIcons";
import {useDispatch, useSelector} from "react-redux";
import {getSuggestedTopics, getTopicSuggestionsRows, getUserAccessToNewTracking} from "../../reducers/user";
import {getGlobalState} from "../../globals";
import {interfaceApiRequest} from "../../utils/dataToInterface";
import {getCurrentArticleId} from "../../reducers/articles";

const SpellbindingSuggestions = ({setProcessingTopic}) => {
    const MAX_PAGES = 5;
    const TOPICS_PER_PAGE = 5
    const siteIdParam = getGlobalState('site_id');

    const dispatch = useDispatch();
    const suggestedTopics = useSelector(getSuggestedTopics);
    const currentArticleId = useSelector(getCurrentArticleId);
    const newTrackingAccess = useSelector(getUserAccessToNewTracking);

    const [activePage, setActivePage] = useState(1)
    const [loadingMoreTopics, setLoadingMoreTopics] = useState(false)
    const [loadingTrackKeyword, setLoadingTrackKeyword] = useState(false)
    const [trackingKeyword, setTrackingKeyword] = useState('')
    const [customError, setCustomError] = useState('')

    const resetError = () => setCustomError('');

    const loadedPages = () => suggestedTopics ? suggestedTopics.length / TOPICS_PER_PAGE : 0;

    const clickHandler = (topic) => {
        if(!loadingTrackKeyword) {
            resetError()
            setLoadingTrackKeyword(true)
            setProcessingTopic(topic)
            setTrackingKeyword(topic)

            let payload;

            if(newTrackingAccess) {
                payload = {
                    keywords: [topic.toLowerCase()],
                    trackKeywordsFromWriter: [topic.toLowerCase()],
                    topic: topic.toLowerCase(),
                    newTopicFromWriter: true,
                    openInterstitialModal: true
                };
            } else {
                payload = {
                    trackKeywordsFromWriter: [topic.toLowerCase()],
                    newTopicFromWriter: true,
                    old: true
                };
            }

            interfaceApiRequest('checkTopicAndStartArticleMessage', payload)
                .then(resp => {
                    if(resp.message) {
                        setCustomError(resp.message)
                    }
                })
                .catch(err => {
                    setCustomError(err.message)
                })
                .finally(() => {
                    setLoadingTrackKeyword(false);
                    setProcessingTopic('');
                    setTrackingKeyword('')
                })
        }
    }

    const handlePageClick = (pageIndex) => {
        resetError()
        setActivePage(pageIndex + 1);
    };

    const loadSuggestedTopics = () => {
        // if(!loadingMoreTopics && siteIdParam && loadedPages() < MAX_PAGES && (activePage === loadedPages() || !loadedPages())) {
        if(!loadingMoreTopics && siteIdParam && loadedPages() < MAX_PAGES) {
            resetError()
            setLoadingMoreTopics(true)

            const payload = {
                siteId: siteIdParam,
                rows: 5,
                offset: loadedPages() * TOPICS_PER_PAGE,
                articleId: currentArticleId
            }

            if(currentArticleId) payload.articleId = currentArticleId;

            dispatch(getTopicSuggestionsRows(payload))
                .then()
                .catch()
                .finally(() => {
                    setLoadingMoreTopics(false)
                })
        }
    }

    useEffect(() => {
        if(suggestedTopics === null) loadSuggestedTopics()
    }, [])
    // }, [activePage, loadingMoreTopics])

    const startIndex = (activePage - 1) * TOPICS_PER_PAGE;
    const endIndex = startIndex + TOPICS_PER_PAGE;
    const topicsToShow = suggestedTopics ? suggestedTopics.slice(startIndex, endIndex) : null;

    const pages = Array.from({ length: loadedPages() }).map((_, index) => (
        <div
            key={index}
            className={`pagination-item ${activePage === index + 1 ? 'active' : ''}`}
            onClick={() => handlePageClick(index)}
        >
            {index + 1}
        </div>
    ));

    return (
        <div className="spellbinding-suggestions">
            <div className="spellbinding-suggestions__title">Strategic Content Topics</div>
            { topicsToShow && topicsToShow.map(({topic, traffic}, index) => (
                <SpellbindingSuggestionsItem
                    key={`topic-${index}`}
                    index={index}
                    topic={topic}
                    traffic={traffic}
                    onClick={clickHandler}
                    disable={topic !== trackingKeyword && !!trackingKeyword}
                />
            ))}
            { !topicsToShow && (
                <>
                    <SpellbindingSuggestionsItem topic={null} />
                    <SpellbindingSuggestionsItem topic={null} />
                    <SpellbindingSuggestionsItem topic={null} />
                    <SpellbindingSuggestionsItem topic={null} />
                    <SpellbindingSuggestionsItem topic={null} />
                </>
            )}

            {loadingTrackKeyword && !customError && (
                <div className="spellbinding-suggestions__loading-info">Processing keyword. Usually takes up to 1 minute. <SpinnerIcon /></div>
            )}
            {!loadingTrackKeyword && (
                <div className="spellbinding-suggestions__pagination">
                    {pages}
                    {!loadingMoreTopics && <span className="pagination-item pagination-item--plus" onClick={loadSuggestedTopics}><PlusIcon /></span>}
                    {loadingMoreTopics && <span className="pagination-item pagination-item--loading"><SpinnerIcon /></span>}
                </div>
            )}
            {!loadingTrackKeyword && customError && (
                <div className="spellbinding-suggestions__error" dangerouslySetInnerHTML={{__html: customError}} />
            )}
        </div>
    );
};

export default SpellbindingSuggestions