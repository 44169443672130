// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content-audit {
    margin-top: 24px;
}
.new-article-modal__content.new-article-modal__content--new-user .modal-content-audit {
    max-width: 100%;
}
.content-audit__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;
    margin-bottom: 8px;
}
.content-audit__boxes {
    background: var(--default-surfaces-light);
    padding: 16px;
    border-radius: 12px;
}
.content-audit__opportunities {
    padding: 8px;
    display: grid;
    gap: 2px;
}
.content-audit__opportunities-count {
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.02em;
}
.content-audit__opportunities-label {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.01em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/NewArticleModal/ModalContentAudit.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,kBAAkB;AACtB;AACA;IACI,yCAAyC;IACzC,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,aAAa;IACb,QAAQ;AACZ;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B","sourcesContent":[".modal-content-audit {\n    margin-top: 24px;\n}\n.new-article-modal__content.new-article-modal__content--new-user .modal-content-audit {\n    max-width: 100%;\n}\n.content-audit__title {\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 30px;\n    letter-spacing: -0.02em;\n    margin-bottom: 8px;\n}\n.content-audit__boxes {\n    background: var(--default-surfaces-light);\n    padding: 16px;\n    border-radius: 12px;\n}\n.content-audit__opportunities {\n    padding: 8px;\n    display: grid;\n    gap: 2px;\n}\n.content-audit__opportunities-count {\n    font-size: 32px;\n    font-weight: 700;\n    line-height: 36px;\n    letter-spacing: -0.02em;\n}\n.content-audit__opportunities-label {\n    font-size: 15px;\n    font-weight: 500;\n    line-height: 18px;\n    letter-spacing: -0.01em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
