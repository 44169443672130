// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage {
  display: flex;
  width: 100%;
  height: 100%;
}

.homepage-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  /*padding-top: 30px*/
}

.homepage-content {
  display: flex;
  /*width: 95%;*/
  justify-content: space-between;
  position: fixed;
  /*top: 100px;*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.homepage-editor {
  flex: 1 1;
  background: #eaeaea;
}

.search-topic__modal-content {
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/HomeContent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,8BAA8B;EAC9B,eAAe;EACf,cAAc;EACd,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;AACT;;AAEA;EACE,SAAO;EACP,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".homepage {\n  display: flex;\n  width: 100%;\n  height: 100%;\n}\n\n.homepage-wrapper {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  /*padding-top: 30px*/\n}\n\n.homepage-content {\n  display: flex;\n  /*width: 95%;*/\n  justify-content: space-between;\n  position: fixed;\n  /*top: 100px;*/\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n\n.homepage-editor {\n  flex: 1;\n  background: #eaeaea;\n}\n\n.search-topic__modal-content {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
