import { createSlice } from "@reduxjs/toolkit";

export const confirmationModal = createSlice({
  name: "confirmationmodal",
  initialState: {
    showGenericConfirmationModal: false,
    genericConfirmationText: '',
    genericConfirmationHandler: null,
    secondaryGenericConfirmationHandler: null,
    onCloseGenericConfirmationHandler: null
  },
  reducers: {
    setShowGenericConfirmationModal: (state, { payload }) => {
      return {
        ...state,
        showGenericConfirmationModal: payload,
      };
    },
    setGenericConfirmationText: (state, { payload }) => {
      return {
        ...state,
        genericConfirmationText: payload,
      };
    },
    setGenericConfirmationHandler: (state, { payload }) => {
      return {
        ...state,
        genericConfirmationHandler: payload,
      };
    },
    setSecondaryGenericConfirmationHandler: (state, { payload }) => {
      return {
        ...state,
        secondaryGenericConfirmationHandler: payload
      }
    },
    setOncloseHandler: (state, {payload}) => {
      return {
        ...state,
        onCloseGenericConfirmationHandler: payload
      }
    }
  },
});

// actions
export const { setShowGenericConfirmationModal } = confirmationModal.actions;
export const { setGenericConfirmationText } = confirmationModal.actions;
export const { setGenericConfirmationHandler } = confirmationModal.actions;
export const { setSecondaryGenericConfirmationHandler } = confirmationModal.actions
export const { setOncloseHandler } = confirmationModal.actions
// selectors
export const showConfirmationModal = ({ confirmationModal: { showGenericConfirmationModal } }) => showGenericConfirmationModal;
export const getGenericConfirmationText = ({ confirmationModal: { genericConfirmationText } }) => genericConfirmationText;
export const getGenericConfirmationHandler = ({ confirmationModal: { genericConfirmationHandler } }) => genericConfirmationHandler;
export const getSecondaryConfirmationHandler = ({ confirmationModal : {secondaryGenericConfirmationHandler}}) => secondaryGenericConfirmationHandler
export const getOncloseHandler = ({ confirmationModal: { onCloseGenericConfirmationHandler }}) => onCloseGenericConfirmationHandler
export default confirmationModal.reducer;
