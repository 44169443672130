import { getUserToken } from "../utils/userStatus";
import {
  setNewArticle,
  saveArticleApi,
  getArticles,
  articleById,
  saveArticleStats,
  getArticleStats,
  uploadCSV,
  exportToPdf,
  updateTechnicalFeedback,
  rateArticle,
  getArticlePlagiarismScore,
  updatePlagiarismScore,
  uploadImage,
  articleContent,
  generateArticleFromOutlineRequest,
  generateNewTitleForOutlineRequest,
  resetOutlineRequest,
  saveOutlineRequest,
  deleteArticleFromDashboard,
  stopGeneratingArticleRequest,
  getStopWordsRequest,
  deleteKeywordRequest,
  getCoverageDataRequest,
  checkTrackedKeywordsRequest,
  getShareHashRequest, addInternalLinkRequest, getInternalLinksRequest, deleteInternalLinkRequest
} from '../api/articles';

import { formatDateForStore, getTimeFromDate } from "../utils/dateUtility";
import {getGlobalState} from "../globals";

export const createNewArticle = async (keyword, articleType, projectId) => {
  const token = getUserToken();

  const { id } = await setNewArticle(
    {
      name: keyword,
      content: "Article content",
      type: articleType,
      project: projectId || 1,
    },
    token
  );

  return id;
};

export const saveArticleData = async (payload) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();

  if(sharedToken) {
    payload.shareHash = sharedToken
    delete payload.id
  }

  const result = await saveArticleApi({ ...payload }, token);
  return result;
};

export const getAllArticles = async () => {
  const token = getUserToken();

  try {
    const articles = await getArticles(token);
    const articlesWithHRDate = articles.map(({ stats, ...rest }) => {
      const newStats = {
        ...stats,
      };
      // failproof for old articles which don't have dateObject saved in the stats
      if (stats && stats.dateObject) {
        const saveTime = getTimeFromDate(stats.dateObject);
        const saveDate = formatDateForStore(stats.dateObject);

        return {
          stats: {
            ...newStats,
            saveTime,
            saveDate,
          },
          ...rest,
        };
      }

      return {
        stats,
        ...rest,
      };
    });

    // failproof
    // get only articles with stats and dateObject so we can sort them in order
    const onlyArticlesWithStats = articlesWithHRDate.filter(
      ({ stats }) => stats
    );
    const filtered = onlyArticlesWithStats.filter(
      (article) => article.stats.dateObject
    );

    const sorted = filtered.sort(
      (a, b) =>
        new Date(b.stats.dateObject).getTime() -
        new Date(a.stats.dateObject).getTime()
    );

    return sorted;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const getArticleById = async (id, sharedArticleToken) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken()
  const payload = {};

  if(sharedToken)
    payload.shareHash = sharedToken
  else if(id)
    payload.id = id

  try {
    const currentArticle = await articleById(payload, token);
    return currentArticle;
  } catch (error) {
    return {
      ...error.data,
      status: error.status,
      error: true,
    };
  }
};

export const saveStats = async (id, section = '', score = null) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();
  const payload = {section};

  if(sharedToken)
    payload.shareHash = sharedToken
  else if(id)
    payload.id = id

  if(score !== null) {
    if(section === 'outline') payload.outlineScore = score
    if(section === 'article') payload.articleScore = score
  }

  const saved = await saveArticleStats(payload, token);
  return saved;
};

export const getStats = async (id, section = '') => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();
  const payload = {};

  if(sharedToken)
    payload.shareHash = sharedToken
  else if(id)
    payload.id = id

  if(section !== '') payload.section = section;

  try {
    const articleStats = await getArticleStats(payload, token);

    return articleStats;
  } catch (error) {
    return [];
  }
};

export const uploadCSVArticles = async (file) => {
  const token = getUserToken();

  let formData = new FormData();
  formData.append("file", file);

  try {
    const uploaded = await uploadCSV(formData, token);
    return uploaded;
  } catch (data) {
    return {
      ...data,
      error: true,
    };
  }
};

export const uploadImageToEditor = async (file, setUploadProgress) => {
  const token = getUserToken();

  let formData = new FormData();
  formData.append("file", file);

  try {
    const uploaded = await uploadImage(formData, setUploadProgress, token);
    return uploaded;
  } catch (data) {
    return {
      ...data,
      error: true,
    };
  }
};

export const exportArticle = async (id, type) => {
  const token = getUserToken();
  await exportToPdf({ id, type }, token);
};

export const saveTechnicalFeedback = async (payload) => {
  const token = getUserToken();

  try {
    const result = await updateTechnicalFeedback(payload, token);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const submitRatingArticle = async (payload) => {
  const token = getUserToken();

  try {
    const result = await rateArticle(payload, token);
    return result;
  } catch ({ data }) {
    return {
      ...data,
      error: true,
    };
  }
};

export const checkPlagiarismScore = async (id) => {
  const token = getUserToken();
  const payload = {
    id,
  };

  const result = await getArticlePlagiarismScore(payload, token);
  return result;
};

export const checkPlagiarismAndUpdate = async (id) => {
  const token = getUserToken();
  const payload = {
    id,
  };

  const result = await updatePlagiarismScore(payload, token);
  return result;
};

export const contentArticle = async (id) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();
  const payload = {};

  if(sharedToken)
    payload.shareHash = sharedToken
  else if(id)
    payload.id = id

  const result = await articleContent(payload, token);
  return result
}

export const resetOutline = async (articleId, testPrompts, prompt, force) => {
  const token = getUserToken();
  const payload = {
      id: articleId,
      test_prompts: testPrompts,
      prompt: prompt,
      force: force
  };

  const result = await resetOutlineRequest(payload, token);
  return result
}

export const saveOutline = async (payload) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();

  if(sharedToken) {
    payload.shareHash = sharedToken
    delete payload.id
  }

  const result = await saveOutlineRequest(payload, token);
  return result
}

export const generateArticleFromOutline = async (payload) => {
  const token = getUserToken();

  const result = await generateArticleFromOutlineRequest(payload, token);
  return result
}


export const generateNewTitleForOutline = async ({ id, testPrompt }) => {
  const token = getUserToken();

  const result = await generateNewTitleForOutlineRequest({ id: id, test_prompts : testPrompt}, token);
  return result
}


export const stopGenerating = async (articleId) => {
  const token = getUserToken();

  const result = await stopGeneratingArticleRequest({id: articleId}, token);
  return result
}

export const getStopWordsArray = async (articleId) => {
  const token = getUserToken();

  const result = await getStopWordsRequest({id: articleId}, token);
  return result
}

export const deleteKeywordFromArticle = async (payload) => {
  const token = getUserToken();

  const result = await deleteKeywordRequest(payload, token);
  return result
}

export const getCoverageData = async (payload) => {
  const sharedToken = getGlobalState('sharedToken');
  const token = getUserToken();

  if(sharedToken) {
    payload.shareHash = sharedToken
    delete payload.id
  }

  const result = await getCoverageDataRequest(payload, token);
  return result
}

export const deleteArticle = async ( payload ) => {
  const token = getUserToken();
  const result = await deleteArticleFromDashboard(payload, token);

  return result
}

export const checkTrackedKeywords = async ( payload ) => {
  const token = getUserToken();
  const result = await checkTrackedKeywordsRequest(payload, token);

  return result
}

export const generateAndGetShareHash = async ( payload ) => {
  const token = getUserToken();
  const result = await getShareHashRequest(payload, token);

  return result
}

export const getInternalLinks = async ( payload ) => {
  const token = getUserToken();
  const result = await getInternalLinksRequest(payload, token);

  return result
}

export const addInternalLink = async ( payload ) => {
  const token = getUserToken();
  const result = await addInternalLinkRequest(payload, token);

  return result
}

export const deleteInternalLink = async ( payload ) => {
  const token = getUserToken();
  const result = await deleteInternalLinkRequest(payload, token);

  return result
}


