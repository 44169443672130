// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.switcher {
    margin: 0;
    display: flex;
    white-space: nowrap;
    gap: 8px;
    cursor: pointer;
    align-items: center;
}
.switcher input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    height: 24px;
    width: 40px;
    background-color: var(--night-symbols-secondary);
    position: relative;
    display: inline-block;
    border-radius: 24px;
    transition: 0.1s linear;
    -webkit-user-select: none;
            user-select: none;
}

.switcher-label{
    color: var(--night-symbols-primary);
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
}

.switcher:hover input ~ .checkmark {
    background-color: var(--night-symbols-secondary);
}
.switcher input:checked ~ .checkmark {
    background-color: var(--night-symbols-primary);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.switcher input:checked ~ .checkmark:after {
    left: 20px;
}
.switcher .checkmark:after {
    left: 4px;
    top: 4px;
    width: 16px;
    height: 16px;
    background: white;
    border-radius: 50%;
    display: block;
    transition: 0.1s linear;
}`, "",{"version":3,"sources":["webpack://./src/Shared/Switcher.css"],"names":[],"mappings":";AACA;IACI,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,eAAe;IACf,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,gDAAgD;IAChD,kBAAkB;IAClB,qBAAqB;IACrB,mBAAmB;IACnB,uBAAuB;IACvB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,mCAAmC;IACnC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,gDAAgD;AACpD;AACA;IACI,8CAA8C;AAClD;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;AACA;IACI,SAAS;IACT,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,uBAAuB;AAC3B","sourcesContent":["\n.switcher {\n    margin: 0;\n    display: flex;\n    white-space: nowrap;\n    gap: 8px;\n    cursor: pointer;\n    align-items: center;\n}\n.switcher input {\n    position: absolute;\n    opacity: 0;\n    cursor: pointer;\n    height: 0;\n    width: 0;\n}\n\n.checkmark {\n    height: 24px;\n    width: 40px;\n    background-color: var(--night-symbols-secondary);\n    position: relative;\n    display: inline-block;\n    border-radius: 24px;\n    transition: 0.1s linear;\n    user-select: none;\n}\n\n.switcher-label{\n    color: var(--night-symbols-primary);\n    font-size: 13px;\n    font-weight: 700;\n    line-height: 16px;\n    letter-spacing: -0.01em;\n}\n\n.switcher:hover input ~ .checkmark {\n    background-color: var(--night-symbols-secondary);\n}\n.switcher input:checked ~ .checkmark {\n    background-color: var(--night-symbols-primary);\n}\n\n.checkmark:after {\n    content: \"\";\n    position: absolute;\n    display: none;\n}\n\n.switcher input:checked ~ .checkmark:after {\n    left: 20px;\n}\n.switcher .checkmark:after {\n    left: 4px;\n    top: 4px;\n    width: 16px;\n    height: 16px;\n    background: white;\n    border-radius: 50%;\n    display: block;\n    transition: 0.1s linear;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
