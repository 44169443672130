/** @format */
import React from 'react';
import { useSelector } from 'react-redux';
import { WhiteTooltipAuto } from "../UITooltips/UITooltip";
import { CheckIcon } from "../../UI_utils/SVGIcons";
import {contentArticleDataIsEmpty, getPublishedArticleData} from '../../reducers/articles';
import { formatPublishDate } from "../../utils/dateUtility";

const PublishButton = ({
                           onClick,
                           className = '',
                           tooltipPosition = 'bottom-end',
                           smallBtn = false,
                           brand = false
                       }) => {
    const publishedData = useSelector(getPublishedArticleData);
    const articleIsEmpty = useSelector(contentArticleDataIsEmpty);

    const disabled = articleIsEmpty && !publishedData?.author;

    const getTooltipContent = () => {
        if(disabled)
            return 'Start building your article from scratch,<br/>using the <b>AI generation</b> or by importing<br />the live version of the article, if available. </br>Then mark as <b>Published</b> to track its<br />performance by monitoring relevant keywords.'

        if (publishedData?.author) {
            const formattedDate = formatPublishDate(publishedData.date);
            return `<span>Marked as published on <b>${formattedDate}</b><br />by <b>${publishedData.author}</b></span>`;
        }
        return 'Mark this article as published to start tracking its performance.';
    };

    const onClickHandler = () => {
        if(!publishedData?.author && !disabled) onClick();
    };

    const buttonClasses = `publish-button ${className} ${smallBtn ? 'small-btn' : ''} ${brand ? 'brand' : ''} ${publishedData?.author ? 'is-published' : ''} ${disabled ? 'disabled' : ''}`;

    return (
        <WhiteTooltipAuto
            placement={tooltipPosition}
            title={<span dangerouslySetInnerHTML={{ __html: getTooltipContent() }} />}
        >
            <div className={buttonClasses} onClick={onClickHandler}>
                {publishedData?.author ? (
                    <>
                        <CheckIcon />
                        <span>Published</span>
                    </>
                ) : (
                    <>Publish</>
                )}
            </div>
        </WhiteTooltipAuto>
    );
};

export default PublishButton;
