import React from 'react';
import AppSwitcher from "../../../Shared/AppSwitcher";
import {WhiteTooltip, WhiteTooltipAuto} from "../../UITooltips/UITooltip";
import {TEXTS} from "../../../common/consts";
import {useSelector} from "react-redux";
import {styleForGeneratingArticle} from "../../../reducers/articleSettings";

const AiCardOptionsArticle = ({
    hidePromptInput,
    generateArticle,
    regenerateCount,
    disabledTooltip,
    lastStep,
    hasSkip,
    stepName,
                                  hideBackButton
}) => {
    const styleEmpty = useSelector(styleForGeneratingArticle);

    const styleEmptyDisableTooltip = () => {
        if(stepName === 'style' && !styleEmpty) return 'Configure style before continuing'
    }

    const returnFinalTooltipToDisplay = () => styleEmptyDisableTooltip() || disabledTooltip || '';

    const continueHandler = () => {
        if(!returnFinalTooltipToDisplay())
            generateArticle('article')
    }

    return (
        <>
            {!hideBackButton && (
                <div className="ai-card-button ai-card-button--reset" onClick={hidePromptInput}>
                    Back
                </div>
            )}
            {hasSkip && (
                <div className="ai-card-button ai-card-button--reset" onClick={generateArticle}>
                    Skip
                </div>
            )}
            <WhiteTooltipAuto
                placement="bottom"
                title={returnFinalTooltipToDisplay()}
            >
                <div className={`ai-card-button ai-card-button--show-prompt ${!!returnFinalTooltipToDisplay() ? 'disabled' : ''}`}
                     onClick={continueHandler}
                >
                    {!lastStep ? 'Continue' : regenerateCount === 0 ? 'Generate' : 'Regenerate'}
                </div>
            </WhiteTooltipAuto>
        </>
    )
};

export default AiCardOptionsArticle;