import React, {useEffect, useMemo, useState, Fragment} from 'react';
import { BubbleMenu } from '@tiptap/react';
import {useDispatch, useSelector} from "react-redux";
import {getImageLimitError, setLimitImagesError} from "../../reducers/images";
import ImageUploader from "./ImageUploader";
import Hyperlink from "./Hyperlink";
import MenuItemTop from "./MenuItemTop";
import Popup from "reactjs-popup";
import AiCommands from "./AiCommands";
import "./TextMenu.css"
import {
    ListBulletIcon,
    ListNumberedIcon,
    Heading2Icon,
    Heading3Icon,
    Heading4Icon,
    ParagraphIcon,
    BoldIcon,
    ItalicIcon,
    LinkIcon,
    ImageIcon,
    LeftAlignIcon,
    CenterAlignIcon,
    RightAlignIcon,
    JustifyAlignIcon,
    CodeBlockIcon,
    HRIcon, WriterMagicPen, UndoIcon, RedoIcon
} from "../../UI_utils/SVGIcons";
import {getGlobalState} from "../../globals";

export const TextMenu = ({ editor, writerMode = 'outline' }) => {
    const sharedToken = getGlobalState('sharedToken');

    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState('');
    const imageLimitError = useSelector(getImageLimitError);


    useEffect(() => {
        if (imageLimitError) {
            setIsOpen(true);
            setError(imageLimitError);
        }
    }, [imageLimitError]);

    const items = useMemo(
        () => {
            const options = [
                {
                    title: 'AI Tools',
                    isComponent: true,
                    Component: <AiCommands editor={editor} />,
                    hideOnOutline: true,
                },
                {
                    type: "divider",
                    hideOnOutline: true,
                },
                {
                    Icon: Heading2Icon,
                    title: 'Heading 2',
                    action: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
                    isActive: () => editor && editor.isActive('heading', { level: 2 }),
                    hideOnOutline: false
                },
                {
                    Icon: Heading3Icon,
                    title: 'Heading 3',
                    action: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
                    isActive: () => editor && editor.isActive('heading', { level: 3 }),
                    hideOnOutline: false
                },
                {
                    Icon: Heading4Icon,
                    title: 'Heading 4',
                    action: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
                    isActive: () => editor && editor.isActive('heading', { level: 4 }),
                    hideOnOutline: false
                },
                {
                    Icon: ParagraphIcon,
                    title: 'Paragraph',
                    action: () => editor.chain().focus().setParagraph().run(),
                    isActive: () => editor && editor.isActive('paragraph'),
                    hideOnOutline: false
                },
                {
                    type: "divider",
                    hideOnOutline: true
                },
                {
                    Icon: BoldIcon,
                    title: 'Bold',
                    action: () => editor.chain().focus().toggleBold().run(),
                    isActive: () => editor && editor.isActive('bold'),
                    hideOnOutline: true
                },
                {
                    Icon: ItalicIcon,
                    title: 'Italic',
                    action: () => editor.chain().focus().toggleItalic().run(),
                    isActive: () => editor && editor.isActive('italic'),
                    hideOnOutline: true
                },
                {
                    Icon: ImageIcon,
                    title: 'Add Image',
                    isComponent: true,
                    hideOnOutline: true,
                    Component: (
                        <ImageUploader
                            Icon={ImageIcon}
                            title="Add Image"
                            onUploadProgres={setProgress}
                            onUploadError={setError}
                            onUploadStart={() => setIsOpen(true)}
                            onUploadSuccess={() => setIsOpen(false)}
                        />
                    ),
                },
                {
                    Icon: LinkIcon,
                    title: 'Link',
                    isComponent: true,
                    Component: <Hyperlink Icon={LinkIcon} title="Link" />,
                    hideOnOutline: true
                },
                {
                    type: "divider",
                    hideOnOutline: true
                },
                {
                    Icon: LeftAlignIcon,
                    title: 'Align Left',
                    action: () => editor.chain().focus().setTextAlign('left').run(),
                    isActive: () => editor && editor.isActive({ textAlign: 'left' }),
                    hideOnOutline: true
                },
                {
                    Icon: CenterAlignIcon,
                    title: 'Align Center',
                    action: () => editor.chain().focus().setTextAlign('center').run(),
                    isActive: () => editor && editor.isActive({ textAlign: 'center' }),
                    hideOnOutline: true
                },
                {
                    Icon: RightAlignIcon,
                    title: 'Align Right',
                    action: () => editor.chain().focus().setTextAlign('right').run(),
                    isActive: () => editor && editor.isActive({ textAlign: 'right' }),
                    hideOnOutline: true
                },
                {
                    Icon: JustifyAlignIcon,
                    title: 'Align Justified',
                    action: () => editor.chain().focus().setTextAlign('justify').run(),
                    isActive: () => editor && editor.isActive({ textAlign: 'justify' }),
                    hideOnOutline: true
                },
                {
                    type: "divider",
                },
                {
                    Icon: ListBulletIcon,
                    title: 'Bullet List',
                    action: () => editor.chain().focus().toggleBulletList().run(),
                    isActive: () => editor && editor.isActive('bulletList'),
                    hideOnOutline: false
                },
                {
                    Icon: ListNumberedIcon,
                    title: 'Ordered List',
                    action: () => editor.chain().focus().toggleOrderedList().run(),
                    isActive: () => editor && editor.isActive('orderedList'),
                    hideOnOutline: false
                },
                {
                    Icon: CodeBlockIcon,
                    title: 'Code Block',
                    action: () => editor.chain().focus().toggleCodeBlock().run(),
                    isActive: () => editor && editor.isActive('codeBlock'),
                    hideOnOutline: true
                },
                {
                    Icon: HRIcon,
                    title: 'Horizontal Rule',
                    action: () => editor && editor.chain().focus().setHorizontalRule().run(),
                    hideOnOutline: true
                },
                {
                    type: "divider",
                },
                {
                    Icon: UndoIcon,
                    title: 'Undo',
                    action: () => editor && editor.chain().focus().undo().run(),
                    isDisabled: () => !editor || !editor.can().undo(),
                    hideOnOutline: true
                },
                {
                    Icon: RedoIcon,
                    title: 'Redo',
                    action: () => editor && editor.chain().focus().redo().run(),
                    isDisabled: () => !editor || !editor.can().redo(),
                    hideOnOutline: true
                },
            ];

            if(sharedToken) options.splice(0, 2);

            return options
        },
        [editor]
    );

    const onClosePopup = () => {
        dispatch(setLimitImagesError(null));
        setError('');
        setIsOpen(false);
    };

    if (!editor) {
        return null;
    }

    return (
        <BubbleMenu
            editor={editor}
            tippyOptions={{
                duration: 100,
                placement: 'top-start',
                maxWidth: 'none'
            }}
            shouldShow={({ editor, state }) => {
                const { selection } = state;
                return !selection.empty && editor.isEditable;
            }}
        >
            <div>
                <div className="text-menu">
                    {items
                        .filter(item => writerMode === 'article' || (writerMode === 'outline' && !item.hideOnOutline))
                        .map((item, index) => (
                            <Fragment key={index}>
                                {item.type === "divider" ? (
                                    <span className={"divider"} />
                                ) : (
                                    <MenuItemTop {...item} />
                                )}
                            </Fragment>
                        ))}
                </div>
                <Popup open={isOpen} onClose={() => onClosePopup()} closeOnDocumentClick={true}>
                        <div className="upload-progress-wrapper">
                            {!error && <p className="upload-progress-message">UPLOADING IMAGE...</p>}
                            {error && <p className="upload-progress-error">{error}</p>}
                            {!imageLimitError && (
                                <div className="upload-progress-bar">
                                    <div
                                        style={{
                                            width: `${progress}%`,
                                            backgroundColor: `#afe9cb`,
                                            height: '14px',
                                            borderRadius: '9px',
                                        }}></div>
                                </div>
                            )}
                        </div>
                    </Popup>
            </div>
        </BubbleMenu>
    );
};
