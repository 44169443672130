import React, { useState, useCallback, useEffect, useRef } from 'react';
import DragHandle from '@tiptap-pro/extension-drag-handle-react';
import { DragIcon } from '../../UI_utils/SVGIcons';

export const ContentItemMenu = ({ editor }) => {
    const [nodeRange, setNodeRange] = useState(null);
    const [nodeRangeStart, setNodeRangeStart] = useState(null);
    const isSelecting = useRef(false);
    const tippyInstanceRef = useRef(null);

    const handleNodeChange = useCallback((data) => setNodeRangeStart(data.pos), []);

    const handleClick = useCallback(() => {
        if (!editor) return;

        editor.commands.setNodeSelection(nodeRangeStart)

        const selectedNode = editor.state.selection.node

        const range = {
            from: nodeRangeStart,
            to: nodeRangeStart + selectedNode.content.size + 1
        }

        editor.commands.focus();
        editor.commands.setTextSelection(range)
    }, [editor, nodeRange, nodeRangeStart]);

    // Reset selection flag and cleanup tippy when component unmounts
    useEffect(() => {
        return () => {
            isSelecting.current = false;
            // Ensure tippy instance is destroyed
            if (editor?.view?.dom) {
                // Remove any existing mouse event listeners
                const editorDom = editor.view.dom;
                const existingHandlers = editorDom._tippy_handlers;
                if (existingHandlers) {
                    Object.entries(existingHandlers).forEach(([event, handler]) => {
                        editorDom.removeEventListener(event, handler);
                    });
                    delete editorDom._tippy_handlers;
                }

                // Cleanup tippy instances
                const tippyInstances = editor.view.dom.querySelectorAll('[data-tippy-root]');
                tippyInstances.forEach(instance => {
                    const tippy = instance._tippy;
                    if (tippy) {
                        tippy.destroy();
                    }
                });
            }
        };
    }, [editor]);

    if (!editor) return null;

    return (
        <div className="drag-handle-wrapper">
            <DragHandle
                pluginKey="ContentItemMenu"
                editor={editor}
                onNodeChange={handleNodeChange}
                tippyOptions={{
                    offset: [2, 4],
                    zIndex: 99,
                    appendTo: document.body,
                    onShow: (instance) => {
                        tippyInstanceRef.current = instance;
                    },
                    onHide: () => {
                        if (tippyInstanceRef.current) {
                            tippyInstanceRef.current = null;
                        }
                    },
                    interactive: true,
                    aria: {
                        content: 'dialog',
                        expanded: true
                    }
                }}
            >
                <div 
                    className="drag-handle-button"
                    onClick={handleClick}
                    role="button"
                    tabIndex={0}
                    aria-label="Drag to reorder"
                >
                    <DragIcon />
                </div>
            </DragHandle>
        </div>
    );
};
