// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option-button {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  color: gray;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}

.option-button:focus {
  outline: none;
}

.option-button--true {
  border: 1px solid #6E78F7;
  border-radius: 4px;
  background: #6E78F7;
  color: white;
  cursor: pointer;
  margin-right: 1rem;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Shared/OptionButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":[".option-button {\n  font: inherit;\n  padding: 0.5rem 1.5rem;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  background: white;\n  color: gray;\n  cursor: pointer;\n  margin-right: 1rem;\n  text-decoration: none;\n  display: inline-block;\n  font-weight: bold;\n}\n\n.option-button:focus {\n  outline: none;\n}\n\n.option-button--true {\n  border: 1px solid #6E78F7;\n  border-radius: 4px;\n  background: #6E78F7;\n  color: white;\n  cursor: pointer;\n  margin-right: 1rem;\n  text-decoration: none;\n  display: inline-block;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
