import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";

import { setArticleId } from "../../reducers/articles";

import { setTopicCoverageData } from "../../reducers/topicCoverage";

import "./DashboardComponent.css";
import { setKeywordsSearch, setTopicData } from "../../reducers/searchTopic";
import AppLoadingScreen from "../../Shared/AppLoadingScreen";
import ArticlesSidebar from "../ArticlesSidebar/ArticlesSidebar";
import NewArticleBox from "../NewArticleModal/NewArticleBox";
import {dashboardData, dashboardTotalRows, getPublishedArticlesCount} from "../../reducers/dashboard";
import {getUserPublishFlow} from "../../reducers/user";
import AppTabSelector from "../../Shared/AppTabSelector";

const DasboardComponent = () => {
  const dispatch = useDispatch();
  const [hasArticles, setHasArticles] = useState(null);

    const totalPublishedArticles = useSelector(getPublishedArticlesCount);
    const dashboard = useSelector(dashboardData);
    const totalArticles = useSelector(dashboardTotalRows);
    const publishFlow = useSelector(getUserPublishFlow);

    const [dummyTabsOptions, setDummyTabsOptions] = useState([
        {
            label: 'In progress',
            key:'in_progress',
            info: ''

        }, {
            label: 'Published',
            key: 'published',
            info: ''
        }])
    const [selectedDummyTab, setSelectedDummyTab] = useState(dummyTabsOptions[0].key);


    useEffect(() => {
        if(totalArticles !== null && totalPublishedArticles !== null) {
            setDummyTabsOptions(prevOptions =>
                prevOptions.map(option =>
                    option.key === 'in_progress'
                        ? { ...option, info: `${totalArticles - totalPublishedArticles}` }
                        : {
                            ...option,
                            info: `${totalPublishedArticles}`,
                            disabled: !totalPublishedArticles,
                            tooltip: !totalPublishedArticles ? 'Publish articles to unlock performance tracking.<br />Published content provides valuable insights on<br />keyword rankings, visibility metrics, and traffic<br />performance that help optimize your SEO strategy' : ''
                        }
                )
            );
        }
    }, [totalPublishedArticles, totalArticles, publishFlow]);


  // clean up all article related data when loading this page
  useEffect(() => {
    dispatch(setArticleId(null));
    dispatch(setTopicData(null));
    //   // reset topic coverage data when coming from eshop
    dispatch(setTopicCoverageData([]));
    //   // reset editor data to empty
    dispatch(setKeywordsSearch(null));
  }, []);

    const openPublishedArticles = () => window.parent.postMessage({openPublished: true}, '*');
    const onSwitch = (option) => {
        if(totalPublishedArticles) {
            setSelectedDummyTab(option);
            if(option === 'published') openPublishedArticles()
        }
    }

  return (
    <>
      {totalArticles === null && <AppLoadingScreen />}
      <div className={`dashboard-page ${!!totalArticles || !!(totalArticles - totalPublishedArticles) ? 'dashboard-page--sidebar' : ''} ${publishFlow ? 'dashboard-page--published' : ''}`}>
          <div className="dashboard-page__sidebar">
            <ArticlesSidebar
                open={publishFlow ? !!(totalArticles - totalPublishedArticles) : !!totalArticles}
                close={false}
                absoluteSidebar={false}
            />
          </div>
        <div className="dashboard-page__content">
            {publishFlow && (
                <AppTabSelector
                    selectedOption={selectedDummyTab}
                    options={dummyTabsOptions}
                    onSwitch={onSwitch}
                    className={"dummy-tabs"}
                    tooltipPlacement={"bottom"}
                />
            )}
          <NewArticleBox noArticles={!totalArticles} close={false} />
        </div>
      </div>
    </>
  )

};

export default DasboardComponent;
