// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h1-article-heading {
  font-size: 14px;
  font-weight: 500;
  color: #384359;
  line-height: 23px;
  margin-right: 30px;
}

.article-heading {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.article-heading .heading-level {
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 4px;
}

.h3-article-heading {
  margin-left: 24px;
}

.h4-article-heading {
  margin-left: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/HeadingsInArticle.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".h1-article-heading {\n  font-size: 14px;\n  font-weight: 500;\n  color: #384359;\n  line-height: 23px;\n  margin-right: 30px;\n}\n\n.article-heading {\n  font-size: 13px;\n  line-height: 20px;\n  letter-spacing: -0.01em;\n  font-weight: 500;\n}\n\n.article-heading .heading-level {\n  font-weight: 700;\n  text-transform: uppercase;\n  margin-right: 4px;\n}\n\n.h3-article-heading {\n  margin-left: 24px;\n}\n\n.h4-article-heading {\n  margin-left: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
