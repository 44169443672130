import React from 'react';
import './HowItWorksBox.css'

const HowItWorksBox = ({step, title, text, clickable, section, clickHandler}) => {

    const clickDispatch = (event) => clickHandler(event, !!clickable, section)

    return (
        <div className="hiwb">
            <div className="hiwb_step">{step}</div>
            <div className="hiwb_info">
                <div className="hiwb_title">{title}</div>
                <div className="hiwb_text"
                     dangerouslySetInnerHTML={{ __html: text }}
                     onClick={(e) => clickDispatch(e)}
                />
            </div>

        </div>
    );
};

export default HowItWorksBox