import { getUserToken } from "../utils/userStatus";
import {
  generateStyleFromUrlRequest, getCampaignStyleRequest,
  getDefaultWritingStyleRequest, getStyleNameRequest, getStyleUsedForGeneratingArticleRequest,
  saveCampaignStyleRequest, saveStyleUsedForGeneratingArticleRequest
} from "../api/article-settings";

export const getDefaultWritingStyle = async () => {
  const token = getUserToken();

  const result = await getDefaultWritingStyleRequest(token);
  return result;
};

export const getStyleUsedForGeneratingArticle = async (payload) => {
  const token = getUserToken();

  const result = await getStyleUsedForGeneratingArticleRequest(payload, token);
  return result;
};

export const saveStyleUsedForGeneratingArticle = async (payload) => {
  const token = getUserToken();

  const result = await saveStyleUsedForGeneratingArticleRequest(payload, token);
  return result;
};

export const saveCampaignStyle = async (payload) => {
  const token = getUserToken();

  const result = await saveCampaignStyleRequest(payload, token);
  return result;
};

export const generateStyleFromUrl = async (payload) => {
  const token = getUserToken();

  const result = await generateStyleFromUrlRequest(payload, token);
  return result;
};

export const getCampaignStyle = async (payload) => {
  const token = getUserToken();

  const result = await getCampaignStyleRequest(payload, token);
  return result;
};

export const getStyleName = async (payload) => {
  const token = getUserToken();

  const result = await getStyleNameRequest(payload, token);
  return result;
};
