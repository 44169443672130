// URL section mapping
export const ARTICLE_SECTIONS = {
    NEW: 'new',
    GENERATED: 'generated',
    CURRENT: 'current',
    OUTLINE: 'outline'
};

// Mapping from tab values to URL sections
export const URL_SECTIONS = {
    article: 'new',
    ai: 'generated',
    live: 'current',
    outline: 'outline'
};

// Reverse mapping from tab values to URL sections
export const REVERSE_URL_SECTIONS = {
    new: 'article',
    generated: 'ai',
    current: 'live',
    outline: 'outline'
};

/**
 * Get URL section key by tab value
 * @param {string} tabValue - The tab value to look up (e.g., 'new', 'generated')
 * @returns {string|undefined} - The corresponding URL section (e.g., 'article', 'ai')
 */
export const getUrlSectionKey = (tabValue) => {
    return REVERSE_URL_SECTIONS[tabValue];
};

/**
 * Get tab value by URL section
 * @param {string} urlSection - The URL section to look up (e.g., 'article', 'ai')
 * @returns {string|undefined} - The corresponding tab value (e.g., 'new', 'generated')
 */
export const getTabValue = (urlSection) => {
    return URL_SECTIONS[urlSection];
};

/**
 * Update URL with article section while preserving site_id
 * @param {string} articleId - The article ID
 * @param {string} section - The section to add to URL
 * @param {string} searchString - The search string from location.search or window.location.search
 * @returns {string} - The new URL path
 */
export const buildArticleUrl = (articleId, section, searchString) => {
    const searchParams = new URLSearchParams(searchString);
    const siteId = searchParams.get('site_id');
    return `/article/${articleId}/${section}${siteId ? `?site_id=${siteId}` : ''}`;
};

/**
 * Update browser URL using either history.push or window.history.pushState
 * @param {string} path - The new URL path
 * @param {object} history - React Router history object (optional)
 */
export const updateUrl = (path, history = null) => {
    if (history) {
        history.push(path);
    } else {
        window.history.pushState({}, '', path);
    }
};
