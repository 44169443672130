import { createSlice } from "@reduxjs/toolkit";
import {
  createNewArticle,
  saveArticleData,
  getArticleById,
  saveStats,
  getStats,
  exportArticle,
  checkPlagiarismScore,
  checkPlagiarismAndUpdate,
  contentArticle,
  resetOutline,
  generateArticleFromOutline,
  saveOutline,
  generateNewTitleForOutline,
  stopGenerating,
  getStopWordsArray,
  deleteKeywordFromArticle,
  getCoverageData,
  deleteArticle,
  checkTrackedKeywords,
  generateAndGetShareHash,
  getInternalLinks,
  addInternalLink,
  deleteInternalLink,
  subscribeUser,
  checkLandingPage,
  getArticleFromLandingPage,
  checkLandingPageData,
  publishArticle,
  getAllScoresForArticle,
  updateFeedback, detectLiveUrl, checkLiveUrl
} from "../services/articles";

import {
  searchTopic,
  requestTopicDetails,
  searchTopicForCategory,
  setLoadingStep2,
  setTopicInfo,
  getCompetitionData,
  setLoadingCompetition, setAnotherMarketCompetitionData,
} from "../reducers/searchTopic";

import { setTopicCoverageData, setTopicCoverage, setHighTopicCoverage, setLowTopicCoverage } from "./topicCoverage";

import { setHeadingsForOptimization, setTopicHeadingCoverage } from "./headingsOptimization";
import { setTopicDensity, setMaxTopicDensity } from "./topicDensity";
import { setMaxImages } from "./images";
import { setMaxWordCount } from "./counters";
import { setKeywordsSearch, setTopicRules, getCompetitionOnTopic, setTopicData, setCompetitionData, getCompetitionOnCategory } from "./searchTopic";
import { setExpectedTitle, setTitleVariations, setTopicTitleCoverage } from "./titleOptimization";
import { getTimeFromDate } from "../utils/dateUtility";
import { sampleArticleStats, sampleArticle, sampleCompetition } from "../config/demoEditorData";

import { TYPE_ARTICLE, TYPE_NATIVE } from "../common/consts";
import { setApiError, setGenericErrorText, setShowGenericErrorModal, setGenericHandler, setSecondaryGenericHandler , setOncloseHandler} from "./apiErrors";
import { resetEditorData, saveEditorDataToStore } from "./textEditor";
import { checkTopicStatus } from "../api/topic-search";
import { getUserToken } from "../utils/userStatus";
import { getGlobalState } from "../globals";
import { articleById } from "../api/articles";
import { dashboardData, dashboardTotalRows, setDashboardData, setTotalRows } from "./dashboard";
import { AlertInfoIcon, MagnifierMinus } from "../UI_utils/SVGIcons";
import {
  setCoverageDataArticleHeading,
  setCoverageDataArticleTitle,
  setCoverageDataArticleTopic,
  setCoverageDataHeading,
  setCoverageDataOutlineHeading,
  setCoverageDataOutlineTitle,
  setCoverageDataOutlineTopic,
  setCoverageDataTitle,
  setCoverageDataTopic,
  setCoverageDataUncompiledHeading,
  setCoverageDataUncompiledTitle,
  setCoverageDataUncompiledTopic,
  setCoverageDataGeneratedHeading,
  setCoverageDataGeneratedTitle,
  setCoverageDataGeneratedTopic,
  setCoverageDataLiveHeading,
  setCoverageDataLiveTitle,
  setCoverageDataLiveTopic,
} from "./keywordsCoverage";
import { setShowArticleSidebar } from './applicationMode';
import {getAndPopulateUserFlags, getAndSaveTiptapJWT, getAndSaveTiptapAIJWT} from "./user";
import {
  getArticleStyle,
  setArticleStyleOptions,
  setArticleStyleOptionsAltered, updateAndSaveArticleStyle
} from "./articleSettings";
import { ARTICLE_SECTIONS, URL_SECTIONS, getUrlSectionKey, getTabValue, buildArticleUrl, updateUrl } from '../utils/urlHelpers';
export const articleSlice = createSlice({
  name: "articles",
  initialState: {
    articleId: null,
    generateImages: false,
    articlesCollection: [],
    currentArticle: null,
    articleScore: null,
    outlineScore: null,
    generatedScore: null,
    liveScore: null,
    articleRealScore: 0,
    saveArticleStatus: "Not Saved",
    plagiarismStatus: null,
    writerMode: "outline",
    defaultTabSelected: "outline",
    newWriterMode: "outline",
    showUncompiled: false,
    getContentArticle: "",
    lastGeneratedArticle: "",
    getUncompiledContentArticle: "",
    getOutline: "",
    articleEditorLoading: true,
    outlineEditorLoading: true,
    // checkingArticle: false,
    outlineProgressStatus: null,
    outlinePrevProgressStatus: 0,
    articlePrevProgressStatus: 0,
    outlinePrevProgressStatusUpdated: false,
    articlePrevProgressStatusUpdated: false,
    outlinePrompt: "",
    uniquenessCountUsed: 0,
    articleAltered: false,
    plagLoading: false,
    completedHeadings: 0,
    totalHeadings: 0,
    articleCompleted: true,
    articleCompilationStatus: 0,
    hideAiCard: true,
    articleJustFinishedGenerating: false,
    showNewArticleModal: false,
    invalidArticleId: false,
    stopWords: [],
    ksid: null,
    interfaceReferralLink: null,
    shareHash: null,
    internalLinks: null,
    regenerateCount: 0,
    generatedWithFreshPerspective: '',
    articleIsComprehensive: false,
    currentWebsiteArticle: null,
    loadingCurrentWebsiteArticle: false,
    contentWriterCounts: {
      additionalArticles: 0,
      articlesLeft: 0,
      articlesTotal : 0,
      campaignsLeft: 0,
      campaignsTotal: 0
    },
    subscribedForGeneratingStatus: false,
    feedback: 0,
    outlineFeedback: 0,
    landingPageUrl: '',
    landingPageData: {
      kwTracked: null,
      totalKw: null,
      gsc: false,
      analytics: false,
      external: false
    },
    publishedArticleData: {
      date: null,
      author: null
    },
    liveUrl: '',
    liveUrlError: '',
    checkingLiveUrl: false,
    deletingArticle: false,
  },

  reducers: {
    setCurrentWebsiteArticle: (state, { payload }) => {
      return {
        ...state,
        currentWebsiteArticle: payload,
      };
    },
    setLiveUrl: (state, { payload }) => {
      return {
        ...state,
        liveUrl: payload,
      };
    },
    setLiveUrlError: (state, { payload }) => {
      return {
        ...state,
        liveUrlError: payload,
      };
    },
    setCheckingLiveUrl: (state, { payload }) => {
      return {
        ...state,
        checkingLiveUrl: payload,
      };
    },
    setLandingPageUrl: (state, { payload }) => {
      return {
        ...state,
        landingPageUrl: payload,
      };
    },
    setGenerateImages: (state, { payload }) => {
      return {
        ...state,
        generateImages: payload,
      };
    },
    setSubscribedForGeneratingStatus: (state, { payload }) => {
      return {
        ...state,
        subscribedForGeneratingStatus: payload,
      };
    },
    setFeedback: (state, { payload }) => {
      return {
        ...state,
        feedback: payload,
      };
    },
    setOutlineFeedback: (state, { payload }) => {
      return {
        ...state,
        outlineFeedback: payload,
      }
    },
    setLastGeneratedArticle: (state, { payload }) => {
      return {
        ...state,
        lastGeneratedArticle: payload,
      };
    },
    setLoadingCurrentWebsiteArticle: (state, { payload }) => {
      return {
        ...state,
        loadingCurrentWebsiteArticle: payload,
      };
    },
    setShareHash: (state, { payload }) => {
      return {
        ...state,
        shareHash: payload,
      };
    },
    setInternalLinks: (state, { payload }) => {
      return {
        ...state,
        internalLinks: payload,
      };
    },
    setArticleJustFinishedGenerating: (state, { payload }) => {
      return {
        ...state,
        articleJustFinishedGenerating: payload,
      };
    },
    setOutlinePrevProgressStatus: (state, { payload }) => {
      return {
        ...state,
        outlinePrevProgressStatus: payload,
      };
    },
    setArticlePrevProgressStatus: (state, { payload }) => {
      return {
        ...state,
        articlePrevProgressStatus: payload,
      };
    },
    setOutlinePrevProgressStatusUpdated: (state, { payload }) => {
      return {
        ...state,
        outlinePrevProgressStatusUpdated: payload,
      };
    },
    setArticlePrevProgressStatusUpdated: (state, { payload }) => {
      return {
        ...state,
        articlePrevProgressStatusUpdated: payload,
      };
    },
    setStopWords: (state, { payload }) => {
      return {
        ...state,
        stopWords: payload,
      };
    },
    setInvalidArticleId: (state, { payload }) => {
      return {
        ...state,
        invalidArticleId: payload,
      };
    },
    setPlagLoading: (state, { payload }) => {
      return {
        ...state,
        plagLoading: payload,
      };
    },
    setUniquenessCountUsed: (state, { payload }) => {
      return {
        ...state,
        uniquenessCountUsed: payload,
      };
    },
    setArticleAltered: (state, { payload }) => {
      return {
        ...state,
        articleAltered: payload,
      };
    },
    setOutlineProgressStatus: (state, { payload }) => {
      return {
        ...state,
        outlineProgressStatus: payload,
      };
    },
    setCompletedHeadings: (state, { payload }) => {
      return {
        ...state,
        completedHeadings: payload,
      };
    },
    setTotalHeadings: (state, { payload }) => {
      return {
        ...state,
        totalHeadings: payload,
      };
    },
    setArticleCompleted: (state, { payload }) => {
      return {
        ...state,
        articleCompleted: payload,
      };
    },
    setArticleCompilationStatus: (state, { payload }) => {
      return {
        ...state,
        articleCompilationStatus: payload,
      };
    },
    setHideAiCard: (state, { payload }) => {
      return {
        ...state,
        hideAiCard: payload,
      };
    },
    // setCheckingArticle: (state, { payload }) => {
    //     return {
    //         ...state,
    //         checkingArticle: payload,
    //     };
    // },

    setArticleId: (state, { payload }) => {
      return {
        ...state,
        articleId: payload,
      };
    },

    setArticle: (state, { payload }) => {
      return {
        ...state,
        currentArticle: payload,
      };
    },

    setArticlesCollection: (state, { payload }) => {
      return {
        ...state,
        articlesCollection: payload,
      };
    },

    setArticleScore: (state, { payload }) => {
      return {
        ...state,
        articleScore: payload,
      };
    },

    setOutlineScore: (state, { payload }) => {
      return {
        ...state,
        outlineScore: payload,
      };
    },
    setGeneratedScore: (state, { payload }) => {
      return {
        ...state,
        generatedScore: payload,
      };
    },
    setLiveScore: (state, { payload }) => {
      return {
        ...state,
        liveScore: payload,
      };
    },

    setArticleRealScore: (state, { payload }) => {
      return {
        ...state,
        articleRealScore: payload,
      };
    },

    setSaveStatus: (state, { payload }) => {
      return {
        ...state,
        saveArticleStatus: payload,
      };
    },
    setPlagiarismStatus: (state, { payload }) => {
      return {
        ...state,
        plagiarismStatus: payload,
      };
    },
    setWriterMode: (state, { payload }) => {
      return {
        ...state,
        writerMode: payload,
      };
    },
    setNewWriterMode: (state, { payload }) => {
      return {
        ...state,
        newWriterMode: payload,
      };
    },
    setDefaultTabSelected: (state, { payload }) => {
      return {
        ...state,
        defaultTabSelected: payload,
      };
    },
    setShowUncompiled: (state, { payload }) => {
      return {
        ...state,
        showUncompiled: payload,
      };
    },
    getContentArticleData: (state, { payload }) => {
      return {
        ...state,
        getContentArticle: payload,
      };
    },
    getUncompiledContentArticleData: (state, { payload }) => {
      return {
        ...state,
        getUncompiledContentArticle: payload,
      };
    },
    getOutlineData: (state, { payload }) => {
      return {
        ...state,
        getOutline: payload,
      };
    },
    setOutlinePrompt: (state, { payload }) => {
      return {
        ...state,
        outlinePrompt: payload,
      };
    },
    setArticleEditorLoadingState: (state, { payload }) => {
      return {
        ...state,
        articleEditorLoading: payload,
      };
    },
    setOutlineEditorLoadingState: (state, { payload }) => {
      return {
        ...state,
        outlineEditorLoading: payload,
      };
    },
    setShowNewArticleModal: (state, { payload }) => {
      return {
        ...state,
        showNewArticleModal: payload,
      };
    },
    setDeletingArticle: (state, { payload }) => {
      return {
        ...state,
        deletingArticle: payload,
      };
    },
    setActiveKsid: (state, { payload }) => {
      return {
        ...state,
        ksid: payload,
      };
    },
    setInterfaceReferralLink: (state, { payload }) => {
      return {
        ...state,
        interfaceReferralLink: payload,
      };
    },
    setContentWriterCounts: (state, {payload}) => {
      return {
        ...state,
        contentWriterCounts: payload
      }
    },
    setRegenerateCount: (state, {payload}) => {
      return {
        ...state,
        regenerateCount: payload
      }
    },
    setGeneratedWithFreshPerspective: (state, {payload}) => {
      return {
        ...state,
        generatedWithFreshPerspective: payload
      }
    },
    setArticleIsComprehensive: (state, {payload}) => {
      return {
        ...state,
        articleIsComprehensive: payload
      }
    },
    setLandingPageData: (state, { payload }) => {
      return {
        ...state,
        landingPageData: payload
      };
    },
    setPublishedArticleData: (state, { payload }) => {
      return {
        ...state,
        publishedArticleData: payload
      };
    }
  }
});

// actions
export const { setArticleId } = articleSlice.actions;
export const { setSubscribedForGeneratingStatus } = articleSlice.actions;
export const { setGenerateImages } = articleSlice.actions;
export const { setFeedback } = articleSlice.actions;
export const { setOutlineFeedback } = articleSlice.actions;
export const { setArticle } = articleSlice.actions;
export const { setOutlineProgressStatus } = articleSlice.actions;
export const { setCompletedHeadings } = articleSlice.actions;
export const { setTotalHeadings } = articleSlice.actions;
export const { setArticleCompleted } = articleSlice.actions;
export const { setArticleCompilationStatus } = articleSlice.actions;
export const { setHideAiCard } = articleSlice.actions;
export const { setArticlesCollection } = articleSlice.actions;
export const { setArticleScore } = articleSlice.actions;
export const { setOutlineScore } = articleSlice.actions;
export const { setGeneratedScore } = articleSlice.actions;
export const { setLiveScore } = articleSlice.actions;
export const { setArticleRealScore } = articleSlice.actions;
export const { setSaveStatus } = articleSlice.actions;
export const { setPlagiarismStatus } = articleSlice.actions;
export const { setWriterMode } = articleSlice.actions;
export const { setNewWriterMode } = articleSlice.actions;
export const { setDefaultTabSelected } = articleSlice.actions;
export const { setShowUncompiled } = articleSlice.actions;
export const { getContentArticleData } = articleSlice.actions;
export const { getUncompiledContentArticleData } = articleSlice.actions;
export const { getOutlineData } = articleSlice.actions;
export const { setOutlinePrompt } = articleSlice.actions;
export const { setUniquenessCountUsed } = articleSlice.actions;
export const { setPlagLoading } = articleSlice.actions;
export const { setArticleJustFinishedGenerating } = articleSlice.actions;
export const { setShareHash } = articleSlice.actions;
export const { setCurrentWebsiteArticle } = articleSlice.actions;
export const { setLiveUrl } = articleSlice.actions;
export const { setLiveUrlError } = articleSlice.actions;
export const { setCheckingLiveUrl } = articleSlice.actions;
export const { setLastGeneratedArticle } = articleSlice.actions;
export const { setLoadingCurrentWebsiteArticle } = articleSlice.actions;
export const { setInternalLinks } = articleSlice.actions;
export const { setOutlinePrevProgressStatus } = articleSlice.actions;
export const { setArticlePrevProgressStatus } = articleSlice.actions;
export const { setStopWords } = articleSlice.actions;
export const { setInvalidArticleId } = articleSlice.actions;
export const { setArticleAltered } = articleSlice.actions;
export const { setArticleEditorLoadingState } = articleSlice.actions;
export const { setOutlineEditorLoadingState } = articleSlice.actions;
export const { setShowNewArticleModal } = articleSlice.actions;
export const { setDeletingArticle } = articleSlice.actions;
export const { setActiveKsid } = articleSlice.actions;
export const { setInterfaceReferralLink } = articleSlice.actions;
export const { setContentWriterCounts } = articleSlice.actions
export const { setRegenerateCount } = articleSlice.actions
export const { setGeneratedWithFreshPerspective } = articleSlice.actions
export const { setArticleIsComprehensive } = articleSlice.actions
export const { setLandingPageData } = articleSlice.actions;
export const { setPublishedArticleData } = articleSlice.actions;
export const { setLandingPageUrl } = articleSlice.actions;
export const { setOutlinePrevProgressStatusUpdated } = articleSlice.actions;
export const { setArticlePrevProgressStatusUpdated } = articleSlice.actions;

// only displays plagiarism
export const plagiarismScore = (id) => async (dispatch) => {
  try {
    const plagiarism = await checkPlagiarismScore(id);
    dispatch(setPlagiarismStatus(plagiarism));
    return plagiarism;
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const setPlagiarismFromSubmit = (plagiarism) => (dispatch) => {
  dispatch(setPlagiarismStatus(plagiarism));
};

// actual check plagiarism
export const checkAndUpdatePlagiarismScore =
  (id, skipCount = false) =>
  async (dispatch, getState) => {
    try {
      const plagiarism = await checkPlagiarismAndUpdate(id);

      const { uniquenessCountUsed } = getState().articles;

      if (!skipCount) dispatch(setUniquenessCountUsed(uniquenessCountUsed + 1));
      dispatch(setArticleAltered(false));
      dispatch(setPlagiarismStatus(plagiarism));
      dispatch(setPlagLoading(false));
      return plagiarism;
    } catch (error) {
      dispatch(setApiError(error));
    }
  };

export const onCreateNewArticle = (type) => async (dispatch, getState) => {
  const { searchKeywords, userProjectId } = getState().searchtopic;

  // add type when creating a new article
  try {
    const articleId = await createNewArticle(searchKeywords, type, userProjectId);

    dispatch(setArticleId(articleId));

    return articleId;
  } catch (error) {
    dispatch(setApiError(error));
  }
};

// provide type 2 when saving the article
export const saveArticle =
  (type, editorType = "", uncompiled = false) =>
  async (dispatch, getState) => {
    // console.log("SAVING ARTICLE");
    dispatch(setSaveStatus("Autosaving..."));
    const { articleData, stats } = dispatch(getDataByType(0));

    const { competitionData, topicInfoData } = getState().searchtopic;
    const { uniquenessCountUsed, articleAltered, outlineScore, articleScore } = getState().articles;

    stats.info = topicInfoData;
    stats.competition = competitionData;
    stats.uniquenessCountUsed = uniquenessCountUsed;
    stats.articleAltered = articleAltered;

    // Object.assign(stats, {competition: competitionData, info: topicInfoData})
    const articlePayload = {
      id: articleData.articleId,
      name: articleData.title,
      content: articleData.editorData,
      uncompiled_content: uncompiled,
    };

    if (uncompiled) dispatch(getUncompiledContentArticleData(articleData.editorData));
    else dispatch(getContentArticleData(articleData.editorData));

    //TODO: get this from stats instead
    const { metaTitle, metaDescription, h1Tag } = getState().categorytexteditor;

    const descriptionPayload = {
      id: articleData.articleId,
      name: articleData.title,
      content: articleData.editorData,
      meta_title: metaTitle,
      h1_tag: h1Tag,
      meta_description: metaDescription,
    };

    const payload = type === TYPE_ARTICLE ? articlePayload : descriptionPayload;

    if (articleData.articleId) {
      try {
        const resultArticle = await saveArticleData(payload);

        const score = editorType === "outline" ? outlineScore : editorType === "article" ? articleScore : null;

        const saved = await saveStats(articleData.articleId, editorType, score);

        if (saved.coverage_data?.[editorType]) {
          dispatch(setCoverage(saved.coverage_data, editorType));
          dispatch(getScoresForArticle(articleData.articleId, editorType));
        }

        return resultArticle;
      } catch (error) {
        dispatch(setApiError(error));
      }
    }
  };

export const getDataByType = (type) => (dispatch, getState) => {
  if (type === TYPE_ARTICLE || true) {
    const {
      editorInstance,
      parsedEditor: { headings1: title },
    } = getState().texteditor;
    // extract editor data (html content) from the editor instance held in state
    const editorData = editorInstance?.getHTML() || '<h1></h1>';
    const { articleId, articleScore, outlineScore } = getState().articles;
    const { maxImages } = getState().images;
    const { maxTopicDensity, topics } = getState().topicdensity;
    const { allheadings, headingCoverage } = getState().headingsoptimization;
    const { maxWords } = getState().counters;
    const { lowTopics, highTopics } = getState().topiccoverage;
    const {
      searchKeywords,
      topicData,
      topicRules,
    } = getState().searchtopic;
    const { expectedTitle, titleVariations, titleCoverage } = getState().titleoptimization;

    const radarData = {
      images: maxImages,
      maxTopicDensity,
      headings: allheadings,
      wordcount: maxWords,
    };

    const topicCoverage = {
      high: highTopics,
      low: lowTopics,
    };

    const today = new Date();
    const stats = {
      keywordSearched: searchKeywords,
      expectedTitle,
      titleVariations,
      estimatedVisits: topicData?.estimatedVisit || 0,
      monthSearches: topicData?.monthSearches || 0,
      searchVolume: topicData?.monthSearches || 0,
      titleCoverage,
      headingCoverage,
      radarData,
      topicCoverage,
      topics,
      topicRules,
      articleScore,
      outlineScore,
      dateObject: today,
    };

    // remove whitespaces and &nbsp from title
    const trimmed = title.trim();
    const cleanTitle = trimmed.replace(/&nbsp;/g, "");

    const articleData = {
      articleId,
      title: cleanTitle || "Insert title here 2...",
      editorData,
    };

    return {
      articleData,
      stats,
    };
  } else {
    const { metaTitle, metaDescription, h1Tag } = getState().categorytexteditor;
    const { editorInstance } = getState().texteditor;
    // extract editor data (html content) from the editor instance held in state
    const categoryEditorData = editorInstance?.getHTML() || '<h1></h1>';
    const { articleId } = getState().articles;
    const { activeTopicCoverage } = getState().topiccoverage;
    const { expectedTitle } = getState().titleoptimization;
    const { searchKeywords } = getState().searchtopic;

    // remove whitespaces and &nbsp from title
    const trimmed = metaTitle && metaTitle.trim();
    const cleanTitle = trimmed && trimmed.replace(/&nbsp;/g, "");

    const articleData = {
      articleId,
      title: cleanTitle || "Insert title here 3...",
      editorData: categoryEditorData,
    };

    const today = new Date();

    const descStats = {
      keywordSearched: searchKeywords,
      topicCoverage: activeTopicCoverage,
      dateObject: today,
      metaDescription,
      h1Tag,
      metaTitle: cleanTitle,
      expectedTitle,
    };

    return {
      articleData,
      stats: descStats,
    };
  }
};

export const requestArticleById = (id, sharedArticleToken = null) => async (dispatch) => {
  dispatch(setLoadingStep2(true));
  // if fetch method was initialised with an id, we get the article from database
  // otherwise we load the sample article, meaning we are in demo mode
  try {
    const article = id || sharedArticleToken? await getArticleById(id, sharedArticleToken) : sampleArticle;
    if (article.type === TYPE_NATIVE) {
      dispatch(setLoadingStep2(false));
      dispatch(getCompetitionOnTopic(article.name));
      // set estimated visits
      const topicData = {
        estimatedVisits: null,
        monthSearches: null,
        searchVolume: null,
      };
      dispatch(setTopicData(topicData));
    } else {
      dispatch(setupStatsByArticleType(article));
    }
    dispatch(setArticle(article));

    dispatch(setArticleId(article.id));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

const checkArticleStatus = (articleId) => async (dispatch, getState) => {
  try {
    const articleData = await getArticleById(articleId);

    // const {completedHeadings, totalHeadings, articleCompleted, content} = articleData;
    const { headingsStatus: completedHeadings, totalHeadings, generateStatus: articleCompleted, content, generatedContent, uncompiledContent, compilationStatus } = articleData;
    const newContentIsEmpty = !content || content === "<h1></h1>" || content === " ";

    dispatch(setArticleEditorLoadingState(newContentIsEmpty));
    // dispatch(getContentArticleData(content));

    //generated article
    if(generatedContent) dispatch(setLastGeneratedArticle(generatedContent));
    dispatch(getUncompiledContentArticleData(uncompiledContent));

    dispatch(setArticleCompilationStatus(articleCompilationStatus(completedHeadings, totalHeadings, compilationStatus)));

    dispatch(setCompletedHeadings(completedHeadings));
    dispatch(setTotalHeadings(totalHeadings));
    dispatch(setArticleCompleted(articleCompleted));

    if (articleCompleted) {
      const { uniquenessCountUsed } = getState().articles;

      if (uniquenessCountUsed === 0) {
        dispatch(setPlagLoading(true));
        dispatch(checkAndUpdatePlagiarismScore(articleId, true));
      }

      dispatch(setArticleJustFinishedGenerating(true));

      dispatch(getScoresForArticle(articleId, "generated"));
      dispatch(getScoresForArticle(articleId, "article"));
      setTimeout(() => dispatch(setArticleJustFinishedGenerating(false)), 2000);
    }

    if (articleCompleted === false) {
      setTimeout(() => dispatch(checkArticleStatus(articleId)), 5000);
    }

    dispatch(getCoverageDataForSection(articleId, "generated"));

    if (articleCompleted) {
      dispatch(getCoverageDataForSection(articleId, "uncompiled"));
    }
  } catch (error) {
    console.log("Error. Retrying article status in 5 seconds...");
    console.error("Error during setup:", error);
    setTimeout(() => dispatch(checkArticleStatus(articleId)), 5000);
  }
};
export const checkArticleIdAndTableRows = () => (dispatch, getState) => {
  const { totalRows, allDashboardData } = getState().dashboard;
  const { invalidArticleId } = getState().articles;

  if (invalidArticleId && totalRows !== null) {
    const siteId = getGlobalState("site_id");
    const lastArticleId = allDashboardData && allDashboardData[0].id;
    const articlePath = `/article/${lastArticleId}?site_id=${siteId}`;
    const dashboardPath = `/dashboard?site_id=${siteId}`;
    const path = totalRows ? articlePath : dashboardPath;

    dispatch(
      setGenericErrorText({
        title: "Article not found",
        description:
          "This article has been previously deleted by the system. This happens when the Writer could not find enough data to build the keyword research.",
      })
    );

    setTimeout(() => (window.location.href = path), 3000);
    // setTimeout(() => dispatch(setShowGenericErrorModal(false)), 4000)

    return dispatch(setShowGenericErrorModal(true));
  }
};

const setupStatsByArticleType = (article) => async (dispatch, getState) => {
  // if fetch method was initialised with an id, we get the article stats from database
  // otherwise we load the sample stats, meaning we are in demo mode
  const { topicLanguage } = getState().searchtopic;
  const retryWithDelay = async (firstTime = true) => {
    try {
      const token = getUserToken();
      const sharedToken = getGlobalState('sharedToken');
      const siteId = getGlobalState("site_id");

      // dispatch(setCheckingArticle(true));
      dispatch(setLoadingCompetition(true));

      if(firstTime) {
        dispatch(setGeneratedWithFreshPerspective(''))
        dispatch(setArticleIsComprehensive(false))
      }

      const articleData = await getArticleById(article.id);

      if (articleData.status == 404) {
        dispatch(setInvalidArticleId(true));
        dispatch(checkArticleIdAndTableRows());

        return;
      }
      // there should be an enum for what each outline status mean
      const outlineStatus = articleData?.processingStatus;

      const initialOutlineStatus = getState().articles.outlineProgressStatus;

      if (outlineStatus !== null) {
        dispatch(setOutlineProgressStatus(parseInt(outlineStatus)));
        if(!getState().articles.setOutlinePrevProgressStatusUpdated) {
          dispatch(setOutlinePrevProgressStatusUpdated(true))
          dispatch(setOutlinePrevProgressStatus(parseInt(outlineStatus)));
        }
          // setArticlePrevProgressStatusUpdated
      }

      //hardcoded
      const {
        headingsStatus: completedHeadings,
        compilationStatus,
        totalHeadings,
        generateStatus: articleCompleted,
        generateImages,
        content,
        generatedContent,
        currentWebsiteContent,
        uncompiledContent,
        name,
        keywordSiteId,
        referral,
        currentTab,
        shareHash,
        shareableAccess,
        notifyEmail,
        regenerateCount,
        feedback,
        outlineFeedback,
        articleUrl,
        published,
      } = articleData;

      dispatch(setKeywordsSearch(name));

      const articleStatus = articleCompilationStatus(completedHeadings, totalHeadings, compilationStatus);
      dispatch(setArticleCompilationStatus(articleStatus));

      if(!getState().articles.setArticlePrevProgressStatusUpdated) {
        dispatch(setArticlePrevProgressStatusUpdated(true))
        dispatch(setArticlePrevProgressStatus(articleStatus));
      }
      //sau aici
      dispatch(setCompletedHeadings(completedHeadings));
      dispatch(setTotalHeadings(totalHeadings));
      dispatch(setArticleCompleted(articleCompleted));
      dispatch(setGenerateImages(generateImages));

      const contentIsNotEmpty = !!content && content !== "<h1></h1>" && content !== " ";

      //get article from articles/get endpoint
      dispatch(getContentArticleData(content));

      //generated article
      if(generatedContent) dispatch(setLastGeneratedArticle(generatedContent));
      if(currentWebsiteContent) {
        dispatch(setCurrentWebsiteArticle(currentWebsiteContent))
      }
      dispatch(getUncompiledContentArticleData(uncompiledContent));

      dispatch(setArticleEditorLoadingState(contentIsNotEmpty && articleCompleted === false));
      dispatch(setActiveKsid(keywordSiteId));
      dispatch(setInterfaceReferralLink(referral));
      // if (contentIsNotEmpty && outlineStatus == 9 && outlineStatus == initialOutlineStatus && firstTime) {
      if (outlineStatus == 9 && firstTime) {
          const section = window.location.pathname.split('/').pop();
          const tabValue = getTabValue(section);
          const defaultTab = tabValue ?? currentTab;
          let defaultWriterMode = defaultTab === "outline" ? "outline" : "article";
          let defaultTabSelected = tabValue ?? (currentTab === 'article' ? 'new' : currentTab);

          if(!tabValue && !sharedToken) {
            const currentTabValue = currentTab === 'article' ? 'new' : currentTab;
            const urlSection = getUrlSectionKey(currentTabValue);
            if(urlSection) {
              const newPath = buildArticleUrl(article.id, urlSection, window.location.search);
              updateUrl(newPath);
            }
          }

          dispatch(setWriterMode(defaultWriterMode));
          dispatch(setDefaultTabSelected(defaultTabSelected));
          dispatch(setNewWriterMode(defaultTabSelected));
      }

      if (outlineStatus > 0) {
        dispatch(setHideAiCard(false));
      }

      if (articleCompleted === false) {
        dispatch(setArticleEditorLoadingState(contentIsNotEmpty));
        setTimeout(() => dispatch(checkArticleStatus(article.id)), 5000);
      }
      //end hardcoded

      // if(firstTime) dispatch(checkArticleBeforeGettingsStats(article.id));

      if (outlineStatus > 0) {
        // setTimeout(() => dispatch(setHideAiCard(false)), 100)
      }

      const shareHashSaved = getState().articles.shareHash;

      if(shareHashSaved === null) {
        if(shareableAccess) {
          if(shareHash) {
            dispatch(setShareHash(shareHash));
            dispatch(getAndSaveTiptapJWT({shareHash}));
            dispatch(getAndSaveTiptapAIJWT({shareHash}));
          }
          else
            dispatch(generateShareHash(article.id))
        } else {
          dispatch(getAndSaveTiptapJWT());
          dispatch(getAndSaveTiptapAIJWT());
        }
      }

      const { stats } = article.id ? await getStats(article.id, false) : sampleArticleStats;

      if(stats) {
        dispatch(setGeneratedWithFreshPerspective(stats.fromAnotherMarketCountry))
        dispatch(setArticleIsComprehensive(!!stats.comprehensive))
      }

      if (outlineStatus != 9 && outlineStatus != 5) {
        if (outlineStatus < 0) {
          return dispatch(handleOutlineErrorStatus(outlineStatus, article));

        } else {
          //set article status and retry
          return setTimeout(() => retryWithDelay(false), 5000); // Retry after 5 seconds
        }
      }

      if(!currentWebsiteContent && !sharedToken) {
        dispatch(checkLiveUrlAndGetArticle())
      }

      // const { stats } = article.id ? await getStats(article.id, false) : sampleArticleStats;

      dispatch(getScoresForArticle(article.id));
      dispatch(getCoverageDataForSection(article.id, "article"));
      dispatch(getCoverageDataForSection(article.id, "outline"));
      dispatch(getCoverageDataForSection(article.id, "uncompiled"));
      dispatch(getCoverageDataForSection(article.id, "generated"));
      dispatch(getCoverageDataForSection(article.id, "live"));
      // set last autosave

      if (article.type === TYPE_ARTICLE) {
        // if we have stats we setup everything in place, otherwise we call for
        // step1 and step2 endpoint
        // console.log("GETTING STATS FOR ARTICLE ", stats);
        if (stats) {
          // dispatch(setCheckingArticle(false));
          const dateFromStats = stats.dateObject;
          const saveTime = getTimeFromDate(dateFromStats);
          dispatch(setSaveStatus(`Last Autosave: ${saveTime}`));
          // set keyword search
          dispatch(setKeywordsSearch(stats.keywordSearched));

          // set topic coverage
          dispatch(setTopicCoverageData(stats.topicCoverage.high));
          dispatch(setHighTopicCoverage(stats.topicCoverage.high));
          dispatch(setLowTopicCoverage(stats.topicCoverage.low));

          // set headings
          dispatch(setHeadingsForOptimization(stats.topicCoverage.high));
          dispatch(setTopicHeadingCoverage(stats.headingCoverage));

          // set title
          dispatch(setExpectedTitle(stats.expectedTitle));
          dispatch(setTitleVariations(stats.titleVariations));
          dispatch(setTopicTitleCoverage(stats.titleCoverage));

          // set topicDensity
          dispatch(setMaxTopicDensity(stats.radarData.maxTopicDensity));
          dispatch(setTopicDensity(stats.topics));

          // set images
          dispatch(setMaxImages(stats.radarData.images));

          // set wordcount
          dispatch(setMaxWordCount(stats.radarData.wordcount));

          // set estimated visits
          const topicData = {
            estimatedVisits: stats.estimatedVisits,
            monthSearches: stats.monthSearches,
            searchVolume: stats.monthSearches,
          };
          dispatch(setTopicData(topicData));

          //set article score from backend
          dispatch(setArticleRealScore(stats.articleScore));
          // dispatch(setArticleScore(stats.articleScore || 0));
          // dispatch(setOutlineScore(stats.outlineScore || 0));

          // set topic rules
          dispatch(setTopicRules(stats.topicRules));

          // get competition data for this topic
          if (article.id) {
            // dispatch(getCompetitionOnTopic(stats.keywordSearched));
            dispatch(setLoadingStep2(false));
          } else {
            dispatch(setCompetitionData(sampleCompetition));
          }

          //stats save info and competition
          // dispatch(setTopicInfo(result));
          if (stats.info) stats.info.loaded = true;

          dispatch(setTopicInfo(stats.info));
          dispatch(setCompetitionData({...stats.competition, market: stats.market }));
          if(stats.fromAnotherMarketCompetition?.avgWords) {
            dispatch(setAnotherMarketCompetitionData({...stats.fromAnotherMarketCompetition, market: stats.fromAnotherMarketCountry}));
          }
          dispatch(setArticleAltered(stats.articleAltered || false));
          dispatch(setUniquenessCountUsed(stats.uniquenessCountUsed || 0));

          if(!sharedToken) {
            dispatch(getAndUpdateKeywordsTrackedStatus({id: article.id}))
            dispatch(getArticleStyle(article.id));
            dispatch(getInternalLinksList(article.id))

            if(!getState().user.flagsUpdated) {
              dispatch(getAndPopulateUserFlags());
            }
          }

          dispatch(setSubscribedForGeneratingStatus(notifyEmail));

          dispatch(setRegenerateCount(regenerateCount))
          dispatch(setLandingPageUrl(articleUrl || ''))
          dispatch(setFeedback(feedback || 0))
          dispatch(setOutlineFeedback(outlineFeedback || 0))
          // dispatch(setGeneratedWithFreshPerspective(stats.fromAnotherMarketCountry))
          // dispatch(setArticleIsComprehensive(!!stats.comprehensive))

          if(articleUrl) {
            dispatch(checkLandingPageDataAction({url: articleUrl, id: article.id}))
          }

          if(published?.user && published?.publishedDate) {
            dispatch(setPublishedArticleData({author: published.user, date: published.publishedDate}))
          }

          // dispatch(setGeneratedWithFreshPerspective(stats.fromAnotherMarketCountry))
          // dispatch(setArticleIsComprehensive(!!stats.comprehensive))

          dispatch(setLoadingCompetition(false));
        } else {
          console.log("Retrying in 5 seconds..");
          setTimeout(() => retryWithDelay(false), 5000); // Retry after 5 seconds
        }
      }
    } catch (error) {
      console.log("Retrying in 5 seconds.... => ", error);
      setTimeout(() => retryWithDelay(false), 5000); // Retry after 5 seconds
    }
  };

  try {
    await retryWithDelay();
  } catch (error) {
    console.error("Error during setup:", error);
  }
};

const articleCompilationStatus = (cH, tH, status) => {
  //if it's not a number, save it as 0
  if(isNaN(status)) return 0

  const compilationStatus = parseInt(status);

  if(cH == tH) {
    //if completedHeadings == totalHeadings, we need to save it at least with 1,
    //but no more than 6 because we have 7 steps and we need the maximum index of those steps
    if(compilationStatus < 7) return compilationStatus + 1

    return 7
  }
}

const handleOutlineErrorStatus = (outlineStatus, article) => (dispatch, getState) => {
  const setInterfaceReferralLink = async () => {
    const payload = {id: getState().articles?.articleId};

    await dispatch(deleteArticleFromDashboard(payload));

    if (getState().articles.interfaceReferralLink) {
      window.parent.postMessage(
        {
          url: decodeURIComponent(getState().articles.interfaceReferralLink),
          newTab: false,
          isFullUrl: true,
        },
        "*"
      );
    } else {
      const siteId = getGlobalState('site_id');
      window.location.href = `/dashboard?site_id=${siteId}`;
      dispatch(setShowGenericErrorModal(false));
    }
  };

  const setCompetitionReviewLink = () => {
    if (getState().articles.ksid) {
      window.parent.postMessage(
        {
          section: "manage",
          ksid: getState().articles.ksid,
          keywordTab: "competition",
          newTab: true,
        },
        "*"
      );
    } else {
      window.parent.postMessage(
        {
          section: "manage",
          manageSection: "competition",
          keywordTab: "",
          newTab: false,
        },
        "*"
      );
    }
  };

  const continueResetingOutline = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    dispatch(resetAndGetOutline(article.id, params.test_prompts || false, "", true));
    dispatch(setShowGenericErrorModal(false));
    dispatch(setGenericErrorText(""));
    dispatch(setGenericHandler(null));
    dispatch(setOutlineProgressStatus(0))
    dispatch(setOutlinePrevProgressStatus(0))
  };
  const statusConfig = {
    "-14": {
      title: "Insufficient Data",
      description: "Available sources provide only indirect  or mixed information. Consider refining your topic to match existing  market coverage or breaking it down into more specific aspects that are  well documented.",
      buttonText: "Review",
      secondaryButtonText: "Go back",
      genericHandler: () => setCompetitionReviewLink(),
      secondaryGenericHandler: () => setInterfaceReferralLink(),
      icon: null,
      hasClose: true,
    },
    "-13": {
      title: "Verification needed",
      description: "Available sources suggest this topic may not exist as searched. Consider verifying the basic premise of the topic before proceeding.",
      buttonText: "Go back",
      secondaryButtonText: "Proceed",
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: () => continueResetingOutline(),
      icon: null,
      hasClose: true,
    },
    "-12": {
      title: "Not enough content found in the selected location for this topic.",
      description: "You can proceed with this article using Authority Enhancing strategy or go back and choose another article type to create the article.",
      buttonText: "Use Authority Enhancer",
      secondaryButtonText: "Go back",
      genericHandler: () => {
        window.parent.postMessage({quickWrite: true, topic: article.name}, '*');
      },
      secondaryGenericHandler: async () => {
        const payload = {id: getState().articles?.articleId};

        await dispatch(deleteArticleFromDashboard(payload))

        window.parent.postMessage({openInterstitialModal: true, topic: article.name}, '*');
      },
      icon: null,
      hasClose: true,
    },
    "-11": {
      title: "Inappropriate topic.",
      description: "This topic is not suitable for content creation. Please choose a different topic that doesn’t involve illegal activities, controlled substances, potential harm to individuals or society, explicit content, or other ethically sensitive issues.",
      buttonText: "Go back",
      secondaryButtonText: "Review",
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: () => setCompetitionReviewLink(),
      icon: null,
      hasClose: true,
    },
    "-10": {
      title: "Highly localized keyword",
      description: "We can’t write about highly localized topics. Please choose a more general subject that isn’t tied to a specific location.",
      buttonText: "Go back",
      secondaryButtonText: "Review",
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: () => setCompetitionReviewLink(),
      icon: null,
      hasClose: true,
    },
    "-9": {
      title: "Keyword with instant answer from Google",
      description: "We can't write about highly localized topics. Please choose a more general subject that isn’t tied to a specific location.",
      buttonText: "Go back",
      secondaryButtonText: "Review",
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: () => setCompetitionReviewLink(),
      icon: null,
      hasClose: true,
    },
    "-8": {
      title: "Limited SEO Potential Detected.",
      description: `
        The Content Writer has identified insufficient relevant keywords for your chosen topic. This may lead to:
        <ul>
          <li>Reduced SEO impact</li>
          <li>Less comprehensive article coverage</li>
        </ul>
      `,
      buttonText: "Go back",
      secondaryButtonText: "Proceed",
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: () => continueResetingOutline(),
      icon: MagnifierMinus,
      hasClose: true,
      infoText: "Use our Keyword Research tool to find high-potential<br>topics in your niche"
    },
    "-7": {
      title: "Low Search Volume",
      description: "This keyword’s low search volume (<50/month) may limit traffic. Consider higher-volume alternatives.",
      buttonText: "Go back",
      secondaryButtonText: "Proceed",
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: () => continueResetingOutline(),
      icon: null,
      hasClose: true,
    },
    "-5": {
      title: "Time-sensitive keyword.",
      // description: 'Targeting this keyword may be challenging due to constantly changing top content that requires frequent updates.',
      description: `This keyword is too time-sensitive for our Content Writer. Please choose a more evergreen topic for best results.`,
      buttonText: "Go back",
      secondaryButtonText: "Review",
      icon: null,
      hasClose: true,
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: () => setCompetitionReviewLink(),
    },
    "-4": {
      title: "Not enough content found on <br/> the competition’s informational <br/> pages for this SERP.",
      description: "A different topic must be selected due to limited SEO data.<br/> Go back and choose another topic or review the competition's content.",
      buttonText: "Go back",
      secondaryButtonText: "Review",
      icon: null,
      hasClose: true,
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: () => setCompetitionReviewLink(),
    },
    "-3": {
      title: "The topic keyword doesn’t <br>have informational intent",
      description: "Content Writer can be used to create only articles targeting keywords with informational intent.",
      buttonText: "Go back",
      secondaryButtonText: null,
      icon: null,
      hasClose: true,
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: null,
    },
    "-2": {
      title: "Not enough content found on <br/> the competition’s informational <br/> pages for this SERP.",
      description: "Reviewing the competitor's content might be helpful. If <br/> needed, you can proceed with writing an article on the topic.",
      buttonText: "Review",
      secondaryButtonText: "Proceed",
      icon: AlertInfoIcon,
      hasClose: true,
      genericHandler: () => setCompetitionReviewLink(),
      secondaryGenericHandler: () => continueResetingOutline(),
    },
    "-1": {
      title: "Data Processing Error",
      description: "We encountered an issue while processing data. Please try again or contact support if the issue persists.",
      buttonText: "Try again",
      secondaryButtonText: null,
      icon: null,
      hasClose: true,
      genericHandler: () => setInterfaceReferralLink(),
      secondaryGenericHandler: null,
    },
  };

  const defaultStatus = {
    title: "Not enough content found on <br/> the competition’s informational <br/> pages for this SERP.",
    description: "Reviewing the competitor's content might be helpful. If <br/> needed, you can proceed with writing an article on the topic.",
    // buttonText: 'Review',
    secondaryButtonText: "Proceed",
    icon: null,
    genericHandler: () => setCompetitionReviewLink(),
    secondaryGenericHandler: null,
  };

  const { title, description, buttonText, secondaryButtonText, icon, genericHandler, secondaryGenericHandler, infoText, hasClose } =
    statusConfig[outlineStatus] || defaultStatus;

  const onCloseHandler = async () => {
      const dashboardData = getState().dashboard?.allDashboardData
      const siteId = getGlobalState('site_id');
      const seomonitorInterfaceUrl = process.env.REACT_APP_SEOMONITOR_INTERFACE || 'https://app.seomonitor.com/';

      if(dashboardData.length){
        await dispatch(deleteArticleFromDashboard({id: getState().articles?.articleId}))
      }else{
        dispatch(setShowNewArticleModal(true));
      }

      window.location.href = `/dashboard?site_id=${siteId}`;
      dispatch(setShowGenericErrorModal(false));
      dispatch(setOncloseHandler(null))
      // dispatch(setShowArticleSidebar(true))
  }
  dispatch(
    setGenericErrorText({
      title,
      description,
      buttonText,
      secondaryButtonText,
      icon,
      infoText: infoText || ''
    })
  );

  if (genericHandler) {
    dispatch(setGenericHandler(genericHandler));
  }

  if (secondaryGenericHandler) {
    dispatch(setSecondaryGenericHandler(secondaryGenericHandler));
  }

  if(hasClose)
    dispatch(setOncloseHandler(onCloseHandler))

  dispatch(setShowGenericErrorModal(true));
};

export const exportArticleToPdf = (type) => async (dispatch, getState) => {
  const { articleId } = getState().articles;

  try {
    await exportArticle(articleId, type);
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const applyWriterMode = (mode) => async (dispatch, getState) => {
  dispatch(setWriterMode(mode));
};

export const getContentArticleRequest = (id) => async (dispatch, getState) => {
  // dispatch(setBothEditorsLoading(true));
  dispatch(setOutlineEditorLoadingState(true));

  try {
    const response = await contentArticle(id);

    dispatch(setOutlineEditorLoadingState(false));
    // dispatch(setBothEditorsLoading(false));

    if (response.error) return dispatch(setShowGenericErrorModal(true));

    if (response.prompt) dispatch(setOutlinePrompt(response.prompt));

    if (response.writingStyles) {
      dispatch(setArticleStyleOptions(response.writingStyles))
      dispatch(setArticleStyleOptionsAltered(response.writingStyles))
    }

    dispatch(getOutlineData(response.outline));

    return response;
  } catch (error) {
    dispatch(setShowGenericErrorModal(true));

    dispatch(setBothEditorsLoading(false));
  }
};

export const stopGeneratingArticle = (articleIdUsed) => async (dispatch, getState) => {
  return await stopGenerating({ id: articleIdUsed });
};
export const generateShareHash = (id) => async (dispatch) => {
  try {
    const response = await generateAndGetShareHash({ id });

    if(response?.shareHash) {
      dispatch(setShareHash(response.shareHash));
      dispatch(getAndSaveTiptapJWT({shareHash: response.shareHash}));
      dispatch(getAndSaveTiptapAIJWT({shareHash: response.shareHash}));
    }
  } catch (e) {
    console.log(e);
  }
};

export const getInternalLinksList = (id) => async (dispatch) => {
  try {
    const response = await getInternalLinks({ id });

    if(response?.internalLinks) {
      dispatch(setInternalLinks(response.internalLinks));
    }
  } catch (e) {
    console.log(e);
  }
};
export const addInternalLinkToList = (id, url) => async (dispatch) => {
  try {
    const response = await addInternalLink({ id, url });

    if(response?.internalLinks) {
      dispatch(setInternalLinks(response.internalLinks));
    }
  } catch (e) {
    console.log(e);
  }
};
export const deleteInternalLinkFromList = (id, url) => async (dispatch) => {
  try {
    const response = await deleteInternalLink({ id, url });

    if(response?.internalLinks) {
      dispatch(setInternalLinks(response.internalLinks));
    }
  } catch (e) {
    console.log(e);
  }
};
export const getStopWordsForArticle = (articleIdUsed) => async (dispatch, getState) => {
  try {
    const response = await getStopWordsArray({ id: articleIdUsed });

    if (response && response.length) dispatch(setStopWords(response));
  } catch (e) {
    console.log(e);
  }
};
export const updateAllCoverageData = (coverage_data) => (dispatch, getState) => {
  if (coverage_data) {
    dispatch(setCoverage(coverage_data, "article"));
    dispatch(setCoverage(coverage_data, "outline"));
    dispatch(setCoverage(coverage_data, "uncompiled"));
  }
};
export const deleteKeyword = (articleIdUsed, section, keyword) => async (dispatch, getState) => {
  try {
    const payload = {
      id: articleIdUsed,
      keyword,
      section, //"title" or "high"
    };

    const { coverage_data, stats } = await deleteKeywordFromArticle(payload);
    stats.info.loaded = true
    dispatch(setTopicInfo(stats.info));

    dispatch(updateAllCoverageData(coverage_data));
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

const setCoverage = (coverage_data, section) => (dispatch) => {
  const titleCoverage = coverage_data?.[section]?.titleCoverage;
  const headingCoverage = coverage_data?.[section]?.headingCoverage;
  const topicCoverage = coverage_data?.[section]?.topicCoverage?.high;

  // Mapping for dispatch actions
  const dispatchActions = {
    article: {
      title: setCoverageDataArticleTitle,
      topic: setCoverageDataArticleTopic,
      heading: setCoverageDataArticleHeading,
    },
    uncompiled: {
      title: setCoverageDataUncompiledTitle,
      topic: setCoverageDataUncompiledTopic,
      heading: setCoverageDataUncompiledHeading,
    },
    outline: {
      title: setCoverageDataOutlineTitle,
      topic: setCoverageDataOutlineTopic,
      heading: setCoverageDataOutlineHeading,
    },
    generated: {
      title: setCoverageDataGeneratedTitle,
      topic: setCoverageDataGeneratedTopic,
      heading: setCoverageDataGeneratedHeading,
    },
    live: {
      title: setCoverageDataLiveTitle,
      topic: setCoverageDataLiveTopic,
      heading: setCoverageDataLiveHeading,
    },
  };

  const actions = dispatchActions[section];

  // Dispatch actions if they exist
  if (actions) {
    if (titleCoverage) dispatch(actions.title(titleCoverage));
    if (topicCoverage) dispatch(actions.topic(topicCoverage));
    if (headingCoverage) dispatch(actions.heading(headingCoverage));
  }
};

export const getCoverageDataForSection = (id, section) => async (dispatch, getState) => {
  try {
    const { coverage_data } = await getCoverageData({ id, section });

    if (coverage_data?.[section]) dispatch(setCoverage(coverage_data, section));
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const getScoresForArticle = (id, section = '') => async (dispatch, getState) => {
  try {
    const {scores} = await getAllScoresForArticle({ id, section });

    if(scores) {
      if(typeof scores.article !== 'undefined')
        dispatch(setArticleScore(scores.article))
      else if(!section)
        dispatch(setArticleScore(0))
      if(typeof scores.outline !== 'undefined')
        dispatch(setOutlineScore(scores.outline))
      else if(!section)
        dispatch(setOutlineScore(0))
      if(typeof scores.generated !== 'undefined')
        dispatch(setGeneratedScore(scores.generated))
      else if(!section)
        dispatch(setGeneratedScore(0))
      if(typeof scores.live !== 'undefined')
        dispatch(setLiveScore(scores.live))
      else if(!section)
        dispatch(setLiveScore(0))
      // dispatch(setLiveScore(scores.article))
    }
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};
export const resetAndGetOutline = (articleIdUsed, testPrompt, prompt, force) => async (dispatch, getState) => {
  const { articleId, fromAnotherMarketCountry, comprehensive } = getState().articles;
  const id = articleIdUsed;
  const test_prompt = testPrompt;
  const type = {};

  if(!!fromAnotherMarketCountry) type.fresh_market = fromAnotherMarketCountry;
  if(comprehensive) type.comprehensive = comprehensive;

  // we may need this condition so that if there is a settimeout that started but the user changes article,
  // to dont load data from the previous article to the new one
  // if(articleIdUsed && articleId && articleIdUsed != articleId) return false
  dispatch(setOutlineEditorLoading(true));
  dispatch(sendArticleFeedback(0, 'outline'))

  try {
    const response = await resetOutline(id, test_prompt, prompt, force, type);

    if (response.code && response.code == 600) {
      // return setTimeout(() => {
      // dispatch(resetAndGetOutline(id))
      dispatch(setGenericErrorText(response.data.data));
      dispatch(setShowGenericErrorModal(true));
      return;
      // }, 5000)
    }

    // dispatch(setOutlineEditorLoading(false));

    if (response.error || response?.data?.error) return dispatch(setShowGenericErrorModal(true));

    if (response?.data?.id)
        // dispatch(getOutlineData(response.data.data))
      dispatch(requestArticleById(response.data.id));

    return response;
  } catch {
    dispatch(setShowGenericErrorModal(true));
  }
};

export const saveOutlineToDB = () => async (dispatch, getState) => {
  const { articleId, outlineScore } = getState().articles;

  const { editorInstance } = getState().texteditor;

  const outline = editorInstance?.getHTML() || '<h1></h1>';
  const payload = { id: articleId, outline};

  try {
    dispatch(getOutlineData(outline));

    // const { stats } = dispatch(getDataByType(0));

    const response = await saveOutline(payload);

    if (response.error) return dispatch(setShowGenericErrorModal(true));

    const { coverage_data } = await saveStats(getState().articles.articleId, "outline", outlineScore);

    if (coverage_data?.outline) {
      dispatch(setCoverage(coverage_data, "outline"));
      dispatch(getScoresForArticle(articleId, "outline"));
    }

    return response;
  } catch {
    dispatch(setShowGenericErrorModal(true));
  }
};

export const getArticleFromOutline = (payload) => async (dispatch, getState) => {
  const { getOutline, generateImages } = getState().articles;
  const { userHasWritingStyles: styleAccess } = getState().user

  if(styleAccess) {
    const { styleForGeneratingArticle: writingStyles } = getState().articleSettings

    payload = {...payload, writingStyles}
  }

  if(generateImages)
    payload = {...payload, generate_images: generateImages}

  dispatch(setArticleEditorLoading(true));
  dispatch(setCompletedHeadings("0"));
  dispatch(setArticleCompleted(false));
  dispatch(setArticleCompilationStatus(0));

  Object.assign(payload, { outline: getOutline });

  // const { getContentArticle } = getState().articles;
  //
  // let newResponse = { ...getContentArticle };

  try {
    dispatch(sendArticleFeedback(0))
    const response = await generateArticleFromOutline(payload);

    // dispatch(setArticleEditorLoading(false));

    if (response.error) return dispatch(setShowGenericErrorModal(true));

    if(styleAccess) {

      const {
        styleForGeneratingArticle: writingStyles,
        nameForGeneratingArticle: styleName,
        typeForGeneratingArticle: styleType,
        descriptionForGeneratingArticle: styleDescription
      } = getState().articleSettings

      const saveStylePayload = {
        writingStyles,
        styleName,
        styleDescription,
        styleType,
        id: payload.id
      }

      dispatch(updateAndSaveArticleStyle(saveStylePayload))
    }
    dispatch(setOutlinePrompt(payload.prompt));
    dispatch(setTotalHeadings(response.headings));
    dispatch(setArticleCompleted(false));
    dispatch(checkArticleStatus(payload.id));

    return response;
  } catch {
    dispatch(setArticleEditorLoading(false));
    dispatch(setShowGenericErrorModal(true));
  }
};

export const getNewTitleForOutline = (payload) => async (dispatch, getState) => {
  try {
    const response = await generateNewTitleForOutline(payload);
    if (response.coverage_data) {
      dispatch(setCoverage(response.coverage_data, "outline"));
      dispatch(getScoresForArticle(response.data.id, "outline"));
    }

    if (response.error) dispatch(setShowGenericErrorModal(true));

    return response.data;
  } catch {
    dispatch(setShowGenericErrorModal(true));
  }
};

// export const deleteArticleFromDashboard = (payload, instantRemoveArticle=false) => async (dispatch, getState) => {
//   try {
//     if (instantRemoveArticle) {
//       const { allDashboardData, totalRows } = getState().dashboard;
//       const updatedData = allDashboardData.filter(article => article.id !== payload.id);
//       dispatch(setDashboardData(updatedData));
//       dispatch(setTotalRows(totalRows - 1));
//     }
//
//     await deleteArticle(payload.id);
//   } catch (error) {
//     dispatch(setApiError(error));
//   }
// };
export const deleteArticleFromDashboard =  (payload, instantRemoveArticle = false, redirectToDashboard = false) => async (dispatch, getState) => {
  try{
    if (instantRemoveArticle) {

      const { allDashboardData, totalRows } = getState().dashboard;
      const updatedData = allDashboardData.filter(article => article.id !== payload.id);
      dispatch(setDashboardData(updatedData));
      dispatch(setTotalRows(totalRows - 1));
    }

    const response = await deleteArticle(payload)

    if (response.error) {
      dispatch(setShowGenericErrorModal(true));
    } else if(redirectToDashboard) {
      const siteId = getGlobalState('site_id');
      window.location.href = `/dashboard?site_id=${siteId}`;
    }

    return response.data
  }
  catch(err) {
    dispatch(setShowGenericErrorModal(true));
  }
}

export const getKeywordsTrackedStatus =  (payload) => async (dispatch) => {
  const response = await checkTrackedKeywords(payload)

  return response.keywords
}

export const getAndUpdateKeywordsTrackedStatus = (payload) => async (dispatch, getState) => {
  try {
    const keywordsStatus = await dispatch(getKeywordsTrackedStatus(payload));
    const keywords = Object.keys(keywordsStatus)

    if(keywords?.length) {
      const { activeTopicCoverage } = getState().topiccoverage;
      const { titleCoverage } = getState().titleoptimization;

      const updatedActiveTopicCoverage = activeTopicCoverage.map(kw => ({
        ...kw,
        tracked: keywordsStatus[kw?.keyword || kw?.name]
      }))

      const updatedTitleCOverage = titleCoverage.map(kw => ({
        ...kw,
        tracked: keywordsStatus[kw?.keyword || kw?.name]
      }))


      dispatch(setTopicCoverageData(updatedActiveTopicCoverage));
      dispatch(setTopicTitleCoverage(updatedTitleCOverage));

    }
  } catch(error) {
    console.error("Error during getting keywords tracking status: ", error);
  }
}

export const setArticleEditorLoading = (payload) => (dispatch) => {
  dispatch(setArticleEditorLoadingState(payload));
};

export const setOutlineEditorLoading = (payload) => (dispatch) => {
  dispatch(setOutlineEditorLoadingState(payload));
};

export const setBothEditorsLoading = (payload) => (dispatch) => {
  dispatch(setOutlineEditorLoadingState(payload));
  dispatch(setArticleEditorLoadingState(payload));
};

export const subscribingUser = (payload) => async (dispatch) => {
  dispatch(setSubscribedForGeneratingStatus(payload.notify_email));

  const response = await subscribeUser(payload);
  return response
};
export const checkLP = (payload) => async (dispatch) => {
  const response = await checkLandingPage(payload);
  return response
};
export const getArticleFromLP = (payload) => async (dispatch) => {
  dispatch(setLoadingCurrentWebsiteArticle(true))

  const response = await getArticleFromLandingPage(payload);

  if(response.content) {
    dispatch(setCurrentWebsiteArticle(response.content))
    dispatch(setLandingPageUrl(payload.url));

    dispatch(getScoresForArticle(payload.id, "live"));
  }

  dispatch(setLoadingCurrentWebsiteArticle(false))
  return response
};

export const sendArticleFeedback = (feedback, type = 'article', text = '') => async (dispatch, getState) => {
  if(type === 'outline') dispatch(setOutlineFeedback(feedback))
  else dispatch(setFeedback(feedback))

  const { articleId } = getState().articles;

  const payload = {
    id: articleId,
    feedback,
    type
  }

  if(text) payload.data = text

  const response = await updateFeedback(payload);

  return response
};
export const checkLandingPageDataAction = (payload) => async (dispatch) => {
  try {
    const response = await checkLandingPageData(payload);
    if (!response.error) {
      dispatch(setLandingPageData(response));
    }
    return response;
  } catch (error) {
    return { error: 'Failed to check landing page data' };
  }
};

export const publishArticleAction = (payload) => async (dispatch) => {
  try {
    const response = await publishArticle(payload);
    if (!response.error) {
      dispatch(setPublishedArticleData(response));
    }
    return response;
  } catch (error) {
    return { error: 'Failed to publish article' };
  }
};

export const checkLiveUrlAndGetArticle = () => async (dispatch, getState) => {
  const { articleId, checkingLiveUrl } = getState().articles;
  if(checkingLiveUrl) return ;

  const payload = { id: articleId };
  dispatch(setLiveUrl(''));
  dispatch(setLiveUrlError(''));

  dispatch(detectLiveUrlAction(payload))
    .then(response => {
      if(response.success) {
        dispatch(checkLiveUrlAction(payload)).then((response) => {
          if(response.success) {
          }
        })
      }
    })
}

export const detectLiveUrlAction = (payload) => async (dispatch) => {
  try {
    dispatch(setCheckingLiveUrl(true))

    const response = await detectLiveUrl(payload);

    if (!response.error && response.live_url) {
      dispatch(setLiveUrl(response.live_url));

      return {success: true}
    } else {
      dispatch(setLiveUrlError('No relevant article found on this topic'));
      // dispatch(setLiveUrlError(response.error || 'Failed to detect article URL'));
    }

    return response;
  } catch (error) {
    dispatch(setLiveUrlError('No relevant article found on this topic'));
    dispatch(setCheckingLiveUrl(false))
    return { error: true };
  }
};

export const checkLiveUrlAction = (payload) => async (dispatch) => {
  try {
    const response = await checkLiveUrl(payload);

    if (!response.error) {
      if(response.status == 0) {
        return setTimeout(() => dispatch(checkLiveUrlAction(payload)), 5000);
      } else {
        dispatch(setCurrentWebsiteArticle(response.content))
        dispatch(getScoresForArticle(payload.id, "live"));
        dispatch(getCoverageDataForSection(payload.id, "live"));
        dispatch(setCheckingLiveUrl(false))
        return {success: true}
      }
    } else {
      // dispatch(setLiveUrlError(response.error || 'No relevant article found on this topic'));
      dispatch(setLiveUrlError('No relevant article found on this topic'));
      dispatch(setCheckingLiveUrl(false))
    }
    return response;
  } catch (error) {
    dispatch(setCheckingLiveUrl(false))
    dispatch(setLiveUrlError('No relevant article found on this topic'));
    return { error: true };
  }
};
// selectors
export const allArticles = ({ articles: { articlesCollection } }) => articlesCollection;

export const getCurrentArticle = ({ articles: { currentArticle } }) => currentArticle;

export const getCurrentArticleId = ({ articles: { articleId } }) => articleId;

export const getArticleSavingStatus = ({ articles: { saveArticleStatus } }) => saveArticleStatus;

export const getPlagiarismStatus = ({ articles: { plagiarismStatus } }) => plagiarismStatus;

export const getArticleScore = ({ articles: { articleScore } }) => articleScore;
export const getOutlineScore = ({ articles: { outlineScore } }) => outlineScore;
export const getGeneratedScore = ({ articles: { generatedScore } }) => generatedScore;
export const getLiveScore = ({ articles: { liveScore } }) => liveScore;

export const getWriterMode = ({ articles: { writerMode } }) => writerMode;
export const getNewWriterMode = ({ articles: { newWriterMode } }) => newWriterMode;
export const getDefaultTabSelected = ({ articles: { defaultTabSelected } }) => defaultTabSelected;
export const getShowUncompiled = ({ articles: { showUncompiled } }) => showUncompiled;

export const contentArticleData = ({ articles: { getContentArticle } }) => getContentArticle;
export const uncompiledContentArticleData = ({ articles: { getUncompiledContentArticle } }) => getUncompiledContentArticle;
export const contentArticleDataIsEmpty = ({ articles: { getContentArticle } }) => !getContentArticle || getContentArticle === "<h1></h1>" || getContentArticle === " ";
export const contentOutlineIsEmpty = ({ articles: { getOutline } }) => !getOutline || getOutline === "<h1></h1>";

export const outlineData = ({ articles: { getOutline } }) => getOutline;
export const getOutlinePrompt = ({ articles: { outlinePrompt } }) => outlinePrompt;
export const getPlagLoading = ({ articles: { plagLoading } }) => plagLoading;
export const getArticleJustFinishedGenerating = ({ articles: { articleJustFinishedGenerating } }) => articleJustFinishedGenerating;
export const getShareHash = ({ articles: { shareHash } }) => shareHash;
export const getCurrentWebsiteArticle = ({ articles: { currentWebsiteArticle } }) => currentWebsiteArticle;
export const getLiveUrl = ({ articles: { liveUrl } }) => liveUrl;
export const getLiveUrlError = ({ articles: { liveUrlError } }) => liveUrlError;
export const getCheckingLiveUrl = ({ articles: { checkingLiveUrl } }) => checkingLiveUrl;
export const getLastGeneratedArticle = ({ articles: { lastGeneratedArticle } }) => lastGeneratedArticle;
export const getLoadingCurrentWebsiteArticle = ({ articles: { loadingCurrentWebsiteArticle } }) => loadingCurrentWebsiteArticle;
export const listOfInternalLinks = ({ articles: { internalLinks } }) => internalLinks;
export const getOutlinePrevProgressStatus = ({ articles: { outlinePrevProgressStatus } }) => outlinePrevProgressStatus;
export const getArticlePrevProgressStatus = ({ articles: { articlePrevProgressStatus } }) => articlePrevProgressStatus;
export const getOutlinePrevProgressStatusUpdated = ({ articles: { outlinePrevProgressStatusUpdated } }) => outlinePrevProgressStatusUpdated;
export const getArticlePrevProgressStatusUpdated = ({ articles: { articlePrevProgressStatusUpdated } }) => articlePrevProgressStatusUpdated;
export const getStopWords = ({ articles: { stopWords } }) => stopWords;
export const getInvalidArticleId = ({ articles: { invalidArticleId } }) => invalidArticleId;
export const getUniquenessCountUsed = ({ articles: { uniquenessCountUsed } }) => uniquenessCountUsed;
export const getArticleAltered = ({ articles: { articleAltered } }) => articleAltered;
// export const getCheckingArticle = ({ articles: { checkingArticle } }) => checkingArticle
export const getOutlineProgressStatus = ({ articles: { outlineProgressStatus } }) => outlineProgressStatus;

export const getArticleEditorLoadingState = ({ articles: { articleEditorLoading } }) => articleEditorLoading;
export const getOutlineEditorLoadingState = ({ articles: { outlineEditorLoading } }) => outlineEditorLoading;

export const getCompletedHeadings = ({ articles: { completedHeadings } }) => completedHeadings;
export const getTotalHeadings = ({ articles: { totalHeadings } }) => totalHeadings;
export const getArticleCompleted = ({ articles: { articleCompleted } }) => articleCompleted;
export const getArticleCompilationStatus = ({ articles: { articleCompilationStatus } }) => articleCompilationStatus;
export const checkIfArticleIsEditable = ({ articles: { articleCompleted, totalHeadings, completedHeadings } }) => {
  return articleCompleted !== false || true;
};
export const getHideAiCard = ({ articles: { hideAiCard } }) => hideAiCard;
export const getShowNewArticleModal = ({ articles: { showNewArticleModal } }) => showNewArticleModal;
export const getDeletingArticle = ({ articles: { deletingArticle } }) => deletingArticle;
export const getContentWriterCounts = ({ articles: { contentWriterCounts }}) => contentWriterCounts
export const getRegenerateCount = ({ articles: { regenerateCount }}) => regenerateCount
export const getGeneratedWithFreshPerspective = ({ articles: { generatedWithFreshPerspective }}) => generatedWithFreshPerspective
export const getArticleIsComprehensive = ({ articles: { articleIsComprehensive }}) => articleIsComprehensive
export const userSubscribed = ({ articles: { subscribedForGeneratingStatus} }) => subscribedForGeneratingStatus
export const getLandingPageUrl = ({ articles: { landingPageUrl} }) => landingPageUrl
export const articleFeedback = ({ articles: { feedback} }) => feedback
export const outlineFeedback = ({ articles: { outlineFeedback} }) => outlineFeedback
export const getLandingPageData = ({ articles: { landingPageData } }) => landingPageData;
export const getPublishedArticleData = ({ articles: { publishedArticleData } }) => publishedArticleData;
export const getGenerateImages = ({ articles: { generateImages } }) => generateImages;

export default articleSlice.reducer;
