// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-keywords-table-header {
    display: flex;
    align-items: center;
    color: var(--default-symbols-primary);
    padding-bottom: 8px;
}

.article-keywords-table-header.loading .search-icon {
    opacity: 0.2;
}

.column-header {
    /*padding-inline: 8px;*/
}

.input-search {
    padding-left: 20px;
    border: none;
    outline: none;
}

.input-search--keyword {
    position: relative;
}

.column-header,
.input-search,
.input-search::placeholder {
    color: var(--default-symbols-primary);
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    text-align: left;
    background: transparent;
}

.search-icon {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.article-keywords-table-header .track-checkbox {
    position: absolute;
    left: -8px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ArticleKeywordsTable/ArticleKeywordsTableHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,qCAAqC;IACrC,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;;;IAGI,qCAAqC;IACrC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,QAAQ;IACR,SAAS;IACT,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".article-keywords-table-header {\n    display: flex;\n    align-items: center;\n    color: var(--default-symbols-primary);\n    padding-bottom: 8px;\n}\n\n.article-keywords-table-header.loading .search-icon {\n    opacity: 0.2;\n}\n\n.column-header {\n    /*padding-inline: 8px;*/\n}\n\n.input-search {\n    padding-left: 20px;\n    border: none;\n    outline: none;\n}\n\n.input-search--keyword {\n    position: relative;\n}\n\n.column-header,\n.input-search,\n.input-search::placeholder {\n    color: var(--default-symbols-primary);\n    font-size: 13px;\n    font-weight: 700;\n    line-height: 16px;\n    letter-spacing: -0.01em;\n    text-align: left;\n    background: transparent;\n}\n\n.search-icon {\n    position: absolute;\n    transform: translateY(-50%);\n    top: 50%;\n    left: 2px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    pointer-events: none;\n}\n\n.article-keywords-table-header .track-checkbox {\n    position: absolute;\n    left: -8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
