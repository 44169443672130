import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import './AiCommands.css';
import {WriterMagicPen} from "../../UI_utils/SVGIcons";

const TONES = {
    PROFESSIONAL: 'professional',
    CASUAL: 'casual',
    CONFIDENT: 'confident',
    FRIENDLY: 'friendly',
    STRAIGHTFORWARD: 'straightforward'
};

const AiCommands = ({ editor }) => {
    const [open, setOpen] = useState(false);
    const [showCustomPrompt, setShowCustomPrompt] = useState(false);
    const [showAITemplate, setShowAITemplate] = useState(false);
    const [customPrompt, setCustomPrompt] = useState('');

    const closeModal = () => {
        setOpen(false);
        setShowCustomPrompt(false);
        setCustomPrompt('');
    };

    const handleCustomPromptSubmit = () => {
        if (customPrompt.trim()) {
            editor.chain().focus().aiCustomTextCommand({
                stream: true,
                format: 'rich-text',
                prompt: customPrompt.trim()
            }).run();
            closeModal();
            setCustomPrompt('');
            setShowCustomPrompt(false);
        }
    };

    const handleCustomPromptCancel = () => {
        setShowCustomPrompt(false);
        setCustomPrompt('');
    };

    const renderCustomPromptUI = () => (
        <div className="custom-prompt-container">
            <textarea
                className="custom-prompt-textarea"
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                placeholder="Enter your prompt here"
                autoFocus
            />
            <div className="custom-prompt-buttons">
                {/*<button*/}
                {/*    className="custom-prompt-button"*/}
                {/*    onClick={() => setShowAITemplate(true)}*/}
                {/*>*/}
                {/*    Template*/}
                {/*</button>*/}
                {/*<button*/}
                {/*    className="custom-prompt-button"*/}
                {/*    onClick={handleCustomPromptCancel}*/}
                {/*>*/}
                {/*    Cancel*/}
                {/*</button>*/}
                <button
                    className="custom-prompt-button submit"
                    onClick={handleCustomPromptSubmit}
                    disabled={!customPrompt.trim()}
                >
                    Apply
                </button>
            </div>
        </div>
    );

    const aiCommands = [
        {
            label: 'Complete',
            key: 'complete',
            action: () => {
                editor.chain().focus().aiComplete({ stream: true, format: 'rich-text' }).run();
                closeModal();
            }
        },
        {
            label: 'Make longer',
            key: 'extend',
            action: () => {
                editor.chain().focus().aiExtend({ stream: true, format: 'rich-text' }).run();
                closeModal();
            }
        },
        {
            label: 'Rephrase',
            key: 'rephrase',
            action: () => {
                editor.chain().focus().aiRephrase({ stream: true, format: 'rich-text' }).run();
                closeModal();
            }
        },
        {
            label: 'Make shorter',
            key: 'shorten',
            action: () => {
                editor.chain().focus().aiShorten({ stream: true, format: 'rich-text' }).run();
                closeModal();
            }
        },
        {
            label: 'Simplify',
            key: 'simplify',
            action: () => {
                editor.chain().focus().aiSimplify({ stream: true, format: 'rich-text' }).run();
                closeModal();
            }
        },
        {
            label: 'Summarize',
            key: 'summarize',
            action: () => {
                editor.chain().focus().aiSummarize({ stream: true, format: 'rich-text' }).run();
                closeModal();
            }
        },
        {
            label: 'Change tone',
            key: 'tone',
            submenu: [
                {
                    label: 'Professional',
                    key: 'tone-professional',
                    action: () => {
                        editor.chain().focus().aiAdjustTone(TONES.PROFESSIONAL, { stream: true, format: 'rich-text' }).run();
                        closeModal();
                    }
                },
                {
                    label: 'Casual',
                    key: 'tone-casual',
                    action: () => {
                        editor.chain().focus().aiAdjustTone(TONES.CASUAL, { stream: true, format: 'rich-text' }).run();
                        closeModal();
                    }
                },
                {
                    label: 'Confident',
                    key: 'tone-confident',
                    action: () => {
                        editor.chain().focus().aiAdjustTone(TONES.CONFIDENT, { stream: true, format: 'rich-text' }).run();
                        closeModal();
                    }
                },
                {
                    label: 'Friendly',
                    key: 'tone-friendly',
                    action: () => {
                        editor.chain().focus().aiAdjustTone(TONES.FRIENDLY, { stream: true, format: 'rich-text' }).run();
                        closeModal();
                    }
                },
                {
                    label: 'Straightforward',
                    key: 'tone-straightforward',
                    action: () => {
                        editor.chain().focus().aiAdjustTone(TONES.STRAIGHTFORWARD, { stream: true, format: 'rich-text' }).run();
                        closeModal();
                    }
                }
            ]
        }
    ];

    const renderCommandsMenu = () => (
        <div className="ai-commands-menu">
            {aiCommands.map((command) => {
                if (command.submenu) {
                    return (
                        <div key={command.key} className="submenu-container">
                            <div className="submenu-trigger">{command.label}</div>
                            <div className="submenu">
                                {command.submenu.map((subCommand) => (
                                    <button
                                        key={subCommand.key}
                                        className="ai-command-button"
                                        onClick={subCommand.action}
                                    >
                                        {subCommand.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    );
                }
                return (
                    <button
                        key={command.key}
                        className="ai-command-button"
                        onClick={command.action}
                    >
                        {command.label}
                    </button>
                );
            })}
            <button
                className="ai-command-button"
                onClick={() => setShowCustomPrompt(true)}
            >
                Custom prompt
            </button>
        </div>
    );

    return (
        <Popup
            open={open}
            onClose={closeModal}
            onOpen={() => setOpen(true)}
            trigger={<button className="ai-commands-trigger"><WriterMagicPen /> Ask AI</button>}
            position="bottom left"
            nested
            className={"ai-commands"}
        >
            {open && renderCustomPromptUI()}
            {showAITemplate && renderCommandsMenu()}
        </Popup>
    );
};

export default AiCommands;
