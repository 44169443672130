import React from 'react';
import { WhiteTooltip } from '../UITooltips/UITooltip';


import './ArticleKeywordsTableRow.css'
import {shortenNumbers} from "../../utils/helpers";
import {
    CheckboxNewTrack,
    CheckboxNotTracked, CheckboxTracked,
    CheckIcon,
    CoverageIcon,
    HeadingIcon,
    TitleIcon,
    TrashIcon
} from "../../UI_utils/SVGIcons";
import ArticleKeywordsTableRowIcon from "./ArticleKeywordsTableRowIcon";
import ArticleKeywordsTableRowDelete from "./ArticleKeywordsTableRowDelete";
import { getSearchKeywords } from '../../reducers/searchTopic'
import { useSelector} from "react-redux";
import {getGlobalState} from "../../globals";

const ArticleKeywordsTableRow = ({
    keyword,
    searchVolume,
    used,
    usedInH,
    broad,
    doublePresencePercentage,
    isTitle,
    isTracked,
    trackMode,
    trackNewKeywords,
    toggleTrackKeyword
}) => {
    const hasSharedToken = getGlobalState('sharedToken');
    const searchedKeywords = useSelector(getSearchKeywords);
    const selectKeywordHandler = () => {
        if(!isTracked) toggleTrackKeyword(keyword)
    }
    const returnCheckboxClass = () => {
        if(isTracked) return 'track-checkbox--tracked'
        if(trackNewKeywords?.includes(keyword)) return 'track-checkbox--checked'

        return ''
    }

    const minCharForKwTooltip = () => trackMode ? 28 : 30;

    return (
        <>
            <div className="article-keywords-table-row">
                {trackMode && (
                    <WhiteTooltip
                        placement="bottom-start"
                        title={isTracked ? 'Tracked keyword' : ''}
                    >
                        <div className={`track-checkbox ${returnCheckboxClass()}`} onClick={selectKeywordHandler}>
                            {isTracked && <CheckIcon />}
                        </div>
                    </WhiteTooltip>
                )}
                <div className="article-keywords-table-column column--keyword column-row">
                    <WhiteTooltip 
                        placement="bottom"
                        title={
                            <div
                                style={{ whiteSpace: 'pre-line' }}
                                dangerouslySetInnerHTML={{
                                    __html: keyword.length > minCharForKwTooltip() ? keyword : '',
                                }}
                            />
                        }
                    >
                        <span>{keyword}</span>
                    </WhiteTooltip>
                    {broad && (
                        <WhiteTooltip
                            placement="bottom"
                            title={
                                <div
                                    style={{ whiteSpace: 'pre-line' }}
                                    dangerouslySetInnerHTML={{
                                        __html: 'This broad keyword is not included in the score calculation. However, it is important to include it in the article to ensure comprehensive coverage of the topic.',
                                    }}
                                />
                            }>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M13.2031 10.3516C13.4844 10.4922 13.5781 10.8438 13.4141 11.125L12.9453 11.8984C12.8047 12.1797 12.4531 12.2734 12.1719 12.1094L8.9375 10.1406L9.00781 13.9375C9.00781 14.2422 8.75 14.5 8.44531 14.5H7.53125C7.22656 14.5 6.96875 14.2422 6.96875 13.9375L7.0625 10.1406L3.80469 12.1094C3.52344 12.2734 3.17188 12.1797 3.03125 11.8984L2.5625 11.125C2.39844 10.8438 2.49219 10.4922 2.77344 10.3516L6.125 8.5L2.77344 6.67188C2.49219 6.53125 2.39844 6.17969 2.5625 5.89844L3.03125 5.125C3.17188 4.84375 3.52344 4.75 3.80469 4.91406L7.0625 6.88281L6.96875 3.08594C6.96875 2.78125 7.22656 2.5 7.53125 2.5H8.44531C8.75 2.5 9.00781 2.78125 9.00781 3.08594L8.9375 6.88281L12.1719 4.91406C12.4531 4.75 12.8047 4.84375 12.9453 5.125L13.4141 5.89844C13.5781 6.17969 13.4844 6.53125 13.2031 6.67188L9.875 8.5L13.2031 10.3516Z"
                                    fill="#89919C"
                                />
                            </svg>
                        </WhiteTooltip>
                    )}
                    {searchedKeywords != keyword && !hasSharedToken &&
                        <ArticleKeywordsTableRowDelete
                            keyword={ keyword }
                            section={ isTitle ? 'title' : 'high' }
                        />
                    }
                    
                </div>
                {typeof searchVolume != 'undefined' && (
                    <div className="article-keywords-table-column column--volume column-row">
                        {typeof searchVolume === 'string' ? searchVolume : shortenNumbers(searchVolume)}
                    </div>
                )}
                <div className="article-keywords-table-column column--presence column-row">
                    {doublePresencePercentage && (
                        <ArticleKeywordsTableRowIcon type={'heading'} used={usedInH} />
                    )}

                    <ArticleKeywordsTableRowIcon type={isTitle ? 'title' : 'topic'} used={used} />
                </div>
            </div>
        </>
    );
}

export default ArticleKeywordsTableRow;
