import React from 'react';
import AppSwitcher from "../../../Shared/AppSwitcher";
import {WhiteTooltip, WhiteTooltipAuto} from "../../UITooltips/UITooltip";
import {TEXTS} from "../../../common/consts";

const AiCardOptionsOutline = ({
    hidePromptInput,
    generateArticle,
    disabledTooltip,
}) => {
    return (
        <>
            <div className="ai-card-button ai-card-button--reset" onClick={hidePromptInput}>
                Go back
            </div>
            <WhiteTooltip
                placement="bottom"
                title={disabledTooltip}
            >
                <div className={`ai-card-button ai-card-button--show-prompt ${disabledTooltip ? 'disabled' : ''}`}
                     onClick={generateArticle}
                >
                    Regenerate
                </div>
            </WhiteTooltip>
        </>
    )
};

export default AiCardOptionsOutline;