/** @format */

import React, {useEffect, useState, useMemo, forwardRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './scoreCard.css';
import { CircularProgressbarWithChildren, buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from './ProgressBar';
import { getTitleOptimization, getTitleCoverage, getTitleCoveragePerc } from '../../reducers/titleOptimization';
import { getTopicDensity, getMaxTopicDensity, getTopicDensityPerc } from '../../reducers/topicDensity';
import {
    getAllHeadings,
    getUsedHeadings,
    getHeadingsPerc,
    getHeadingCoverage,
    // getHeadingCoveragePerc,
    setHeadingCoveragePerc,
} from '../../reducers/headingsOptimization';
import { useApplicationType } from '../../utils/useApplicationType';
import { getMaxImages, getCurrentImages, getUpdatedImagesPerc } from '../../reducers/images';
import { getWordCount, getMaxWordCount, getUpdatedWordsPercentage } from '../../reducers/counters';
import { getTopicCoveragePerc, getHeadingCoveragePerc } from '../../reducers/topicCoverage';
import {
    checkAndUpdatePlagiarismScore,
    exportArticleToPdf,
    getArticleAltered,
    getArticleEditorLoadingState,
    getArticleSavingStatus,
    getCurrentArticleId,
    getOutlineEditorLoadingState,
    getPlagiarismStatus,
    getPlagLoading,
    getUniquenessCountUsed,
    saveArticle,
    setArticleScore,
    setOutlineScore,
    setPlagLoading,
    getNewWriterMode,
    // setUniquenessCountUsed,
    getDataByType,
    getShowUncompiled,
    getArticleCompleted,
    contentArticleDataIsEmpty,
    getCheckingLiveUrl,
    getLastGeneratedArticle, getCurrentWebsiteArticle,
} from '../../reducers/articles';
import { isDemoApplication } from '../../reducers/applicationMode';
import { getArticleScore, getOutlineScore, getGeneratedScore, getLiveScore } from '../../reducers/articles';
import { getCompetitionData, loadingCompetitionData, loadingStep2Data } from '../../reducers/searchTopic';
import { WhiteTooltip } from '../UITooltips/UITooltip';
import {ChevronRight, SpinnerIcon} from '../../UI_utils/SVGIcons';
import { debounce } from 'lodash';
import { saveStats } from '../../services/articles';
import {updateArticleScores} from '../../reducers/dashboard'
import LoadingBlock from "../LoadingBlock/LoadingBlock";
import {
    getActiveCoverageDataHeading,
    getActiveCoverageDataTitle,
    getActiveCoverageDataTopic,
    getAllCoverageDataTitle,
    activeCoverageDataFetched,
} from "../../reducers/keywordsCoverage";

const ScoreCard = forwardRef(
    (
        { writerMode, updateShowCompetition, compact },
        ref
    ) => {
    const dispatch = useDispatch();
    const isDemoApp = useSelector(isDemoApplication);
    const applicationType = useApplicationType();

    const topicCoveragePercentage = useSelector(getTopicCoveragePerc);

    const lastGeneratedArticle = useSelector(getLastGeneratedArticle);
    const currentWebsiteArticle = useSelector(getCurrentWebsiteArticle);
    const titleCoverage = useSelector(getTitleCoverage);
    const titleCoveragePerc = useSelector(getTitleCoveragePerc);
    const articleCompleted = useSelector(getArticleCompleted);

    const currentTopicDensity = useSelector(getTopicDensity);
    const maxTopicDensity = useSelector(getMaxTopicDensity);
    const topicDensityPercentage = useSelector(getTopicDensityPerc);

    const currentHeadings = useSelector(getUsedHeadings);
    const headingCoverage = useSelector(getHeadingCoverage);
    const headingCoveragePerc = useSelector(getHeadingCoveragePerc);
    const maxHeadings = useSelector(getAllHeadings);
    const headingsPercentage = useSelector(getHeadingsPerc);

    const maxImages = useSelector(getMaxImages);
    const newWriterMode = useSelector(getNewWriterMode);
    const currentImages = useSelector(getCurrentImages);
    const imagePercentage = useSelector(getUpdatedImagesPerc);

    const currentWordCount = useSelector(getWordCount);
    const maxWordCount = useSelector(getMaxWordCount);
    const wordsPercentage = useSelector(getUpdatedWordsPercentage);

    const articleLoading = useSelector(getArticleEditorLoadingState);
    const outlineLoading = useSelector(getOutlineEditorLoadingState);
    const liveLoading = useSelector(getCheckingLiveUrl);

    const loadingContent = () => {
        if(newWriterMode === 'outline')
            return outlineLoading
        if(newWriterMode === 'current')
            return liveLoading
        if(newWriterMode === 'generated')
            return articleCompleted === false;
        return false
    }

    const plagiarism = useSelector(getPlagiarismStatus);
    const [plagScore, setPlagScore] = useState(null);
    // const [plagLoading, setPlagLoading] = useState(false)
    const [articlePlagiarism, setArticlePlagiarism] = useState(null);
    const currentArticleId = useSelector(getCurrentArticleId);
    const uniquenessCountUsed = useSelector(getUniquenessCountUsed);
    const articleAltered = useSelector(getArticleAltered);
    const plagLoading = useSelector(getPlagLoading);

    const articleScore = useSelector(getArticleScore);
    const outlineScore = useSelector(getOutlineScore);
    const generatedScore = useSelector(getGeneratedScore);
    const liveScore = useSelector(getLiveScore);
    const isTopicLoading = useSelector(loadingStep2Data);

    const competition = useSelector(getCompetitionData);

    const coverageDataTitle = useSelector(getActiveCoverageDataTitle);
    const coverageDataTopic = useSelector(getActiveCoverageDataTopic);
    const coverageDataHeading = useSelector(getActiveCoverageDataHeading);

    const activeCoverageFetched = useSelector(activeCoverageDataFetched);

    const articleIsEmpty = useSelector(contentArticleDataIsEmpty);


    const openPlagiarism = async () => {
        if (plagLoading) return;

        setArticlePlagiarism(null);

        if (!isDemoApp) {
            dispatch(setPlagLoading(true));
            // save article first and wait for response
            const res = await dispatch(saveArticle(applicationType, writerMode));
            // if article was saved do check for plagiarism
            if (res && res.id) {
                const response = await dispatch(checkAndUpdatePlagiarismScore(currentArticleId));
                if (response) {
                    setArticlePlagiarism(response);
                }
                dispatch(setPlagLoading(false));
            }
        }
    };

    const returnWordCountPoints = () => {
        if(!isNaN(maxWordCount)) return currentWordCount / maxWordCount >= 0.5 ? 12.5 : 0

        return wordsPercentage === 100 ? 12.5 : 0
    }

    const calculateFinalScore = () => {
        const topicCoveragePoints = (25 * coverageDataTopic?.percent || 0) / 100;
        const headingCoveragePoints = (25 * coverageDataHeading?.percent || 0) / 100;
        const titleCoveragePoints = (25 * coverageDataTitle?.percent || 0) / 100;
        const imagePoints = currentImages / maxImages >= 0.5 ? 12.5 : 0;
        const wordCountPoints = returnWordCountPoints()
        const plagScoreDiscount = plagScore !== null && !articleAltered ? plagScore * -1 : 0;

        const finalScore = topicCoveragePoints + headingCoveragePoints + titleCoveragePoints + imagePoints + wordCountPoints + plagScoreDiscount;

        return finalScore > 0 ? Math.round(finalScore) : 0;
    };

    const calculateFinalScoreOutline = () => {
        const topicPercent = coverageDataTopic?.percent || 0;
        const headingPercent = coverageDataHeading?.percent || 0;
        const titlePercent = coverageDataTitle?.percent || 0;

        return Math.round((topicPercent + headingPercent + titlePercent) / 3)
    };

    const savePlagiarismScore = debounce(() => {
        try {
            dispatch(
                updateArticleScores({
                    id: currentArticleId,
                    articleScore: articleScore,
                    outlineScore: outlineScore
                })
            );
        } catch (e) {
            console.log(e)
        }
    }, 500)

    useEffect(() => {
        // if (writerMode === 'outline') dispatch(setOutlineScore(calculateFinalScoreOutline()));
        // else dispatch(setArticleScore(calculateFinalScore()));
    }, [
        currentWordCount,
        currentImages,
        plagScore,
        articleAltered,
        writerMode,
        newWriterMode,
        coverageDataTitle,
        coverageDataTopic,
        coverageDataHeading,
    ]);

    useEffect(() => {
        if (plagiarism) {
            setPlagScore(plagiarism.plagiarismScore);
        }
    }, [plagiarism]);



    const tooltips = {
        score: () =>
            writerMode === 'article' ? 'Measures how well the article follows the SEO brief. Considers keyword usage in title, headings, and content. Factors in word count, image usage, and uniqueness. 100% indicates full keyword inclusion with optimal supporting factors' : 'Evaluates how closely the article outline aligns with the SEO brief, focusing on keyword inclusion in the proposed title, headings, and content guidelines.',
        title: () => "Percentage of briefed keywords' search volume included in the article title.",
        headings: () => 'Percentage of briefed keywords\' search volume included in the article headings.',
        coverage: () => "Percentage of briefed keywords' search volume included in the article body.",
        wordcount: () =>
            "Compares your content length to the average of top-ranking articles. Scores decrease for articles significantly shorter or longer than the average.",
        images: () =>
            "Compares your image usage to the average of top-ranking articles. Scores decrease for significantly fewer images than the average.",
        uniqueness: () => {
            let tooltipCounter = '';
            const tooltip = 'Plagiarism check using a third-party tool. Aim for >90% uniqueness for best results.';

            if (!isNaN(uniquenessCountUsed)) tooltipCounter = `${5 - uniquenessCountUsed}/5 uniqueness checks left.<br><br>`;

            return `${tooltipCounter}${tooltip}`;
        },
    };

    const returnExpectedScore = useMemo(() => {
        if (newWriterMode === 'outline') {
            return outlineScore;
        } else if (newWriterMode === 'generated') {
            return lastGeneratedArticle ? generatedScore : '';
        } else if (newWriterMode === 'current') {
            return currentWebsiteArticle ? liveScore : '';
        }
        return articleScore;
    }, [
        newWriterMode,
        outlineScore,
        generatedScore,
        liveScore,
        articleScore,
        lastGeneratedArticle,
        currentWebsiteArticle
    ]);

    useEffect(() => {
        if (returnExpectedScore) savePlagiarismScore();
    }, [returnExpectedScore]);

    const returnWordCountRange = () => isNaN(maxWordCount) ? `${maxWordCount[0]}-${maxWordCount[1]}` : maxWordCount
    const loadingState = () => isTopicLoading || loadingContent() || !activeCoverageFetched
    const imagesLoaded = () => (newWriterMode !== 'outline' && currentImages === null)

    return (
        <>
            <div ref={ref} className={
                `score-card-container 
                ${writerMode === 'outline' 
                    ? 'score-card-container--outline' 
                    : 'score-card-container--article'}
                ${compact ? 'compact-mode' : ''}
                `
            }>
                <div className="first-score-section">
                    <WhiteTooltip placement="right" title={<div style={{ whiteSpace: 'pre-line' }}>{tooltips.score()}</div>}>
                        {returnExpectedScore !== null && (
                            <div className="progress-score">
                                <CircularProgressbarWithChildren
                                    value={loadingState() || imagesLoaded() ? 0 : returnExpectedScore}
                                    strokeWidth={16}
                                    styles={buildStyles({
                                        pathTransitionDuration: 0.5,
                                        pathColor: writerMode === 'article' ? '#40E246' : '#745ECA',
                                        strokeLinecap: 'butt',
                                        backgroundColor: 'transparent',
                                    })}>
                                    {(loadingState() || imagesLoaded()) && <LoadingBlock height="16" width="30"/>
                                        || (
                                            <p className="progress-circular-text">{returnExpectedScore === null ? '' : returnExpectedScore}</p>
                                        )}
                                </CircularProgressbarWithChildren>
                            </div>
                        ) || (
                            <div className="progress-score spinning">
                                <CircularProgressbarWithChildren
                                    value={25}
                                    strokeWidth={16}
                                    styles={buildStyles({
                                        pathTransitionDuration: 0.5,
                                        pathColor: writerMode === 'article' ? '#40E246' : '#745ECA',
                                        strokeLinecap: 'butt',
                                        backgroundColor: 'transparent',
                                    })}>
                                    <LoadingBlock height="16" width="30"/>
                                </CircularProgressbarWithChildren>
                            </div>
                        )}
                    </WhiteTooltip>

                    <div className="score-description"
                         onClick={loadingState() || returnExpectedScore === null ? null : updateShowCompetition}>
                        {(!competition || loadingContent() || returnExpectedScore === null) && (
                            <>
                                <LoadingBlock height={'24px'} width={'100px'}/>
                                <LoadingBlock height={'16px'} width={'150px'}/>
                            </>
                        )}
                        {competition && !loadingContent() && returnExpectedScore !== null && (
                            <>
                                <p className="score-title">SEO score</p>
                                <p className="score-competition-description">
                                    Based on top 10 articles <ChevronRight/>
                                </p>
                            </>
                        )}
                    </div>
                </div>
                <div className="second-score-section">
                    <ProgressBar
                        loading={loadingState()}
                        valueIsPercent
                        label={'Title'}
                        value={coverageDataTitle?.percent || 0}
                        tooltipText={tooltips.title()}
                    />
                    <ProgressBar
                        loading={loadingState()}
                        valueIsPercent
                        label={'Headings'}
                        value={coverageDataHeading?.percent || 0}
                        tooltipText={tooltips.headings()}
                    />
                    <ProgressBar
                        loading={loadingState()}
                        valueIsPercent
                        label={'Coverage'}
                        value={coverageDataTopic?.percent || 0}
                        tooltipText={tooltips.coverage()}
                    />
                    {writerMode !== 'outline' && articleCompleted !== false && (
                        <>
                            <ProgressBar
                                onClick={loadingState() ? null : updateShowCompetition}
                                loading={loadingState()}
                                label={'Wordcount'}
                                valueIsPercent={false}
                                value={writerMode === 'outline' ? 0 : wordsPercentage}
                                currentValue={currentWordCount}
                                totalValue={returnWordCountRange()}
                                disabled={writerMode === 'outline'}
                                tooltipText={writerMode === 'article' ?  tooltips.wordcount() : false}
                            />
                            <ProgressBar
                                loading={loadingState()}
                                label={'Images'}
                                valueIsPercent={false}
                                value={imagePercentage}
                                currentValue={imagesLoaded() ? 0 : currentImages}
                                totalValue={maxImages}
                                disabled={writerMode === 'outline'}
                                tooltipText={writerMode === 'article' ? tooltips.images() : false}
                            />
                            <ProgressBar
                                label={'Uniqueness'}
                                value={plagScore ? 100 - plagScore : 100}
                                isPlagiarism={true}
                                refreshPlagiarism={openPlagiarism}
                                plagLoading={plagLoading}
                                loading={loadingState()}
                                disabled={writerMode === 'outline'}
                                invalidValue={!articleCompleted || articleIsEmpty}
                                tooltipText={writerMode === 'article' ? tooltips.uniqueness() : false}
                            />
                        </>
                    )}
                </div>

            </div>

            <div className={
                `score-card-container--compact score-card-container 
                ${writerMode === 'outline'
                    ? 'score-card-container--outline'
                    : 'score-card-container--article'}
                ${compact ? 'compact-mode' : ''}
                `
            }>
            <div className="score-card--compact">

                <WhiteTooltip placement="right" title={<div style={{ whiteSpace: 'pre-line' }}>{tooltips.score()}</div>}>
                    <div className="progress-score">
                        <CircularProgressbarWithChildren
                            value={loadingState() ? 0 : returnExpectedScore}
                            strokeWidth={16}
                            styles={buildStyles({
                                pathTransitionDuration: 0.5,
                                pathColor: writerMode === 'article' ? '#40E246' : '#745ECA',
                                strokeLinecap: 'butt',
                                backgroundColor: 'transparent',
                            })}>
                            <p className="progress-circular-text">{loadingState() ? '' : returnExpectedScore}</p>
                        </CircularProgressbarWithChildren>
                    </div>
                </WhiteTooltip>
                <div className="right-side">
                    <p className="score-title">SEO score</p>
                    <div className="score-percentages">
                        <WhiteTooltip placement="bottom" title={<div style={{ whiteSpace: 'pre-line' }}>{tooltips.title()}</div>}>
                            <div className="score-cell">T: <b>{coverageDataTitle?.percent || 0}%</b></div>
                        </WhiteTooltip>
                        <WhiteTooltip placement="bottom" title={<div style={{ whiteSpace: 'pre-line' }}>{tooltips.headings()}</div>}>
                            <div className="score-cell">H: <b>{coverageDataHeading?.percent || 0}%</b></div>
                        </WhiteTooltip>
                        <WhiteTooltip placement="bottom" title={<div style={{ whiteSpace: 'pre-line' }}>{tooltips.coverage()}</div>}>
                            <div className="score-cell">C: <b>{coverageDataTopic?.percent || 0}%</b></div>
                        </WhiteTooltip>
                    </div>
                </div>
            </div>
            </div>

        </>
    );
});

export default ScoreCard;
