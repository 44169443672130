// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-keywords-table-row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.article-keywords-table-column{
    display: flex;
    align-items: center
}
.article-keywords-table-column div:not(.track-checkbox) > svg {
    margin-left: 4px
}
`, "",{"version":3,"sources":["webpack://./src/Components/ArticleKeywordsTable/ArticleKeywordsTableRow.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb;AACJ;AACA;IACI;AACJ","sourcesContent":[".article-keywords-table-row {\n    display: flex;\n    align-items: center;\n    margin-bottom: 8px;\n}\n\n.article-keywords-table-column{\n    display: flex;\n    align-items: center\n}\n.article-keywords-table-column div:not(.track-checkbox) > svg {\n    margin-left: 4px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
