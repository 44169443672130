import React, {useEffect, useRef, useState} from 'react';
import './PromptPlaceholder.css'
// import {CrossIcon} from "../../UI_utils/SVGIcons";

const PromptPlaceholder = ({ close = () => void(0)}) => {

    return (
        <div className="prompt-placeholder">
            E.g.
            <ul>
                <li>Brand rules and mentions</li>
                <li>The level of technical jargon and knowledge</li>
                <li>How conversational vs formal the writing style is</li>
                <li>Article formatting, length, use of headings, images, etc.</li>
                <li>The perspective the articles are written from (1st person, 2nd person, 3rd person)</li>
                <li>Any other defining characteristics of the site's content style</li>
            </ul>
        </div>
    );
};

export default PromptPlaceholder