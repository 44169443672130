import React, {useEffect, useState} from 'react';
import './SpellbindingSuggestions.css'
import SpellbindingSuggestionsItem from "./SpellbindingSuggestionsItem";
import {PlusIcon, SpinnerIcon, ChevronRight, ChevronLeft} from "../../UI_utils/SVGIcons";
import {useDispatch, useSelector} from "react-redux";
import {getSuggestedTopics, getTopicSuggestionsRows, getUserAccessToNewTracking} from "../../reducers/user";
import {getGlobalState} from "../../globals";
import {interfaceApiRequest} from "../../utils/dataToInterface";
import {getCurrentArticleId} from "../../reducers/articles";

const SpellbindingSuggestions = ({setProcessingTopic}) => {
    const MAX_PAGES = 20;
    const TOPICS_PER_PAGE = 5
    const siteIdParam = getGlobalState('site_id');

    const dispatch = useDispatch();
    const suggestedTopics = useSelector(getSuggestedTopics);
    const currentArticleId = useSelector(getCurrentArticleId);
    const newTrackingAccess = useSelector(getUserAccessToNewTracking);

    const [activePage, setActivePage] = useState(1)
    const [loadingMoreTopics, setLoadingMoreTopics] = useState(false)
    const [loadingTrackKeyword, setLoadingTrackKeyword] = useState(false)
    const [trackingKeyword, setTrackingKeyword] = useState('')
    const [customError, setCustomError] = useState('')

    const resetError = () => setCustomError('');

    const loadedPages = () => suggestedTopics ? Math.ceil(suggestedTopics.length / TOPICS_PER_PAGE) : 0;

    const generatePaginationButtons = () => {
        const totalPages = loadedPages();
        if (totalPages < 2) return [{ page: 1, label: '1' }];

        const buttons = [];
        const showingSteps = 2; // Pages to show on each side of active page

        // Always show first page
        buttons.push({ page: 1, label: '1' });

        // If total pages is 8 or less, show all pages sequentially
        if (totalPages <= 8) {
            for (let i = 2; i <= totalPages; i++) {
                buttons.push({ page: i, label: String(i) });
            }
        } else {
            // More complex pagination for more than 8 pages
            // If we're in the first half of pages
            if (activePage <= 6) {
                // Show first 6 pages, then ellipsis, then last page
                if (activePage <= 5) {
                    // 2-5 pages
                    for (let i = 2; i <= 6; i++) {
                        buttons.push({ page: i, label: String(i) });
                    }
                    buttons.push({ page: null, label: '...' });
                } else {
                    // Page 6
                    for (let i = 2; i <= 6; i++) {
                        buttons.push({ page: i, label: String(i) });
                    }
                    buttons.push({ page: null, label: '...' });
                }
            }
            // If we're in the last half of pages
            else if (activePage >= totalPages - 5) {
                // Show ellipsis, then last 6 pages
                buttons.push({ page: null, label: '...' });
                for (let i = totalPages - 5; i < totalPages; i++) {
                    buttons.push({ page: i, label: String(i) });
                }
            }
            // If we're in the middle pages
            else {
                // Show first ellipsis, center pages, last ellipsis
                buttons.push({ page: null, label: '...' });
                for (let i = activePage - 1; i <= activePage + 1; i++) {
                    buttons.push({ page: i, label: String(i) });
                }
                buttons.push({ page: null, label: '...' });
            }
        }

        // Always show last page if more than 1 page
        if (totalPages > 1 && buttons[buttons.length - 1].page !== totalPages) {
            buttons.push({ page: totalPages, label: String(totalPages) });
        }

        return buttons;
    };

    const handlePageChange = (newPage, disabled = false) => {
        if (newPage >= 1 && newPage <= loadedPages() && !disabled) {
            resetError();
            setActivePage(newPage);
        }
    };
    const clickHandler = (topic) => {
        if(!loadingTrackKeyword) {
            resetError()
            setLoadingTrackKeyword(true)
            setProcessingTopic(topic)
            setTrackingKeyword(topic)

            let payload;

            if(newTrackingAccess) {
                payload = {
                    keywords: [topic.toLowerCase()],
                    trackKeywordsFromWriter: [topic.toLowerCase()],
                    topic: topic.toLowerCase(),
                    newTopicFromWriter: true,
                    openInterstitialModal: true
                };
            } else {
                payload = {
                    trackKeywordsFromWriter: [topic.toLowerCase()],
                    newTopicFromWriter: true,
                    old: true
                };
            }

            interfaceApiRequest('checkTopicAndStartArticleMessage', payload)
                .then(resp => {
                    if(resp.message) {
                        setCustomError(resp.message)
                    }
                })
                .catch(err => {
                    setCustomError(err.message)
                })
                .finally(() => {
                    setLoadingTrackKeyword(false);
                    setProcessingTopic('');
                    setTrackingKeyword('')
                })
        }
    }

    const handlePageClick = (pageIndex) => {
        resetError()
        setActivePage(pageIndex + 1);
    };


    const loadSuggestedTopics = (forceLoad = false) => {
        // Only load topics when explicitly requested by plus icon or on initial load
        if (
            !loadingMoreTopics &&
            siteIdParam &&
            loadedPages() < MAX_PAGES &&
            forceLoad
        ) {
            resetError();
            setLoadingMoreTopics(true);

            const payload = {
                siteId: siteIdParam,
                rows: 5,
                offset: loadedPages() * TOPICS_PER_PAGE,
                articleId: currentArticleId
            };

            dispatch(getTopicSuggestionsRows(payload))
                .then()
                .catch()
                .finally(() => {
                    setLoadingMoreTopics(false);
                });
        }
    };

    useEffect(() => {
        // Initial load of first batch of topics
        if (suggestedTopics === null) {
            loadSuggestedTopics(true);
        }
    }, []);

    const startIndex = (activePage - 1) * TOPICS_PER_PAGE;
    const endIndex = startIndex + TOPICS_PER_PAGE;
    const topicsToShow = suggestedTopics ? suggestedTopics.slice(startIndex, endIndex) : null;

    const pages = Array.from({ length: loadedPages() }).map((_, index) => (
        <div
            key={index}
            className={`pagination-item ${activePage === index + 1 ? 'active' : ''}`}
            onClick={() => handlePageClick(index)}
        >
            {index + 1}
        </div>
    ));


    const paginationButtons = generatePaginationButtons();

    return (
        <div className="spellbinding-suggestions">
            <div className="spellbinding-suggestions__title">Strategic Content Topics</div>

            {topicsToShow && topicsToShow.map(({topic, traffic}, index) => (
                <SpellbindingSuggestionsItem
                    key={`topic-${index}`}
                    index={index}
                    topic={topic}
                    traffic={traffic}
                    onClick={clickHandler}
                    disable={topic !== trackingKeyword && !!trackingKeyword}
                />
            ))}

            {!topicsToShow && (
                <>
                    <SpellbindingSuggestionsItem topic={null} />
                    <SpellbindingSuggestionsItem topic={null} />
                    <SpellbindingSuggestionsItem topic={null} />
                    <SpellbindingSuggestionsItem topic={null} />
                    <SpellbindingSuggestionsItem topic={null} />
                </>
            )}

            {loadingTrackKeyword && !customError && (
                <div className="spellbinding-suggestions__loading-info">
                    Processing keyword. Usually takes up to 2 minute. <SpinnerIcon />
                </div>
            )}

            {!loadingTrackKeyword && (
                <div className="spellbinding-suggestions__pagination">
                    <div
                        className={`pagination-item pagination-item--arrow ${activePage === 1 ? 'disabled' : ''}`}
                        onClick={() => handlePageChange(activePage - 1, activePage === 1)}
                        disabled={activePage === 1}
                    >
                        <ChevronLeft/>
                    </div>

                    {paginationButtons.map((btn, index) => (
                        <div
                            key={index}
                            className={`pagination-item ${btn.page === activePage ? 'active' : ''} ${btn.page === null ? 'ellipsis' : ''}`}
                            onClick={() => btn.page && handlePageChange(btn.page)}
                        >
                            {btn.label}
                        </div>
                    ))}

                    {!loadingMoreTopics && loadedPages() < MAX_PAGES && (
                        <span
                            className="pagination-item pagination-item--plus"
                            onClick={() => loadSuggestedTopics(true)}
                        >
                            <PlusIcon/>
                        </span>
                    )}

                    {loadingMoreTopics && (
                        <span className="pagination-item pagination-item--loading">
                            <SpinnerIcon/>
                        </span>
                    )}

                    {loadedPages() > 1 && (
                        <div
                            className={`pagination-item pagination-item--arrow ${activePage === loadedPages() ? 'disabled' : ''}`}
                            onClick={() => handlePageChange(activePage + 1, activePage === loadedPages())}
                            disabled={activePage === loadedPages()}
                        >
                            <ChevronRight/>
                        </div>
                    )}
                </div>
            )}

            {!loadingTrackKeyword && customError && (
                <div
                    className="spellbinding-suggestions__error"
                    dangerouslySetInnerHTML={{__html: customError}}
                />
            )}
        </div>
    );
};

export default SpellbindingSuggestions;