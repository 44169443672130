import {useDispatch, useSelector} from "react-redux";
import {getDraftConfigOptions, getUserData, showDraftConfigOptions} from "../../../reducers/user";
import CustomSelect from "../../../Shared/CustomSelect";
import CustomCheckbox from "../../../Shared/CustomCheckbox";
import React, {useEffect, useRef, useState} from "react";
import AiCardInternalLinksItem from "./AiCardInternalLinksItem";
import Input from "../../../Shared/Input";
import "./AiCardInternalLinks.css";
import {VALIDATOR_EMAIL} from "../../../utils/validators";
import {
    addInternalLinkToList,
    deleteInternalLinkFromList,
    listOfInternalLinks
} from "../../../reducers/articles";

const AiCardInternalLinks = ({
    currentArticleId,
    onHeightUpdate
}) => {
    const dispatch = useDispatch();
    const internalLinks = useSelector(listOfInternalLinks);

    const containerRef = useRef(null);
    const [newLink, setNewLink] = useState('');
    const [addingNewLink, setAddingNewLink] = useState('');

    const newLinkInputHandler = (newValue) => setNewLink(newValue)

    const addNewLink = () => {
        if(newLink) {
            setAddingNewLink(true);
            dispatch(addInternalLinkToList(currentArticleId, newLink))
                .then(resp => {
                    setNewLink('')
                })
                .finally(() => {
                    setAddingNewLink(false)
                })
        }
    };

    const deleteLink = (url) => {
        if(url) dispatch(deleteInternalLinkFromList(currentArticleId, url))
    }

    const openUrl = (url) => window.open(url, '_blank');

    return (
        <div className="ai-card-links" ref={containerRef}>
            {(!internalLinks && (
                <AiCardInternalLinksItem
                    url={null}
                    title={null}
                    description={null}
                    orderNumber={null}
                />
            )) || internalLinks?.map(({url, title, description}, index) => (
                <AiCardInternalLinksItem
                    key={`url-${index}`}
                    url={url}
                    title={title}
                    description={description}
                    orderNumber={index + 1}
                    onDelete={() => deleteLink(url)}
                    onClick={() => openUrl(url)}
                />
            )) }
            <div className="ai-card-links__input">
                <Input
                    type="text"
                    element="input"
                    label="Add internal link"
                    placeholder="E.g. https://yourwebsite.com/link-to-related-page"
                    inputValue={newLink}
                    showIcon={true}
                    loading={addingNewLink}
                    onChange={newLinkInputHandler}
                    onEnter={addNewLink}
                    iconClick={addNewLink}
                />
            </div>
        </div>
    );
};

export default AiCardInternalLinks;
