import React, { useState, useEffect } from 'react';
import { TextMenu } from './TextMenu';

export const TextMenuContainer = ({ editor, writerMode }) => {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        // On mount or mode change, delay rendering to ensure clean DOM
        setShouldRender(false);
        const timer = setTimeout(() => {
            setShouldRender(true);
        }, 100);

        return () => {
            clearTimeout(timer);
            setShouldRender(false);
        };
    }, [writerMode]);

    if (!shouldRender || !editor) {
        return null;
    }

    return (
        <div id={`text-menu-${writerMode}`}>
            <TextMenu editor={editor} writerMode={writerMode}/>
        </div>
    );
};
