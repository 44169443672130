// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.articles-sidebar__wrapper.absolute-sidebar {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.articles-sidebar {
    width: 100%;
    max-width: 470px;
    min-width: 470px;
    height: 100%;
    background: var(--default-surfaces-base);
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
}

.articles-sidebar-content {
    overflow: auto;
}

.articles-sidebar-content .no-results{
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/ArticlesSidebar/ArticlesSidebar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,UAAU;AACd;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,wCAAwC;IACxC,UAAU;IACV,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".articles-sidebar__wrapper.absolute-sidebar {\n    display: flex;\n    align-items: center;\n    position: fixed;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    z-index: 3;\n}\n\n.articles-sidebar {\n    width: 100%;\n    max-width: 470px;\n    min-width: 470px;\n    height: 100%;\n    background: var(--default-surfaces-base);\n    z-index: 1;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n}\n\n.articles-sidebar-content {\n    overflow: auto;\n}\n\n.articles-sidebar-content .no-results{\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
