// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spellbinding-suggestions-item {
    padding: 8px;
    border-radius: 12px;
    display: grid;
    align-items: center;
    column-gap: 4px;
    grid-template-columns: auto auto 1fr;
    max-width: 100%;
}
.spellbinding-suggestions-item.disabled {
    opacity: 0.25;
    cursor: default;
}

.spellbinding-suggestions-item span {
    line-height: 16px;
}

.spellbinding-suggestions-item:not(.loading):not(.disabled):hover {
    background: var(--night-surfaces-ghost-hv);
    cursor: pointer;
}

.spellbinding-suggestions-item .loading-block {
    border-radius: 4px;
    background: var(--night-surfaces-ghost-hv);
}

.spellbinding-suggestions-item__topic {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.spellbinding-suggestions-item__traffic {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-secondary)
}

.spellbinding-suggestions-item svg {
    height: 13px;
    width: 13px;
}

.spellbinding-suggestions-item path {
    fill: var(--default-symbols-secondary)
}`, "",{"version":3,"sources":["webpack://./src/Components/NewArticleModal/SpellbindingSuggestionsItem.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,oCAAoC;IACpC,eAAe;AACnB;AACA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,0CAA0C;AAC9C;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB;AACJ;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;AACJ","sourcesContent":[".spellbinding-suggestions-item {\n    padding: 8px;\n    border-radius: 12px;\n    display: grid;\n    align-items: center;\n    column-gap: 4px;\n    grid-template-columns: auto auto 1fr;\n    max-width: 100%;\n}\n.spellbinding-suggestions-item.disabled {\n    opacity: 0.25;\n    cursor: default;\n}\n\n.spellbinding-suggestions-item span {\n    line-height: 16px;\n}\n\n.spellbinding-suggestions-item:not(.loading):not(.disabled):hover {\n    background: var(--night-surfaces-ghost-hv);\n    cursor: pointer;\n}\n\n.spellbinding-suggestions-item .loading-block {\n    border-radius: 4px;\n    background: var(--night-surfaces-ghost-hv);\n}\n\n.spellbinding-suggestions-item__topic {\n    font-size: 13px;\n    font-weight: 700;\n    line-height: 16px;\n    letter-spacing: -0.01em;\n    max-width: 100%;\n    overflow: hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n}\n\n.spellbinding-suggestions-item__traffic {\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 16px;\n    letter-spacing: -0.01em;\n    color: var(--default-symbols-secondary)\n}\n\n.spellbinding-suggestions-item svg {\n    height: 13px;\n    width: 13px;\n}\n\n.spellbinding-suggestions-item path {\n    fill: var(--default-symbols-secondary)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
