import React, { useEffect, useState } from "react";
import { loadSpace } from "@usersnap/browser";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useParams
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Login from "./Pages/Login";
import MessageRouteChange from "./Pages/MessageRouteChange";
import Home from "./Pages/Home";
import Template from "./Shared/Template";
import Dasboard from "./Pages/Dasboard";
import {
  LOGIN_PATH,
  HOME_PATH,
  DEMO_APP_PATH,
  // ESHOP_PATH,
  DASHBOARD_PATH,
    SPACE_API_KEY,
    SHARED_ARTICLE,
  // NATIVE_PATH,
  // CLIENT_BRIEF_PATH,
  // CLIENTS_DASHBOARD_PATH,
  // CHANGE_PASS_PATH,
  // PERFORMANCE_DASHBOARD_PATH,
  // PROJECTS_PERFORMANCE_DASHBOARD_PATH,
  // MULTI_STEP_REGISTER,
  // SEARCH_RESULTS_PATH,
  // AI_OUTLINE_PATH,
} from "./common/consts";
import useCacheBuster from "./utils/useCacheBuster";
import {
  apiErrorMessage,
  isApiError,
  setApiError,
  setApiErrorMessage,
  setGenericErrorText,
  setShowGenericErrorModal,
  showErrorModal,
  setGenericHandler,
} from "./reducers/apiErrors";
import { QueryClient, QueryClientProvider } from "react-query";
import {getUserToken, setUserToken} from "./utils/userStatus";
import {setGlobalState} from "./globals";
import TryAgainModal from "./Shared/TryAgainModal";
// import AIOutlinePage from "./Pages/AIOutline";
import {checkToken, syncUser} from "./api/user";
import {
  setMissingPagesCount,
  setPagesManyKeywordsCount,
  setSuboptimalContentCount,
  setWrongIntentKeywordCount,
  setLowRankPageCount,
  setFaqCount,
} from "./reducers/contentAuditIssues";
import {
  setDraftConfigOptions,
  setUserData,
  setIsContentWriterAuthor,
  setUserCanEdit,
  getAndPopulateUserFlags, setCampaignIsGlobal
} from './reducers/user'
import ErrorBoundary from "./UI_utils/ErrorBoundary";
import { setContentWriterCounts } from "./reducers/articles";

import { init as FullStoryInit } from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import './assets/styles/_global_typography.scss'
import AppLoadingScreen from "./Shared/AppLoadingScreen";
import ConfirmationModal from "./Shared/ConfirmationModal";
import {
  setShowGenericConfirmationModal,
  setGenericConfirmationText,
  showConfirmationModal, showTopicSessionForNewArticle, setOncloseHandler, setSecondaryGenericConfirmationHandler
} from "./reducers/confirmationModal";
import {showAppLoading} from "./reducers/dashboard";


const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name);
    });
  }
  // delete browser cache and hard reload
  if (!window.location.hash) {
    window.location = window.location + "#loaded";
    window.location.reload(true);
  }
};

const App = () => {
  const { forceRefresh } = useCacheBuster();
  const error = useSelector(isApiError);
  const dispatch = useDispatch();
  const errorMessage = useSelector(apiErrorMessage);
  const genericErrorModal = useSelector(showErrorModal);
  const appLoadingVisible = useSelector(showAppLoading);
  const genericConfirmationModal = useSelector(showConfirmationModal);
  const [snackOpen, setSnackOpen] = useState(false);
  const token = getUserToken();
  const isSharedUrl = window.location.pathname.startsWith('/shared-article/');

  const [userSynced, setUserSynced] = useState(false);
  if(!userSynced && !isSharedUrl)
    syncUser().then(resp => resp.data ? setUserSynced(true) : false);
  const checkTokenAndRedirect = async () => {
    const newToken = await checkToken();

    syncUser()

    if (newToken?.token) {
      setUserToken(newToken.token)
      window.location.reload();
    }
  };

  useEffect (() => {
    if(!token && !isSharedUrl) checkTokenAndRedirect();
  });

  /* if application is in demo mode we need to change the url to this format: `/demo/article/1`*/
  const currentHomePath = `${HOME_PATH}/:id/:section?`;

  useEffect(() => {
    if (forceRefresh) {
      refreshCacheAndReload();
    }
  }, [forceRefresh]);

  useEffect(() => {
    if (error) {
      setSnackOpen(true);
    }
  }, [error]);

  const onCloseSnack = () => {
    setSnackOpen(false);
    dispatch(setApiError(null));
    dispatch(setApiErrorMessage(null));
  };

  const closeGenericErrorModal = () => {
    dispatch(setShowGenericErrorModal(false));
    dispatch(setGenericErrorText(""));
  };

  const closeGenericConfirmationModal = () => {
    dispatch(setShowGenericConfirmationModal(false))
    dispatch(setGenericConfirmationText(''))
  };

  const client = new QueryClient();

  // Get the current URL
  const currentUrl = window.location.search;
  // Parse the query parameters
  const urlSearchParams = new URLSearchParams(currentUrl);
  // Get the value of the 'site_id' parameter
  const siteId = urlSearchParams.get('site_id');
  const beta = urlSearchParams.get('beta');
  const openArticlesSidebar = urlSearchParams.get('openArticlesSidebar');

  if(siteId) setGlobalState('site_id', siteId);
  if(beta) setGlobalState('beta', beta);
  if(openArticlesSidebar) setGlobalState('openArticlesSidebar', openArticlesSidebar);

  window["_fs_run_in_iframe"] = true;

  const issueTypeHandler = (data) => {
    switch (data.issueType) {
      case "missingpages":
        dispatch(setMissingPagesCount(data.issueCount));
        break;
      case "suboptimalcontent":
        dispatch(setSuboptimalContentCount(data.issueCount));
        break;
      case "pagesmanykeywords":
        dispatch(setPagesManyKeywordsCount(data.issueCount));
        break;
      case "wrongintentkeyword":
        dispatch(setWrongIntentKeywordCount(data.issueCount));
        break;
      case "lowrankingpage":
        dispatch(setLowRankPageCount(data.issueCount));
        break;
    }
  };

  const userDataHandler = (data) => {
    dispatch(setUserData(data.userData));
    if (Sentry) {
      Sentry.setUser({ email: data.userData.email });
    }
  };

  const draftConfigOptionsHandler = (data) => {
    dispatch(setDraftConfigOptions(data.draftConfigOptions));
  }

  const isContentWriterAuthorHandler = (data) => {
    dispatch(setIsContentWriterAuthor(data.isContentWriterAuthor));
  }

  const setUserCanEditDispatch = (canEdit) => {
    dispatch(setUserCanEdit(canEdit));
  }

  loadSpace(SPACE_API_KEY).then((api) => {
    api.init();
    window.usersnapApi = api; // Save the API to the window object for easy access
  });

  const triggerScreenshot = () => {
    if (window.usersnapApi) {
      window.usersnapApi.logEvent("show_usersnap_button_app");
    } else {
      console.error("Usersnap API is not loaded yet.");
    }
  };

  useEffect(() => {
    // Listen for messages from the parent
    const receiveMessage = (event) => {
      // Ensure the message is from an allowed origin if needed
      // if (event.origin !== 'http://allowed-origin.com') return;
      const data = event.data;

      // Handle the received data from the parent (Vue app)
      if (data.issueType) {
        // setIssueCounter(data)
        issueTypeHandler(data);
      }

      if (data.userData) {
        userDataHandler(data);
      }

      if (data.draftConfigOptions) {
        draftConfigOptionsHandler(data);
      }

      if (data.isContentWriterAuthor) {
        isContentWriterAuthorHandler(data);
      }

      if (data.openUsersnap) {
        triggerScreenshot();
      }

      if (data.contentWriterCounts) {
        dispatch(setContentWriterCounts(data.contentWriterCounts));
      }

      if (data.canEdit) {
        setUserCanEditDispatch(data.canEdit);
      }

      if (data.generalSiteProperties) {
        dispatch(setCampaignIsGlobal(data.generalSiteProperties.location === 'Global'));
      }

      if (data.faqCount) {
        dispatch(setFaqCount(data.faqCount.count));
      }
    };

    // Attach the event listener
    window.addEventListener("message", receiveMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    FullStoryInit({
      orgId: "RR4G",
      cookieDomain: ".seomonitor.com", // Ensure the cookie is shared across subdomains
      host: 'echo-fs.seomonitor.com', // Replace with your custom domain
      script: 'echo-fs.seomonitor.com/s/fs.js', // Replace with the path to your Fullstory script on the custom domain
    });

    if(token) dispatch(getAndPopulateUserFlags());

  }, []);

  if((!token && !isSharedUrl) || appLoadingVisible) return <AppLoadingScreen />


  return (
    <>
      <>
        {genericErrorModal && (
            <TryAgainModal buttonAction={closeGenericErrorModal}/>
        )}
        {genericConfirmationModal && (
            <ConfirmationModal />
        )}
        {errorMessage && ( // Only render Snackbar if errorMessage exists
          <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackOpen} onClose={onCloseSnack}>
            <Alert onClose={onCloseSnack} severity="error" sx={{ width: "100%" }}>
              {errorMessage}
            </Alert>
          </Snackbar>
        )}

        <ErrorBoundary>
          <QueryClientProvider client={client}>
            <Router>
              <MessageRouteChange />
              <Switch>
                <Route path={LOGIN_PATH} exact>
                  {token ? (
                    <Redirect to={DASHBOARD_PATH} />
                  ) : (
                    <Template>
                      <Login />
                    </Template>
                  )}
                </Route>
                <Route path={SHARED_ARTICLE}>
                  <Template>
                    <Home />
                  </Template>
                </Route>
                <Route path={currentHomePath}>
                  <Template>
                    <Home />
                  </Template>
                </Route>
                <Route path={DASHBOARD_PATH}>
                  <Template>
                    <Dasboard />
                  </Template>
                </Route>
              </Switch>
            </Router>
          </QueryClientProvider>
        </ErrorBoundary>
      </>
    </>
  );
};

export default App;
