import React, { useState } from 'react';
import './ArticlesSidebarItem.css'
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {getGlobalState} from "../../globals";
import {WhiteTooltip, WhiteTooltipAuto} from "../UITooltips/UITooltip";
import {
    BooksIcon,
    CalendarIcon,
    CrownIcon,
    FlashIcon,
    FreshArticleIcon,
    GlobeIcon,
    RefreshIcon, TrashIcon,
    TrendUpIcon
} from "../../UI_utils/SVGIcons";
import {TEXTS} from "../../common/consts";
import {setGenericErrorText, setGenericHandler, setSecondaryGenericHandler} from "../../reducers/apiErrors";
import {setShowAppLoading} from "../../reducers/dashboard";
import {
    setGenericConfirmationHandler, setGenericConfirmationText,
    setSecondaryGenericConfirmationHandler,
    setShowGenericConfirmationModal
} from "../../reducers/confirmationModal";
import {useDispatch, useSelector} from "react-redux";
import {deleteArticleFromDashboard, setDeletingArticle, getDeletingArticle} from "../../reducers/articles";

const seomonitorInterfaceUrl = process.env.REACT_APP_SEOMONITOR_INTERFACE || 'https://app.seomonitor.com/';

const ArticlesSidebarItem = ({
    topic,
    addTraffic,
    title,
    date,
    updatedDate,
    author,
    articleId,
    showArticleScore = false,
    outlineScore,
    articleScore,
    freshMarket,
    activeItem,
    comprehensive,
}) => {
    const dispatch = useDispatch();
    const siteId = getGlobalState('site_id');
    const isBeta = getGlobalState("beta")
    const deletingArticle = useSelector(getDeletingArticle)
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const goToArticle = () => {
        const path = `/article/${articleId}?site_id=${siteId}`;
        window.location.href = path;
    }

    const returnArticlePath = () => {
        return `${seomonitorInterfaceUrl}v2/${siteId}/content/writer${isBeta ? 'Beta' : ''}/article/${articleId}`;
    }

    const clickPrevented = (event) => {
        event.preventDefault();

        return goToArticle()
    }

    const getScoreToShow = () => {
        if(articleScore){
            return articleScore
        }else{
            if(outlineScore){
                return outlineScore
            }
        }
    }

    const articleTypeTooltip = () => {
        if(!!freshMarket) return TEXTS.FRESH_PERSPECTIVE_TOOLTIP;
        if(comprehensive) return TEXTS.COMPREHENSIVE_ARTICLE_TOOLTIP;
        return TEXTS.NORMAL_ARTICLE_TOOLTIP;
    }

    const deleteArticle = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setTooltipOpen(false);

        dispatch(setGenericConfirmationHandler( () => {
            const payload = {
                id: articleId,
                instantRemoveArticle: true
            };

            dispatch(deleteArticleFromDashboard(payload, !activeItem, activeItem));
            if(activeItem){
                dispatch(setDeletingArticle(true))
            } else {
                dispatch(setShowGenericConfirmationModal(false))
            }
        }))
        dispatch(setSecondaryGenericConfirmationHandler( () => dispatch(setShowGenericConfirmationModal(false))))

        dispatch(setGenericConfirmationText({
            title: 'Are you sure you want to delete this draft?',
            buttonText: 'Delete',
            secondaryButtonText: 'Cancel',
            icon: TrashIcon,
            className: 'delete-article'
        }))

        dispatch(setShowGenericConfirmationModal(true));
    }

    return (
        <div className={`articles-sidebar-item__wrapper ${activeItem ? 'item--active' : ''}`}>
            {/*<div className="articles-sidebar-item" onClick={goToArticle}>*/}
            <a className="articles-sidebar-item" href={returnArticlePath()} onClick={clickPrevented}>
                <div className="article-sidebar-item-info">
                    <div className="article-item-topic_wrapper">
                        <div className="article-item-topic">{topic}</div>
                    </div>
                    <div className={`article-item-title ${title ? '' : 'empty'}`} dangerouslySetInnerHTML={{ __html: title ? title : '(missing title)' }}/>
                    <div className="article-item-details">
                        <WhiteTooltipAuto
                            placement="bottom-start"
                            title={<span dangerouslySetInnerHTML={{ __html: articleTypeTooltip() }} />}
                        >
                            <span className="article-item-fresh">
                            {freshMarket && (
                                <>
                                    <GlobeIcon />
                                    <span>{freshMarket}</span>
                                </>
                            )}
                            {comprehensive && <BooksIcon />}
                            {!comprehensive && !freshMarket && <CrownIcon /> }
                            </span>
                        </WhiteTooltipAuto>
                        <WhiteTooltipAuto
                            placement="bottom-start"
                            title={<span dangerouslySetInnerHTML={{ __html: `Created on <strong>${date}</strong>` }} />}
                        >
                            <div className="article-item-date">
                                <CalendarIcon /><span>{date}</span>
                            </div>
                        </WhiteTooltipAuto>
                        {updatedDate && date !== updatedDate && (
                            <WhiteTooltipAuto
                                placement="bottom-start"
                                title={<span dangerouslySetInnerHTML={{ __html: `Updated on <strong>${updatedDate}</strong>` }} />}
                                >
                                <div className="article-item-date">
                                    <RefreshIcon /><span>{updatedDate}</span>
                                </div>
                            </WhiteTooltipAuto>
                        )}
                        <WhiteTooltipAuto
                            placement="bottom-start"
                            title={<span dangerouslySetInnerHTML={{ __html: `Estimated Traffic <strong>${addTraffic}</strong>` }} />}
                        >
                            <div className="article-item-add-traffic">
                                <TrendUpIcon /><span>{addTraffic}/mo</span>
                            </div>
                        </WhiteTooltipAuto>
                        <WhiteTooltipAuto
                            placement="bottom-start"
                            title={<span dangerouslySetInnerHTML={{ __html: `Updated by <strong>${author}</strong>` }} />}
                        >
                            <div className="article-item-author">
                                <span>{author}</span>
                            </div>
                        </WhiteTooltipAuto>
                    </div>
                </div>
                <div className="article-sidebar-item-score">
                    <div className="article-sidebar-item-score__chart">
                        <CircularProgressbarWithChildren
                            value={getScoreToShow() || 0}
                            strokeWidth={9}
                            styles={buildStyles({
                                pathTransitionDuration: 0.5,
                                pathColor: showArticleScore ? '#40E246' : '#745ECA',
                                strokeLinecap: 'butt',
                                backgroundColor: 'transparent',
                            })}>
                            <p className="article-sidebar-item-score_value">{getScoreToShow() || 0}</p>
                        </CircularProgressbarWithChildren>
                    </div>
                    <div className={"delete-article"} onClick={deleteArticle}>
                        <WhiteTooltipAuto 
                            placement="bottom" 
                            title={"Delete article"}
                            open={tooltipOpen}
                            onOpen={() => setTooltipOpen(true)}
                            onClose={() => setTooltipOpen(false)}
                        >
                            <div><TrashIcon/></div>
                        </WhiteTooltipAuto>
                    </div>
                </div>
            </a>
            {/*</div>*/}
        </div>
    );
};

export default ArticlesSidebarItem