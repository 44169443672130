import { createSlice } from "@reduxjs/toolkit";

const defaultCoverage = () => ({
  outline: {
    keywords: {},
    percent: null
  },
  article: {
    keywords: {},
    percent: null
  },
  uncompiled: {
    keywords: {},
    percent: null
  }
})

export const keywordsCoverageSlice = createSlice({
  name: "keywordscoverage",
  initialState: {
    titleCoverage: defaultCoverage(),
    topicCoverage: defaultCoverage(),
    headingCoverage: defaultCoverage(),
  },
  reducers: {
    setCoverageDataTitle: (state, { payload }) => {
      state.titleCoverage = payload;
    },
    setCoverageDataOutlineTitle: (state, { payload }) => {
      state.titleCoverage.outline = payload;
    },
    setCoverageDataArticleTitle: (state, { payload }) => {
      state.titleCoverage.article = payload;
    },
    setCoverageDataUncompiledTitle: (state, { payload }) => {
      state.titleCoverage.uncompiled = payload;
    },
    setCoverageDataTopic: (state, { payload }) => {
      state.topicCoverage = payload;
    },
    setCoverageDataOutlineTopic: (state, { payload }) => {
      state.topicCoverage.outline = payload;
    },
    setCoverageDataArticleTopic: (state, { payload }) => {
      state.topicCoverage.article = payload;
    },
    setCoverageDataUncompiledTopic: (state, { payload }) => {
      state.topicCoverage.uncompiled = payload;
    },
    setCoverageDataHeading: (state, { payload }) => {
      state.headingCoverage = payload;
    },
    setCoverageDataOutlineHeading: (state, { payload }) => {
      state.headingCoverage.outline = payload;
    },
    setCoverageDataArticleHeading: (state, { payload }) => {
      state.headingCoverage.article = payload;
    },
    setCoverageDataUncompiledHeading: (state, { payload }) => {
      state.headingCoverage.uncompiled = payload;
    },
  },
});

// actions
export const {
  setCoverageDataTitle,
  setCoverageDataOutlineTitle,
  setCoverageDataArticleTitle,
  setCoverageDataUncompiledTitle,
  setCoverageDataTopic,
  setCoverageDataOutlineTopic,
  setCoverageDataArticleTopic,
  setCoverageDataUncompiledTopic,
  setCoverageDataHeading,
  setCoverageDataOutlineHeading,
  setCoverageDataArticleHeading,
  setCoverageDataUncompiledHeading,
} = keywordsCoverageSlice.actions;

// selectors
export const getAllCoverageDataTitle = ({ keywordscoverage: { titleCoverage } }) => titleCoverage;

export const getActiveCoverageDataTitle = ({ keywordscoverage: { titleCoverage }, articles: { writerMode, showUncompiled } }) => {
  if(writerMode === 'outline') return titleCoverage.outline

  if(showUncompiled) return titleCoverage.uncompiled

  return titleCoverage.article;
};
export const getActiveCoverageDataTopic = ({ keywordscoverage: { topicCoverage }, articles: { writerMode, showUncompiled } }) => {
  if(writerMode === 'outline') return topicCoverage.outline

  if(showUncompiled) return topicCoverage.uncompiled

  return topicCoverage.article;
};
export const getActiveCoverageDataHeading = ({ keywordscoverage: { headingCoverage }, articles: { writerMode, showUncompiled } }) => {
  if(writerMode === 'outline') return headingCoverage.outline

  if(showUncompiled) return headingCoverage.uncompiled

  return headingCoverage.article;
};
export const activeCoverageDataFetched = ({ keywordscoverage: { titleCoverage }, articles: { writerMode, showUncompiled } }) => {
  if(writerMode === 'outline') return titleCoverage.outline.percent !== null

  if(showUncompiled) return titleCoverage.uncompiled.percent !== null

  return titleCoverage.article.percent !== null;
};


export default keywordsCoverageSlice.reducer;
