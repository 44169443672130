import React, {useEffect, useState} from 'react';
import './DefaultSettingsSidebar.css'

import AppOverlay from "../../Shared/AppOverlay";
import DefaultSettingsSidebarHeader from "./DefaultSettingsSidebarHeader";
import {useDispatch, useSelector} from "react-redux";
import DefaultSettingsSidebarContentPrompt from "./DefaultSettingsSidebarContentPrompt";
import {setShowSettingsSidebar, showSettingsSidebar} from "../../reducers/articleSettings";
import DefaultSettingsSidebarSubHeader from "./DefaultSettingsSidebarSubHeader";
import AppTabSelector from "../../Shared/AppTabSelector";
import DefaultSettingsSidebarContentStyle from "./DefaultSettingsSidebarContentStyle";
import {BookIcon, ChatIcon, ConfigurationIcon, FileIcon, GlobeIcon, MagicIcon, SaveIcon} from "../../UI_utils/SVGIcons";
import {getUserHasWritingStyles} from "../../reducers/user";

const DefaultSettingsSidebar = ({}) => {

    const dispatch = useDispatch();
    const showSidebarType = useSelector(showSettingsSidebar)
    const styleAccess = useSelector(getUserHasWritingStyles)
    const setSettingsContent = (type) => dispatch(setShowSettingsSidebar(type));
    const close = () => setSettingsContent(null)

    const [settingsOptions, setSettingsOptions] = useState([
        {
            label: 'Writing style',
            key:'style',

        }, {
            label: 'Campaign brief',
            key: 'prompt',
        }])

    return (
        <>
            {!!showSidebarType && (
                <div className="default-settings-sidebar__wrapper">
                    <AppOverlay open={true} close={close} />

                    <div className="default-settings-sidebar">
                        <DefaultSettingsSidebarHeader close={close} />
                        {styleAccess && (
                            <DefaultSettingsSidebarSubHeader
                                selectedOption={showSidebarType}
                                options={settingsOptions}
                                onSwitch={setSettingsContent}
                            />
                        )}
                        <div className="default-settings-sidebar__content">
                            {showSidebarType === 'prompt' && <DefaultSettingsSidebarContentPrompt/>}
                            {showSidebarType === 'style' && (<DefaultSettingsSidebarContentStyle/>)}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default DefaultSettingsSidebar