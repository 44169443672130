import React, {useEffect, useRef, useState} from "react";
import Select, {components} from "react-select";
import "./AppSelect.css";
import {ChevronDown} from "../UI_utils/SVGIcons";

// Custom ValueContainer to display concatenated selected values
const CustomValueContainer = ({ children, ...props }) => {
    const { getValue, selectProps } = props;
    const values = getValue();

    // If multi-select, concatenate the selected values into a single string
    const concatenatedValue = selectProps.isMulti && values.length > 0
        ? values.map(item => item.label).join(", ")
        : children;

    return (
        <components.ValueContainer {...props}>
            {concatenatedValue}
        </components.ValueContainer>
    );
};

const AppSelect = ({
    selectedValue,
    options,
    styles,
    label = '',
    onChange,
    className = '',
    isSearchable = false,
    isClearable = false,
    isMulti = false,
    hideSelectedOptions = false,
}) => {
    const selectRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleChange = (selected) => {
        // Prevent user from deselecting all options in multi-select
        if (isMulti) {
            if (!selected || selected.length === 0) {
                return; // Ignore if trying to deselect all
            }
        }
        onChange(selected)
    };

    const handleMenuClose = () => {
        console.log('close')
        setOpen(false);
    };

    const handleMenuOpen = () => {
        console.log('open')
        setOpen(true);
        if (selectRef.current) {
            console.log('focus')
            selectRef.current.focus(); // Ensure the select gains focus
        }
    };

    const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
            handleMenuClose();
        }
    };

    useEffect(() => {
        // Add click event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`app-select ${className}`} ref={selectRef}>
            {!open && <div className="app-select__trigger" onClick={handleMenuOpen}></div>}
            <div className="app-select__inner">
                {!!label && (
                    <div className="app-select__label">{label}</div>
                )}
                <Select
                    className={"app-select__select"}
                    classNamePrefix="app-select"
                    defaultValue={selectedValue}
                    value={selectedValue}
                    options={options}
                    isMulti={isMulti}
                    styles={styles}
                    onChange={handleChange}
                    isSearchable={isSearchable}
                    closeMenuOnSelect={!isMulti}
                    hideSelectedOptions={hideSelectedOptions}
                    isClearable={isClearable}
                    menuIsOpen={open}
                    onMenuClose={handleMenuClose}
                    onMenuOpen={handleMenuOpen}
                    components={{ ValueContainer: CustomValueContainer }} // Custom ValueContainer
                />
            </div>
            <div className="app-select__icon"><ChevronDown /></div>
        </div>
    );
};

export default AppSelect;
