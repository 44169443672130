import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    BookIcon, ChatIcon,
    CloseIcon,
    CogIcon, MagicIcon,
    RefreshIcon,
    SaveIcon,
    UserIcon,
    WriterMagicPen
} from "../../../UI_utils/SVGIcons";
import './AiCardStyle.css'
import {
    allStyles,
    articleStyleOptions,
    customCampaignStyle,
    hasStyle,
    setArticleStyle,
    setArticleStyleOptionsAltered,
    setShowSettingsSidebar,
    styleDescription,
    styleName,
    updateAndSaveCampaignStyle,
    setStyleForGeneratingArticle,
    customStyleIcon,
    styleType,
    customStyleTooltip,
    setTypeForGeneratingArticle,
    setNameForGeneratingArticle,
    setDescriptionForGeneratingArticle
} from "../../../reducers/articleSettings";
import Select from "react-select";
import AppSelect from "../../../Shared/AppSelect";
import AppTabSelector from "../../../Shared/AppTabSelector";
import {WhiteTooltipAuto} from "../../UITooltips/UITooltip";
import WritingStyleOverview from "./WritingStyleOverview"

const AiCardStyle = ({
}) => {
    const dispatch = useDispatch();
    const articleHasStyle = useSelector(hasStyle)
    const customStyle = useSelector(customCampaignStyle)
    const articleStyle = useSelector(articleStyleOptions)
    const styles = useSelector(allStyles)
    const customStyleDescription = useSelector(styleDescription)
    const savedStyleDescription = useSelector(styleDescription)
    const savedStyleName = useSelector(styleName)
    const savedStyleType = useSelector(styleType)

    const [optionsToBeUsed, setOptionsToBeUsed] = useState(articleStyle || customStyle || [])

    // Create a state for each select component based on its key
    const initialSelections = optionsToBeUsed.reduce((acc, config) => {
        acc[config.key] = {
            value: config.selected[0],
            label: config.selected[0]
        };
        return acc;
    }, {});

    useEffect(() => {
        dispatch(setArticleStyleOptionsAltered(optionsToBeUsed))
    }, [dispatch, optionsToBeUsed]);

    const [selectedValues, setSelectedValues] = useState(initialSelections);

    const handleChange = (key, selected) => {
        setSelectedValues((prev) => ({
            ...prev,
            [key]: selected,
        }));

        setOptionsToBeUsed((prevOptions) =>
            prevOptions.map((config) =>
                config.key === key
                    ? { ...config, selected: Array.isArray(selected) ? selected.map((item) => item.value) : [selected.value] }
                    : config
            )
        );

        dispatch(setArticleStyleOptionsAltered(optionsToBeUsed))
    };

    const openStyleSidebar = () => {
        dispatch(setShowSettingsSidebar('style'))
        dispatch(setArticleStyle(true));
    }

    const saveAsDefault = () => {
        dispatch(updateAndSaveCampaignStyle(optionsToBeUsed))
    }
    const resetStyle = () => {
        setOptionsToBeUsed(customStyle)

        setSelectedValues(optionsToBeUsed.reduce((acc, config) => {
            acc[config.key] = {
                value: config.selected[0],
                label: config.selected[0]
            };
            return acc;
        }, {}));
    }


    const [selectedTypeOption, setSelectedTypeOption] = useState('custom')
    const [typeOptions, setTypeOptions] = useState([
        {
            label: 'Professional',
            title: 'Professional Style',
            description: 'Formal business tone ideal for corporate content, reports and official documentation in settings.',
            key: 'professional',
            icon: UserIcon,
        },
        {
            label: 'Educational',
            title: 'Educational Style',
            description: 'Clear instructional content for teaching, learning materials and resources to enhance understanding',
            key:'educational',
            icon: BookIcon
        },
        {
            label: 'Casual',
            title: 'Casual Style',
            description: 'Friendly, conversational tone perfect for blogs, social media and that connects with audience.',
            key: 'casual',
            icon: ChatIcon
        },
        {
            label: 'Custom',
            title: savedStyleName || 'Custom style',
            description: savedStyleDescription || 'Configure style before continuing',
            key:'custom',
            icon: MagicIcon
        }]
    )


    // Update the tooltip for "Custom" when savedStyleDescription changes
    useEffect(() => {
        setTypeOptions(prevOptions =>
            prevOptions.map(option =>
                option.key === 'custom'
                    ? { ...option, description: savedStyleDescription || 'Configure style before continuing', title: savedStyleName || 'Custom style', icon: customStyleIcon(savedStyleType) }
                    : option
            )
        );
    }, [savedStyleDescription, savedStyleType]);

    const returnSelectedOptionConfig = () => ({
        ...typeOptions.find(option => option.key === selectedTypeOption),
        style: styles[selectedTypeOption]
    })

    const onSwitch = (option) => {
        setSelectedTypeOption(option);
    }

    const { icon: SelectedIcon, title, description, style, key } = returnSelectedOptionConfig();

    useEffect(() => {
        dispatch(setStyleForGeneratingArticle(style))
    }, [style])
    useEffect(() => {
        dispatch(setTypeForGeneratingArticle(key === 'custom' ? savedStyleType : null))
    }, [savedStyleType])
    useEffect(() => {
        dispatch(setNameForGeneratingArticle(title))
    }, [title])
    useEffect(() => {
        dispatch(setDescriptionForGeneratingArticle(description))
    }, [description])

    // if(!campaignStyle) return (
    return (
        <div className="ai-card-style no-style">

            <AppTabSelector
                selectedOption={selectedTypeOption}
                options={typeOptions}
                onSwitch={onSwitch}
                type={'brand'}
            />

            <WritingStyleOverview
                isCustom={key === 'custom'}
                styleType={savedStyleType}
                Icon={SelectedIcon}
                title={title}
                description={description}
                readOnly={false}
                openStyleSidebar={openStyleSidebar}
                style={style}

            />
        </div>
    )
};

export default AiCardStyle;
