import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEditorInstance } from "../../reducers/textEditor";
import CustomSvgIcon from "./CustomSvgIcon";
import { Menu, MenuItem } from "@material-ui/core";
import CustomFileUploader from "../CustomFileUploader";

import "./ImageUploader.css";
import { getCurrentImages, setLimitImagesError } from "../../reducers/images";
import { IMG_NOT_ALLOWED, MAX_IMAGES_ALLOWED } from "../../common/consts";

const ImageUploader = ({
  icon,
  Icon,
  title,
  isActive = null,
  onUploadProgres,
  onUploadError,
  onUploadStart,
  onUploadSuccess,
}) => {
  const editor = useSelector(getEditorInstance);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [updateProgress, setUpdateProgress] = useState(0);
  const [isImageUploading, setImageUploading] = useState(false)
  const totalImages = useSelector(getCurrentImages);
  const [isImageError, setImageUploadError] = useState(false)

  const uploadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_803_3265)">
      <mask id="mask0_803_3265" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <path d="M13.0859 7.82031C14.4688 8.10156 15.5 9.32031 15.5 10.75C15.5 12.4141 14.1406 13.75 12.5 13.75H3.875C2 13.75 0.5 12.25 0.5 10.375C0.5 8.92188 1.4375 7.65625 2.75 7.21094C2.75 7.14062 2.75 7.07031 2.75 7C2.75 4.9375 4.41406 3.25 6.5 3.25C7.88281 3.25 9.10156 4.02344 9.73438 5.14844C10.0859 4.89062 10.5312 4.75 11 4.75C12.2422 4.75 13.25 5.75781 13.25 7C13.25 7.30469 13.1797 7.5625 13.0859 7.82031ZM9.80469 9.32031C10.0391 9.08594 10.0391 8.73438 9.80469 8.52344L7.625 6.34375C7.41406 6.13281 7.0625 6.13281 6.85156 6.34375L4.67188 8.52344C4.4375 8.73438 4.4375 9.08594 4.67188 9.32031L4.92969 9.57812C5.14062 9.78906 5.51562 9.78906 5.72656 9.55469L6.5 8.75781V11.6875C6.5 12.0156 6.73438 12.25 7.0625 12.25H7.4375C7.74219 12.25 8 12.0156 8 11.6875V8.75781L8.75 9.55469C8.96094 9.78906 9.33594 9.78906 9.54688 9.57812L9.80469 9.32031Z" fill="#171717"/>
      </mask>
      <g mask="url(#mask0_803_3265)">
      <rect width="16" height="16" fill="#171717" fill-opacity="0.5"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_803_3265">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
      </svg>`;



  const insertImageWithSpacing = (src) => {
    if (!editor) return;
    const pos = editor.state.selection.to;

    editor.chain().focus().setTextSelection(pos).setImage({ src }).run();
  };

  const sendImageUrl = () => {
    if (imageUrl !== "") {
      insertImageWithSpacing(imageUrl);
    }
    setImageUrl("");
    handleClose();
  };

  const handleClick = (event) => {
    if (totalImages < MAX_IMAGES_ALLOWED) {
      setAnchorEl(event.currentTarget);
    }
    if (totalImages >= MAX_IMAGES_ALLOWED) {
      dispatch(setLimitImagesError(IMG_NOT_ALLOWED));
    }
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const onImageUploaded = (image) => {
    insertImageWithSpacing(image.url);
    setImageUploading(false);
    handleClose();
  };

  const onInputChange = (e) => {
    setImageUrl(e.target.value);

    // onUploadStart();
  };

  const onImageUpload = () =>{
    setImageUploading(true)
  }

  const onImageError = () => {
    setImageUploadError()
  }
  return (
      <>
          <button
              className={`menu-item${isActive && isActive() ? ' is-active' : ''}`}
              onClick={handleClick}
              title={title}
          >
            {icon && <span dangerouslySetInnerHTML={{ __html: icon }}/>}
            {Icon && <Icon />}
          </button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className="image-opener">
              {/* <MenuItem id="url-insert"> */}
              <div className="image-input-url">
                  <div className="image-input-container">
                      <label>Image URL</label>
                      <input type="text" value={imageUrl} placeholder="E.g. https://www.google.com/image..." onChange={onInputChange} />
                  </div>
                  <button className={imageUrl.length ? 'insert-image-btn' : 'disabled-image insert-image-btn'} onClick={sendImageUrl}>
                      Save
                  </button>
              </div>
              {/* </MenuItem> */}
              {/* <MenuItem id="uploader" onClick={handleClose}> */}
              {!isImageUploading && (
                  <CustomFileUploader
                      onSuccess={onImageUploaded}
                      onStart={onImageUpload}
                      onError={onImageError}
                      title={`<p className="editor-upload">${uploadIcon} Upload or drop a file</p>`}
                      onUpdatedProgress={onImageUpload}
                  />
              )}

              {isImageUploading && (
                  <p className="upload-spinner-image">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g clipPath="url(#clip0_828_70658)">
                              <mask id="mask0_828_70658" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                  <path
                                      d="M8.75 3.4375C8.75 3.0625 9.07812 2.78125 9.42969 2.875C11.9375 3.53125 13.8125 5.80469 13.8125 8.5C13.8125 11.7109 11.2109 14.3125 8 14.3125C4.78906 14.3359 2.1875 11.7578 2.1875 8.54688C2.16406 5.82812 4.01562 3.53125 6.52344 2.875C6.89844 2.78125 7.25 3.0625 7.25 3.4375V3.8125C7.25 4.07031 7.0625 4.28125 6.82812 4.35156C5 4.86719 3.6875 6.53125 3.6875 8.5C3.6875 10.8906 5.60938 12.8125 8 12.8125C10.3672 12.8125 12.3125 10.8906 12.3125 8.5C12.3125 6.53125 10.9766 4.86719 9.14844 4.35156C8.91406 4.28125 8.75 4.07031 8.75 3.8125V3.4375Z"
                                      fill="#171717"
                                  />
                              </mask>
                              <g mask="url(#mask0_828_70658)">
                                  <rect width="16" height="16" fill="#171717" />
                              </g>
                          </g>
                          <defs>
                              <clipPath id="clip0_828_70658">
                                  <rect width="16" height="16" fill="white" />
                              </clipPath>
                          </defs>
                      </svg>{' '}
                      Uploading{' '}
                  </p>
              )}
              {isImageError && <p className="upload-image-error-text">Error uploading image</p>}
              {/* </MenuItem> */}
          </Menu>
      </>
  );
};

export default ImageUploader;
