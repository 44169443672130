import React from 'react';
import './AppTabSelector.css'
import {WhiteTooltipAuto} from "../Components/UITooltips/UITooltip";
import {TEXTS} from "../common/consts";

const AppTabSelector = ({onSwitch , options, selectedOption, type = 'default'}) => {
    return (
        <div className={`app-tab-selector ${type}`}>
            {options.map(({key, label, icon: Icon, iconTooltip}) => (
                <div className={selectedOption === key ? 'active switch-item' : 'switch-item'} key={label} onClick={() => onSwitch(key)}>
                    {Icon && (
                        <WhiteTooltipAuto placement="bottom-start" title={iconTooltip || ''}>
                            <span><Icon /></span>
                        </WhiteTooltipAuto>
                    )}
                    <span className="app-tab-selector__label">
                        {label}
                    </span>
                </div>
            ))}
        </div>
    );
}
export default AppTabSelector