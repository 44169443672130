import { createSlice } from "@reduxjs/toolkit";
import {
  requestUserProfile,
  requestAllMasters,
  getProjectDefaultPromptReq,
  saveProjectDefaultPromptReq, getTopicSuggestions, getTopicSuggestionsTraffic,
  getUserFlags
} from "../services/user";
import { setApiError } from "./apiErrors";
import {getGlobalState} from "../globals";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    activeUser: null,
    masterUsers: null,
    userData: null,
    projectDefaultPrompt: '',
    campaignIsGlobal: false,
    isContentWriterAuthor: false,
    userCanEdit: true,
    suggestedTopics: null,
    flagsUpdated: false,
    userAccessToNewTracking: false,
    userAccessToInternalLinks: false,
    userHasArticlesLeft: true,
    userIsAdmin: true,
    userIsSeo: false,
    userAccessToSuggestedTopics: false,
    userAccessToNotifyMe: false,
    userHasWritingStyles: false,
    userHasImageAccess: false,
    draftConfigOptions: {
      aiModeSelector: false,
      testPromptSelector: false,
      ukCheckbox: false
    }
  },
  reducers: {
    setActiveUser: (state, { payload }) => {
      return {
        ...state,
        activeUser: payload,
      };
    },
    setCampaignIsGlobal: (state, { payload }) => {
      return {
        ...state,
        campaignIsGlobal: payload,
      };
    },
    setUserIsSeo: (state, { payload }) => {
      return {
        ...state,
        userIsSeo: payload,
      };
    },
    setFlagsUpdated: (state, { payload }) => {
      return {
        ...state,
        flagsUpdated: payload,
      };
    },
    setUserHasArticlesLeft: (state, { payload }) => {
      return {
        ...state,
        userHasArticlesLeft: payload,
      };
    },
    setUserHasWritingStyles: (state, { payload }) => {
      return {
        ...state,
        userHasWritingStyles: payload,
      };
    },
    setUserIsAdmin: (state, { payload }) => {
      return {
        ...state,
        userIsAdmin: payload,
      };
    },
    setUserHasImageAccess: (state, { payload }) => {
      return {
        ...state,
        userHasImageAccess: payload,
      };
    },
    setUserAccessToSuggestedTopics: (state, { payload }) => {
      return {
        ...state,
        userAccessToSuggestedTopics: payload,
      };
    },
    setUserAccessToNotifyMe: (state, { payload }) => {
      return {
        ...state,
        userAccessToNotifyMe: payload,
      };
    },
    setUserAccessToNewTracking: (state, { payload }) => {
      return {
        ...state,
        userAccessToNewTracking: payload,
      };
    },
    setUserAccessToInternalLinks: (state, { payload }) => {
      return {
        ...state,
        userAccessToInternalLinks: payload,
      };
    },
    setSuggestedTopics: (state, { payload }) => {
      return {
        ...state,
        suggestedTopics: payload,
      };
    },
    setDraftConfigOptions: (state, { payload }) => {
      return {
        ...state,
        draftConfigOptions: payload,
      };
    },
    setIsContentWriterAuthor: (state, { payload }) => {
      return {
        ...state,
        isContentWriterAuthor: payload,
      };
    },
    setUserCanEdit: (state, { payload }) => {
      return {
        ...state,
        userCanEdit: payload,
      };
    },
    setProjectDefaultPrompt: (state, { payload }) => {
      return {
        ...state,
        projectDefaultPrompt: payload,
      };
    },
    setMasterUsers: (state, { payload }) => {
      return {
        ...state,
        masterUsers: payload,
      };
    },
    setUserData: (state, { payload }) => {
      return {
        ...state,
        userData: payload
      }
    }
  },
});

export const { setActiveUser } = userSlice.actions;
export const { setCampaignIsGlobal } = userSlice.actions;
export const { setUserIsSeo } = userSlice.actions;
export const { setSuggestedTopics } = userSlice.actions;
export const { setFlagsUpdated } = userSlice.actions;
export const { setUserHasArticlesLeft } = userSlice.actions;
export const { setUserHasWritingStyles } = userSlice.actions;
export const { setUserIsAdmin } = userSlice.actions;
export const { setUserHasImageAccess } = userSlice.actions;
export const { setUserAccessToSuggestedTopics } = userSlice.actions;
export const { setUserAccessToNotifyMe } = userSlice.actions;
export const { setUserAccessToNewTracking } = userSlice.actions;
export const { setUserAccessToInternalLinks } = userSlice.actions;
export const { setDraftConfigOptions } = userSlice.actions;
export const { setIsContentWriterAuthor } = userSlice.actions;
export const { setUserCanEdit } = userSlice.actions;
export const { setProjectDefaultPrompt } = userSlice.actions;
export const { setMasterUsers } = userSlice.actions;
export const { setUserData } = userSlice.actions

export const getUserProfile = (token) => async (dispatch) => {
  try {
    const user = await requestUserProfile(token);
    dispatch(setActiveUser(user));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const getMasterUsers = (token) => async (dispatch) => {
  try {
    const masters = await requestAllMasters(token);

    dispatch(setMasterUsers(masters));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const getDefaultPrompt = (siteId) => async (dispatch) => {
    const response = await getProjectDefaultPromptReq(siteId);

    dispatch(setProjectDefaultPrompt(response?.prompt || ''));

    return response.prompt
};

export const saveProjectDefaultPrompt = (siteId, prompt) => async (dispatch) => {
    dispatch(setProjectDefaultPrompt(prompt));

    const savedPrompt = await saveProjectDefaultPromptReq(siteId, prompt);

    return savedPrompt
};

export const getAndPopulateUserFlags = () => async (dispatch) => {
    const siteId = getGlobalState('site_id');

    const { flags } = await getUserFlags(siteId);

    if(flags) {
        dispatch(setUserAccessToNewTracking(!!flags.track_keywords))
        dispatch(setUserAccessToInternalLinks(!!flags.internal_links))
        dispatch(setUserHasArticlesLeft(!!flags.can_write))
        dispatch(setUserHasWritingStyles(typeof flags.writing_styles !== 'undefined' ? !!flags.writing_styles : true))
        dispatch(setUserIsAdmin(typeof flags.is_admin !== 'undefined' ? !!flags.is_admin : true));
        dispatch(setUserHasImageAccess(!!flags.image_access));
        dispatch(setUserAccessToSuggestedTopics(!!flags.suggested_topics))
        dispatch(setUserIsSeo(!!flags.seo_user))
        dispatch(setUserAccessToNotifyMe(!!flags.notify_email))
        dispatch(setFlagsUpdated(true));
    }

    return flags
}

/**
 * @param payload = {siteId, rows, offset}
 * @returns []string
 */
export const getTopicSuggestionsRows = (payload) => async (dispatch, getState) => {
    const response = await getTopicSuggestions(payload);

    if(response.topics) {
      const formatTopics = response.topics.map(topic => ({topic, traffic: null}))
      // Get the current suggested topics from the state
      const currentTopics = getState().user.suggestedTopics || [];

      // Append new topics to the existing ones
      const updatedTopics = [...currentTopics, ...formatTopics];

      // Dispatch the updated list to the Redux store
      dispatch(setSuggestedTopics(updatedTopics));

      dispatch(getTrafficForSuggestedTopics({siteId: payload.siteId, topics: response.topics}))
    }

    return response
};

/**
 * @param payload = {siteId, topics}
 * @returns []string
 */
export const getTrafficForSuggestedTopics = (payload) => async (dispatch, getState) => {
    const response = await getTopicSuggestionsTraffic(payload);

    if(response.processing) {
      const repeatingPayload = payload;

      setTimeout(() => dispatch(getTrafficForSuggestedTopics(repeatingPayload)), 5000)
    } else if(response.traffic || response.topics) {
      const currentTopics = getState().user.suggestedTopics || [];
      const topicsToUpdate = Object.keys(response.traffic || response.topics);
      const trafficUpdates = currentTopics.map(topicItem => {
        if(topicsToUpdate.includes(topicItem.topic)) {
          return {
            topic: topicItem.topic,
            traffic: response.traffic ? response.traffic[topicItem.topic] : response.topics[topicItem.topic]
          }
        }
        return topicItem
      })

      dispatch(setSuggestedTopics(trafficUpdates))
    }

    return response
};

export const activeUser = ({ user: { activeUser } }) => activeUser;
export const campaignIsGlobal = ({ user: { campaignIsGlobal } }) => campaignIsGlobal;
export const getDraftConfigOptions = ({ user: { draftConfigOptions } }) => draftConfigOptions;
export const getIsContentWriterAuthor = ({ user: { isContentWriterAuthor } }) => isContentWriterAuthor;
export const getUserCanEdit = ({ user: { userCanEdit } }) => userCanEdit;
export const showDraftConfigOptions = ({ user: { draftConfigOptions } }) => Object.values(draftConfigOptions).includes(true);
export const allMasterUsers = ({ user: { masterUsers } }) => masterUsers;
export const getUserData = ({ user: { userData} }) => userData
export const getUserAccessToNewTracking = ({ user: { userAccessToNewTracking} }) => userAccessToNewTracking
export const getUserAccessToInternalLinks = ({ user: { userAccessToInternalLinks} }) => userAccessToInternalLinks
export const getProjectDefaultPrompt = ({ user: { projectDefaultPrompt} }) => projectDefaultPrompt
export const getFlagsUpdated = ({ user: { flagsUpdated } }) => flagsUpdated
export const getUserHasArticlesLeft = ({ user: { userHasArticlesLeft } }) => userHasArticlesLeft
export const getUserHasWritingStyles = ({ user: { userHasWritingStyles } }) => userHasWritingStyles
export const getUserIsAdmin = ({ user: { userIsAdmin } }) => userIsAdmin
export const getUserHasImageAccess = ({ user: { userHasImageAccess } }) => userHasImageAccess
export const getUserAccessToSuggestedTopics = ({ user: { userAccessToSuggestedTopics } }) => userAccessToSuggestedTopics
export const getUserAccessToNotifyMe = ({ user: { userAccessToNotifyMe } }) => userAccessToNotifyMe
export const getSuggestedTopics = ({ user: { suggestedTopics} }) => suggestedTopics
export const getUserIsSeo = ({ user: { userIsSeo} }) => userIsSeo

export default userSlice.reducer;
