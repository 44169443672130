// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article-keywords-table-group-header {
    background: #f3f3f3;
    border-radius: 12px;
    padding: 8px 16px 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    cursor: pointer;
}

.group-header-title svg {
    height: 8px;
    margin-left: 4px;
}
.group-header-title svg path {
    fill: var(--default-symbols-secondary)
}

.article-keywords-table-group-header .column--volume {
    padding-right: 16px;
}

.group-header-progress {
    margin-left: 8px;
}

.progress-icon--active,
.progress-icon {
    margin-left: 5px;
    font-size: 0;
    line-height: 1;
    width: 35px;
    text-align: right;
}

.progress-icon path{
    fill:var(--default-symbols-secondary)
}


.progress-icon--active path {
    fill: var(--hero-symbols-primary);
}`, "",{"version":3,"sources":["webpack://./src/Components/ArticleKeywordsTable/ArticleKeywordsTableGroupHeader.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;AACA;IACI;AACJ;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;;IAEI,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI;AACJ;;;AAGA;IACI,iCAAiC;AACrC","sourcesContent":[".article-keywords-table-group-header {\n    background: #f3f3f3;\n    border-radius: 12px;\n    padding: 8px 16px 8px 8px;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    margin-bottom: 14px;\n    cursor: pointer;\n}\n\n.group-header-title svg {\n    height: 8px;\n    margin-left: 4px;\n}\n.group-header-title svg path {\n    fill: var(--default-symbols-secondary)\n}\n\n.article-keywords-table-group-header .column--volume {\n    padding-right: 16px;\n}\n\n.group-header-progress {\n    margin-left: 8px;\n}\n\n.progress-icon--active,\n.progress-icon {\n    margin-left: 5px;\n    font-size: 0;\n    line-height: 1;\n    width: 35px;\n    text-align: right;\n}\n\n.progress-icon path{\n    fill:var(--default-symbols-secondary)\n}\n\n\n.progress-icon--active path {\n    fill: var(--hero-symbols-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
