import React, {useState} from "react";

import "./EditorContentInfo.css";
import {
    descriptionForGeneratingArticle,
    nameForGeneratingArticle,
    styleForGeneratingArticle,
    typeForGeneratingArticle
} from "../../reducers/articleSettings";
import {useSelector} from "react-redux";
import WritingStyleOverview from "./AiCard/WritingStyleOverview";
import {
    BookIcon,
    ChatIcon,
    ChevronDown, ChevronUp,
    ConfigurationIcon,
    FileIcon,
    GlobeIcon,
    MagicIcon,
    UserIcon
} from "../../UI_utils/SVGIcons";

const EditorContentInfo = ({}) => {
    const style = useSelector(styleForGeneratingArticle);
    const title = useSelector(nameForGeneratingArticle);
    const description = useSelector(descriptionForGeneratingArticle);
    const type = useSelector(typeForGeneratingArticle);

    const returnIcon = () => {
        if(!title) return MagicIcon

        if(type)
            if(type === 'selected') return ConfigurationIcon
            if(type === 'article') return FileIcon
            if(type === 'website') return GlobeIcon

        const loweredTitle = title.toLowerCase();

        if(loweredTitle === 'professional style') return UserIcon
        if(loweredTitle === 'educational style') return BookIcon
        if(loweredTitle === 'casual style') return ChatIcon
    }

    const [styleExpanded, setStyleExpanded] = useState(false)

    if(!style || true) return <div></div>

    return (
        <div className="editor-content-info">
            <div className="editor-content-info__header">
                <div></div>
                <div className="toggle-styling" onClick={() => setStyleExpanded(!styleExpanded)}>
                    {title}
                    {!styleExpanded && <ChevronDown/>}
                    {styleExpanded && <ChevronUp/>}
                </div>
            </div>
            {style && styleExpanded && (
                <WritingStyleOverview
                    isCustom={!!type}
                    styleType={type}
                    Icon={returnIcon()}
                    title={title}
                    description={description}
                    readOnly={true}
                    style={style}
                    type={'white'}
                />
            )}
        </div>
    );
};

export default EditorContentInfo;
