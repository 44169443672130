// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-modal.confirmation-modal {
    z-index: 22;
}
.confirmation-modal .app-modal-text {
    padding-right: 36px;
}

.confirmation-modal .app-modal-button {
    background: var(--default-surfaces-light);
    padding: 8px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-primary);
    width: 50%;
}

.confirmation-modal .app-modal-button:first-child:last-child {
    width: 100%;
}

.confirmation-modal .app-modal-button:not(.app-modal-button--secondary) {
    background: var(--night-surfaces-anchor);
    color: var(--inverted-symbols-primary);
}

.confirmation-modal.delete-article .app-modal-button:not(.app-modal-button--secondary) {
    background: var(--default-symbols-primary);
    color: var(--inverted-symbols-primary);
}

.confirmation-modal .app-modal-button:not(.app-modal-button--secondary) path {
    fill: var(--inverted-symbols-primary);
}
`, "",{"version":3,"sources":["webpack://./src/Shared/ConfirmationModal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,yCAAyC;IACzC,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,qCAAqC;IACrC,UAAU;AACd;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,wCAAwC;IACxC,sCAAsC;AAC1C;;AAEA;IACI,0CAA0C;IAC1C,sCAAsC;AAC1C;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".app-modal.confirmation-modal {\n    z-index: 22;\n}\n.confirmation-modal .app-modal-text {\n    padding-right: 36px;\n}\n\n.confirmation-modal .app-modal-button {\n    background: var(--default-surfaces-light);\n    padding: 8px;\n    border-radius: 50px;\n    font-size: 13px;\n    font-weight: 700;\n    line-height: 16px;\n    letter-spacing: -0.01em;\n    color: var(--default-symbols-primary);\n    width: 50%;\n}\n\n.confirmation-modal .app-modal-button:first-child:last-child {\n    width: 100%;\n}\n\n.confirmation-modal .app-modal-button:not(.app-modal-button--secondary) {\n    background: var(--night-surfaces-anchor);\n    color: var(--inverted-symbols-primary);\n}\n\n.confirmation-modal.delete-article .app-modal-button:not(.app-modal-button--secondary) {\n    background: var(--default-symbols-primary);\n    color: var(--inverted-symbols-primary);\n}\n\n.confirmation-modal .app-modal-button:not(.app-modal-button--secondary) path {\n    fill: var(--inverted-symbols-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
