// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  /*background-color: #d9e1e9;*/
  /*height: 1.25rem;*/
  /*margin-left: 0.5rem;*/
  /*margin-right: 0.75rem;*/
  /*width: 2px;*/
}

.editor__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 32px 12px 32px;
}

.editor__header .editor__header-left{
  display: flex;
  align-items: center
}

.editor__header .editor__header-right{
  display: flex;
  align-items: center
}

.upload-progress-wrapper {
  background: white;
  padding: 30px;
  border-radius: 20px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.upload-progress-message {
  font-size: 14px;
  color: #384359;
  font-weight: 700;
  margin-bottom: 10px;
}

.upload-progress-error {
  font-size: 14px;
  color: #ec5032;
  font-weight: 700;
  margin-bottom: 10px;
}

.upload-progress-bar {
  width: 200px;
  height: 14px;
  background-color: #b5b5c3;
  border-radius: 9px;
}
.edit__header-left-container{
  display: flex;
  align-items: center;
  gap: 16px
}
`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/EditorMenuTop.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb;AACF;;AAEA;EACE,aAAa;EACb;AACF;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB;AACF","sourcesContent":[".divider {\n  /*background-color: #d9e1e9;*/\n  /*height: 1.25rem;*/\n  /*margin-left: 0.5rem;*/\n  /*margin-right: 0.75rem;*/\n  /*width: 2px;*/\n}\n\n.editor__header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 6px 32px 12px 32px;\n}\n\n.editor__header .editor__header-left{\n  display: flex;\n  align-items: center\n}\n\n.editor__header .editor__header-right{\n  display: flex;\n  align-items: center\n}\n\n.upload-progress-wrapper {\n  background: white;\n  padding: 30px;\n  border-radius: 20px;\n  min-width: 360px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: left;\n}\n\n.upload-progress-message {\n  font-size: 14px;\n  color: #384359;\n  font-weight: 700;\n  margin-bottom: 10px;\n}\n\n.upload-progress-error {\n  font-size: 14px;\n  color: #ec5032;\n  font-weight: 700;\n  margin-bottom: 10px;\n}\n\n.upload-progress-bar {\n  width: 200px;\n  height: 14px;\n  background-color: #b5b5c3;\n  border-radius: 9px;\n}\n.edit__header-left-container{\n  display: flex;\n  align-items: center;\n  gap: 16px\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
