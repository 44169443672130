// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-overlay.open {
    opacity: 1;
}

.app-overlay {
    background: var(--default-surfaces-dark);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: 0.5s ease;
}`, "",{"version":3,"sources":["webpack://./src/Shared/AppOverlay.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,wCAAwC;IACxC,eAAe;IACf,MAAM;IACN,SAAS;IACT,QAAQ;IACR,OAAO;IACP,UAAU;IACV,UAAU;IACV,qBAAqB;AACzB","sourcesContent":[".app-overlay.open {\n    opacity: 1;\n}\n\n.app-overlay {\n    background: var(--default-surfaces-dark);\n    position: fixed;\n    top: 0;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    z-index: 1;\n    opacity: 0;\n    transition: 0.5s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
