import React, { useState } from 'react';
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';
import AppModal from '../../Shared/AppModal';
import {InfoCircleIcon, InfoCircleLightIcon} from '../../UI_utils/SVGIcons';
import './FeedbackModal.css';
import {sendArticleFeedback} from "../../reducers/articles";

const FeedbackModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState('');

    const handleSendFeedback = () => {
        dispatch(sendArticleFeedback(-1, 'article', feedback));
        onClose();
    };

    return (
        <AppModal
            className="feedback-modal"
            icon={InfoCircleIcon}
            title="Help us improve this article"
            text="Your feedback helps us create better content for your SEO needs. What specific aspects of this article could be improved?"
            buttonText="Send feedback"
            secondaryButtonText="Don't share"
            buttonAction={handleSendFeedback}
            secondaryButtonAction={onClose}
            onCloseAction={onClose}
            disableButton={!feedback.trim()}
            width={'400px'}
            customContent={
                <textarea
                    className="feedback-modal__textarea"
                    placeholder="Tell us what didn't meet your expectations (e.g., accuracy, depth, relevance, readability, examples)"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                />
            }
        />
    );
};

FeedbackModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default FeedbackModal;
