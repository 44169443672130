import React, {useCallback} from 'react';
import './AppTabSelector.css'
import {WhiteTooltipAuto} from "../Components/UITooltips/UITooltip";
import {TEXTS} from "../common/consts";

const AppTabSelector = ({onSwitch , options, selectedOption, type = 'default', switcherAuto = false, className, tooltipPlacement = 'bottom-start'}) => {
    const singleOption = useCallback(() => options.length === 1, [options])
    const customClass = useCallback(() => {
        return `${singleOption() ? 'single-option' : ''} ${type}`
    }, [options, type])
    return (
        <div className={`app-tab-selector ${customClass()} ${className}`}>
            {options.map(({key, label, icon: Icon, iconTooltip, info, score, tooltip, disabled}) => (
                <WhiteTooltipAuto
                    key={label}
                    placement={tooltipPlacement}
                    title={tooltip ? <span dangerouslySetInnerHTML={{ __html: tooltip }} /> : ''}
                >
                    <div
                        className={`${switcherAuto ? 'switch-item--auto' : ''} ${selectedOption === key ? 'active switch-item' : 'switch-item'} ${disabled ? 'disabled' : ''}`}
                        onClick={() => !disabled && onSwitch(key)}
                    >
                        {Icon && (
                            <WhiteTooltipAuto placement={tooltipPlacement} title={iconTooltip || ''}>
                                <span className={"app-tab-selector__icon"}><Icon /></span>
                            </WhiteTooltipAuto>
                        )}
                        <span className="app-tab-selector__label">
                            {label}
                        </span>
                        {info && <span className="app-tab-selector__info">{info}</span>}
                        {!!score && <span className="app-tab-selector__info">{score}</span>}
                    </div>
                </WhiteTooltipAuto>
            ))}
        </div>
    );
}
export default AppTabSelector