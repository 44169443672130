import { createSlice } from "@reduxjs/toolkit";
import { defaultTopicDetails, defaultTopicInfo } from './defaultTopicResults';
import {
    getTopicDetails,
    topicInfo,
    searchVolumeTopicService,
    estimatedVisitsTopicService,
    searchIntentTopicService,
} from "../services/topic";
import {
  requestCompetition,
  requestCategoryCompetition,
} from "../services/competition";
import {
  setTopicCoverageData, setTopicCoverageLoadingState,
} from "./topicCoverage";
import { setExpectedTitle } from "./titleOptimization";
import { getUserToken } from "../utils/userStatus";
import {saveArticle} from "../reducers/articles";
import { TYPE_DESCRIPTION } from "../common/consts";
import { setDescriptionDataLoaded } from "../reducers/categoryTextEditor";
import { setApiError, setApiErrorMessage } from "./apiErrors";
import {
  transformData,
} from "../services/topic-helpers";


export const searchTopicSlice = createSlice({
    name: 'searchtopic',
    initialState: {
        topicData: null,
        topicDataLoading: null,
        topicStep2Loading: false,
        topicRules: defaultTopicDetails,
        searchKeywords: null,
        userProjectId: null,
        searchTopicError: null,
        competitionData: null,
        anotherMarketCompetitionData: null,
        competitionDataLoading: null,
        fetchDataError: false,
        fetchCompetitionError: false,
        topicLanguage: null,
        topicInfoData: defaultTopicInfo,
    },
    reducers: {
        setTopicData: (state, { payload }) => {
            return {
                ...state,
                topicData: payload && payload.code ? null : payload,
                searchTopicError: payload && payload.code,
            };
        },

        setKeywordsSearch: (state, { payload }) => {
            return {
                ...state,
                searchKeywords: payload,
            };
        },

        setUserProjectId: (state, { payload }) => {
            return {
                ...state,
                userProjectId: payload,
            };
        },

        setTopicLanguage: (state, { payload }) => {
            return {
                ...state,
                topicLanguage: payload,
            };
        },

        setLoading: (state, { payload }) => {
            return {
                ...state,
                topicDataLoading: payload,
            };
        },

        setLoadingStep2: (state, { payload }) => {
            console.log('payload => ', payload)
            return {
                ...state,
                topicStep2Loading: payload,
            };
        },

        setTopicRules: (state, { payload }) => {
            return {
                ...state,
                topicRules: payload,
            };
        },

        setCompetitionData: (state, { payload }) => {
            return {
                ...state,
                competitionData: payload,
            };
        },

        setAnotherMarketCompetitionData: (state, { payload }) => {
            return {
                ...state,
                anotherMarketCompetitionData: payload,
            };
        },

        setLoadingCompetition: (state, { payload }) => {
            return {
                ...state,
                competitionDataLoading: payload,
            };
        },

        setFetchError: (state, { payload }) => {
            return {
                ...state,
                fetchDataError: payload,
            };
        },

        setFetchCompetitionError: (state, { payload }) => {
            return {
                ...state,
                fetchCompetitionError: payload,
            };
        },
        setTopicInfo: (state, { payload }) => {
            return {
                ...state,
                topicInfoData: payload,
            };
        },
    },
});

export const { setLoading } = searchTopicSlice.actions;
export const { setLoadingStep2 } = searchTopicSlice.actions;
export const { setTopicData } = searchTopicSlice.actions;
export const { setTopicRules } = searchTopicSlice.actions;
export const { setKeywordsSearch } = searchTopicSlice.actions;
export const { setUserProjectId } = searchTopicSlice.actions;
export const { setTopicLanguage } = searchTopicSlice.actions;
export const { setCompetitionData } = searchTopicSlice.actions;
export const { setAnotherMarketCompetitionData } = searchTopicSlice.actions;
export const { setLoadingCompetition } = searchTopicSlice.actions;
export const { setFetchError } = searchTopicSlice.actions;
export const { setFetchCompetitionError } = searchTopicSlice.actions;
export const { setTopicInfo } = searchTopicSlice.actions;

export const setDefaultTopicData = (topic) => (dispatch) => {
  const defaultTopicData = {
    topic,
    monthlyValues: [
      {id: 1, name: 'Jan', value: '5000'},
      {id: 2, name: 'Feb', value: '5000'},
      {id: 3, name: 'Mar', value: '5000'},
      {id: 4, name: 'Apr', value: '5000'},
      {id: 5, name: 'May', value: '5000'},
      {id: 6, name: 'Jun', value: '5000'},
      {id: 7, name: 'Jul', value: '5000'},
      {id: 8, name: 'Aug', value: '5000'},
      {id: 9, name: 'Sep', value: '5000'},
      {id: 10, name: 'Oct', value: '5000'},
      {id: 11, name: 'Nov', value: '5000'},
      {id: 12, name: 'Dec', value: '5000'},
    ]
  }
  const formattedDefaultTopicData = transformData(defaultTopicData);
  dispatch(setTopicData(formattedDefaultTopicData));
}
export const searchIntentPromise = (topicObj, token) => async (dispatch, getState) => {
  try {
    const searchIntentData = await searchIntentTopicService(topicObj, token);
    const initialTopicData = getState().searchtopic.topicData;
    const topicData = { ...initialTopicData, ...searchIntentData};

    dispatch(setTopicData(topicData));

  } catch(error) {
    throw error
  }
}
export const estimatedVisitsPromise = (topicObj, token) => async (dispatch, getState) => {
  try {
    const estimatedVisitsData = await estimatedVisitsTopicService(topicObj, token);
    const initialTopicData = getState().searchtopic.topicData;
    const topicData = { ...initialTopicData, ...estimatedVisitsData};

    dispatch(setTopicData(topicData));

  } catch(error) {
    throw error
  }
}
export const searchVolumePromise = (topicObj, token) => async (dispatch, getState) => {
  try {
    const searchVolumeData = await searchVolumeTopicService(topicObj, token);
    const initialTopicData = getState().searchtopic.topicData;
    const topicData = { ...initialTopicData, ...searchVolumeData};

    dispatch(setTopicData(topicData));

  } catch(error) {
    throw error
  }
}

export const getCompetitionOnTopic = (topic) => async (dispatch) => {
  try {
      //scos language
    dispatch(setLoadingCompetition(true));
    const competition = await requestCompetition(topic);
    dispatch(setCompetitionData(competition));
    dispatch(setLoadingCompetition(false));
  } catch (error) {
    dispatch(setApiError(error));
    dispatch(setApiErrorMessage("error getting competition"));
  }
};

// this method only applies when we are in application mode -> category
export const searchTopicForCategory = (value, language) => async (dispatch) => {
  const token = getUserToken();
  try {
    dispatch(setLoadingCompetition(true));
    dispatch(setLoadingStep2(true));
    dispatch(setTopicCoverageLoadingState(false));
    dispatch(setKeywordsSearch(value));

    const { topicCoverageHigh, expectedTitle } = await getTopicDetails(
      value,
      language,
      TYPE_DESCRIPTION,
      token
    );

    dispatch(setTopicCoverageData(topicCoverageHigh));
    dispatch(setExpectedTitle(expectedTitle));
    dispatch(setLoadingStep2(false));
    dispatch(setDescriptionDataLoaded(true));

    dispatch(saveArticle(TYPE_DESCRIPTION));
  } catch (error) {
    dispatch(setApiError(error));
  }
};

export const getCompetitionOnCategory =
  (topic, language) => async (dispatch) => {
    try {
      dispatch(setLoadingCompetition(true));
      const categoryCompetition = await requestCategoryCompetition(
        topic,
        language
      );
      const { categoryCompetitionData } = categoryCompetition;

      dispatch(setFetchCompetitionError(false));
      dispatch(setCompetitionData(categoryCompetitionData));

      dispatch(setLoadingCompetition(false));
    } catch (error) {
      dispatch(setFetchCompetitionError(true));
      dispatch(setApiError(error));
    }
  };

export const getTopicInfo = (topic) => async (dispatch) => {
  const token = getUserToken();

      try {
        const result = await topicInfo(topic, token);
        dispatch(setTopicInfo(result));

      } catch (error) {
        dispatch(setApiError(error));
      }
  };


// selectors
export const getCompetitionData = ({ searchtopic: { competitionData } }) =>
  competitionData;
export const getAnotherMarketCompetitionData = ({ searchtopic: { anotherMarketCompetitionData } }) => anotherMarketCompetitionData;

export const loadingStep2Data = ({ searchtopic: { topicStep2Loading } }) =>
  topicStep2Loading;

export const getSearchKeywords = ({ searchtopic: { searchKeywords } }) =>
  searchKeywords;

export const loadingCompetitionData = ({
  searchtopic: { competitionDataLoading },
}) => competitionDataLoading;

export const isFetchCompetitionError = ({
  searchtopic: { fetchCompetitionError },
}) => fetchCompetitionError;

export const getTopicInfoData = ({ searchtopic: { topicInfoData } }) => topicInfoData;
export default searchTopicSlice.reducer;
