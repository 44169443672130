import React, {useState, useCallback, useMemo, useEffect} from "react";
import "./EditorSwitcher.css";
import { useDispatch, useSelector } from "react-redux";
import { TEXTS } from '../../common/consts'
import {
    GlobeIcon,
    RefreshIcon,
    SpinnerIcon,
} from "../../UI_utils/SVGIcons";
import AppTabSelector from "../../Shared/AppTabSelector";
import CurrentArticleModal from "./CurrentArticleModal";
import RestoreGeneratedModal from "./RestoreGeneratedModal";
import { WhiteTooltipAuto } from "../UITooltips/UITooltip";
import {
    getArticleFromLP,
    getArticleScore,
    getGeneratedScore,
    getLiveScore,
    getOutlineScore,
    getCurrentArticleId
} from "../../reducers/articles";
import { useHistory, useLocation } from "react-router-dom";
import { ARTICLE_SECTIONS, getUrlSectionKey, buildArticleUrl, updateUrl } from '../../utils/urlHelpers';
import {getGlobalState} from "../../globals";

const EditorSwitcher = ({
    selectedTypeOption,
    setSelectedTypeOption,
    typeOptions,
    loadingCurrentWebsiteArticle,
    currentWebsiteArticle,
    restoreGenerated,
    onSwitch
}) => {
    const sharedToken = getGlobalState('sharedToken');

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const liveScore = useSelector(getLiveScore);
    const articleId = useSelector(getCurrentArticleId);

    const [activeModal, setActiveModal] = useState('');
    const [previousType, setPreviousType] = useState('');

    const switchAndUpdateUrl = useCallback((option) => {
        onSwitch(option);

        const urlSection = getUrlSectionKey(option);
        if (urlSection && articleId && !sharedToken) {
            const newPath = buildArticleUrl(articleId, urlSection, location.search);
            updateUrl(newPath, history);
        }
    }, [currentWebsiteArticle, loadingCurrentWebsiteArticle, selectedTypeOption, articleId, history, location.search, previousType]);

    // Handlers
    const onSwitchHandler = useCallback(
        (option) => {
            if(option === selectedTypeOption) return ;

            if (option === 'current' && currentWebsiteArticle === null && !loadingCurrentWebsiteArticle) {
                setActiveModal(option);
                setPreviousType(selectedTypeOption);
            } else if (option === 'current' && currentWebsiteArticle !== null && selectedTypeOption === 'current') {
                setActiveModal(option);
            }
            switchAndUpdateUrl(option)
        },
        [currentWebsiteArticle, loadingCurrentWebsiteArticle, selectedTypeOption, articleId, history, location.search, previousType]
    );

    useEffect(() => {
        if(selectedTypeOption === 'current' && !activeModal && !loadingCurrentWebsiteArticle) {
            setActiveModal('current')
            switchAndUpdateUrl('current')
        }
    }, []);

    const closeModal = useCallback((skipSwitch = false) => {
        setActiveModal('')
        if(previousType && !skipSwitch) onSwitch(previousType);
        setPreviousType('');
    }, [previousType]);

    const liveOptions = useMemo(() => [
        {label: 'Live', key: 'current', icon: GlobeIcon, score: liveScore, tooltip: TEXTS.LIVE_TAB_TOOLTOP}
    ], [liveScore]);

    return (
        <div className="editor-switcher__wrapper">
            {/* Modals */}
            {activeModal === 'current' && (
                <CurrentArticleModal
                    closeHandler={closeModal}
                    setSelectedTypeOption={onSwitch}
                />
            )}
            {activeModal === 'generated' && (
                <RestoreGeneratedModal closeHandler={closeModal} restoreHandler={restoreGenerated} />
            )}
                <AppTabSelector
                    selectedOption={selectedTypeOption}
                    options={typeOptions}
                    onSwitch={onSwitchHandler}
                    switcherAuto={true}
                    tooltipPlacement={'bottom'}
                    rightText={'score'}
                />

                {!sharedToken && (
                    <AppTabSelector
                        selectedOption={selectedTypeOption}
                        options={liveOptions}
                        onSwitch={onSwitchHandler}
                        switcherAuto={true}
                        tooltipPlacement={'bottom'}
                    />
                )}
            <br/>
        </div>
    );
};

export default EditorSwitcher;
