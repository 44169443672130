import {useDispatch, useSelector} from "react-redux";
import {
    getDraftConfigOptions,
    getProjectDefaultPrompt,
    getUserData, getUserHasImageAccess,
    showDraftConfigOptions
} from "../../../reducers/user";
import CustomSelect from "../../../Shared/CustomSelect";
import CustomCheckbox from "../../../Shared/CustomCheckbox";
import {setShowSettingsSidebar} from "../../../reducers/articleSettings";
import {InfoCircleIcon, InfoCircleLightIcon} from "../../../UI_utils/SVGIcons";
import React, {useState} from "react";
import {WhiteTooltipAuto} from "../../UITooltips/UITooltip";
import {getGenerateImages, setGenerateImages} from "../../../reducers/articles";

const AiCardPrompt = ({
    promptType,
    text,
    handleTextareaChange,
    textareaRef,
    aiMode,
    setAiMode,
    selectedTestPrompt,
    setSelectedTestPrompt,
    selectedMarketSource,
    setSelectedMarketSource,
    isOutline
}) => {
    const dispatch = useDispatch();
    const userData = useSelector(getUserData);
    const hasDraftConfigOptions = useSelector(showDraftConfigOptions);
    const draftConfigOptions = useSelector(getDraftConfigOptions);
    const defaultPrompt = useSelector(getProjectDefaultPrompt)
    const imageAccess = useSelector(getUserHasImageAccess);
    const generateImages = useSelector(getGenerateImages);

    // const [generateImages, setGenerateImages] = useState(false);
    const updateGenerateImages = (checked) => {
        dispatch(setGenerateImages(checked))
    }

    const aiSource = [
        { label: 'OpenAI GPT-4', value: 'openai' },
        { label: 'OpenAI GPT-4o (faster)', value: 'openai4o' },
        { label: 'Claude', value: 'claude' },
        { label: 'Gemini', value: 'gemini' },
    ];
    const marketSource = [{ label: 'True', value: true }, { label: 'False', value: false }]
    const testPrompt = [
        { label: 'Default', vlaue: false },
        { label: 'Alternative', value: true },
    ];

    const PLACEHOLDER_ARTICLE_PROMPT = 'Any additional guidelines for the AI generation';
    const PLACEHOLDER_OUTLINE_PROMPT = `E.g. 'Include a FAQ section' or 'Focus on benefits'`;

    const openDefaultPromptSidebar = () => dispatch(setShowSettingsSidebar('prompt'));

    return (
        <div className="ai-card-prompt__wrapper">
            <div className="ai-card-prompt">
                <div className="ai-card-prompt__textarea-wrapper">
                <label htmlFor="prompt" className={"prompt-label"}>User prompt</label>
                    <textarea
                        name="prompt"
                        id="prompt"
                        placeholder={
                            promptType === 'article' ? PLACEHOLDER_ARTICLE_PROMPT : PLACEHOLDER_OUTLINE_PROMPT
                        }
                        ref={textareaRef}
                        value={text}
                        onChange={handleTextareaChange}
                        style={{ overflowY: 'hidden' }}
                    />
                    <span className={`prompt__counter ${text.length > 800 ? 'exceed' : ''}`}>{text.length}/800</span>
                </div>

                {!!defaultPrompt && (
                    <div className={"default-prompt-info"}>
                        <InfoCircleLightIcon />
                        Campaign brief is defined and will be used during article generation. <b onClick={openDefaultPromptSidebar}>Edit.</b>
                    </div>
                )}


                {!!imageAccess && promptType === 'article' && (
                    <div className={"generate-images"}>
                        <CustomCheckbox
                            inputId="pageSubmittedToGoogle"
                            label="Auto-generate or import optimized images for your content"
                            checked={generateImages}
                            handleChange={updateGenerateImages}
                            className={'reverse'}
                        />
                        <WhiteTooltipAuto
                            placement="bottom"
                            title={<div style={{ whiteSpace: 'pre-line' }}>Content Writer will analyze your article to identify image needs, then either<br />generate relevant images using AI or import them from your website<br />or official sources, when applicable. All images undergo automatic quality<br />verification to ensure only high-quality visuals are included.</div>}>
                            <span><InfoCircleIcon /></span>
                        </WhiteTooltipAuto>
                        <div className="beta-label">BETA</div>
                    </div>
                )}


            </div>

            {(hasDraftConfigOptions && isOutline) && (
                <div className="writer-source">
                    {draftConfigOptions?.aiModeSelector && (
                        <CustomSelect
                            inputId={'aiModeSelector'}
                            defaultValue={aiMode}
                            options={aiSource}
                            handleChange={setAiMode}
                            label="Writer model"
                        />
                    )}
                    {draftConfigOptions?.testPromptSelector && (
                        <CustomSelect
                            inputId={'testPromptSelector'}
                            defaultValue={selectedTestPrompt}
                            options={testPrompt}
                            handleChange={setSelectedTestPrompt}
                            label="Test prompt"
                        />
                    )}
                    {draftConfigOptions?.ukCheckbox && (
                        <CustomCheckbox
                            inputId={'ukCheckbox'}
                            label="Get data from UK"
                            defaultValue={selectedMarketSource}
                            handleChange={(value) => setSelectedMarketSource(value)}
                            tooltipText={'<span>Gathers data for the topic in UK<br>instead of campaign’s target<br>location. Useful for products<br>and services that are new or<br>lack articles about them in<br>target language.</span>'}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default AiCardPrompt;
