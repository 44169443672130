// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-how-it-works {
    border-left: 1px solid var(--default-borders-light);
    padding-left: 16px;
}

.modal-how-it-works_boxes {
    margin-top: 16px;
    display: grid;
    row-gap: 16px;
}

.modal-how-it-works_title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: var(--default-symbols-primary);
}`, "",{"version":3,"sources":["webpack://./src/Components/NewArticleModal/ModalHowItWorks.css"],"names":[],"mappings":"AAAA;IACI,mDAAmD;IACnD,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,qCAAqC;AACzC","sourcesContent":[".modal-how-it-works {\n    border-left: 1px solid var(--default-borders-light);\n    padding-left: 16px;\n}\n\n.modal-how-it-works_boxes {\n    margin-top: 16px;\n    display: grid;\n    row-gap: 16px;\n}\n\n.modal-how-it-works_title {\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 24px;\n    color: var(--default-symbols-primary);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
