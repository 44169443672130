import React, { useEffect, useState } from 'react';

import './ArticleKeywordsTable.css'
import ArticleKeywordsTableHeader from "./ArticleKeywordsTableHeader";
import ArticleKeywordsTableGroup from "./ArticleKeywordsTableGroup";
import {useSelector} from "react-redux";
import {
    getTopicCoverageData,
} from "../../reducers/topicCoverage";
import ArticleKeywordsTableGroupLoading from "./ArticleKeywordsTableGroupLoading";
import {
    getTitleCoverage,
} from "../../reducers/titleOptimization";
import { getParsedData } from "../../reducers/textEditor";
import {filterUsedKeywords} from "../tagsHelpers";
import {
    getArticleEditorLoadingState,
    getOutlineEditorLoadingState,
} from "../../reducers/articles";
import {
    getActiveCoverageDataHeading,
    getActiveCoverageDataTitle, getActiveCoverageDataTopic,
} from "../../reducers/keywordsCoverage";
import {WhiteTooltip} from "../UITooltips/UITooltip";
import LoadingBlock from "../LoadingBlock/LoadingBlock";
import {getGlobalState} from "../../globals";

const ArticleKeywordsTable = ({
    mode,
    writerMode,
    trackMode,
    trackNewKeywords,
    setTrackMode,
    setTrackNewKeywords,
    trackingNewKeywordsLoader
}) => {
    const processedKeywords = useSelector(getTopicCoverageData);

    const coverageDataTitle = useSelector(getActiveCoverageDataTitle);
    const coverageDataTopic = useSelector(getActiveCoverageDataTopic);
    const coverageDataHeading = useSelector(getActiveCoverageDataHeading);

    const titleCoverage = useSelector(getTitleCoverage);
    const parsedData = useSelector(getParsedData);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [allKeywords, setAllKeywords] = useState(processedKeywords);

    const articleLoading = useSelector(getArticleEditorLoadingState);
    const outlineLoading = useSelector(getOutlineEditorLoadingState);

    const hasSharedToken = getGlobalState('sharedToken');

    const loadingContent = () => writerMode === 'outline' ? outlineLoading : articleLoading;

    const [activeTags, setActiveTags] = useState(allKeywords);

    const filterCoverage = (coverageArray, parsedData, field, useIn) => {
        if (coverageArray && coverageArray !== 'undefined') {
            const filteredCoverage = coverageArray
                .filter(item => item?.name ? item.name.includes(searchKeyword) : item.length && item.includes(searchKeyword))
                .map(item => {

                    return {
                        keyword: item?.name || item,
                        search_volume: item?.search_volume,
                        broad: false,
                        tracked:!!item?.tracked
                    }
                })
                .sort((a, b) => b.search_volume - a.search_volume)

            return filteredCoverage;
        } else {
            return [];
        }
    }

    const filteredTitles = () => filterCoverage(titleCoverage, parsedData, 'headings1', 'use_in_h1');

    const filterKeywords = (filter) => {
        setSearchKeyword(filter);
    };

    useEffect(() => {
        const filteredKeywords = processedKeywords.filter(({ keyword }) =>
            keyword?.includes(searchKeyword)
        );
        setAllKeywords(filteredKeywords);

    }, [processedKeywords, searchKeyword]);



    useEffect(() => {
        const filtered = filterUsedKeywords(allKeywords, loadingContent());

        setActiveTags(filtered);

    }, [allKeywords, articleLoading, outlineLoading]);


    useEffect(() => {
        if(loadingContent()) setTrackMode(false);
    }, [writerMode, outlineLoading, articleLoading])

    const totalKwCount = () => {
        const array = [...filteredTitles(), ...activeTags].map(kw => kw.keyword);
        return Array.from(new Set(array))?.length
    };
    const totalTrackedKwCount = () => {
        const array = [...filteredTitles(), ...activeTags].filter(kw => kw?.tracked).map(kw => kw.keyword)
        return Array.from(new Set(array))?.length
    };
    const allKwAreTracked = () => totalTrackedKwCount() === totalKwCount()

    const toggleTrackKeyword = (keyword) => {
        const array = [...trackNewKeywords];
        const index = array.findIndex(kw => kw === keyword)

        if(index > -1) {
            array.splice(index, 1)
            setTrackNewKeywords(array)
        } else {
            array.push(keyword);
            setTrackNewKeywords(array)
        }
    }

    const toggleSelectAllKeywordsHandler = () => {
        const leftKeywordUntracked = totalKwCount() - totalTrackedKwCount();

        if(trackNewKeywords.length === leftKeywordUntracked) setTrackNewKeywords([])

        else {
            const array = [...filteredTitles(), ...activeTags].filter(kw => !kw.tracked).map(kw => kw.keyword);
            setTrackNewKeywords(Array.from(new Set(array)))
        }
    }

    const toggleTrackMode = () => allKwAreTracked() ? false : setTrackMode(!trackMode)
    const totalTrackedKeywordsClass = () => {
        let initialClass = 'total-tracked-keywords';

        if(allKwAreTracked()) initialClass += ' all-tracked'

        return initialClass
    }

    /*end new*/

    return (
        <div className={
            `article-keywords-table 
            ${trackingNewKeywordsLoader ? 'loading' : ''}
            ${trackMode ? 'track-mode' : ''}
        `}>
            <div className="article-keywords-table__title">
                <WhiteTooltip placement="bottom-start" title={"Keyword recommendations to maximize ranking potential for top-searched keywords within the topic. The SEO score primarily reflects the inclusion of these keywords in your outline and article."}>
                    <span>SEO brief</span>
                </WhiteTooltip>

                {!hasSharedToken && ((totalKwCount() > 0 && !loadingContent() && (
                    <WhiteTooltip
                        placement="bottom-end"
                        title={
                            allKwAreTracked() ?
                            <div>All keywords tracked</div> :
                            <div>Click to select more keywords<br />and track them daily.</div>
                    }>
                        <span className={totalTrackedKeywordsClass()} onClick={toggleTrackMode}>{ `${totalTrackedKwCount()}/${totalKwCount()}` } tracked</span>
                    </WhiteTooltip>
                )) || <LoadingBlock height="24" width="95" rounded={true}/>)}
            </div>
            <ArticleKeywordsTableHeader
                trackMode={trackMode}
                allTracked={allKwAreTracked()}
                userHasCheckedKeywords={!!trackNewKeywords.length}
                onFilter={filterKeywords}
                loadingTopics={(!activeTags.length && !searchKeyword) || loadingContent()}
                toggleSelectAllKeywordsHandler={toggleSelectAllKeywordsHandler}
            />
            {(((!activeTags.length && searchKeyword == '' ) || loadingContent()) && (
                <ArticleKeywordsTableGroupLoading />

            )) || (
                <>
                    <ArticleKeywordsTableGroup
                        keywords={filteredTitles()}
                        keywordsCoverage={coverageDataTitle.keywords}
                        percentage={loadingContent() ? 0 : coverageDataTitle?.percent}
                        groupName={'Title Optimization'}
                        skipComplexLogic={true}
                        filteredKeywords={!!searchKeyword}
                        isTitle={true}
                        trackMode={trackMode}
                        trackNewKeywords={trackNewKeywords}
                        toggleTrackKeyword={toggleTrackKeyword}
                    />
                    <ArticleKeywordsTableGroup
                        keywords={activeTags}
                        keywordsCoverage={coverageDataTopic?.keywords}
                        secondKeywordsCoverage={coverageDataHeading?.keywords}
                        percentage={loadingContent() ? 0 : coverageDataTopic?.percent}
                        secondPercentage={loadingContent() ? 0 : coverageDataHeading?.percent}
                        groupName={'Headings & Coverage'}
                        filteredKeywords={!!searchKeyword}
                        doublePresencePercentage={true}
                        trackMode={trackMode}
                        trackNewKeywords={trackNewKeywords}
                        toggleTrackKeyword={toggleTrackKeyword}
                    />
                </>
            )}
        </div>
    );
}

export default ArticleKeywordsTable;
