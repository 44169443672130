import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getEditorInstance } from "../../reducers/textEditor";
import CustomSvgIcon from "./CustomSvgIcon";
import { Menu, MenuItem } from "@material-ui/core";

import "./Hyperlink.css";

const Hyperlink = ({ Icon, icon, title, isActive = null }) => {
  const editor = useSelector(getEditorInstance);

  const [anchorEl, setAnchorEl] = useState(null);
  const [linkUrl, setLinkUrl] = useState("");

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);

      const previousUrl = editor.getAttributes("link").href;
      if (previousUrl !== undefined && previousUrl !== "") {
        setLinkUrl(previousUrl);
      }
    },
    [editor]
  );

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const setHiperlink = () => {
    // cancelled
    if (linkUrl === null) {
      return;
    }

    // empty
    if (linkUrl === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: linkUrl.trim() })
      .run();

    setLinkUrl("");
    handleClose();
  };

  return (
      <>
          <button
              className={`menu-item${isActive && isActive() ? ' is-active' : ''}`}
              onClick={handleClick}
              title={title}
              >
              {icon && <span dangerouslySetInnerHTML={{ __html: icon }}/>}
              {Icon && <Icon />}
          </button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className="hyperlink-opener">
              {/* <MenuItem id="url-insert"> */}
              <div className="link-input-url">
                  <div style={{ width: '100%' }}>
                      <label>External link</label>
                      <input type="text" value={linkUrl} placeholder="https://google.com/" onChange={(e) => setLinkUrl(e.target.value)} />
                  </div>

                  <button className={linkUrl.length ? 'insert-link-btn' : 'link-disabled insert-link-btn'} onClick={setHiperlink}>
                      Save
                  </button>
              </div>
              {/* </MenuItem> */}
          </Menu>
      </>
  );
};

export default Hyperlink;
