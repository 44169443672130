import React, { useState, useMemo, useCallback } from "react";
import "./EditorContentInfo.css";
import {
    styleUsedForGenerating
} from "../../../reducers/articleSettings";
import { useDispatch, useSelector } from "react-redux";
import WritingStyleOverview from "../AiCard/WritingStyleOverview";
import {
    BookIcon,
    ChatIcon,
    ChevronDown,
    ChevronUp,
    ConfigurationIcon, DraftIcon, DuplicateIcon,
    FileIcon,
    GlobeIcon, InfoCircleLightIcon,NewTabIcon, LockIcon,
    MagicIcon,
    RefreshIcon,
    WriterMagicPen,
    SpinnerIcon,
    UserIcon,
} from "../../../UI_utils/SVGIcons";
import AppTabSelector from "../../../Shared/AppTabSelector";
import CurrentArticleModal from "../CurrentArticleModal";
import RestoreGeneratedModal from "../RestoreGeneratedModal";
import PublishButton from "../PublishButton";
import {
    checkLiveUrlAndGetArticle, contentArticleDataIsEmpty,
    getArticleCompleted,
    getArticleFromLP,
    getCurrentWebsiteArticle,
    getLandingPageUrl, getLiveUrl
} from "../../../reducers/articles";
import {getSearchKeywords} from "../../../reducers/searchTopic";
import EditorContentButton from "./EditorContentButton";
import EditorContentInfoHeader, { ArticleAction } from "./EditorContentInfoHeader/EditorContentInfoHeader";
import { WhiteTooltipAuto } from "../../UITooltips/UITooltip";

const tooltipText = {
  aiBtnDraft: `Overwrite working draft by importing the AI generated <br /> version or start the process if AI Version is not already <br/> generated.`,
  liveBtnDraft: `Overwrite working draft by importing the Live Version <br /> of the article or start the process of importing it.`,
  liveBtnStartGenerated: `Copy/Overwrite the generated content to<br />the Article view and start editing`,
  liveBtnStartNotGenerated: `This will overwrite the Article edits.`,
  liveBtnRefresh: `This action will fetch the live content.`,
  aiGenerateBtnGenerate: `This action will generate the article with AI`,
  aiGenerateBtnRegenerate: `This action will regenerate the article with AI`,
  outlineBtnRegenerate: "This action will regenerate Outline with AI",
};

const EditorContentInfo = ({
    selectedTypeOption,
    setSelectedTypeOption,
    typeOptions,
    loadingCurrentWebsiteArticle,
    currentWebsiteArticle,
    restoreGenerated,
    activeConfigurationStep,
    setActiveConfigurationStep,
    setPromptType,
    regenerateCount,
    setShowPublishModal,
    switchTo,
    disableActions = false
}) => {
    const dispatch = useDispatch();
    const styleConfigForGeneratedArticle = useSelector(styleUsedForGenerating);
    const initialPageUrl = useSelector(getLandingPageUrl);
    const articleCompleted = useSelector(getArticleCompleted);
    const topicName = useSelector(getSearchKeywords);
    const liveUrl = useSelector(getLiveUrl);
    const articleIsEmpty = useSelector(contentArticleDataIsEmpty);

    const [styleExpanded, setStyleExpanded] = useState(false);
    const [activeModal, setActiveModal] = useState('');
    // const [previousType, setPreviousType] = useState('');

    // Template Flags
     const isReadOnlyMode = selectedTypeOption === "generated" || selectedTypeOption === "current";
     const isCurrentMode = selectedTypeOption === "current";
     const isGeneratedMode = selectedTypeOption === "generated";
     const isOutlineMode = selectedTypeOption === "outline";
     const isNewMode = selectedTypeOption === "new";
    // Handlers
    const getCurrentArticleHandler = useCallback(() => {
        setSelectedTypeOption('current');
        setActiveModal('');
        dispatch(getArticleFromLP());
    }, [dispatch, setSelectedTypeOption]);

    // const onSwitch = useCallback(
    //     (option) => {
    //         if (option === 'current' && currentWebsiteArticle === null && !loadingCurrentWebsiteArticle) {
    //             setActiveModal(option);
    //             // setPreviousType(selectedTypeOption)
    //         }
    //         // else {
    //             setSelectedTypeOption(option);
    //         // }
    //     },
    //     [currentWebsiteArticle, loadingCurrentWebsiteArticle, setSelectedTypeOption]
    // );

    const showRestoreConfirmationModal = useCallback((forcedTypeOption = null) => {
        if(disableActions) return ;

        if(articleIsEmpty) {
            restoreGenerated(forcedTypeOption ?? selectedTypeOption);
            setActiveModal('')
        } else {
            setActiveModal(forcedTypeOption ?? selectedTypeOption)
        }
    }, [selectedTypeOption, articleIsEmpty, disableActions, currentWebsiteArticle]);

    const closeModal = useCallback(() => {
        setActiveModal('')

        // if(previousType) {
        //     setSelectedTypeOption(previousType)
        //     setPreviousType('');
        // }
    }, []);

    // Icon Selector
    const returnIcon = useMemo(() => {
        if (!styleConfigForGeneratedArticle) return MagicIcon;

        const { styleName: title, styleType: type } = styleConfigForGeneratedArticle;

        if (type) {
            switch (type) {
                case 'selected':
                    return ConfigurationIcon;
                case 'article':
                    return FileIcon;
                case 'website':
                    return GlobeIcon;
                default:
                    return MagicIcon;
            }
        }

        if (title) {
            const loweredTitle = title.toLowerCase();
            switch (loweredTitle) {
                case 'professional style':
                    return UserIcon;
                case 'educational style':
                    return BookIcon;
                case 'casual style':
                    return ChatIcon;
                default:
                    return MagicIcon;
            }
        }

        return MagicIcon;
    }, [styleConfigForGeneratedArticle]);

    const {
        writingStyles: style,
        styleName: title,
        styleType: type,
        styleDescription: description,
    } = styleConfigForGeneratedArticle || {};

    const generateHandler = (type) => {
        if(activeConfigurationStep === 0) {
            setPromptType(type)
            setActiveConfigurationStep(1);
        }
    }

    const refreshCurrentArticle = () => {
        if(!disableActions)
            dispatch(checkLiveUrlAndGetArticle())
    };
    const generateOutlineHandler = () => generateHandler('outline');
    const generateArticleHandler = () => generateHandler('article');
    const generateButtonsClass = () => activeConfigurationStep !== 0 ? 'disabled' : '';

    const openPublishModal = () => setShowPublishModal(true)

    const restoreGeneratedHandler = () => {
        if(disableActions) return ;
        restoreGenerated(activeModal);
        closeModal();
    }
    
    const draftButtonsCallback = useCallback((fileToCheck, selectedType) => {
       return fileToCheck
         ? showRestoreConfirmationModal(selectedType)
         : switchTo(selectedType);
    }, [showRestoreConfirmationModal, switchTo]);

    return (
      <div
        className={`editor-content-info ${disableActions ? "disabled" : ""}`}
      >
        {activeModal && (
          <RestoreGeneratedModal
            closeHandler={closeModal}
            restoreHandler={restoreGeneratedHandler}
            isGenerated={activeModal === "generated"}
          />
        )}
        {!isNewMode ? (
          <React.Fragment>
            {/* Header */}
            <EditorContentInfoHeader>
              <ArticleAction isArticleAction="true">
                {isReadOnlyMode && (
                  <span className="read-only">
                    <LockIcon />
                    Read-only
                  </span>
                )}
                {isCurrentMode && (
                  <EditorContentButton
                    onClick={refreshCurrentArticle}
                    tooltipText={tooltipText.liveBtnRefresh}
                    btnText={`Refresh`}
                    IconComponent={RefreshIcon}
                  />
                )}
                {(isGeneratedMode || isOutlineMode) && (
                  <React.Fragment>
                    {isOutlineMode && (
                      <EditorContentButton
                        onClick={generateOutlineHandler}
                        btnColor={generateButtonsClass()}
                        tooltipText={tooltipText.outlineBtnRegenerate}
                        btnText={`Regenerate Outline`}
                        IconComponent={RefreshIcon}
                      />
                    )}

                    <EditorContentButton
                      onClick={generateArticleHandler}
                      btnColor={`${generateButtonsClass()} ${
                        isOutlineMode ? "brand" : ""
                      }`}
                      tooltipText={
                        articleCompleted
                          ? tooltipText.aiGenerateBtnRegenerate
                          : tooltipText.aiGenerateBtnRegenerate
                      }
                      btnText={`${
                        articleCompleted === null ? "Generate" : "Regenerate"
                      } Article`}
                      IconComponent={WriterMagicPen}
                    />
                  </React.Fragment>
                )}

                {isReadOnlyMode && (
                  <EditorContentButton
                    onClick={() => showRestoreConfirmationModal("generated")}
                    btnText={`Start editing ${
                      isCurrentMode ? "from this version" : ""
                    } `}
                    btnColor={isGeneratedMode ? "brand" : ""}
                    tooltipText={
                      isCurrentMode
                        ? tooltipText.liveBtnStartGenerated
                        : tooltipText.liveBtnStartNotGenerated
                    }
                    IconComponent={DuplicateIcon}
                  />
                )}
              </ArticleAction>
              {style && isGeneratedMode && (
                <div
                  className="toggle-styling"
                  onClick={() => setStyleExpanded((prev) => !prev)}
                >
                  {title}
                  {styleExpanded ? <ChevronUp /> : <ChevronDown />}
                </div>
              )}
              {isCurrentMode && !!currentWebsiteArticle && (
                <WhiteTooltipAuto placement="bottom" title={liveUrl}>
                  <a
                    href={liveUrl}
                    className="current-article-info info-secondary-bold"
                    target="_blank"
                  >
                    Landing page
                    <NewTabIcon />
                  </a>
                </WhiteTooltipAuto>
              )}
            </EditorContentInfoHeader>

            {/* Style Overview */}
            {style && styleExpanded && isGeneratedMode && (
              <WritingStyleOverview
                isCustom={!!type}
                styleType={type}
                Icon={returnIcon}
                title={title}
                description={description}
                readOnly={true}
                style={style}
                type="white"
              />
            )}
          </React.Fragment>
        ) : (
          // Draft  section buttons
          <EditorContentInfoHeader>
            <EditorContentButton
              onClick={() =>
                draftButtonsCallback(articleCompleted, "generated")
              }
              btnText={`${
                articleCompleted === null || isOutlineMode
                  ? "Generate"
                  : "Import"
              } Ai Version`}
              tooltipText={tooltipText.aiBtnDraft}
              IconComponent={WriterMagicPen}
            />
            <EditorContentButton
              onClick={() =>
                draftButtonsCallback(currentWebsiteArticle, "current")
              }
              btnText={`${isCurrentMode ? "Get" : "Import"} Live Version`}
              tooltipText={tooltipText.liveBtnDraft}
              IconComponent={DuplicateIcon}
            />
          </EditorContentInfoHeader>
        )}
      </div>
    );
};


export default EditorContentInfo;
