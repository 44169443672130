import React, { useState, useCallback } from 'react';
import './LinkEditorPanel.css';

const LinkEditorPanel = ({ initialUrl, onSetLink, onCancel }) => {
  const [url, setUrl] = useState(initialUrl || '');
  const [openInNewTab, setOpenInNewTab] = useState(true);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    if (url.trim()) {
      onSetLink(url, openInNewTab);
    }
  }, [url, openInNewTab, onSetLink]);

  return (
    <div className="link-editor-panel">
      <form onSubmit={handleSubmit}>
        <div className="link-input-group">
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            placeholder="Enter URL"
            className="link-input"
          />
          <div className="link-checkbox">
            <label>
              <input
                type="checkbox"
                checked={openInNewTab}
                onChange={(e) => setOpenInNewTab(e.target.checked)}
              />
              Open in new tab
            </label>
          </div>
        </div>
        <div className="link-actions">
          <button type="button" className="link-button cancel" onClick={onCancel}>
            Cancel
          </button>
          <button type="submit" className="link-button apply" disabled={!url.trim()}>
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default LinkEditorPanel;
