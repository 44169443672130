import {useDispatch} from "react-redux";
import React from "react";
import './WritingStyleOverview.css'
import {
    customStyleTooltip,
} from "../../../reducers/articleSettings";
import {WhiteTooltipAuto} from "../../UITooltips/UITooltip";

const WritingStyleOverview = ({isCustom, styleType, Icon, title, description, readOnly, openStyleSidebar, style, type = 'brand'}) => {
    const clickHandler = () => openStyleSidebar()

    return (
        <div className={`writing-style-overview ${type}`}>
            <div className="writing-style-overview__info">
                <div className="writing-style-overview__title">
                    <WhiteTooltipAuto
                        placement="bottom-start"
                        title={isCustom ? customStyleTooltip(styleType) : ''}
                    >
                            <span>
                                <Icon />
                            </span>
                    </WhiteTooltipAuto>
                    {title}
                </div>
                <div className="writing-style-overview__description">{description}</div>
            </div>

            {isCustom && !readOnly && <div className="writing-style-overview__configure" onClick={clickHandler}>Configure</div>}

            {style && (
                <div className="writing-style-overview__options">
                    {style.map(({label, selected}) => (
                        <div className="option-selected" key={label}>
                            <div className="option-label">{label}</div>
                            <div className="option-value">{selected}</div>
                        </div>
                    ))}
                </div>
            )}

        </div>
    )
};

export default WritingStyleOverview;
