// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-bubble-menu {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.link-url {
  color: #0066cc;
  text-decoration: none;
  font-size: 14px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link-url:hover {
  text-decoration: underline;
}

.link-actions {
  display: flex;
  gap: 8px;
  border-left: 1px solid #eee;
  padding-left: 8px;
}

.link-action-button {
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  color: #666;
}

.link-action-button:hover {
  background: #f5f5f5;
}

.link-action-button.edit {
  color: #0066cc;
}

.link-action-button.delete {
  color: #dc3545;
}

.link-action-button.delete:hover {
  background: #fff5f5;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/LinkBubbleMenu.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,wCAAwC;EACxC,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,2BAA2B;EAC3B,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".link-bubble-menu {\n  background: white;\n  border-radius: 4px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 8px;\n  display: flex;\n  align-items: center;\n  gap: 12px;\n}\n\n.link-url {\n  color: #0066cc;\n  text-decoration: none;\n  font-size: 14px;\n  max-width: 300px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n\n.link-url:hover {\n  text-decoration: underline;\n}\n\n.link-actions {\n  display: flex;\n  gap: 8px;\n  border-left: 1px solid #eee;\n  padding-left: 8px;\n}\n\n.link-action-button {\n  background: none;\n  border: none;\n  padding: 4px 8px;\n  cursor: pointer;\n  font-size: 14px;\n  border-radius: 4px;\n  color: #666;\n}\n\n.link-action-button:hover {\n  background: #f5f5f5;\n}\n\n.link-action-button.edit {\n  color: #0066cc;\n}\n\n.link-action-button.delete {\n  color: #dc3545;\n}\n\n.link-action-button.delete:hover {\n  background: #fff5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
