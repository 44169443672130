import React, {useState} from 'react';
import AiCardOptionsDefault from "./AiCardOptionsDefault";
import AiCardOptionsOutline from "./AiCardOptionsOutline";
import AiCardOptionsArticle from "./AiCardOptionsArticle";
import AiCardOptionsStopGeneratingArticle from "./AiCardOptionsStopGeneratingArticle";

const AiCardOptions = ({
    isOutline,
    outlineProgressStatus,
    generateArticle,
    resetOutline,
    promptType,
    articleCompleted,
    disableStopGenerating,
    stopGenerating,
    disableGeneratingArticle,
    showInternalLinks,
    regenerateCount,
    hasArticlesLeft,
    activeStep,
    totalSteps,
    lastStep,
    increaseConfigurationStep,
    decreaseConfigurationStep,
    activeStepHasSkip,
    activeStepName,
    hideBackButton
}) => {
    const generateButtonTooltip = () => disableGeneratingArticle

    const generateHandler = (type) => {
        return disableGeneratingArticle ? false : lastStep ? generateArticle() : increaseConfigurationStep('article');
    }
    if(articleCompleted === false) {
        return (
            <AiCardOptionsStopGeneratingArticle
                disableStopGenerating={disableStopGenerating}
                stopGenerating={stopGenerating}
            />
        );
    }

    if (activeStep > 0 && outlineProgressStatus !== null) {
        return (
            <div className="ai-card-options">
                <div className="ai-card-buttons">
                    {promptType === 'article' && !!activeStep && (
                        <div className="article-configuration__steps">
                            Step {activeStep} of {totalSteps}
                        </div>
                    )}
                    {!activeStep && (
                        <AiCardOptionsDefault
                            regenerateCount={regenerateCount}
                            hasArticlesLeft={hasArticlesLeft}
                            showPromptInput={increaseConfigurationStep}
                        />
                    )}
                    {!!activeStep && promptType === 'outline' && (
                        <AiCardOptionsOutline
                            hidePromptInput={decreaseConfigurationStep}
                            generateArticle={generateHandler}
                            configStep={activeStep}
                            disabledTooltip={generateButtonTooltip()}
                        />
                    )}
                    {!!activeStep && promptType === 'article' && (
                        <AiCardOptionsArticle
                            hideInternalLinks={decreaseConfigurationStep}
                            showPromptInput={increaseConfigurationStep}
                            hidePromptInput={decreaseConfigurationStep}
                            generateArticle={generateHandler}
                            disabledTooltip={generateButtonTooltip()}
                            disableGenerating={disableGeneratingArticle}
                            regenerateCount={regenerateCount}
                            showLinks={showInternalLinks}
                            configStep={activeStep}
                            lastStep={totalSteps === activeStep}
                            hasSkip={activeStepHasSkip}
                            stepName={activeStepName}
                            hideBackButton={hideBackButton}
                        />
                    )}
                </div>
            </div>
        );
    }
};

export default AiCardOptions;