// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-switcher__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 0 auto;
    padding: 8px 0;
    border-bottom: 1px solid var(--default-borders-light,#1717170d);
}`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/EditorSwitcher.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,cAAc;IACd,cAAc;IACd,+DAA+D;AACnE","sourcesContent":[".editor-switcher__wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 16px;\n    margin: 0 auto;\n    padding: 8px 0;\n    border-bottom: 1px solid var(--default-borders-light,#1717170d);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
