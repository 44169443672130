import "./Input.css";
import {PlusIcon, SpinnerIcon} from "../UI_utils/SVGIcons";
import React, {forwardRef} from "react";
import {TEXTS} from "../common/consts";
import {shortenNumbers} from "../utils/helpers";
import {WhiteTooltipAuto} from "../Components/UITooltips/UITooltip";

const Input = forwardRef(({
    loading,
    showIcon,
    inputValue,
    onEnter,
    onChange,
    iconClick,
    placeholder,
    className,
    label,
    disabled,
    error = '',
    infoText = '',
    IconToShow = null,
    iconTooltip = ''
}, ref) => {


  const iconClickHandler = (event) => {
    if(iconClick) iconClick()
  }

  const onEnterHandler = (event) => {
    if(onEnter) onEnter()
  }

  const enterHandler = (event) => {
    if (event.key === 'Enter') onEnterHandler(event)
  }

  const handleInputChange = (event) => onChange(event.target.value)

  return (

      <div className={`input__wrapper--outer ${!!error ? 'has--error' : ''}`}>
          <div className={`
          input__wrapper 
          ${loading ? 'input__wrapper--loading' : ''} 
          ${showIcon ? 'has-icon' : ''}`
          }>
            {label && (<div className="input__label">{ label }</div>)}
            <input ref={ref}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={enterHandler}
                className={`input ${className}`}
                placeholder={placeholder}
                disabled={disabled}
            />
            {showIcon && (
                <WhiteTooltipAuto
                    placement="bottom-end"
                    title={
                        <div dangerouslySetInnerHTML={{__html: iconTooltip}}/>
                    }
                >
                  <div className="input__action" onClick={iconClickHandler}>
                    {!loading && (
                        <>
                            {IconToShow && <IconToShow /> || <PlusIcon />}
                        </>
                    )}
                    {loading && (
                        <SpinnerIcon />
                    )}
                  </div>
                </WhiteTooltipAuto>
            )}
          </div>
        {(!!infoText || !!error) && (
          <div className="info-text">{error || infoText}</div>
        )}
      </div>
  );
});

export default Input;
