import React from "react"
import AppModal from "../../../Shared/AppModal"
import './WriterAccessModal.css'
import {BackIcon, ExclamationIcon, WriterMagicPen} from '../../../UI_utils/SVGIcons'
import ModalBody from './ModalBody'
import WriterAccessModalFooter from './WriterAccessModalFooter'
import {TEXTS} from "../../../common/consts";
import {useSelector} from "react-redux";
import {getUserIsAdmin} from "../../../reducers/user";


const WriterAccessModal = ({userCanGenerateArticle, generateArticle,  setShowArticleAccessModal}) => {
    const isAdmin = useSelector(getUserIsAdmin);
    const titleLimitReached = () => isAdmin ? TEXTS.NO_ARTICLES_LEFT_MODAL_TITLE_ADMIN : TEXTS.NO_ARTICLES_LEFT_MODAL_TITLE_GUEST
    const bodyLimitReached = () => isAdmin ? TEXTS.NO_ARTICLES_LEFT_MODAL_BODY_ADMIN : TEXTS.NO_ARTICLES_LEFT_MODAL_BODY_GUEST

    const title = userCanGenerateArticle() ? 'This action will consume 1 article.' : titleLimitReached()
    const description =   userCanGenerateArticle() ? 'If you have no articles available, they will be added to your paid resources for the next billing cycle.' : bodyLimitReached()

    const actionClick = () => {
        if(userCanGenerateArticle()){
            generateArticle()
        }else{
            setShowArticleAccessModal(false)
        }
    }
    return (
        <AppModal height={472} width={740} padding={'36px'}>
            <div className="writerAccessModal__header" onClick={() => setShowArticleAccessModal(false)}>
                <BackIcon/>
                <p>Go back</p>
            </div>
            <div className="writerAccessModal__body">
                <div>
                    <ModalBody title={title} description={description}/>
                    <WriterAccessModalFooter userCanGenerateArticle={userCanGenerateArticle} actionClick={actionClick}/>
                </div>
                {userCanGenerateArticle() ? <WriterMagicPen/> :  <ExclamationIcon/>}
            </div>
        </AppModal>
    )
}

export default WriterAccessModal