import {useSelector} from "react-redux";
import {getDraftConfigOptions, getUserData, showDraftConfigOptions} from "../../../reducers/user";
import CustomSelect from "../../../Shared/CustomSelect";
import CustomCheckbox from "../../../Shared/CustomCheckbox";
import LoadingBlock from "../../LoadingBlock/LoadingBlock";
import React from "react";
import {TrashIcon} from "../../../UI_utils/SVGIcons";

const AiCardInternalLinksItem = ({
    orderNumber,
    url,
    title,
    description,
    onDelete,
    onClick
}) => {
    const deleteHandler = (event) => {
        event.stopPropagation();
        onDelete()
    }

    return (
        <div className="ai-card-link-item">
            {url && (
            <span className="delete-link" onClick={deleteHandler}>
                <TrashIcon />
             </span>
            )}
            <div className="ai-card-link-item__url">
            {url && (
                <>
                    <div className="link-item__order-number">{ orderNumber }</div>
                    <div className="link-item__url" onClick={onClick}>{ url }</div>
                </>
            ) || <LoadingBlock height="16" width="250" rounded={true}/>}
            </div>
            <div className="ai-card-link-item__title">
                {title && (
                    <div className="link-item__title">{ title }</div>
                ) || <LoadingBlock height="24" width="300" rounded={true}/>}
            </div>
            <div className="ai-card-link-item__description">
                {description && (
                    <div className="link-item__description">{ description }</div>
                ) || (
                    <>
                        <LoadingBlock height="13" width="100%" margin="0 0 7px 0 " rounded={true}/>
                        <LoadingBlock height="13" width="100%" margin="0 0 7px 0 " rounded={true}/>
                        <LoadingBlock height="13" width="25%" margin="0 0 7px 0 " rounded={true}/>
                    </>
                )}
            </div>
        </div>
    );
};

export default AiCardInternalLinksItem;
