import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./ArticleDatePicker.css";

const ArticleDatePicker = ({ selectedDate, onDateChange }) => {
    const formatDate = (date) => {
        if (!date) return '';
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }).format(date);
    };

    return (
        <div className="article-date-picker">
            <label className="article-date-picker__label">Published Date</label>
            <div className="article-date-picker__input-wrapper">
                <DatePicker
                    selected={selectedDate}
                    onChange={onDateChange}
                    dateFormat="MMMM d, yyyy"
                    customInput={
                        <input
                            className="article-date-picker__input"
                            value={formatDate(selectedDate)}
                        />
                    }
                    maxDate={new Date()}
                />
            </div>
        </div>
    );
};

ArticleDatePicker.propTypes = {
    selectedDate: PropTypes.instanceOf(Date),
    onDateChange: PropTypes.func.isRequired
};

export default ArticleDatePicker;
