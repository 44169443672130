// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-page {
  height: 100vh;
  max-height: 100vh;
  background: rgba(23, 23, 23, 0.05);
  overflow: hidden;
}
.dashboard-page--sidebar {
  display: flex;
}
.dashboard-page--sidebar .articles-sidebar__wrapper {
  height: 100%;
}
.dashboard-page--sidebar .new-article-modal {
  max-width: 800px;
  margin: 0 auto;
}
.dashboard-page--published .dashboard-page__content {
  padding-top: 16px;
}

.dashboard-page__content {
  padding: 48px 24px;
  flex-grow: 1;
  overflow: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/ManagementDashboard/DashboardComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,kCAAkC;EAClC,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".dashboard-page {\n  height: 100vh;\n  max-height: 100vh;\n  background: rgba(23, 23, 23, 0.05);\n  overflow: hidden;\n}\n.dashboard-page--sidebar {\n  display: flex;\n}\n.dashboard-page--sidebar .articles-sidebar__wrapper {\n  height: 100%;\n}\n.dashboard-page--sidebar .new-article-modal {\n  max-width: 800px;\n  margin: 0 auto;\n}\n.dashboard-page--published .dashboard-page__content {\n  padding-top: 16px;\n}\n\n.dashboard-page__content {\n  padding: 48px 24px;\n  flex-grow: 1;\n  overflow: auto;\n  max-height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
