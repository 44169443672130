import React, {useEffect, useRef, useState} from 'react';
import './SpellbindingSuggestionsItem.css'
import {interfaceApiRequest} from "../../utils/dataToInterface";
import LoadingBlock from "../LoadingBlock/LoadingBlock";
import {shortenNumbers} from "../../utils/helpers";
import {SpinnerIcon} from "../../UI_utils/SVGIcons";
import {WhiteTooltip, WhiteTooltipAuto} from "../UITooltips/UITooltip";
import {useSelector} from "react-redux";
import {getUserHasArticlesLeft} from "../../reducers/user";
import {TEXTS} from "../../common/consts";

const SpellbindingSuggestionsItem = ({
    topic,
    traffic,
    onClick,
    index,
    disable
}) => {
    const hasArticlesLeft = useSelector(getUserHasArticlesLeft);

    const clickHandler = () => {
        if(topic && hasArticlesLeft) onClick(topic)
    }

    const returnLoaderWidth = () => {
        const dividend = index + 1;
        const divisor = 3;
        const reminder = dividend % divisor;

        return reminder === 2 ? 140 : reminder === 1 ? 180 : 160
    }

    const dynamicClass = () => {
        let dynamicClassName = 'spellbinding-suggestions-item';

        if(!topic) dynamicClassName += ' loading'
        if(disable || !hasArticlesLeft) dynamicClassName += ' disabled'

        return dynamicClassName
    }

    return (

        <WhiteTooltipAuto
            placement="bottom-start"
            title={hasArticlesLeft ? '' : TEXTS.NO_ARTICLES_LEFT}
        >
            <div className={dynamicClass()} onClick={clickHandler}>
            {topic && (
                <>
                    <WhiteTooltip
                        placement="top"
                        title={hasArticlesLeft ? "Click to start writing" : ""}
                    >
                        <div className="spellbinding-suggestions-item__topic">{topic}</div>
                    </WhiteTooltip>
                    {traffic !== null && (
                        <WhiteTooltip
                            placement="top"
                            title={"Estimated traffic"}
                        >
                            <div className="spellbinding-suggestions-item__traffic">{shortenNumbers(traffic)}/mo</div>
                        </WhiteTooltip>
                    ) || (
                        <WhiteTooltip
                            placement="top"
                            title={"Processing keyword"}
                        >
                            <span>
                                <SpinnerIcon />
                            </span>
                        </WhiteTooltip>
                    )}
                </>
            )}
            {!topic && (
                <>
                    <LoadingBlock height="16" width={returnLoaderWidth()} rounded={4} />
                    <LoadingBlock height="16" width="50" />
                </>
            )}
        </div>
        </WhiteTooltipAuto>
    );
};

export default SpellbindingSuggestionsItem