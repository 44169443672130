// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-options-container{
    background: var(--default-surfaces-base);
    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.10);
    border-radius: 12px;
    padding: 8px;
    position: absolute;
    z-index: 1;
    width: 254px;
    right: 0;
    margin-top: 8px;
}

.save-options-container .option-item{
    padding: 8px;
    color: var(--default-symbols-primary);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 123.077% */
    letter-spacing: -0.13px;
    cursor: pointer
}

.save-options-container .option-item:hover{
    background: var(--default-surfaces-light)
}`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/saveOptions.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;IACxC,iDAAiD;IACjD,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,aAAa;IAChC,uBAAuB;IACvB;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".save-options-container{\n    background: var(--default-surfaces-base);\n    box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.10);\n    border-radius: 12px;\n    padding: 8px;\n    position: absolute;\n    z-index: 1;\n    width: 254px;\n    right: 0;\n    margin-top: 8px;\n}\n\n.save-options-container .option-item{\n    padding: 8px;\n    color: var(--default-symbols-primary);\n    font-family: Inter;\n    font-size: 13px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 16px; /* 123.077% */\n    letter-spacing: -0.13px;\n    cursor: pointer\n}\n\n.save-options-container .option-item:hover{\n    background: var(--default-surfaces-light)\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
