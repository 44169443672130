// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-modal__textarea {
    width: 100%;
    min-height: 120px;
    background: var(--default-surfaces-light);
    padding: 12px 16px;
    border: none;
    border-radius: 12px;
    resize: vertical;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.01em;
}

.feedback-modal__textarea::placeholder {
    color: #9CA3AF;
}

.feedback-modal__textarea:focus {
    outline: none;
    border-color: #6B7280;
}`, "",{"version":3,"sources":["webpack://./src/Components/FeedbackModal/FeedbackModal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,yCAAyC;IACzC,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".feedback-modal__textarea {\n    width: 100%;\n    min-height: 120px;\n    background: var(--default-surfaces-light);\n    padding: 12px 16px;\n    border: none;\n    border-radius: 12px;\n    resize: vertical;\n    font-weight: 700;\n    font-size: 15px;\n    line-height: 18px;\n    letter-spacing: -0.01em;\n}\n\n.feedback-modal__textarea::placeholder {\n    color: #9CA3AF;\n}\n\n.feedback-modal__textarea:focus {\n    outline: none;\n    border-color: #6B7280;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
