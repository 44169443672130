import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './DefaultSettingsSidebarContentStyle.css'
import {
    campaignStyleOptions,
    customCampaignStyle,
    defaultStyleOptions,
    getStyleFromUrl,
    getStyleNameAndType,
    setArticleStyleOptionsAltered,
    setShowSettingsSidebar, setStyleDescription,
    setStyleName,
    setStyleType,
    styleDescription,
    styleName,
    styleType,
    styleUrl,
    updateAndSaveCampaignStyle
} from "../../reducers/articleSettings";
import AppSelect from "../../Shared/AppSelect";
import Input from "../../Shared/Input";
import {WhiteTooltipAuto} from "../UITooltips/UITooltip";
import {ConfigurationIcon, FileIcon, GlobeIcon, InfoCircleIcon, PlusCircle} from "../../UI_utils/SVGIcons";
import {TEXTS} from "../../common/consts";

const DefaultSettingsSidebarContentStyle = ({ }) => {
    const dispatch = useDispatch();
    const defaultOptions = useSelector(defaultStyleOptions);
    const campaignStyle = useSelector(campaignStyleOptions)
    const campaignStyle2 = useSelector(customCampaignStyle)
    const savedStyleUrl = useSelector(styleUrl)
    const savedStyleType = useSelector(styleType)
    const savedStyleName = useSelector(styleName)
    const savedStyleDescription = useSelector(styleDescription)
    const [optionsToBeUsed, setOptionsToBeUsed] = useState(campaignStyle2 || defaultOptions)

    const [newStyleType, setNewStyleType] = useState(savedStyleType);
    const [newStyleName, setNewStyleName] = useState(savedStyleName);
    const [newStyleDescription, setNewStyleDescription] = useState(savedStyleDescription);
    const [newStyleUrl, setNewStyleUrl] = useState(savedStyleUrl);
    const [articleSampleUrl, setArticleSampleUrl] = useState(savedStyleUrl);
    const [addingArticleSampleUrl, setAddingArticleSampleUrl] = useState(false);


    const WRITER_TAB_INFO = {
        selected: {
            icon: ConfigurationIcon,
            iconTooltip: 'Based on the selected settings.'
        },
        article: {
            icon: FileIcon,
            iconTooltip: 'Based on analyzing article sample.'
        },
        website: {
            icon: GlobeIcon,
            iconTooltip: 'Based on analyzing website.'
        }
    }

    // useEffect(() => {
    //     updateStyleType(WRITER_TAB_INFO[newStyleType])
    // }, [newStyleType])

    const IconComponent = WRITER_TAB_INFO[newStyleType]?.icon;
    const returnInfoTooltip = () => WRITER_TAB_INFO[newStyleType]?.iconTooltip


    // Create a state for each select component based on its key
    const initialSelections = optionsToBeUsed.reduce((acc, config) => {
        acc[config.key] = {
            value: config.selected[0],
            label: config.selected[0]
        };
        return acc;
    }, {});

    const [selectedValues, setSelectedValues] = useState(initialSelections);
    const handleChange = (key, selected) => {
        setSelectedValues((prev) => ({
            ...prev,
            [key]: selected,
        }));

        setOptionsToBeUsed((prevOptions) =>
            prevOptions.map((config) =>
                config.key === key
                    ? { ...config, selected: Array.isArray(selected) ? selected.map((item) => item.value) : [selected.value] }
                    : config
            )
        );

        // dispatch(setStyleType('selected'))
        setNewStyleType('selected');
        setArticleSampleUrl(null)
    };

    useEffect(() => {
        dispatch(getStyleNameAndType(optionsToBeUsed))
            .then(({styleName, styleDescription}) => {
                if(styleName) setNewStyleName(styleName)
                if(styleDescription) setNewStyleDescription(styleDescription)
            })
    }, [optionsToBeUsed])

    const closeSidebar = () => dispatch(setShowSettingsSidebar(null))
    const saveSettings = () => {
        const payload = {
            writingStyles: optionsToBeUsed,
            url: newStyleUrl,
            styleName: newStyleName,
            styleType: newStyleType,
            styleDescription: newStyleDescription
        }
        dispatch(updateAndSaveCampaignStyle(payload))
    }

    const articleSampleUrlInputHandler = (newValue) => setArticleSampleUrl(newValue)

    const addArticleSampleUrl = () => {
        if(articleSampleUrl) {
            setAddingArticleSampleUrl(true);
            dispatch(getStyleFromUrl(articleSampleUrl))
                .then(styleOptionsByUrl => {
                    if(styleOptionsByUrl) {
                        setNewStyleType('article')
                        setNewStyleUrl(articleSampleUrl)
                        dispatch(getStyleNameAndType(styleOptionsByUrl))
                            .then(response => {
                                const {styleName, styleDescription} = response;
                                if(styleName) setNewStyleName(styleName)
                                if(styleDescription) setNewStyleDescription(styleDescription)
                            })
                    }
                })
                .catch(err => {

                })
                .finally(() => {
                    setAddingArticleSampleUrl(false);
                })
        }
    };
    return (
        <div className="writer-style">
            <div className="writer-style__name">
                {IconComponent && (
                    <WhiteTooltipAuto placement="bottom-end" title={returnInfoTooltip()}>
                        <span><IconComponent /></span>
                    </WhiteTooltipAuto>
                )}
                {newStyleName}
            </div>
            <div className="writer-style__options">
                {optionsToBeUsed.map((config) => (
                    <AppSelect className={'grey'}
                        key={config.key}
                        label={config.label}
                        selectedValue={selectedValues[config.key]}
                        options={config.options.map((option) => ({
                            value: option,
                            label: option,
                        }))}
                        isMulti={config.multiselect}
                        onChange={(selected) => handleChange(config.key, selected)}
                    />
                ))}

                {/*<div className="writer-style__article-sample">*/}
                {/*    <div className="article-sample__label">Define with article sample</div>*/}
                {/*    <Input*/}
                {/*        type="text"*/}
                {/*        element="input"*/}
                {/*        label="Article sample URL"*/}
                {/*        placeholder="E.g. https://website.com/article-sample"*/}
                {/*        inputValue={articleSampleUrl}*/}
                {/*        showIcon={articleSampleUrl && articleSampleUrl !== savedStyleUrl}*/}
                {/*        loading={addingArticleSampleUrl}*/}
                {/*        IconToShow={PlusCircle}*/}
                {/*        onChange={articleSampleUrlInputHandler}*/}
                {/*        onEnter={addArticleSampleUrl}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div className="writer-style__actions">
                <div className="writer-style__button cancel" onClick={closeSidebar}>Cancel</div>
                <div className="writer-style__button save" onClick={saveSettings}>Save settings</div>
            </div>
        </div>
    );
};

export default DefaultSettingsSidebarContentStyle