import React from 'react';
import './CustomCheckbox.css';
import {CheckIcon, InfoCircleLightIcon} from "../UI_utils/SVGIcons";
import {WhiteTooltip} from "../Components/UITooltips/UITooltip";

const CustomCheckbox = ({ checked, handleChange, label, inputId, tooltipText, className }) => {

    return (
        <div className={`custom-checkbox ${className}`}>
            <label className="checkbox-label" htmlFor={inputId}>
                <input type="checkbox" id={inputId} name={inputId} checked={checked} onChange={(event) => handleChange(event.target.checked)}/>
                <span className="checkbox"><CheckIcon /></span>
                <span className="checkbox-label-text" dangerouslySetInnerHTML={{ __html: label }} />
                {!!tooltipText && (
                    <WhiteTooltip
                        placement="bottom"
                        title={
                            <div
                                style={{ whiteSpace: 'pre-line' }}
                                dangerouslySetInnerHTML={{
                                    __html: tooltipText,
                                }}
                            />
                        }>
                        <span className="info-tooltip"><InfoCircleLightIcon /></span>
                    </WhiteTooltip>
                )}
            </label>
        </div>
    );
};

export default CustomCheckbox;