import LoadingBlock from "../LoadingBlock/LoadingBlock";
import React from "react";

const ArticleLoadingBlocks = ({}) => {
    return (
        <div className="article-loading-blocks">
            <LoadingBlock height="36" width="80%" margin="0 0 14px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="40%" margin="0 0 36px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="40%" margin="0 0 36px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="100%" margin="0 0 8px 0 "/>
            <LoadingBlock height="20" width="40%" margin="0 0 36px 0 "/>
        </div>
    );
};

export default ArticleLoadingBlocks;
