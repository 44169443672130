// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.template {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-content: center;*/
/*  box-sizing: border-box;*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*}*/

/*.template-body {*/
/*  flex: 1 0 auto;*/
/*}*/`, "",{"version":3,"sources":["webpack://./src/Shared/Template.css"],"names":[],"mappings":"AAAA,cAAc;AACd,mBAAmB;AACnB,4BAA4B;AAC5B,2BAA2B;AAC3B,4BAA4B;AAC5B,iBAAiB;AACjB,mBAAmB;AACnB,IAAI;;AAEJ,mBAAmB;AACnB,oBAAoB;AACpB,IAAI","sourcesContent":["/*.template {*/\n/*  display: flex;*/\n/*  flex-direction: column;*/\n/*  align-content: center;*/\n/*  box-sizing: border-box;*/\n/*  width: 100%;*/\n/*  height: 100vh;*/\n/*}*/\n\n/*.template-body {*/\n/*  flex: 1 0 auto;*/\n/*}*/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
