import React from "react";
import {CircleCrossIcon, RefreshIcon} from "../../UI_utils/SVGIcons";
import AppModal from "../../Shared/AppModal";

const RestoreGeneratedModal = ({ closeHandler, restoreHandler, isGenerated }) => {
    return (
        <AppModal
            icon={isGenerated ? RefreshIcon : CircleCrossIcon}
            title={isGenerated ? "Restore generated article?" : "Existing article content will be replaced."}
            text={isGenerated ? "Article will be restored to its last generated version." : ""}
            buttonText="Overwrite"
            secondaryButtonText="Cancel"
            buttonAction={restoreHandler}
            secondaryButtonAction={closeHandler}
            onCloseAction={closeHandler}
        />
    );
};

export default RestoreGeneratedModal;
