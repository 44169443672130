import React, { Children, useMemo } from 'react';
import './EditorContentInfoHeader.css';


const EditorContentInfoHeader = ({ children }) => {
    const { articleActions, otherContent } = useMemo(() => {
        return Children.toArray(children).reduce((acc, child) => {
            if (child.props?.isArticleAction) {
                acc.articleActions.push(child);
            } else {
                acc.otherContent.push(child);
            }
            return acc;
        }, { articleActions: [], otherContent: [] });
    }, [children]);

    return (
        <div className="editor-content-info__header">
            {articleActions.length > 0 ? (
                <React.Fragment>
                    <div className="article-actions">
                        {articleActions}
                    </div>
                    {otherContent}
                </React.Fragment>
            ) :(
                <div className="article-actions">
                    {Children.map(children, (child) => child )}
                </div>
            )}
            
        </div>
    );
};

const ArticleAction = ({ children, isArticleAction = true }) => children;
export { ArticleAction };

export default EditorContentInfoHeader;