import React, { useState, useEffect } from 'react';
import LinkBubbleMenu from './LinkBubbleMenu';

export const LinkBubbleMenuContainer = ({ editor }) => {
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        // Ensure editor is available before rendering
        if (!editor) return;

        // On mount, delay rendering to ensure clean DOM
        setShouldRender(false);
        const timer = setTimeout(() => {
            setShouldRender(true);
        }, 100);

        return () => {
            clearTimeout(timer);
            setShouldRender(false);
        };
    }, [editor]);

    if (!shouldRender || !editor) {
        return null;
    }

    return <LinkBubbleMenu editor={editor} />;
};

export default LinkBubbleMenuContainer;
