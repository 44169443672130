// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item {
  background-color: transparent;
  border: none;
  border-radius: 6px;
  /* color: #6870f6; */
  color: #808080;
  height: 24px;
  margin-right: 4px;
  padding: 0;
  /* width: 1.75rem; */
  cursor: pointer;
  position: relative;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-item svg {
  color: var(--default-surfaces-ghost);
  fill: var(--default-surfaces-ghost)
}

.menu-item[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

/* .menu-item svg {
  fill: currentColor;
  height: 100%;
  width: 100%;
} */

.menu-item:hover{
  background: var(--default-surfaces-ghost-hv)
}
/* .menu-item:hover, */
.is-active {
  background-color: var(--default-surfaces-light-hv);
}
`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/MenuItemTop.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,YAAY;EACZ,kBAAkB;EAClB,oBAAoB;EACpB,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,UAAU;EACV,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;EACpC;AACF;;AAEA;EACE,oBAAoB;EACpB,YAAY;AACd;;AAEA;;;;GAIG;;AAEH;EACE;AACF;AACA,sBAAsB;AACtB;EACE,kDAAkD;AACpD","sourcesContent":[".menu-item {\n  background-color: transparent;\n  border: none;\n  border-radius: 6px;\n  /* color: #6870f6; */\n  color: #808080;\n  height: 24px;\n  margin-right: 4px;\n  padding: 0;\n  /* width: 1.75rem; */\n  cursor: pointer;\n  position: relative;\n  width: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.left-item svg {\n  color: var(--default-surfaces-ghost);\n  fill: var(--default-surfaces-ghost)\n}\n\n.menu-item[disabled] {\n  pointer-events: none;\n  opacity: 0.5;\n}\n\n/* .menu-item svg {\n  fill: currentColor;\n  height: 100%;\n  width: 100%;\n} */\n\n.menu-item:hover{\n  background: var(--default-surfaces-ghost-hv)\n}\n/* .menu-item:hover, */\n.is-active {\n  background-color: var(--default-surfaces-light-hv);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
