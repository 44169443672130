import React from "react";
import "./AppModal.css";
import AppOverlay from "./AppOverlay";
import { CloseIcon } from '../UI_utils/SVGIcons';

const AppModal = ({
   className = '',
   icon: Icon,
   buttonIcon: ButtonIcon,
   title = '',
   text = '',
   buttonText = '',
   buttonAction,
   secondaryButtonText = '',
   secondaryButtonAction,
   onCloseAction,
   height,
   width,
   padding,
   children,
   infoText = '',
   customContent,
   disableButton = false,
   disableSecondaryButton = false,
}) => {
    const handlePrimaryClick = () => {
        if (!disableButton && buttonAction) buttonAction();
    };

    const handleSecondaryClick = () => {
        if (!disableSecondaryButton && secondaryButtonAction) secondaryButtonAction();
    };

    const renderButtons = () => {
        if (!buttonText && !secondaryButtonText) return null;

        return (
            <div className="app-modal__buttons-wrapper">
                {secondaryButtonText && (
                    <div
                        className={`app-modal-button app-modal-button--secondary ${disableSecondaryButton ? 'disabled' : ''}`}
                        onClick={handleSecondaryClick}
                    >
                        {secondaryButtonText}
                    </div>
                )}
                {buttonText && (
                    <div
                        className={`app-modal-button 
                            ${!secondaryButtonText ? 'big-button' : ''}
                            ${disableButton ? 'disabled' : ''}`}
                        onClick={handlePrimaryClick}
                    >
                        {ButtonIcon && <ButtonIcon />}
                        {buttonText}
                    </div>
                )}
            </div>
        );
    };


    const renderContent = () => (
        <>
            <div className="app-modal__header">
                {Icon && <div className="app-modal-icon"><Icon /></div>}
                {onCloseAction && <span className={"close-icon__wrapper"}><CloseIcon onClick={onCloseAction} /></span>}
            </div>
            {title && (
                <div
                    className="app-modal-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                />
            )}
            {text && (
                <div
                    className="app-modal-text"
                    dangerouslySetInnerHTML={{ __html: text }}
                />
            )}
            {customContent && (
                <div className="app-modal__custom-content">{customContent}</div>
            )}
            {renderButtons()}
            {infoText && (
                <div
                    className="app-modal__info-text"
                    dangerouslySetInnerHTML={{ __html: infoText }}
                />
            )}
        </>
    )

    return (
        <div className={`app-modal ${className}`}>
            <AppOverlay open={true} />
            <div className="app-modal__content" style={{ height, width, padding }}>
                {children || renderContent()}
            </div>
        </div>
    );
};

export default AppModal;
