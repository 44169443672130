import React, { useState, useCallback, useEffect } from "react";
import "./CurrentArticleModal.css";
import { InfoCircleIcon, SpinnerIcon, InfoCircleLightIcon } from "../../UI_utils/SVGIcons";
import AppModal from "../../Shared/AppModal";
import { useDispatch, useSelector } from "react-redux";
import {
    checkLiveUrlAction,
    detectLiveUrlAction,
    getArticleFromLP,
    getCurrentArticleId,
    getLandingPageUrl,
    getLiveUrl,
    getLiveUrlError,
    getCheckingLiveUrl, getCurrentWebsiteArticle, checkLiveUrlAndGetArticle,
} from "../../reducers/articles";
import ArticleUrlInput from "./ArticleUrlInput";
import Input from "../../Shared/Input";

const CurrentArticleModal = ({ closeHandler, setSelectedTypeOption }) => {
    const dispatch = useDispatch();
    const initialPageUrl = useSelector(getLandingPageUrl);
    const currentArticleId = useSelector(getCurrentArticleId);
    const liveUrl = useSelector(getLiveUrl);
    const liveUrlError = useSelector(getLiveUrlError);
    const checkingLiveUrl = useSelector(getCheckingLiveUrl);
    const currentWebsiteArticle = useSelector(getCurrentWebsiteArticle);

    const [error, setError] = useState('');
    const [checkingLandingPage, setCheckingLandingPage] = useState(false);
    const [currentUrl, setCurrentUrl] = useState(initialPageUrl);

    // const handleLandingPageCheck = useCallback((url) => {
    //     if(checkingLandingPage || !url) return;
    //
    //     const payload = {
    //         id: currentArticleId,
    //         url: url,
    //     };
    //
    //     setCheckingLandingPage(true);
    //
    //     dispatch(getArticleFromLP(payload))
    //         .then((response) => {
    //             if(response.error) {
    //                 setError(response.error || 'Error while fetching current article.');
    //             } else {
    //                 closeHandler()
    //                 setSelectedTypeOption('current')
    //             }
    //         })
    //         .catch ((error) => {
    //             setError('Error while fetching current article.');
    //         })
    //         .finally(() => {
    //             setCheckingLandingPage(false);
    //         });
    // }, [currentArticleId, dispatch, closeHandler, setSelectedTypeOption]);

    useEffect(() => {
        if(!liveUrlError) {
            dispatch(checkLiveUrlAndGetArticle())
        }
    }, [])

    useEffect(() => {
        if(!checkingLiveUrl && !!currentWebsiteArticle) {
            closeHandler(true)
            setSelectedTypeOption('current')
        }
    }, [checkingLiveUrl])

    const isActionDisabled = useCallback(() => {
        return checkingLandingPage || !!error || !currentUrl?.trim();
    }, [checkingLandingPage, error, currentUrl]);

    return (

        <AppModal
            className={"current-article-modal"}
            icon={InfoCircleIcon}
            title="View published article for reference"
            text="Would you like to view your published article in here? This makes it easier to compare while working on your update."
            onCloseAction={closeHandler}
            disableButton={isActionDisabled()}
            customContent={
                <Input
                    type="text"
                    element="input"
                    label="Published Article URL"
                    placeholder="E.g. https://website.com/article-sample"
                    inputValue={liveUrlError || liveUrl || "Looking for article..."}
                    showIcon={true}
                    loading={checkingLiveUrl}
                    IconToShow={checkingLiveUrl ? SpinnerIcon : InfoCircleLightIcon}
                    iconTooltip={liveUrlError && 'No relevant article found on this topic'}
                    disabled={true}
                    error={liveUrlError}
                    infoText={!liveUrlError && liveUrl && 'Processing article...'}
                />
            }
        />
    );
};

export default CurrentArticleModal;
