import React, { useMemo } from "react";
import { WhiteTooltipAuto } from "../../UITooltips/UITooltip";
import {
  MagicIcon,
  RefreshIcon,
  WriterMagicPen,
} from "../../../UI_utils/SVGIcons";
import { styleForGeneratingArticle } from "../../../reducers/articleSettings";

const EditorContentButton = ({
  onClick,
  tooltipPlacement = "bottom",
  disableTooltip = false,
  tooltipText= "",
  btnText,
  btnColor="",
  IconComponent,
}) => {


  return disableTooltip ? (
      <div className={`article-action ${btnColor}`} onClick={onClick}>
        {IconComponent()} {btnText}
      </div>
    ) :(
    <WhiteTooltipAuto 
        placement={ tooltipPlacement }
        title={<span dangerouslySetInnerHTML={{ __html: tooltipText }} />}
    >
      <div 
        className={`article-action ${btnColor}`} 
        onClick={onClick}>
            {IconComponent()} {btnText}
      </div>
    </WhiteTooltipAuto>
    );
};

export default EditorContentButton;
