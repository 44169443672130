// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-header {
    color: var(--default-symbols-primary);
}

.modal-header_welcome {
    font-size: 20px;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: -0.02em;
}

.modal-header_title {
    font-size: 62px;
    font-weight: 900;
    line-height: 68px;
    letter-spacing: -0.02em;
    margin-bottom: 24px;
}

.modal-header_subtitle {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.02em;

}

.modal-header_subtitle--colored {
    color: var(--night-symbols-primary);
}

.modal-header_close {
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 0;
    padding: 8px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewArticleModal/ModalHeader.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;AACzC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;;AAE3B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".modal-header {\n    color: var(--default-symbols-primary);\n}\n\n.modal-header_welcome {\n    font-size: 20px;\n    font-weight: 800;\n    line-height: 24px;\n    letter-spacing: -0.02em;\n}\n\n.modal-header_title {\n    font-size: 62px;\n    font-weight: 900;\n    line-height: 68px;\n    letter-spacing: -0.02em;\n    margin-bottom: 24px;\n}\n\n.modal-header_subtitle {\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 30px;\n    letter-spacing: -0.02em;\n\n}\n\n.modal-header_subtitle--colored {\n    color: var(--night-symbols-primary);\n}\n\n.modal-header_close {\n    position: absolute;\n    right: 16px;\n    top: 16px;\n    font-size: 0;\n    padding: 8px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
