import React, { useEffect } from 'react';
import { ContentItemMenu } from './ContentItemMenu';

export const EditorMenuWrapper = ({ editor, writerMode }) => {
    useEffect(() => {
        return () => {
            // Cleanup tippy instances on unmount or mode change
            const tippyInstances = document.querySelectorAll('[data-tippy-root]');
            tippyInstances.forEach(instance => instance.remove());
        };
    }, [writerMode]);

    // Force remount of menu when mode changes
    return <ContentItemMenu key={writerMode} editor={editor} />;
};
