import axios from "axios";
import { BASE_URL, IMAGE_UPLOAD_PATH } from "../common/consts";

const baseEndpoint = "api/articles";
const newArticle = "new";
const saveArticleUrl = "edit";
const allArticles = "all";
const currentArticle = "get";
const stats = "stats";
const getStats = "get-stats";
const remove = "delete";
const exportArticle = "export-pdf";
const uploadCsv = "upload-csv";
const technicalFeedback = "technical-check";
const rating = "rating";
const plagiarism = "plagiarism";
const plagiarismCheck = "plagiarism-check";
const getArticleModes = 'get';
const generateArticleFromOutline = 'api/article/generate-article';
const regenerateTitle = 'api/article/regenerate-title';
const stopGeneratingArticleEndpoint = 'api/article/stop-generate-article';
const stopWordsEndpoint = 'api/article/stopwords';
const deleteKeywordEndpoint = 'api/article/remove-keyword';
const getCoverageDataEndpoint = 'api/articles/get-coverage';
const deleteArticle = 'api/article/delete'
const checkTrackedKeywordsEndpoint = 'api/article/check-tracked-keywords'
const getShareHashEndpoint = 'api/article/generate-share-hash'
const getInternalLinks = 'api/article/get-internal-links'
const addInternalLink = 'api/article/add-internal-links'
const deleteInternalLink = 'api/article/remove-internal-links'
const subscribeUserPath = "api/article/notify-email"
const getCurrentArticlePath = "api/article/check-article-url"
const updateFeedbackPath = "api/article/feedback"
const checkLandingPageDataPath = "api/article/publish-data";
const publishArticlePath = "api/article/save-publish-url";
const getAllScoresPath = "api/articles/get-scores";
const detectLiveUrlPath = "api/article/detect-live-url";
const checkLiveUrlPath = "api/article/check-live-url";

// create a new article
export const setNewArticle = (article, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${newArticle}`, article, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// save content on existing article
export const saveArticleApi = (articleData, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${saveArticleUrl}`, articleData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get a list of all articles per user
export const getArticles = (token) =>
  axios
    .get(`${BASE_URL}/${baseEndpoint}/${allArticles}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get an article by ID
// /articles/get

export const articleById = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${currentArticle}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => {
        // data.completedHeadings = 0
        // data.totalHeadings = 0
        // data.articleCompleted = true
        return data
    })
    .catch((error) => {
        return Promise.reject(error.response)
    });

// delete article by ID
export const deleteArticleFromList = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${remove}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// save article stats
export const saveArticleStats = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${stats}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get article stats
export const getArticleStats = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${getStats}`, id, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// export to PDF
export const exportToPdf = (id, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${exportArticle}`, id, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data }) => data)
    .then((data) => {
      const fileURL = URL.createObjectURL(data);
      window.open(fileURL);
    })
    .catch((error) => Promise.reject(error.response));

// upload CSV file from dashboard
export const uploadCSV = (formData, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${uploadCsv}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));

// upload Image file from editor
export const uploadImage = (formData, setUploadProgress, token) =>
  axios
    .post(`${BASE_URL}/${IMAGE_UPLOAD_PATH}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress);
      },
    })
    .then(({ data }) => data)
    .catch((error) => Promise.reject(error.response));

export const updateTechnicalFeedback = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${technicalFeedback}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const rateArticle = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${rating}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const getArticlePlagiarismScore = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${plagiarism}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

export const updatePlagiarismScore = (payload, token) =>
  axios
    .post(`${BASE_URL}/${baseEndpoint}/${plagiarismCheck}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(({ data: { data } }) => data)
    .catch((error) => Promise.reject(error.response));

// get an article when redirecting from content audit
export const articleContent = (id, token) =>
    axios
        .post(`${BASE_URL}/api/article/get`, id, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));

// reset outline content
export const resetOutlineRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/api/article/generate-outline`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data }) => data)
        .catch((error) => {
            console.log('G.O. error => ', error)
            return Promise.reject(error.response)
        });

// save outline content
export const saveOutlineRequest = (data, token) =>
    axios
        .post(`${BASE_URL}/api/article/save-outline`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));

// generate article from the outline
export const generateArticleFromOutlineRequest = (payload, token) =>
    // new Promise((resolve, reject) => {
    // console.log(`generate article for id ${id} with the outline = ${outline} and prompt saying: '${prompt}'` )
    //     setTimeout(() => {
    //         resolve({content: '<h1>This is a hardcoded title</h1>'});
    //     }, 3000);
    // })

    axios
        .post(`${BASE_URL}/${generateArticleFromOutline}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));

// generate new title for the outline
export const generateNewTitleForOutlineRequest = ({ id, test_prompts  = false}, token) =>
    axios
        .post(`${BASE_URL}/${regenerateTitle}`, {id: id, test_prompts: test_prompts}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) =>  data)
        .catch((error) => Promise.reject(error.response));

// generate new title for the outline
export const stopGeneratingArticleRequest = ({ id }, token) =>
    axios
        .post(`${BASE_URL}/${stopGeneratingArticleEndpoint}`, {id: id}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data.data || data)
        .catch((error) => Promise.reject(error.response));

// get stop words
export const getStopWordsRequest = ({ id }, token) =>
    axios
        .post(`${BASE_URL}/${stopWordsEndpoint}`, {id: id}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data.data || data)
        .catch((error) => Promise.reject(error.response));

// delete keywords from coverage
export const deleteKeywordRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${deleteKeywordEndpoint}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));

// get coverage data
export const getCoverageDataRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${getCoverageDataEndpoint}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));

  export const deleteArticleFromDashboard = (payload, token) => 
        axios
            .post(`${BASE_URL}/${deleteArticle}`, payload, {
              headers:{
                Authorization: 'Bearer ' + token
              }
            })
            .then(({ data: { data } }) => data)
            .catch((error) => Promise.reject(error.response))

  export const checkTrackedKeywordsRequest = (payload, token) =>
        axios
            .post(`${BASE_URL}/${checkTrackedKeywordsEndpoint}`, payload, {
              headers:{
                Authorization: 'Bearer ' + token
              }
            })
            .then(({ data: { data } }) => data)
            .catch((error) => Promise.reject(error.response))

  export const getShareHashRequest = (payload, token) =>
        axios
            .post(`${BASE_URL}/${getShareHashEndpoint}`, payload, {
              headers:{
                Authorization: 'Bearer ' + token
              }
            })
            .then(({ data: { data } }) => data)
            .catch((error) => Promise.reject(error.response))

  export const getInternalLinksRequest = (payload, token) =>
        axios
            .post(`${BASE_URL}/${getInternalLinks}`, payload, {
              headers:{
                Authorization: 'Bearer ' + token
              }
            })
            .then(({ data: { data } }) => data)
            .catch((error) => Promise.reject(error.response))

  export const addInternalLinkRequest = (payload, token) =>
        axios
            .post(`${BASE_URL}/${addInternalLink}`, payload, {
              headers:{
                Authorization: 'Bearer ' + token
              }
            })
            .then(({ data: { data } }) => data)
            .catch((error) => Promise.reject(error.response))

  export const deleteInternalLinkRequest = (payload, token) =>
        axios
            .post(`${BASE_URL}/${deleteInternalLink}`, payload, {
              headers:{
                Authorization: 'Bearer ' + token
              }
            })
            .then(({ data: { data } }) => data)
            .catch((error) => Promise.reject(error.response))


export const subscribeUserRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${subscribeUserPath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => ({ error: 'Failed to get traffic for suggested topics' }));
export const checkLandingPageDataRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${checkLandingPageDataPath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));

export const publishArticleRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${publishArticlePath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => Promise.reject(error.response));

export const checkLandingPageRequest = (payload, token) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve({valid: !!Math.round(Math.random())});
        }, 1000); // Simulates a 1-second delay
    });

export const getCurrentArticleRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${getCurrentArticlePath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data, error } }) => data || {error})
        // .then(({ data: { data } }) => data)
        .catch((error) => ({ error: 'Failed to get current article' }));


export const updateFeedbackRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${updateFeedbackPath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data } }) => data)
        .catch((error) => ({ error: 'Failed to get traffic for suggested topics' }));


export const getAllScores = (payload, token) =>
    axios
        .post(`${BASE_URL}/${getAllScoresPath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data, error } }) => data || {error})
        // .then(({ data: { data } }) => data)
        .catch((error) => ({ error: 'Failed to get all scores' }));
export const detectLiveUrlRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${detectLiveUrlPath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data, error } }) => data || {error})
        .catch((error) => Promise.reject(error.response));

export const checkLiveUrlRequest = (payload, token) =>
    axios
        .post(`${BASE_URL}/${checkLiveUrlPath}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(({ data: { data, error } }) => data || {error})
        .catch((error) => Promise.reject(error.response));