import React, {useState} from "react";
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import "./PublishArticleModal.css";

import AppModal from "../../Shared/AppModal";
import { InfoCircleLightIcon } from "../../UI_utils/SVGIcons";
import {
    getCurrentArticleId,
    getLandingPageUrl,
    checkLandingPageDataAction,
    publishArticleAction,
    getLandingPageData,
    setLandingPageData,
} from "../../reducers/articles";
import { getSearchKeywords } from "../../reducers/searchTopic";
import { WhiteTooltipAuto } from "../UITooltips/UITooltip";
import ArticleUrlInput from "./ArticleUrlInput";
import ArticleDatePicker from "./ArticleDatePicker";
import CustomCheckbox from "../../Shared/CustomCheckbox";
import { getTopicCoverageData } from "../../reducers/topicCoverage";
import { getTitleCoverage } from "../../reducers/titleOptimization";
import { interfaceApiRequest } from "../../utils/dataToInterface";
import { TrackingInfo } from './TrackingInfo';
import { formatDateToYYYYMMDD } from '../../utils/dateUtility';

const INTERVAL_CHECK_DELAY = 5000;

const usePublishArticle = (initialPageUrl, currentArticleId, handleClose) => {
    const dispatch = useDispatch();
    const [error, setError] = useState('');
    const [checkingLandingPage, setCheckingLandingPage] = useState(false);
    const [pageSubmittedToGoogle, setPageSubmittedToGoogle] = useState(false);
    const [publishDate, setPublishDate] = useState(new Date());
    const landingPageData = useSelector(getLandingPageData);
    const [localLandingPageData, setLocalLandingPageData] = useState(landingPageData);
    const [checkInterval, setCheckInterval] = useState(null);
    const topicName = useSelector(getSearchKeywords);
    const processedKeywords = useSelector(getTopicCoverageData);
    const titleCoverage = useSelector(getTitleCoverage);

    React.useEffect(() => {
        setLocalLandingPageData(landingPageData);
        setPageSubmittedToGoogle(false);

        return () => {
            if (checkInterval) {
                clearInterval(checkInterval);
            }
        };
    }, [landingPageData]);

    const checkLandingPage = React.useCallback(async (url, isUserAction = true) => {
        if (isUserAction) {
            if (checkingLandingPage) return;
            setCheckingLandingPage(true);
            setError('');
        }

        try {
            const response = await dispatch(checkLandingPageDataAction({ url, id: currentArticleId }));
            if (!response.error) {
                setLocalLandingPageData(response);
                isUserAction && setCheckingLandingPage(false);
            } else {
                isUserAction && setError(response.error);
                isUserAction && setCheckingLandingPage(false);
            }
        } catch (error) {
            console.error('Error checking landing page:', error);
            isUserAction && setError('Failed to check landing page');
            isUserAction && setCheckingLandingPage(false);
        }
    }, [checkingLandingPage, currentArticleId, dispatch]);

    const markAsPublished = React.useCallback(async () => {
        if(!initialPageUrl) return ;

        try {
            const formattedDate = formatDateToYYYYMMDD(publishDate);
            if (!formattedDate) {
                setError('Invalid publish date');
                return;
            }

            const response = await dispatch(publishArticleAction({
                url: initialPageUrl,
                id: currentArticleId,
                publish_date: formattedDate,
                date: formattedDate
            }));

            if (!response.error) {
                dispatch(setLandingPageData(localLandingPageData));

                const keywords = [...processedKeywords, ...titleCoverage]
                    .map(kw => kw.keyword || kw.name);

                interfaceApiRequest('checkTopicAndStartArticleMessage', {
                    keywords,
                    topic: topicName
                }).catch(console.error);

                handleClose();
            } else {
                setError(response.error);
            }
        } catch (error) {
            console.error('Error marking as published:', error);
            setError('Failed to publish article');
        }
    }, [initialPageUrl, pageSubmittedToGoogle, currentArticleId, localLandingPageData, processedKeywords, titleCoverage, topicName, publishDate]);

    const connect = React.useCallback((type) => {
        try {
            if (type === 'ga' && !localLandingPageData.gsc) {
                return false;
            }

            window.parent.postMessage({ openAccountSettings: true, type }, '*');

            if (!checkInterval) {
                const interval = setInterval(() => {
                    checkLandingPage(initialPageUrl, false);
                }, INTERVAL_CHECK_DELAY);
                setCheckInterval(interval);
            }
        } catch (error) {
            console.error('Error in connect function:', error);
        }
    }, [localLandingPageData.gsc, checkInterval, initialPageUrl, checkLandingPage]);

    return {
        error,
        setError,
        checkingLandingPage,
        pageSubmittedToGoogle,
        setPageSubmittedToGoogle,
        publishDate,
        setPublishDate,
        localLandingPageData,
        checkLandingPage,
        markAsPublished,
        connect
    };
};

const PublishArticleModal = ({ setShowPublishModal }) => {
    const initialPageUrl = useSelector(getLandingPageUrl);
    const currentArticleId = useSelector(getCurrentArticleId);

    const handleClose = React.useCallback(() => {
        setShowPublishModal(false);
    }, [setShowPublishModal]);

    const {
        error,
        setError,
        checkingLandingPage,
        pageSubmittedToGoogle,
        setPageSubmittedToGoogle,
        publishDate,
        setPublishDate,
        localLandingPageData,
        checkLandingPage,
        markAsPublished,
        connect
    } = usePublishArticle(initialPageUrl, currentArticleId, handleClose);

    return (
        <AppModal
            className="publish-article-modal"
            icon={false}
            title="Start Tracking Performance"
            text="Configure tracking for your new article"
            onCloseAction={handleClose}
            disableButton={false}
            customContent={
                <div className="publish-article-modal__content">
                    <ArticleUrlInput
                        initialUrl={initialPageUrl}
                        onSubmit={checkLandingPage}
                        loading={checkingLandingPage}
                        error={error}
                        setError={setError}
                    />
                    <ArticleDatePicker
                        selectedDate={publishDate}
                        onDateChange={setPublishDate}
                    />
                    {localLandingPageData.totalKw !== null && (
                        <TrackingInfo
                            total_kw={localLandingPageData.totalKw}
                            has_gsc={localLandingPageData.gsc}
                            has_analytics={localLandingPageData.analytics}
                            connect={connect}
                        />
                    )}
                    <div className="publish-article-modal__footer">
                        <span className="publish-article-modal__info">
                            <CustomCheckbox
                                inputId="pageSubmittedToGoogle"
                                label="I've submitted this page to Google Search Console"
                                checked={pageSubmittedToGoogle}
                                handleChange={setPageSubmittedToGoogle}
                            />
                            <WhiteTooltipAuto
                                placement="bottom"
                                title={<span dangerouslySetInnerHTML={{ __html: "You can manually submit your page to speed up<br />Google's indexing process. Tick the checkbox, if<br />you submitted." }} />}
                            >
                                <span className="info-tooltip">
                                    <InfoCircleLightIcon />
                                </span>
                            </WhiteTooltipAuto>
                        </span>
                        <button
                            onClick={markAsPublished}
                            disabled={!!error || !initialPageUrl}>
                            Mark as published
                        </button>
                    </div>
                </div>
            }
        />
    );
};

PublishArticleModal.propTypes = {
    setShowPublishModal: PropTypes.func.isRequired
};

export default PublishArticleModal;
