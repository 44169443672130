import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './ArticleKeywordsTableHeader.css'
import {WhiteTooltip} from "../UITooltips/UITooltip";
import {CheckIcon} from "../../UI_utils/SVGIcons";

const ArticleKeywordsTableHeader = ({
    onFilter,
    loadingTopics,
    trackMode,
    allTracked,
    userHasCheckedKeywords,
    toggleSelectAllKeywordsHandler
}) => {
    const onSearch = (e) => {
        if(loadingTopics) return false;

        const value = e.target.value
        onFilter(value);
    };


    const returnCheckboxClass = () => {
        if(allTracked) return 'track-checkbox--tracked'
        if(userHasCheckedKeywords) return 'track-checkbox--checked'

        return ''
    }

    return (
        <>
            <div className={`article-keywords-table-header ${loadingTopics ? 'loading' : ''}`}>
                <div className="article-keywords-table-column column--keyword column-header">
                    <div className="input-search--keyword">
                        {!trackMode && <div className="search-icon"><img src="/images/search.svg" alt="info"/></div>}

                        {trackMode && (
                            <WhiteTooltip
                                placement="bottom-start"
                                title={allTracked ? 'Keywords already tracked' : ''}
                            >
                                <div className={`track-checkbox ${returnCheckboxClass()}`} onClick={toggleSelectAllKeywordsHandler}>
                                    {allTracked && <CheckIcon />}
                                </div>
                            </WhiteTooltip>
                        )}
                        <input
                            id="search"
                            onChange={onSearch}
                            type="text"
                            className="input-search"
                            placeholder="Keyword"
                            disabled={loadingTopics}
                        />
                    </div>
                </div>
                <div className="article-keywords-table-column column--volume column-header">Volume</div>
                <div className="article-keywords-table-column column--presence column-header">Presence</div>
            </div>
        </>
    );
}

export default ArticleKeywordsTableHeader;
