import React, {useState} from 'react';
import './DefaultSettingsSidebarSubHeader.css'
import AppTabSelector from "../../Shared/AppTabSelector";

const DefaultSettingsSidebarSubHeader = ({ selectedOption, options, onSwitch}) => {
    return (
        <div className="default-prompt-sidebar-sub-header">
            <AppTabSelector
                selectedOption={selectedOption}
                options={options}
                onSwitch={onSwitch}
            />
        </div>
    );
};

export default DefaultSettingsSidebarSubHeader