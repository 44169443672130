// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../_global-vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-label {
  font-weight: bold;
  font-size: 15px;
  margin: 0.3rem 0;
  color: var(--color-dark-text);
}`, "",{"version":3,"sources":["webpack://./src/Shared/CustomToggle.css"],"names":[],"mappings":"AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,gBAAgB;EAChB,6BAA6B;AAC/B","sourcesContent":["@import \"../_global-vars.css\";\n\n.toggle-label {\n  font-weight: bold;\n  font-size: 15px;\n  margin: 0.3rem 0;\n  color: var(--color-dark-text);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
