// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editor-content-info input {
  background: var(--default-surfaces-light);
  border: 2px solid transparent;
}
.editor-content-info input:focus {
  border-color: var(--hero-borders-anchor);
  background: var(--default-surfaces-base)
}
.current-article-modal .input__action {
  cursor: initial !important;
}
.current-article-modal .has--error .info-text {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/TipTapEditor/CurrentArticleModal.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,6BAA6B;AAC/B;AACA;EACE,wCAAwC;EACxC;AACF;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;AACf","sourcesContent":[".editor-content-info input {\n  background: var(--default-surfaces-light);\n  border: 2px solid transparent;\n}\n.editor-content-info input:focus {\n  border-color: var(--hero-borders-anchor);\n  background: var(--default-surfaces-base)\n}\n.current-article-modal .input__action {\n  cursor: initial !important;\n}\n.current-article-modal .has--error .info-text {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
