import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {activeUser, getDefaultPrompt, userTiptapJWT, userTiptapAIJWT} from "../reducers/user";
import {
    getOutlineProgressStatus,
    getCurrentArticle,
    getWriterMode,
    setArticleEditorLoading,
    setBothEditorsLoading,
    setShowNewArticleModal,
    getShowNewArticleModal,
} from '../reducers/articles';
import TipTapEditor from "../Components/TipTapEditor/TipTapEditor";
import WriterLeftSidebar from '../Components/WriterLeftSidebar/WriterLeftSidebar';
import { getContentArticleRequest, applyWriterMode } from '../reducers/articles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getGlobalState } from '../globals';
import Competition from "../Components/Competition";
import NewArticleModal from "../Components/NewArticleModal/NewArticleModal";
import ArticlesSidebar from "../Components/ArticlesSidebar/ArticlesSidebar";
import {setNoDataResults} from '../reducers/dashboard'
import DefaultSettingsSidebar from "../Components/DefaultSettingsSidebar/DefaultSettingsSidebar";
import { getShowArticleSidebar, setShowArticleSidebar } from '../reducers/applicationMode'
import {getCampaignWritingStyleOptions, setShowSettingsSidebar, showSettingsSidebar} from "../reducers/articleSettings";
import PublishArticleModal from "../Components/TipTapEditor/PublishArticleModal";
import TipTapEditor2 from "../Components/TipTapEditor/TipTapEditor2";

const ArticleMode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(activeUser);
  const currentArticle = useSelector(getCurrentArticle);
  const outlineProgressStatus = useSelector(getOutlineProgressStatus);
  const showAddArticleModal = useSelector(getShowNewArticleModal);
  const showArticlesSidebar = useSelector(getShowArticleSidebar)
  const showSidebarType = useSelector(showSettingsSidebar)

  const siteId = getGlobalState('site_id');
  const sharedToken = getGlobalState('sharedToken');
  const isBeta = getGlobalState("beta");

  const [firstLoad, setFirstLoad] = useState(true);
  const [showPublishModal, setShowPublishModal] = useState(false);

  const search = useLocation().search;
  const siteIdParams = new URLSearchParams(search).get('site_id');
  const {id} = useParams();

  const tiptapToken = useSelector(userTiptapJWT);
  const tiptapAIToken = useSelector(userTiptapAIJWT);

  useEffect(() => {
    if (currentArticle) {
      if(!sharedToken) {
          dispatch(getDefaultPrompt(siteIdParams))
          dispatch(getCampaignWritingStyleOptions(siteIdParams))
      }

    }

  }, [currentArticle]);

  const switchToArticle = () => {
      if(!sharedToken)
        history.push(`/article/${id}?site_id=${siteId || siteIdParams}`);

      dispatch(applyWriterMode('article'));
  }

  const switchToOutline = () => {
      if(!sharedToken)
        history.push(`/article/${id}/outline?site_id=${siteId || siteIdParams}`);

      dispatch(applyWriterMode('outline'));
  }

  useEffect(() => {
    if (showAddArticleModal && outlineProgressStatus < 0) {
        dispatch(setNoDataResults(true));
        history.push(`/dashboard?site_id=${id}`);
    }
  }, [showAddArticleModal]);

  useEffect(() => {

  if(outlineProgressStatus == 9) {
      dispatch(getContentArticleRequest(id))
        .then((response) => {
              if (id) {
                  if(firstLoad) setFirstLoad(false)

                  if (!response.outline) {
                      setArticleEditorLoading(false);
                  }
              }
          })
          .catch()
          .finally(() => {
              setBothEditorsLoading(false);
          });
  }
  }, [outlineProgressStatus])

    const writerMode = useSelector(getWriterMode)

    const setWriterMode = (mode) => {
      console.log('mode => ', mode)
        dispatch(applyWriterMode(mode));
        if (mode !== 'undefined' && mode === 'article') {
          switchToArticle();
        } else {
          switchToOutline();
        }
    }

    const [showCompetition, setShowCompetition] = useState(false);

    const updateShowCompetition = () => setShowCompetition(true);
    const closeCompetition = () => setShowCompetition(false);

    const toggleShowAddArticleModal = () => dispatch(setShowNewArticleModal(!showAddArticleModal));
    const toggleShowArticlesSidebar = () => dispatch(setShowArticleSidebar(!showArticlesSidebar));

    const updateShowSettingsSidebar = (type) => dispatch(setShowSettingsSidebar(type))

  return (
    <>
        {showCompetition && (
            <Competition closeCompetition={closeCompetition} />
        )}
        {showPublishModal && (
            <PublishArticleModal setShowPublishModal={setShowPublishModal} />
        )}
        {!sharedToken && (
            <ArticlesSidebar close={toggleShowArticlesSidebar} open={showArticlesSidebar}/>
        )}
        {!!showSidebarType && <DefaultSettingsSidebar />}
        {showAddArticleModal && (
            <NewArticleModal close={toggleShowAddArticleModal} noArticles={false} />
        )}
      <div className="homepage-content">
        <WriterLeftSidebar
            setWriterMode={setWriterMode}
            writerMode={writerMode}
            updateShowCompetition={updateShowCompetition}
            showAddArticleModal={toggleShowAddArticleModal}
            showArticlesSidebar={toggleShowArticlesSidebar}
            openDefaultSettings={updateShowSettingsSidebar}
        />
        <div className="homepage-editor">
            {tiptapAIToken && (
                <TipTapEditor
                    isReadOnly={false}
                    writerMode={writerMode}
                    switchToArticle={switchToArticle}
                    firstLoad={firstLoad}
                    tiptapToken={tiptapToken}
                    tiptapAIToken={tiptapAIToken}
                    setShowPublishModal={setShowPublishModal}
                />
            )}
            {/**
             Left this for when we'll start working on collab. TipTapEditor2 will be used for beta.
             Make sure to add !isBeta on for the regular TipTapEditor component from the above
             */}
            {/*{isBeta && tiptapAIToken && (*/}
            {/*    <TipTapEditor2*/}
            {/*        isReadOnly={false}*/}
            {/*        writerMode={writerMode}*/}
            {/*        switchToArticle={switchToArticle}*/}
            {/*        firstLoad={firstLoad}*/}
            {/*        tiptapToken={tiptapToken}*/}
            {/*        tiptapAIToken={tiptapAIToken}*/}
            {/*        setShowPublishModal={setShowPublishModal}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
      </div>
    </>
  );
};

export default ArticleMode;
