import React from 'react';
import AppSwitcher from "../../../Shared/AppSwitcher";
import {WhiteTooltip, WhiteTooltipAuto} from "../../UITooltips/UITooltip";
import {TEXTS} from "../../../common/consts";

const AiCardOptionsStopGeneratingArticle = ({
    disableStopGenerating,
    stopGenerating,
}) => {
    return (
        <div className="ai-card-buttons">
            <div
                className={`ai-card-button ai-card-button--stop ${disableStopGenerating ? 'disabled' : ''}`}
                onClick={stopGenerating}>
                Stop generating
            </div>
        </div>
    );
};

export default AiCardOptionsStopGeneratingArticle;