// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prompt-placeholder {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: var(--default-symbols-secondary);
    position: absolute;
    left: 0;
    top: 26px;
    bottom: 36px;
    overflow: hidden;
    padding: 12px 16px;
    border: 2px solid transparent;
    pointer-events: none;
}

.prompt-placeholder ul {
    line-height: 1.6;
}`, "",{"version":3,"sources":["webpack://./src/Components/DefaultSettingsSidebar/PromptPlaceholder.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,uBAAuB;IACvB,uCAAuC;IACvC,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,6BAA6B;IAC7B,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".prompt-placeholder {\n    font-size: 15px;\n    font-weight: 700;\n    line-height: 18px;\n    letter-spacing: -0.01em;\n    color: var(--default-symbols-secondary);\n    position: absolute;\n    left: 0;\n    top: 26px;\n    bottom: 36px;\n    overflow: hidden;\n    padding: 12px 16px;\n    border: 2px solid transparent;\n    pointer-events: none;\n}\n\n.prompt-placeholder ul {\n    line-height: 1.6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
