import { createSlice } from "@reduxjs/toolkit";
import {
  generateStyleFromUrl, getCampaignStyle,
  getDefaultWritingStyle, getStyleName,
  saveStyleUsedForGeneratingArticle,
  saveCampaignStyle, getStyleUsedForGeneratingArticle
} from "../services/article-settings";
import {getGlobalState} from "../globals";
import {ConfigurationIcon, FileIcon, GlobeIcon, MagicIcon} from "../UI_utils/SVGIcons";

export const articleSettings = createSlice({
  name: "articlesettings",
  initialState: {
    hasStyle: false,
    showSettingsSidebar: null,
    defaultStyleOptions: null,
    campaignStyleOptions: null,
    articleStyleOptions: null,
    articleStyleOptionsAltered: null,
    styleForGeneratingArticle: null,
    nameForGeneratingArticle: null,
    typeForGeneratingArticle: null,
    descriptionForGeneratingArticle: null,
    styles: {
      professional: null,
      educational: null,
      casual: null,
      custom: null
    },
    styleUrl: null,
    styleDescription: null,
    styleName: null,
    styleType: null,
    styleUsedForGenerating: null
  },
  reducers: {
    setStyleUrl: (state, { payload }) => {
      return {
        ...state,
        styleUrl: payload,
      };
    },
    setCustomStyle: (state, { payload }) => {
      state.styles.custom = payload;
    },
    setStyleUsedForGenerating: (state, { payload }) => {
      return {
        ...state,
        styleUsedForGenerating: payload,
      };
    },
    setStyleForGeneratingArticle: (state, { payload }) => {
      return {
        ...state,
        styleForGeneratingArticle: payload,
      };
    },
    setNameForGeneratingArticle: (state, { payload }) => {
      return {
        ...state,
        nameForGeneratingArticle: payload,
      };
    },
    setTypeForGeneratingArticle: (state, { payload }) => {
      return {
        ...state,
        typeForGeneratingArticle: payload,
      };
    },
    setDescriptionForGeneratingArticle: (state, { payload }) => {
      return {
        ...state,
        descriptionForGeneratingArticle: payload,
      };
    },
    setStyleName: (state, { payload }) => {
      return {
        ...state,
        styleName: payload,
      };
    },
    setStyleDescription: (state, { payload }) => {
      return {
        ...state,
        styleDescription: payload,
      };
    },
    setStyleType: (state, { payload }) => {
      return {
        ...state,
        styleType: payload,
      };
    },
    setStyles: (state, { payload }) => {
      return {
        ...state,
        styles: payload,
      };
    },
    setArticleStyle: (state, { payload }) => {
      return {
        ...state,
        hasStyle: payload,
      };
    },
    setShowSettingsSidebar: (state, { payload }) => {
      return {
        ...state,
        showSettingsSidebar: payload,
      };
    },
    setDefaultStyleOptions: (state, { payload }) => {
      return {
        ...state,
        defaultStyleOptions: payload,
      };
    },
    setCampaignStyleOptions: (state, { payload }) => {
      return {
        ...state,
        campaignStyleOptions: payload,
      };
    },
    setArticleStyleOptions: (state, { payload }) => {
      return {
        ...state,
        articleStyleOptions: payload,
      };
    },
    setArticleStyleOptionsAltered: (state, { payload }) => {
      return {
        ...state,
        articleStyleOptionsAltered: payload,
      };
    },
  },
});

// actions
export const {
  setStyleUrl,
  setStyleName,
  setStyleDescription,
  setStyleType,
  setNameForGeneratingArticle,
  setTypeForGeneratingArticle,
  setDescriptionForGeneratingArticle,
  setStyleUsedForGenerating,
  setCustomStyle,
  setStyleForGeneratingArticle,
  setStyles,
  setArticleStyle,
  setShowSettingsSidebar,
  setDefaultStyleOptions,
  setCampaignStyleOptions,
  setArticleStyleOptions,
  setArticleStyleOptionsAltered,
} = articleSettings.actions;
// getDefaultWritingStyle
// saveArticleStyle
// saveDefaultStyle
// generateStyleFromUrl
export const getDefaultWritingStyleOptions = () => async (dispatch) => {
  try {
    const {writingStyles} = await getDefaultWritingStyle();

    if(writingStyles) {
      writingStyles.forEach(option => {
        if(!option.selected.length) option.selected.push(option.options[0])
      })

      dispatch(setDefaultStyleOptions(writingStyles))
      const {styleName, styleDescription} = await dispatch(getStyleNameAndType(writingStyles));

      if(styleName) dispatch(setStyleName(styleName))
      if(styleDescription) dispatch(setStyleDescription(styleDescription))
    }
  } catch (e) {
      console.error('Error getting default writing style: ', e)
  }
}
export const getStyleFromUrl = (url) => async (dispatch) => {
  try {
    const {writingStyles} = await generateStyleFromUrl({url});

    if(writingStyles) {
      dispatch(setDefaultStyleOptions(writingStyles))
    }

    return writingStyles
  } catch (e) {
      console.error('Error getting default writing style: ', e)
  }
}

export const updateAndSaveArticleStyle = (payload) => async (dispatch, getState) => {
  try {
    const response = await saveStyleUsedForGeneratingArticle(payload);

    if(response.writingStyles) {
      dispatch(setStyleUsedForGenerating(response))
    }

    return response
  } catch (e) {
    console.error('Error saving style used for generating article: ', e)
  }
}

export const getArticleStyle = (articleId) => async (dispatch, getState) => {

  try {
    const response = await getStyleUsedForGeneratingArticle({id: articleId});

    if(response.writingStyles) {
      const { writingStyles, styleName, styleType, styleDescription } = response;
      console.log('')
      console.log('writingStyle ', writingStyles)
      console.log('styleName ', styleName)
      console.log('styleType ', styleType)
      console.log('styleDescription ', styleDescription)
      dispatch(setStyleForGeneratingArticle(writingStyles))
      dispatch(setNameForGeneratingArticle(styleName))
      dispatch(setTypeForGeneratingArticle(styleType))
      dispatch(setDescriptionForGeneratingArticle(styleDescription))
      // dispatch(setStyleUsedForGenerating(response))
    }

    return response
  } catch (e) {
      console.error('Error saving style used for generating article: ', e)
  }
}


export const updateAndSaveCampaignStyle = (data) => async (dispatch, getState) => {
  const siteId = getGlobalState("site_id");

  const payload = {
    id: siteId,
    ...data
  }

  try {
    const {writingStyles} = await saveCampaignStyle(payload);

    if(writingStyles) {
      dispatch(setCampaignStyleOptions(writingStyles))
      dispatch(setCustomStyle(writingStyles))
      dispatch(setStyleType(data.styleType))
      dispatch(setStyleName(data.styleName))
      dispatch(setStyleDescription(data.styleDescription))
      dispatch(setStyleUrl(data.url))
      dispatch(setShowSettingsSidebar(null))
    }

    return writingStyles
  } catch (e) {
      console.error('Error getting default writing style: ', e)
  }
}

export const getStyleNameAndType = (writingStyles) => async (dispatch) => {
  const payload = {
    writingStyles,
  }

  try {
    const response = await getStyleName(payload);

    return response
  } catch (e) {
      console.error('Error getting default writing style: ', e)
  }
}

export const getCampaignWritingStyleOptions = (id) => async (dispatch) => {
  try {
    const response = await getCampaignStyle({id});
    if(response.types) {
      const styleTypes = {
        professional: response.types.professional,
        educational: response.types.educational,
        casual: response.types.casual,
        custom: response.types.custom || null
      }

      dispatch(setStyles(styleTypes));
      dispatch(setStyleUrl(response.url));
      dispatch(setStyleName(response.styleName));
      dispatch(setStyleType(response.styleType));
      dispatch(setStyleDescription(response.styleDescription))

      if(!styleTypes.custom) {
        dispatch(setStyleType('website'));
        dispatch(getDefaultWritingStyleOptions())
      }
    } else if(response.writingStyles) {
      dispatch(setCampaignStyleOptions(response.writingStyles))
      dispatch(setCustomStyle(response.writingStyles))


    } else {
      dispatch(setStyles('website'));
      dispatch(getDefaultWritingStyleOptions())
    }
    return response
  } catch (e) {
      // throw new Error(e);
  }
}
const WRITER_TAB_INFO = {
  selected: {
    icon: ConfigurationIcon,
    iconTooltip: 'Based on the selected settings.'
  },
  article: {
    icon: FileIcon,
    iconTooltip: 'Based on analyzing article sample.'
  },
  website: {
    icon: GlobeIcon,
    iconTooltip: 'Based on analyzing website.'
  }
}
export const customIconTooltip = (styleType) => WRITER_TAB_INFO[styleType];
export const customStyleIcon = (styleType) => WRITER_TAB_INFO[styleType]?.icon || MagicIcon;
export const customStyleTooltip = (styleType) => WRITER_TAB_INFO[styleType]?.iconTooltip;

// selectors
export const hasStyle = ({ articleSettings: { hasStyle } }) => hasStyle;
export const showSettingsSidebar = ({ articleSettings: { showSettingsSidebar } }) => showSettingsSidebar;
export const defaultStyleOptions = ({ articleSettings: { defaultStyleOptions } }) => defaultStyleOptions;
export const campaignStyleOptions = ({ articleSettings: { campaignStyleOptions } }) => campaignStyleOptions;
export const articleStyleOptions = ({ articleSettings: { articleStyleOptions } }) => articleStyleOptions;
export const allStyles = ({ articleSettings: { styles } }) => styles;
export const customCampaignStyle = ({ articleSettings: { styles } }) => styles.custom;
export const styleUrl = ({ articleSettings: { styleUrl } }) => styleUrl;
export const styleName = ({ articleSettings: { styleName } }) => styleName;
export const styleType = ({ articleSettings: { styleType } }) => styleType;
export const styleDescription = ({ articleSettings: { styleDescription } }) => styleDescription;
export const styleForGeneratingArticle = ({ articleSettings: { styleForGeneratingArticle } }) => styleForGeneratingArticle;
export const styleUsedForGenerating = ({ articleSettings: { styleUsedForGenerating } }) => styleUsedForGenerating;
export const nameForGeneratingArticle = ({ articleSettings: { nameForGeneratingArticle } }) => nameForGeneratingArticle;
export const typeForGeneratingArticle = ({ articleSettings: { typeForGeneratingArticle } }) => typeForGeneratingArticle;
export const descriptionForGeneratingArticle = ({ articleSettings: { descriptionForGeneratingArticle } }) => descriptionForGeneratingArticle;
export const articleStyleOptionsAltered = ({ articleSettings: { articleStyleOptionsAltered } }) => articleStyleOptionsAltered;
export default articleSettings.reducer;
