import { createSlice } from "@reduxjs/toolkit";
import {getGlobalState} from "../globals";

const defaultCoverage = () => ({
  outline: {
    keywords: {},
    percent: null
  },
  article: {
    keywords: {},
    percent: null
  },
  uncompiled: {
    keywords: {},
    percent: null
  },
  generated: {
    keywords: {},
    percent: null
  },
  live: {
    keywords: {},
    percent: null
  },
})

export const keywordsCoverageSlice = createSlice({
  name: "keywordscoverage",
  initialState: {
    titleCoverage: defaultCoverage(),
    topicCoverage: defaultCoverage(),
    headingCoverage: defaultCoverage(),
  },
  reducers: {
    setCoverageDataTitle: (state, { payload }) => {
      state.titleCoverage = payload;
    },
    setCoverageDataOutlineTitle: (state, { payload }) => {
      state.titleCoverage.outline = payload;
    },
    setCoverageDataArticleTitle: (state, { payload }) => {
      state.titleCoverage.article = payload;
    },
    setCoverageDataUncompiledTitle: (state, { payload }) => {
      state.titleCoverage.uncompiled = payload;
    },
    setCoverageDataGeneratedTitle: (state, { payload }) => {
      state.titleCoverage.generated = payload;
    },
    setCoverageDataLiveTitle: (state, { payload }) => {
      state.titleCoverage.live = payload;
    },
    setCoverageDataTopic: (state, { payload }) => {
      state.topicCoverage = payload;
    },
    setCoverageDataOutlineTopic: (state, { payload }) => {
      state.topicCoverage.outline = payload;
    },
    setCoverageDataArticleTopic: (state, { payload }) => {
      state.topicCoverage.article = payload;
    },
    setCoverageDataUncompiledTopic: (state, { payload }) => {
      state.topicCoverage.uncompiled = payload;
    },
    setCoverageDataGeneratedTopic: (state, { payload }) => {
      state.topicCoverage.generated = payload;
    },
    setCoverageDataLiveTopic: (state, { payload }) => {
      state.topicCoverage.live = payload;
    },
    setCoverageDataHeading: (state, { payload }) => {
      state.headingCoverage = payload;
    },
    setCoverageDataOutlineHeading: (state, { payload }) => {
      state.headingCoverage.outline = payload;
    },
    setCoverageDataArticleHeading: (state, { payload }) => {
      state.headingCoverage.article = payload;
    },
    setCoverageDataUncompiledHeading: (state, { payload }) => {
      state.headingCoverage.uncompiled = payload;
    },
    setCoverageDataGeneratedHeading: (state, { payload }) => {
      state.headingCoverage.generated = payload;
    },
    setCoverageDataLiveHeading: (state, { payload }) => {
      state.headingCoverage.live = payload;
    },
  },
});

// actions
export const {
  setCoverageDataTitle,
  setCoverageDataOutlineTitle,
  setCoverageDataArticleTitle,
  setCoverageDataUncompiledTitle,
  setCoverageDataGeneratedTitle,
  setCoverageDataLiveTitle,
  setCoverageDataTopic,
  setCoverageDataOutlineTopic,
  setCoverageDataArticleTopic,
  setCoverageDataUncompiledTopic,
  setCoverageDataGeneratedTopic,
  setCoverageDataLiveTopic,
  setCoverageDataHeading,
  setCoverageDataOutlineHeading,
  setCoverageDataArticleHeading,
  setCoverageDataUncompiledHeading,
  setCoverageDataGeneratedHeading,
  setCoverageDataLiveHeading,
} = keywordsCoverageSlice.actions;

// selectors
export const getAllCoverageDataTitle = ({ keywordscoverage: { titleCoverage } }) => titleCoverage;

export const getActiveCoverageDataTitle = ({ keywordscoverage: { titleCoverage }, articles: { writerMode, showUncompiled, newWriterMode } }) => {
  if(writerMode === 'outline') return titleCoverage.outline

  if(showUncompiled) return titleCoverage.uncompiled

  if(newWriterMode === 'generated') return titleCoverage.generated;
  if(newWriterMode === 'current') return titleCoverage.live;

  return titleCoverage.article;
};
export const getActiveCoverageDataTopic = ({ keywordscoverage: { topicCoverage }, articles: { writerMode, showUncompiled, newWriterMode } }) => {
  if(writerMode === 'outline') return topicCoverage.outline

  if(showUncompiled) return topicCoverage.uncompiled

  if(newWriterMode === 'generated') return topicCoverage.generated;
  if(newWriterMode === 'current') return topicCoverage.live;

  return topicCoverage.article;
};
export const getActiveCoverageDataHeading = ({ keywordscoverage: { headingCoverage }, articles: { writerMode, showUncompiled, newWriterMode } }) => {
  if(writerMode === 'outline') return headingCoverage.outline

  if(showUncompiled) return headingCoverage.uncompiled

  if(newWriterMode === 'generated') return headingCoverage.generated;
  if(newWriterMode === 'current') return headingCoverage.live;

  return headingCoverage.article;
};
export const activeCoverageDataFetched = ({ keywordscoverage: { titleCoverage }, articles: { writerMode, showUncompiled, newWriterMode } }) => {
  if(writerMode === 'outline') return titleCoverage.outline.percent !== null

  if(showUncompiled) return titleCoverage.uncompiled.percent !== null

  if(newWriterMode === 'generated') return titleCoverage.generated.percent !== null;
  if(newWriterMode === 'current') return titleCoverage.live.percent !== null;

  return titleCoverage.article.percent !== null;
};


export default keywordsCoverageSlice.reducer;
