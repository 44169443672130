import React from 'react'
import classNames from 'classnames'


const ModalBodyFooter = ({userCanGenerateArticle, actionClick}) => {
    return (
        <div className="modal-body-footer">
            <button  onClick={actionClick} className={classNames({'footer-button': true, default: !userCanGenerateArticle(), night: userCanGenerateArticle()})}>
                {userCanGenerateArticle() ? 'Continue' : 'Go back'}
            </button>
            <div className="checkbox-container">
                <label className="container-checkbox">
                    <input type="checkbox" name="not-show" id="not-show" className="" />
                    <span class="checkmark"></span>
                </label>
            <p>Don't show it again</p>
            </div>
        </div>
    )
}

export default ModalBodyFooter