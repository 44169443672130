/** @format */

import React, { useState, useEffect, useRef } from 'react';
import './AiCard.css';
import {
    contentArticleDataIsEmpty,
    getArticleCompleted,
    getArticleFromOutline,
    getCompletedHeadings,
    getContentArticle,
    getCurrentArticleId,
    getHideAiCard,
    getOutlinePrompt,
    getTotalHeadings,
    resetAndGetOutline,
    setArticleAltered,
    setOutlinePrompt,
    stopGeneratingArticle,
    requestArticleById,
    setTotalHeadings,
    setArticleCompleted,
    setCompletedHeadings,
    setOutlineProgressStatus, contentOutlineIsEmpty
} from '../../../reducers/articles';
// import { getUserData } from '../../../reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalState } from '../../../globals';
import {getEditorHTMLData, getParsedData} from '../../../reducers/textEditor';
import { useParams } from 'react-router-dom';
import AppModal from '../../../Shared/AppModal';
import { CircleCrossIcon } from '../../../UI_utils/SVGIcons';
import TryAgainModal from '../../../Shared/TryAgainModal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from 'react-select';
import CustomSelect from "../../../Shared/CustomSelect";
import CustomCheckbox from "../../../Shared/CustomCheckbox";
import CustomToggle from "../../../Shared/CustomToggle";
import AppSwitcher from "../../../Shared/AppSwitcher";
import AiCardPrompt from "./AiCardPrompt";
import AiCardModal from "./AiCardModal";
import AiCardOptions from "./AiCardOptions";
import AiCardInternalLinks from "./AiCardInternalLinks";
import {getUserAccessToInternalLinks, getUserHasArticlesLeft, getUserHasWritingStyles} from "../../../reducers/user";
import ArticleAccessModal from '../ArticleAccessModal/WriterAccessModal';
import {getContentWriterCounts, getRegenerateCount } from '../../../reducers/articles'
import {interfaceApiRequest} from '../../../utils/dataToInterface'
import AiCardStyle from "./AiCardStyle";
import {
    customCampaignStyle,
    hasStyle,
} from "../../../reducers/articleSettings";

const AiCard = ({
    isOutline,
    switchToArticle,
    generating,
    outlineProgressStatus,
    aiMode,
    setAiMode,
    selectedTestPrompt,
    setSelectedTestPrompt,
    activeConfigurationStep,
    setActiveConfigurationStep,
    promptType,
    setPromptType,
    regenerateCount,
    selectedTypeOption,
    hideBackButton = false
}) => {
    const siteId = getGlobalState('site_id');
    const dispatch = useDispatch();
    const editorHTMLData = useSelector(getEditorHTMLData);
    const parsedData = useSelector(getParsedData);
    const outlinePrompt = useSelector(getOutlinePrompt);
    const hideAiCard = useSelector(getHideAiCard);
    const styleAccess = useSelector(getUserHasWritingStyles)
    const contentWriterCounts = useSelector(getContentWriterCounts)
    const hasArticlesLeft = useSelector(getUserHasArticlesLeft);

    // const [promptType, setPromptType] = useState('article')
    const [showCardOnArticle, setShowCardOnArticle] = useState(false);
    const [showFreshArticleText, setShowFreshArticleText] = useState(false);

    const currentArticleId = useSelector(getCurrentArticleId);
    const hasSharedToken = getGlobalState('sharedToken');

    // const userData = useSelector(getUserData);
    const { id } = useParams();

    /**
     * logic for generating article
     */

    const completedHeadings = useSelector(getCompletedHeadings);
    const totalHeadings = useSelector(getTotalHeadings);
    const articleCompleted = useSelector(getArticleCompleted);
    const [completedHeadingsUpdated, setCompletedHeadingsUpdated] = useState(false);

    const [selectedMarketSource, setSelectedMarketSource] = useState(false);
    /**
     * Text that will be displayed
     */
    const returnGeneratingArticle = () =>
        `The article is being generated by Al following the brief, the outline and the user prompt.`;
    const ARTICLE_ALMOST_DONE = 'Almost ready! Fine-tuning the content to ensure perfect alignment with the context. This can take up to one or two minutes.';
    const FRESH_ARTICLE_TEXT = 'Completed! The final version of the article has been generated below. Feel free to refine it further and export.';
    const ARTICLE_TEXT = 'Article draft complete. Review and refine content, then proceed with your website publication process.';
    const GENERATING_OUTLINE_TEXT = 'AI is generating the outline based on top-searched keywords and ranking signals from SERP-leading articles.';
    const GENERATING_ARTICLE_TEXT = 'The article is being generated. This may take up to 10 minutes depending on the length of the article.';
    const OUTLINE_TEXT = 'AI-generated outline for your topic based on the brief and the structure of the top ranking articles. Edit Title, Headings, and keywords as needed, then generate the article.';
    const OUTLINE_PROMPT_TEXT = 'Optional: Add custom guidelines for the AI outline generation.';
    // const ARTICLE_PROMPT_TEXT = 'Optional: Add custom guidelines for AI content generation.'
    const ARTICLE_PROMPT_TEXT = 'Add custom guidelines for content generation'
    const CHECKING_STATUS_TEXT = 'Researching keyword as a topic.';
    const STOP_ARTICLE_GENERATION = 'Article generation stopped. You can now edit the content and export it, or return to the Outline view to adjust settings and regenerate.';
    const ADDING_INTERNAL_LINKS = 'Add internal links.';
    const ADDING_STYLE = 'Add writing style.';

    // State variables to track whether each text has been shown at least once
    const [outlineTextShown, setOutlineTextShown] = useState('');
    const [outlinePromptTextShown, setOutlinePromptTextShown] = useState('');
    const [articleTextShown, setArticleTextShown] = useState('');
    const [freshArticleTextShown, setFreshArticleTextShown] = useState('');
    const [generatingArticleTextShown, setGeneratingArticleTextShown] = useState('');
    const [topicStatusTextShown, setTopicStatusTextShown] = useState('');
    const [generatingOutlineTextShown, setGeneratingOutlineTextShown] = useState('');
    const [addingInternalLinksTextShown, setAddingInternalLinksTextShown] = useState('');
    const [addingStyleTextShown, setAddingStyleTextShown] = useState('');
    const [articleAlmostDoneText, setArticleAlmostDoneText] = useState('');
    const [showArticleAccessModal, setShowArticleAccessModal] = useState(false)

    const getSetupConfig = (styleAccess) => ({
        outline: [{
            name: 'prompt',
            goNext: () => generateArticle()
        }],
        article: styleAccess ? [{ name: 'style' }, { name: 'links' }, { name: 'prompt' }] : [{ name: 'links' }, { name: 'prompt' }],
    });

    const [setup, setSetup] = useState(getSetupConfig(styleAccess))

    useEffect(() => setSetup(getSetupConfig(styleAccess)), [styleAccess])

    const configurationSteps = {
        outline: setup.outline.length,
        article: setup.article.length
    }

    const returnActiveStep = () => activeConfigurationStep > 0 && setup[promptType][activeConfigurationStep - 1].name;
    const activeStepIs = (section) => returnActiveStep() === section
    const returnActiveStepHasSkipOption = () => {
        return (activeConfigurationStep > 0 &&
        typeof setup[promptType][activeConfigurationStep - 1].hasSkip === 'function' &&
        setup[promptType][activeConfigurationStep - 1].hasSkip())
    }

    const increaseConfigurationStep = (type) => {
        setPromptType(type)

        if(typeof setup[promptType][activeConfigurationStep - 1]?.goNext === 'function') {
            setup[promptType][activeConfigurationStep - 1].goNext()
        }

        if(activeConfigurationStep <= configurationSteps[promptType]) {
            setActiveConfigurationStep(activeConfigurationStep + 1)
        }
    }

    const decreaseConfigurationStep = () => {
        // hidePromptInput()

        if(activeConfigurationStep > 0)
            setActiveConfigurationStep(activeConfigurationStep-1)
    }

    const showText = () => {
        if (outlineProgressStatus === null) {
            return { text: CHECKING_STATUS_TEXT, shownText: topicStatusTextShown, setShownText: setTopicStatusTextShown };
        }

        if (articleCompleted === false) {
            return { text: GENERATING_ARTICLE_TEXT, shownText: generatingArticleTextShown, setShownText: setGeneratingArticleTextShown };
        }

        if(activeConfigurationStep > 0) {
            if (activeConfigurationStep === configurationSteps[promptType]) {
                return { text: promptType === 'outline' ?  OUTLINE_PROMPT_TEXT : ARTICLE_PROMPT_TEXT, shownText: outlinePromptTextShown, setShownText: setOutlinePromptTextShown };
            }

            if(promptType === 'article') {
                if(showFreshArticleText) {
                    return {text: FRESH_ARTICLE_TEXT, shownText: freshArticleTextShown, setShownText: setFreshArticleTextShown};
                }

                if (showCardOnArticle && disableStopGenerating) {
                    return { text: STOP_ARTICLE_GENERATION, shownText: articleTextShown, setShownText: setArticleTextShown };
                }

                if(activeConfigurationStep === 1) {
                    if(styleAccess) {
                        return { text: ADDING_STYLE, shownText: addingStyleTextShown, setShownText: setAddingStyleTextShown };
                    }
                    return { text: ADDING_INTERNAL_LINKS, shownText: addingInternalLinksTextShown, setShownText: setAddingInternalLinksTextShown };
                }

                if(activeConfigurationStep === 2) {
                    if(styleAccess) {
                        return { text: ADDING_INTERNAL_LINKS, shownText: addingInternalLinksTextShown, setShownText: setAddingInternalLinksTextShown };
                    }
                }

            } else {
                if (generating) {
                    return { text: GENERATING_OUTLINE_TEXT, shownText: generatingOutlineTextShown, setShownText: setGeneratingOutlineTextShown };
                }
            }
        }

        return {};
    };
    // const showText = () => {
    //     if (outlineProgressStatus === null) {
    //         return { text: CHECKING_STATUS_TEXT, shownText: topicStatusTextShown, setShownText: setTopicStatusTextShown };
    //     }
    //
    //     if (articleCompleted === false) {
    //         // if (completedHeadings == totalHeadings) {
    //         //     return { text: ARTICLE_ALMOST_DONE, shownText: articleAlmostDoneText, setShownText: setArticleAlmostDoneText };
    //         // }
    //         return { text: GENERATING_ARTICLE_TEXT, shownText: generatingArticleTextShown, setShownText: setGeneratingArticleTextShown };
    //     }
    //
    //     if (!isOutline) {
    //         if (showCardOnArticle && !disableStopGenerating) {
    //             if(showFreshArticleText) {
    //                 return {text: FRESH_ARTICLE_TEXT, shownText: freshArticleTextShown, setShownText: setFreshArticleTextShown};
    //             }
    //             // return {text: ARTICLE_TEXT, shownText: articleTextShown, setShownText: setArticleTextShown};
    //         }
    //         if (showCardOnArticle && disableStopGenerating)
    //             return { text: STOP_ARTICLE_GENERATION, shownText: articleTextShown, setShownText: setArticleTextShown };
    //
    //         // setShowCardOnArticle(false)
    //         return {};
    //     }
    //
    //     if (generating) {
    //         return { text: GENERATING_OUTLINE_TEXT, shownText: generatingOutlineTextShown, setShownText: setGeneratingOutlineTextShown };
    //     }
    //
    //     if (activeConfigurationStep === configurationSteps[promptType]) {
    //         return { text: promptType === 'outline' ?  OUTLINE_PROMPT_TEXT : ARTICLE_PROMPT_TEXT, shownText: outlinePromptTextShown, setShownText: setOutlinePromptTextShown };
    //     }
    //
    //     if(activeConfigurationStep === 1 && promptType === 'article') {
    //         if(styleAccess)
    //             return { text: ADDING_STYLE, shownText: addingStyleTextShown, setShownText: setAddingStyleTextShown };
    //         return { text: ADDING_INTERNAL_LINKS, shownText: addingInternalLinksTextShown, setShownText: setAddingInternalLinksTextShown };
    //     }
    //
    //     if(activeConfigurationStep === 2 && promptType === 'article') {
    //         if(styleAccess)
    //             return { text: ADDING_INTERNAL_LINKS, shownText: addingInternalLinksTextShown, setShownText: setAddingInternalLinksTextShown };
    //     }
    //
    //     return { text: OUTLINE_TEXT, shownText: outlineTextShown, setShownText: setOutlineTextShown };
    // };
    const userCanGenerateArticle  = () => {
        return contentWriterCounts && (contentWriterCounts.articlesLeft > 0 && contentWriterCounts.additionalArticles > 0)
    }

    useEffect(() => {
        userCanGenerateArticle()
    }, [contentWriterCounts])



    useEffect(() => {
        if (articleCompleted !== false) return setCompletedHeadingsUpdated(false);

        setShowCardOnArticle(true);
        setShowFreshArticleText(true);

        // if last character is '.' it means that is completed.
        // I didn't want to compare the length because it can be different if
        // initially the completed headings had one digit and later it had 2 digits
        const textCompleted = generatingArticleTextShown.slice(-1) === '.';

        if (textCompleted) {
            setCompletedHeadingsUpdated(true);
        }
    }, [completedHeadings]);

    /**
     * Text that needs to be displayed it will be "typed"
     */
    const TIMEOUT = 150;

    const [text, setText] = useState(outlinePrompt);

    useEffect(() => setText(outlinePrompt), [outlinePrompt]);

    const disableContinueTooltip = () => {
        if(activeConfigurationStep === configurationSteps[promptType] && text.length > 800)
            return "Shorten your custom guidelines to 800 characters.";
        if(disableGeneratingArticle())
            return "To generate an article, please enter a title, add headings, and include relevant keywords.";
        return ""
    }

    useEffect(() => {
        const showTextObject = showText();
        const texts = showTextObject?.text;

        if (texts !== undefined) {
            const textCompleted = showTextObject?.shownText.slice(-1) === '.';
            if (textCompleted) {
                return showTextObject.setShownText(showTextObject?.text);
            }

            // Split the full text into words
            const words = texts.split(' '); // Split the text by spaces to get individual words

            const intervalId = setInterval(() => {
                showTextObject.setShownText((prevText) => {
                    // Split the current shown text by words to keep track
                    const currentWords = prevText.split(' ');
                    const currentWordCount = currentWords.length;

                    // If there are more words to show, append the next word
                    if (currentWordCount < words.length) {
                        return words.slice(0, currentWordCount + 1).join(' ');
                    } else {
                        // If all words are shown, clear the interval
                        clearInterval(intervalId);
                        return prevText;
                    }
                });
            }, TIMEOUT);

            return () => {
                // console.log('Cleanup');
                clearInterval(intervalId);
            };
        }
    }, [isOutline, activeConfigurationStep, generating, outlineProgressStatus, completedHeadings, articleCompleted, totalHeadings, showFreshArticleText, activeConfigurationStep, promptType]);

    const dynamicMainClass = () => {
        let classList = '';
        if (isOutline) classList += ` ai-card--outline ${generating ? 'ai-card--generating-outline' : ''}`;
        // articleCompleted
        if(!isOutline) classList += ` ai-card--outline ${generating || articleCompleted !== false ? 'ai-card--generating-article' : ''}`;

        if(articleCompleted === false) classList += ` ai-card--full-text`;
        if(!hasArticlesLeft) classList += ` no-articles-left`

        return classList
    };

    const resetOutline = () => {
        if (generating) return false;

        dispatch(resetAndGetOutline(currentArticleId, selectedTestPrompt.value, text, false));
    };

    //Confirm generate article
    const articleIsEmpty = useSelector(contentArticleDataIsEmpty);
    const outlineIsEmpty = useSelector(contentOutlineIsEmpty);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    //End confirm generate article

    const generateArticle = () => {
        if(!hasArticlesLeft) {
           return setShowArticleAccessModal(true)
        }

        setShowArticleAccessModal(false)

        if (!showConfirmModal) {
            if((promptType === 'article' && !articleIsEmpty) || (promptType === 'outline' && !outlineIsEmpty))
                return setShowConfirmModal(true);
        }

        setShowConfirmModal(false);

        if (promptType === 'article') {
            dispatch(setCompletedHeadings(0))
            dispatch(setArticleCompleted(false))

            setTimeout(() => {
                dispatch(
                    getArticleFromOutline({ id: id, outline: editorHTMLData, prompt: text, mode: aiMode.value, test_prompts: selectedTestPrompt.value, get_from_uk: selectedMarketSource })
                );

            switchToArticle('generated');
              
            interfaceApiRequest('getWriterArticleCount');
            setActiveConfigurationStep(0)

            }, 300)
        } else {
            dispatch(setOutlineProgressStatus(null))
            setActiveConfigurationStep(0)
            resetOutline()
        }
       
    };

    /**
     * Logic that shrinks or enlarges the text area based on the content
     */
    const textareaRef = useRef(null);

    useEffect(() => {
        const textarea = textareaRef.current;

        if (textarea) {
            updateTextareaHeight(textarea);
        }
    }, [activeConfigurationStep]);

    const handleTextareaChange = (event) => {
        setText(event.target.value);
        updateTextareaHeight(event.target);
    };

    const updateTextareaHeight = (element) => {
        element.style.height = '0';
        element.style.height = `${element.scrollHeight}px`;
    };

    const [disableStopGenerating, setDisableStopGenerating] = useState(false);

    const stopGenerating = () => {
        if (disableStopGenerating) return;

        setDisableStopGenerating(true);

        dispatch(stopGeneratingArticle(currentArticleId));
    };
    useEffect(() => {
        setDisableStopGenerating(false);
        setShowFreshArticleText(false);
    }, [isOutline]);


    const disableGeneratingArticle = () => {
        return promptType === 'article' && outlineIsEmpty
    }

    // Conditions to hide the AI card
    const shouldHideCard = 
        (activeConfigurationStep === 0 && articleCompleted !== false) || // Hide if first step and article is completed
        hasSharedToken ||                                               // Hide if has shared token
        (['new', 'current'].includes(selectedTypeOption) && articleCompleted !== false) ||              // Hide for new/current type options
        (selectedTypeOption !== 'outline' && promptType === 'outline'); // Hide for non-outline type when prompt is outline

    if (shouldHideCard) return <></>;

    return (
        <div className={`ai-card__wrapper ${!!activeConfigurationStep && promptType === 'article' ? 'ai-card__wrapper--show-links' : ''}`}>
            {showArticleAccessModal &&
            <ArticleAccessModal
                userCanGenerateArticle={userCanGenerateArticle}
                generateArticle={generateArticle}
                setShowArticleAccessModal={setShowArticleAccessModal}
                />}
            {showConfirmModal && (
                <AiCardModal
                    promptType={promptType}
                    generateArticle={generateArticle}
                    resetOutline={resetOutline}
                    cancelArticle={() => setShowConfirmModal(false)}
                />
            )}
            <div className={`ai-card ${dynamicMainClass()}`}>
                <div className="ai-card__content">
                    <div className={`ai-card-text ${promptType === 'article' && !!activeConfigurationStep ? 'ai-card-text--big' : ''}`}>
                        {showText()?.shownText}
                        <span className="blinking-dot"></span>
                    </div>
                    <AiCardOptions
                        configurationStep={activeConfigurationStep}
                        isOutline={isOutline}
                        outlineProgressStatus={outlineProgressStatus}
                        increaseConfigurationStep={increaseConfigurationStep}
                        decreaseConfigurationStep={decreaseConfigurationStep}
                        generateArticle={generateArticle}
                        resetOutline={resetOutline}
                        promptType={promptType}
                        articleCompleted={articleCompleted}
                        disableStopGenerating={disableStopGenerating}
                        stopGenerating={stopGenerating}
                        disableGeneratingArticle={disableContinueTooltip()}
                        regenerateCount={regenerateCount}
                        hasArticlesLeft={hasArticlesLeft}
                        activeStep={activeConfigurationStep}
                        activeStepHasSkip={returnActiveStepHasSkipOption()}
                        totalSteps={configurationSteps[promptType]}
                        lastStep={activeConfigurationStep === configurationSteps[promptType]}
                        activeStepName={returnActiveStep()}
                        hideBackButton={hideBackButton}
                    />
                </div>
                {activeStepIs('style') && activeConfigurationStep && (
                    <AiCardStyle />
                )}
                {activeStepIs('links') && activeConfigurationStep && (
                    <AiCardInternalLinks currentArticleId={currentArticleId}/>
                )}
                {activeStepIs('prompt') && activeConfigurationStep && (
                    <AiCardPrompt
                        promptType={promptType}
                        text={text}
                        handleTextareaChange={handleTextareaChange}
                        textareaRef={textareaRef}
                        aiMode={aiMode}
                        setAiMode={setAiMode}
                        selectedTestPrompt={selectedTestPrompt}
                        setSelectedTestPrompt={setSelectedTestPrompt}
                        selectedMarketSource={selectedMarketSource}
                        setSelectedMarketSource={setSelectedMarketSource}
                        isOutline={isOutline}
                    />
                )}
            </div>
        </div>
    );
};

export default AiCard;
