import React, {useEffect, useState} from 'react';
import AppModal from "../../Shared/AppModal";
import {SpinnerIcon} from "../../UI_utils/SVGIcons";

const ProcessingModal = ({processingTopic, isSuggested = true}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const maxProgress = 99;
        const increment = 10;
        const intervalTime = 12000;

        const intervalId = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress + increment >= maxProgress) {
                    clearInterval(intervalId); // Stop the interval when reaching 99
                    return maxProgress;
                }
                return prevProgress + increment;
            });
        }, intervalTime);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const title = () => `Processing keyword...${progress}%`;
    const text = `Gathering information on <strong>‘${processingTopic}’</strong> topic. It usually takes less than 2 minutes.`;

    return (
        <AppModal
            icon={SpinnerIcon}
            title={title()}
            text={text}
        />
    );
};

export default ProcessingModal