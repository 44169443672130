import React from "react";

export const SmallInfoIcon = React.forwardRef((props, ref) => (
    <svg
        ref={ref}
        width="13px"
        height="13px"
        viewBox="0 0 126 126"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
          id="Performance-Articles"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
      >
        <g
            id="1-overview"
            transform="translate(-1097.000000, -336.000000)"
            fill="#9A9FA5"
        >
          <g
              id="UI-icon/info/light"
              transform="translate(1097.000000, 336.000000)"
          >
            <path
                d="M63,113.4 C90.83529,113.4 113.4,90.83529 113.4,63 C113.4,35.164836 90.83529,12.6 63,12.6 C35.164836,12.6 12.6,35.164836 12.6,63 C12.6,90.83529 35.164836,113.4 63,113.4 Z M63,126 C97.79364,126 126,97.79364 126,63 C126,28.206045 97.79364,0 63,0 C28.206045,0 0,28.206045 0,63 C0,97.79364 28.206045,126 63,126 Z"
                id="Ellipse-134-(Stroke)"
            ></path>
            <path
                d="M64.5,50 C68.08982,50 71,52.877802 71,56.4277542 L71,88.5722458 C71,92.1221659 68.08982,95 64.5,95 C60.9101475,95 58,92.1221659 58,88.5722458 L58,56.4277542 C58,52.877802 60.9101475,50 64.5,50 Z"
                id="Path-51-(Stroke)"
            ></path>
            <path
                d="M71,39.5 C71,43.08982 68.08982,46 64.5,46 C60.91018,46 58,43.08982 58,39.5 C58,35.910115 60.91018,33 64.5,33 C68.08982,33 71,35.910115 71,39.5 Z"
                id="Ellipse-137"
                fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
));

export const CrossIcon = () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M7.98884 6.21429L11.0022 9.25781C11.394 9.61942 11.394 10.2221 11.0022 10.5837L10.3393 11.2467C9.97768 11.6384 9.375 11.6384 9.01339 11.2467L6 8.23326L2.95647 11.2467C2.59487 11.6384 1.99219 11.6384 1.63058 11.2467L0.967634 10.5837C0.575893 10.2221 0.575893 9.61942 0.967634 9.25781L3.98103 6.21429L0.967634 3.20089C0.575893 2.83929 0.575893 2.23661 0.967634 1.875L1.63058 1.21205C1.99219 0.820312 2.59487 0.820312 2.95647 1.21205L6 4.22545L9.01339 1.21205C9.375 0.820312 9.97768 0.820312 10.3393 1.21205L11.0022 1.875C11.394 2.23661 11.394 2.83929 11.0022 3.20089L7.98884 6.21429Z"
          fill="#171717"/>
    </svg>
);

export const CircleCrossIcon = () => (
    <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M0.857141 23.6429C0.857141 10.8962 11.1629 0.500004 24 0.500004C36.7466 0.500004 47.1429 10.8962 47.1429 23.6429C47.1429 36.4799 36.7466 46.7857 24 46.7857C11.1629 46.7857 0.857141 36.4799 0.857141 23.6429ZM16.6775 19.394L20.9263 23.6429L16.6775 27.8917C15.7734 28.7958 15.7734 30.1518 16.6775 30.9654C17.4911 31.8694 18.8471 31.8694 19.6607 30.9654L23.9096 26.7165L28.2489 30.9654C29.0625 31.8694 30.4185 31.8694 31.2321 30.9654C32.1362 30.1518 32.1362 28.7958 31.2321 27.8917L26.9833 23.6429L31.2321 19.394C32.1362 18.5804 32.1362 17.2243 31.2321 16.3203C30.4185 15.5067 29.0625 15.5067 28.2489 16.3203L23.9096 20.6596L19.6607 16.3203C18.8471 15.5067 17.4911 15.5067 16.6775 16.3203C15.7734 17.2243 15.7734 18.5804 16.6775 19.394Z"
          fill="#171717"/>
    </svg>
);

export const RefreshIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
          d="M9.52823 4.29435C8.84073 3.65121 7.93145 3.27419 6.97782 3.27419C5.27016 3.29637 3.78427 4.47177 3.38508 6.09073C3.34073 6.22379 3.22984 6.29032 3.11895 6.29032H1.85484C1.67742 6.29032 1.54435 6.15726 1.58871 5.97984C2.05444 3.42944 4.29435 1.5 7 1.5C8.46371 1.5 9.79435 2.09879 10.7923 3.03024L11.5907 2.23185C11.9234 1.89919 12.5 2.14315 12.5 2.60887V5.58065C12.5 5.89113 12.256 6.1129 11.9677 6.1129H8.97379C8.50806 6.1129 8.26411 5.55847 8.59677 5.22581L9.52823 4.29435ZM2.03226 7.8871H5.00403C5.46976 7.8871 5.71371 8.46371 5.38105 8.79637L4.4496 9.72782C5.1371 10.371 6.04637 10.748 7 10.748C8.70766 10.7258 10.1935 9.5504 10.5927 7.93145C10.6371 7.79839 10.748 7.73185 10.8589 7.73185H12.123C12.3004 7.73185 12.4335 7.86492 12.3891 8.04234C11.9234 10.5927 9.68347 12.5 7 12.5C5.51411 12.5 4.18347 11.9234 3.18548 10.9919L2.3871 11.7903C2.05444 12.123 1.5 11.879 1.5 11.4133V8.41935C1.5 8.13105 1.72177 7.8871 2.03226 7.8871Z"
          fill="#171717"/>
    </svg>
);

export const FileIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16" fill="none">
      <path
          d="M7.28516 4.25C7.28516 4.6875 7.59766 5 8.03516 5H12.2852V15.25C12.2852 15.6875 11.9414 16 11.5352 16H1.03516C0.597656 16 0.285156 15.6875 0.285156 15.25V0.75C0.285156 0.34375 0.597656 0 1.03516 0H7.28516V4.25ZM12.2852 3.8125V4H8.28516V0H8.47266C8.66016 0 8.84766 0.09375 9.00391 0.21875L12.0664 3.28125C12.1914 3.4375 12.2852 3.625 12.2852 3.8125Z"
          fill="#171717"/>
    </svg>
);

export const PlusIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
          d="M13.2321 5.59821H8.65179V1.01786C8.65179 0.477121 8.17467 0 7.63393 0H6.61607C6.04353 0 5.59821 0.477121 5.59821 1.01786V5.59821H1.01786C0.445313 5.59821 0 6.07534 0 6.61607V7.63393C0 8.20647 0.445313 8.65179 1.01786 8.65179H5.59821V13.2321C5.59821 13.8047 6.04353 14.25 6.61607 14.25H7.63393C8.17467 14.25 8.65179 13.8047 8.65179 13.2321V8.65179H13.2321C13.7729 8.65179 14.25 8.20647 14.25 7.63393V6.61607C14.25 6.07534 13.7729 5.59821 13.2321 5.59821Z"
          fill="#171717"/>
    </svg>
);

export const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
          d="M11.8359 10.8828C12.0469 11.1172 12.0469 11.4688 11.8125 11.6797L11.1562 12.3359C10.9453 12.5703 10.5938 12.5703 10.3594 12.3359L8.03906 10.0156C7.92188 9.89844 7.875 9.75781 7.875 9.61719V9.21875C7.03125 9.875 6 10.25 4.875 10.25C2.17969 10.25 0 8.07031 0 5.375C0 2.70312 2.17969 0.5 4.875 0.5C7.54688 0.5 9.75 2.70312 9.75 5.375C9.75 6.52344 9.35156 7.55469 8.71875 8.375H9.09375C9.23438 8.375 9.375 8.44531 9.49219 8.53906L11.8359 10.8828ZM4.875 8.375C6.51562 8.375 7.875 7.03906 7.875 5.375C7.875 3.73438 6.51562 2.375 4.875 2.375C3.21094 2.375 1.875 3.73438 1.875 5.375C1.875 7.03906 3.21094 8.375 4.875 8.375Z"
          fill="#171717"/>
    </svg>
);


export const CountdownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
      <path
          d="M7.44531 0.6875C7.71191 0.6875 7.9375 0.913086 7.9375 1.17969V1.50781C7.9375 1.79492 7.71191 2 7.44531 2C7.44531 3.86621 6.37891 5.44531 4.96387 5.9375C6.37891 6.4502 7.44531 8.0293 7.44531 9.875C7.71191 9.875 7.9375 10.1006 7.9375 10.3672V10.6953C7.9375 10.9824 7.71191 11.1875 7.44531 11.1875H0.554688C0.267578 11.1875 0.0625 10.9824 0.0625 10.6953V10.3672C0.0625 10.1006 0.267578 9.875 0.554688 9.875C0.554688 8.0293 1.60059 6.4502 3.01562 5.9375C1.60059 5.44531 0.554688 3.86621 0.554688 2C0.267578 2 0.0625 1.79492 0.0625 1.50781V1.17969C0.0625 0.913086 0.267578 0.6875 0.554688 0.6875H7.44531ZM5.88672 8.5625C5.53809 7.61914 4.82031 6.92188 4 6.92188C3.15918 6.92188 2.44141 7.61914 2.09277 8.5625H5.88672ZM5.88672 3.3125C6.05078 2.92285 6.13281 2.47168 6.13281 2H1.86719C1.86719 2.47168 1.92871 2.92285 2.09277 3.3125H5.88672Z"
          fill="#171717"/>
    </svg>
);


// export const CheckIcon = () => (
//     <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
//       <path
//           d="M3.66569 6.85659C3.84815 7.03905 4.15834 7.03905 4.3408 6.85659L9.70514 1.49225C9.88761 1.30979 9.88761 0.999602 9.70514 0.817142L9.04829 0.160283C8.86583 -0.0221777 8.57389 -0.0221777 8.39143 0.160283L4.01237 4.53934L1.95056 2.49578C1.7681 2.31332 1.47616 2.31332 1.2937 2.49578L0.636846 3.15264C0.454385 3.3351 0.454385 3.64528 0.636846 3.82774L3.66569 6.85659Z"
//           fill="#171717"/>
//     </svg>
// );


export const CheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M6.47341 12.4075C6.68193 12.6161 7.03643 12.6161 7.24495 12.4075L13.3756 6.27684C13.5842 6.06832 13.5842 5.71382 13.3756 5.5053L12.6249 4.7546C12.4164 4.54607 12.0828 4.54607 11.8742 4.7546L6.86961 9.75924L4.51326 7.42374C4.30473 7.21521 3.97109 7.21521 3.76256 7.42374L3.01186 8.17444C2.80334 8.38296 2.80334 8.73746 3.01186 8.94598L6.47341 12.4075Z"
            fill="#171717"/>
    </svg>

);


export const ThinCheckIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="31" viewBox="0 0 42 31" fill="none">
        <path
            d="M40.346 2.08259C41.5212 3.16741 41.5212 5.06585 40.346 6.15067L17.2031 29.2935C16.1183 30.4688 14.2199 30.4688 13.135 29.2935L1.56361 17.7221C0.388391 16.6373 0.388391 14.7388 1.56361 13.654C2.64844 12.4788 4.54687 12.4788 5.63169 13.654L15.1239 23.1462L36.2779 2.08259C37.3627 0.907369 39.2612 0.907369 40.346 2.08259Z"
            fill="#171717"/>
    </svg>
);


export const SpinnerIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"
         className="spinner-icon">
        <path
            d="M6.65625 1.50781C6.65625 1.17969 6.94336 0.933594 7.25098 1.01562C9.44531 1.58984 11.0859 3.5791 11.0859 5.9375C11.0859 8.74707 8.80957 11.0234 6 11.0234C3.19043 11.0439 0.914062 8.78809 0.914062 5.97852C0.893555 3.59961 2.51367 1.58984 4.70801 1.01562C5.03613 0.933594 5.34375 1.17969 5.34375 1.50781V1.83594C5.34375 2.06152 5.17969 2.24609 4.97461 2.30762C3.375 2.75879 2.22656 4.21484 2.22656 5.9375C2.22656 8.0293 3.9082 9.71094 6 9.71094C8.07129 9.71094 9.77344 8.0293 9.77344 5.9375C9.77344 4.21484 8.60449 2.75879 7.00488 2.30762C6.7998 2.24609 6.65625 2.06152 6.65625 1.83594V1.50781Z"
          fill="#171717"/>
    </svg>
);


export const ChevronRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
          d="M9.92969 7.64844C10.1406 7.4375 10.1406 7.08594 9.92969 6.875L5.38281 2.30469C5.14844 2.09375 4.79688 2.09375 4.58594 2.30469L4.04688 2.84375C3.83594 3.05469 3.83594 3.40625 4.04688 3.64062L7.65625 7.25L4.04688 10.8828C3.83594 11.1172 3.83594 11.4688 4.04688 11.6797L4.58594 12.2188C4.79688 12.4297 5.14844 12.4297 5.38281 12.2188L9.92969 7.64844Z"
          fill="#171717"/>
    </svg>
);

export const ChevronLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path d="M3.79688 7.125L8.34375 2.55469C8.57812 2.34375 8.92969 2.34375 9.14062 2.55469L9.67969 3.09375C9.89062 3.30469 9.89062 3.65625 9.67969 3.89062L6.07031 7.5L9.67969 11.1328C9.89062 11.3672 9.89062 11.7188 9.67969 11.9297L9.14062 12.4688C8.92969 12.6797 8.57812 12.6797 8.34375 12.4688L3.79688 7.89844C3.58594 7.6875 3.58594 7.33594 3.79688 7.125Z"
            fill="black"/>
    </svg>
);

export const CogIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M14.8668 9.84679C15.0223 9.94011 15.0845 10.1267 15.0534 10.2823C14.6802 11.4021 14.1203 12.3974 13.3426 13.2373C13.2182 13.3617 13.0316 13.3928 12.8761 13.2995L11.5385 12.5529C10.9786 13.0195 10.3565 13.3928 9.67223 13.6416V15.1658C9.67223 15.3213 9.5478 15.4768 9.36117 15.5391C8.27249 15.7568 7.1216 15.7879 5.9707 15.5391C5.81517 15.4768 5.69075 15.3213 5.69075 15.1658V13.6416C4.97533 13.3928 4.35323 13.0195 3.79333 12.5529L2.45581 13.2995C2.30028 13.3928 2.11365 13.3617 1.98923 13.2373C1.2427 12.3974 0.651703 11.4021 0.309545 10.2823C0.247334 10.1267 0.309545 9.94011 0.465071 9.84679L1.8026 9.10027C1.67818 8.35374 1.67818 7.63832 1.8026 6.89179L0.465071 6.14527C0.309545 6.05195 0.247334 5.86532 0.309545 5.70979C0.651703 4.59 1.2427 3.59464 1.98923 2.7548C2.11365 2.63037 2.30028 2.59927 2.45581 2.69258L3.79333 3.47022C4.35323 2.97253 4.97533 2.59927 5.69075 2.35043V0.82627C5.69075 0.670743 5.78407 0.515217 5.9707 0.484112C7.05938 0.23527 8.21028 0.204164 9.36117 0.484112C9.5478 0.515217 9.67223 0.670743 9.67223 0.82627V2.35043C10.3565 2.59927 10.9786 2.97253 11.5385 3.43911L12.8761 2.69258C13.0316 2.59927 13.2182 2.63037 13.3426 2.7548C14.0892 3.59464 14.6802 4.59 15.0223 5.70979C15.0845 5.86532 15.0223 6.05195 14.8668 6.14527L13.5293 6.89179C13.6848 7.63832 13.6848 8.35374 13.5293 9.10027L14.8668 9.84679ZM7.68149 10.4689C9.05012 10.4689 10.1699 9.38021 10.1699 7.98048C10.1699 6.61185 9.05012 5.49206 7.68149 5.49206C6.28175 5.49206 5.19307 6.61185 5.19307 7.98048C5.19307 9.38021 6.28175 10.4689 7.68149 10.4689Z" fill="#171717"/>
    </svg>
);

export const AlertInfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
          d="M47.1428 24C47.1428 36.7846 36.6912 47.1428 24 47.1428C11.2154 47.1428 0.857132 36.7846 0.857132 24C0.857132 11.3088 11.2154 0.85714 24 0.85714C36.6912 0.85714 47.1428 11.3088 47.1428 24ZM24 28.6659C21.5737 28.6659 19.7074 30.6256 19.7074 32.9585C19.7074 35.3848 21.5737 37.2511 24 37.2511C26.3329 37.2511 28.2926 35.3848 28.2926 32.9585C28.2926 30.6256 26.3329 28.6659 24 28.6659ZM19.894 13.2684L20.5472 25.9597C20.6405 26.6129 21.1071 26.9862 21.667 26.9862H26.2396C26.7995 26.9862 27.2661 26.6129 27.3594 25.9597L28.0127 13.2684C28.106 12.6152 27.5461 12.0553 26.8928 12.0553H21.0138C20.3606 12.0553 19.8007 12.6152 19.894 13.2684Z"
          fill="#171717"/>
    </svg>
)

export const AlertInfoLightIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M8.00223 1.34857C11.6451 1.34857 14.6451 4.34857 14.6451 7.99143C14.6451 11.6611 11.6451 14.6343 8.00223 14.6343C4.33259 14.6343 1.35938 11.6611 1.35938 7.99143C1.35938 4.34857 4.33259 1.34857 8.00223 1.34857ZM8.00223 13.3486C10.9487 13.3486 13.3594 10.9646 13.3594 7.99143C13.3594 5.045 10.9487 2.63429 8.00223 2.63429C5.02902 2.63429 2.64509 5.045 2.64509 7.99143C2.64509 10.9646 5.02902 13.3486 8.00223 13.3486ZM9.12723 10.5629C9.12723 11.2057 8.6183 11.6879 8.00223 11.6879C7.35938 11.6879 6.87723 11.2057 6.87723 10.5629C6.87723 9.94679 7.35938 9.43786 8.00223 9.43786C8.6183 9.43786 9.12723 9.94679 9.12723 10.5629ZM6.9308 4.91107C6.9308 4.72357 7.06473 4.56286 7.25223 4.56286H8.72545C8.91295 4.56286 9.04688 4.72357 9.04688 4.91107L8.85938 8.55393C8.85938 8.71464 8.69866 8.84857 8.53795 8.84857H7.43973C7.27902 8.84857 7.1183 8.71464 7.1183 8.55393L6.9308 4.91107Z"
            fill="black"/>
    </svg>
)

export const HeadingIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM3.71875 3.28125V10.5H5.69318V7.68018H8.30682V10.5H10.2812V3.28125H8.30682V6.10107H5.69318V3.28125H3.71875Z"
            fill="#171717"/>
    </svg>
)

export const TitleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM3.71875 3.5V5.1748H5.98982V11.1562H8.01018V5.1748H10.2812V3.5H3.71875Z"
            fill="#171717"/>
    </svg>
)

export const CoverageIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM8.55611 6.03054H10.6591C10.6193 5.55575 10.5062 5.13441 10.3198 4.76651C10.1333 4.39861 9.88725 4.08913 9.5815 3.83807C9.27575 3.58452 8.92028 3.39187 8.51509 3.26012C8.10991 3.12837 7.66619 3.0625 7.18395 3.0625C6.49787 3.0625 5.87642 3.21289 5.3196 3.51367C4.76278 3.81197 4.32031 4.2532 3.99219 4.83736C3.66406 5.41903 3.5 6.13494 3.5 6.98509C3.5 7.83026 3.66033 8.54492 3.981 9.12908C4.30415 9.71325 4.7429 10.1557 5.29723 10.4565C5.85405 10.7573 6.48295 10.9077 7.18395 10.9077C7.74325 10.9077 8.2342 10.8219 8.65678 10.6504C9.07937 10.4789 9.43359 10.2527 9.71946 9.97177C10.0078 9.68839 10.2303 9.38015 10.3869 9.04705C10.5435 8.71147 10.6342 8.3821 10.6591 8.05895L8.55611 8.04403C8.53125 8.2081 8.48278 8.356 8.41069 8.48775C8.34109 8.61701 8.25035 8.72763 8.13849 8.8196C8.02663 8.91158 7.89489 8.98242 7.74325 9.03214C7.59162 9.07937 7.4201 9.10298 7.22869 9.10298C6.89311 9.10298 6.606 9.02468 6.36737 8.86808C6.12873 8.70898 5.94602 8.47283 5.81925 8.15962C5.69496 7.84393 5.63281 7.45242 5.63281 6.98509C5.63281 6.54261 5.69372 6.16353 5.81552 5.84783C5.93981 5.53214 6.12127 5.28977 6.35991 5.12074C6.60103 4.9517 6.8956 4.86719 7.24361 4.86719C7.44247 4.86719 7.62021 4.89577 7.77681 4.95295C7.93342 5.00764 8.06641 5.08718 8.17578 5.19158C8.28764 5.2935 8.37465 5.41531 8.43679 5.557C8.50142 5.69869 8.54119 5.85653 8.55611 6.03054Z"
            fill="#171717"/>
    </svg>
)

export const TrashIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
          d="M1.5 11.375V3.5H10.5V11.375C10.5 12.0078 9.98438 12.5 9.375 12.5H2.625C1.99219 12.5 1.5 12.0078 1.5 11.375ZM7.875 5.375V10.625C7.875 10.8359 8.03906 11 8.25 11C8.4375 11 8.625 10.8359 8.625 10.625V5.375C8.625 5.1875 8.4375 5 8.25 5C8.03906 5 7.875 5.1875 7.875 5.375ZM5.625 5.375V10.625C5.625 10.8359 5.78906 11 6 11C6.1875 11 6.375 10.8359 6.375 10.625V5.375C6.375 5.1875 6.1875 5 6 5C5.78906 5 5.625 5.1875 5.625 5.375ZM3.375 5.375V10.625C3.375 10.8359 3.53906 11 3.75 11C3.9375 11 4.125 10.8359 4.125 10.625V5.375C4.125 5.1875 3.9375 5 3.75 5C3.53906 5 3.375 5.1875 3.375 5.375ZM10.875 1.25C11.0625 1.25 11.25 1.4375 11.25 1.625V2.375C11.25 2.58594 11.0625 2.75 10.875 2.75H1.125C0.914062 2.75 0.75 2.58594 0.75 2.375V1.625C0.75 1.4375 0.914062 1.25 1.125 1.25H3.9375L4.14844 0.828125C4.24219 0.640625 4.42969 0.5 4.64062 0.5H7.33594C7.54688 0.5 7.73438 0.640625 7.82812 0.828125L8.0625 1.25H10.875Z"/>
    </svg>
)

// export const SpinnerIcon = () => {
//   <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
//       <path xmlns="http://www.w3.org/2000/svg" d="M7.7427 1.60958C7.7427 1.3752 7.95364 1.21114 8.18801 1.25802C10.813 1.79708 12.8052 4.14083 12.8052 6.92989C12.8052 10.1408 10.2036 12.7424 6.9927 12.7424C3.78176 12.7658 1.1802 10.1877 1.1802 6.97677C1.15676 4.16427 3.12551 1.82052 5.77395 1.25802C6.00832 1.21114 6.2427 1.3752 6.2427 1.60958V2.00802C6.2427 2.17208 6.10207 2.33614 5.93801 2.35958C3.85207 2.85177 2.3052 4.70333 2.3052 6.92989C2.3052 9.53145 4.39114 11.6174 6.9927 11.6174C9.57082 11.6174 11.6802 9.53145 11.6802 6.92989C11.6802 4.70333 10.1099 2.85177 8.02395 2.35958C7.85989 2.33614 7.7427 2.17208 7.7427 2.00802V1.60958Z"/>
//   </svg>
// }

export const SerpBackgroundIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="97" height="120" viewBox="0 0 97 120" fill="none">
      <path
          d="M60 0C26.8548 0 0 27.0968 0 60C0 93.1452 26.8548 120 60 120C92.9032 120 120 93.1452 120 60C120 27.0968 92.9032 0 60 0ZM60 108.387C33.1452 108.387 11.6129 86.8548 11.6129 60C11.6129 33.3871 33.1452 11.6129 60 11.6129C86.6129 11.6129 108.387 33.3871 108.387 60C108.387 86.8548 86.6129 108.387 60 108.387ZM85.8871 46.6935C85.8871 34.5968 73.0645 25.1613 61.2097 25.1613C49.8387 25.1613 42.5806 30 37.0161 38.4677C36.0484 39.6774 36.2903 41.371 37.5 42.3387L44.2742 47.4194C45.4839 48.3871 47.4194 48.1452 48.3871 46.9355C52.0161 42.3387 54.6774 39.6774 60.2419 39.6774C64.5968 39.6774 69.9193 42.3387 69.9193 46.6935C69.9193 49.8387 67.2581 51.2903 62.9032 53.7097C58.0645 56.6129 51.5323 60 51.5323 68.7097V70.6452C51.5323 72.3387 52.7419 73.5484 54.4355 73.5484H65.3226C67.0161 73.5484 68.2258 72.3387 68.2258 70.6452V69.1936C68.2258 63.1452 85.8871 62.9032 85.8871 46.6935ZM70.1613 87.0968C70.1613 81.5323 65.5645 76.9355 60 76.9355C54.1935 76.9355 49.8387 81.5323 49.8387 87.0968C49.8387 92.9032 54.1935 97.2581 60 97.2581C65.5645 97.2581 70.1613 92.9032 70.1613 87.0968Z"
          fill="#171717" fillOpacity="0.05"/>
    </svg>
)

export const WriterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="138" height="138" viewBox="0 0 138 138" fill="none">
      <path
          d="M60.4289 26.1429L56.1432 17.5714L47.5718 13.2857L56.1432 9L60.4289 0.428574L64.7146 9L73.2861 13.2857L64.7146 17.5714L60.4289 26.1429ZM21.8575 43.2857L14.6253 29.0893L0.428922 21.8571L14.6253 14.8929L21.8575 0.428574L28.8218 14.8929L43.2861 21.8571L28.8218 29.0893L21.8575 43.2857ZM116.143 77.5714L123.107 92.0357L137.572 99L123.107 106.232L116.143 120.429L108.911 106.232L94.7146 99L108.911 92.0357L116.143 77.5714Z"
          fill="#745ECA" fillOpacity="0.08"/>
      <path
          d="M133.554 38.7321L121.232 51.0536C120.161 52.125 118.018 52.125 116.679 51.0536L86.9468 21.3214C85.8753 19.9821 85.8753 17.8393 86.9468 16.7679L99.2682 4.44643C104.357 -0.642855 112.661 -0.642855 117.482 4.44643L133.554 20.5179C138.643 25.3393 138.643 33.6429 133.554 38.7321ZM76.5003 27.2143C77.5718 26.1429 79.7146 26.1429 81.0539 27.2143L110.786 56.9464C112.125 58.2857 112.125 60.4286 110.786 61.5L40.3396 131.946L7.92891 137.571C3.37534 138.375 -0.374658 134.625 0.428914 130.071L6.05391 97.6607L76.5003 27.2143ZM24.0003 114V101.143H14.0896L11.1432 118.554L19.4468 126.857L36.8575 123.911V114H24.0003Z"
          fill="#745ECA" fillOpacity="0.08"/>
    </svg>
)

export const MissingPageCountIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g opacity="0.3">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M12 3.42857C12 1.53502 13.535 0 15.4286 0H21.7403V3.49091H15.4286V9.56033H12V3.42857ZM28.8312 0H37.5672L41.8529 4.36364L39.4286 6.83208L36.8571 4.2139V6.58512H33.4286V3.49091H28.8312V0ZM43.7143 6.25883L48 10.6225V19.3587H44.5714V14.8364H41.5325V11.3455H43.8613L41.2899 8.72727L43.7143 6.25883ZM36.8571 8.25124V11.3455H39.8961V14.8364H33.4286V8.25124H36.8571ZM15.4286 16.1851V31.8149H12V16.1851H15.4286ZM48 23.405V35.9405H44.5714V23.405H48ZM15.4286 38.4397V44.5091H19.8701V48H15.4286C13.535 48 12 46.465 12 44.5714V38.4397H15.4286ZM48 39.9868V44.5714C48 46.465 46.465 48 44.5714 48H40.1299V44.5091H44.5714V39.9868H48ZM23.8442 44.5091H36.1558V48H23.8442V44.5091Z"
              fill="#171717"/>
        <path
            d="M0 18.8571C0 15.07 3.07005 12 6.85714 12H20.5714C24.3585 12 27.4286 15.07 27.4286 18.8571V32.5714C27.4286 36.3585 24.3585 39.4286 20.5714 39.4286H6.85714C3.07005 39.4286 0 36.3585 0 32.5714V18.8571Z"
            fill="#171717"/>
        <path d="M15.2816 19.8368V31.5919H12.1469V19.8368H15.2816Z" fill="white"/>
      </g>
    </svg>
)

export const WrongIntentCountIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g opacity="0.3">
        <path
            d="M18.5811 35.7166L12.6911 40.9501C12.1891 41.3977 11.3524 41.0189 11.3524 40.2959L11.3524 37.5414C5.99791 37.507 1.71429 36.3708 1.71429 31.1373C1.71429 29.037 3.05292 26.9367 4.52542 25.8349C4.96047 25.4906 5.62979 25.9382 5.46246 26.4891C3.9565 31.4816 6.16524 32.7899 11.3524 32.8588L11.3524 29.8633C11.3524 29.1403 12.1891 28.7615 12.6911 29.2091L18.581 34.4426C18.9492 34.7869 18.9492 35.3723 18.5811 35.7166Z"
            fill="#171717"/>
        <path
            d="M0 6.85714C0 3.07005 3.07005 0 6.85714 0H20.5714C24.3585 0 27.4286 3.07005 27.4286 6.85714V20.5714C27.4286 24.3585 24.3585 27.4286 20.5714 27.4286H6.85714C3.07005 27.4286 0 24.3585 0 20.5714V6.85714Z"
            fill="white"/>
        <path
            d="M0 6.85714C0 3.07005 3.07005 0 6.85714 0H20.5714C24.3585 0 27.4286 3.07005 27.4286 6.85714V20.5714C27.4286 24.3585 24.3585 27.4286 20.5714 27.4286H6.85714C3.07005 27.4286 0 24.3585 0 20.5714V6.85714Z"
            fill="#171717" fillOpacity="0.3"/>
        <path
            d="M19.0779 12.284H15.9268C15.9044 12.0233 15.8448 11.7868 15.748 11.5745C15.6549 11.3622 15.5245 11.1797 15.3569 11.0269C15.193 10.8705 14.9937 10.7513 14.7591 10.6694C14.5244 10.5837 14.2581 10.5409 13.9601 10.5409C13.4387 10.5409 12.9973 10.6675 12.636 10.9208C12.2784 11.1741 12.0065 11.5372 11.8203 12.0103C11.6378 12.4833 11.5465 13.0513 11.5465 13.7143C11.5465 14.4145 11.6397 15.0012 11.8259 15.4742C12.0159 15.9435 12.2896 16.2974 12.6472 16.5358C13.0048 16.7704 13.435 16.8877 13.9378 16.8877C14.2246 16.8877 14.4816 16.8524 14.7088 16.7816C14.936 16.7071 15.1334 16.6009 15.301 16.4631C15.4686 16.3253 15.6046 16.1596 15.7089 15.9659C15.8169 15.7685 15.8895 15.5469 15.9268 15.301L19.0779 15.3234C19.0406 15.8076 18.9047 16.3011 18.67 16.8039C18.4353 17.303 18.102 17.7649 17.6699 18.1895C17.2416 18.6104 16.7108 18.9494 16.0776 19.2064C15.4444 19.4634 14.7088 19.5919 13.8707 19.5919C12.8204 19.5919 11.878 19.3665 11.0437 18.9158C10.2131 18.4651 9.5557 17.8021 9.07149 16.9268C8.591 16.0515 8.35076 14.9807 8.35076 13.7143C8.35076 12.4405 8.59659 11.3678 9.08825 10.4962C9.57991 9.62088 10.2429 8.95975 11.0772 8.51279C11.9116 8.0621 12.8427 7.83676 13.8707 7.83676C14.5933 7.83676 15.2582 7.93546 15.8653 8.13287C16.4724 8.33028 17.0051 8.61894 17.4632 8.99886C17.9213 9.37505 18.2901 9.83878 18.5694 10.39C18.8488 10.9413 19.0183 11.5726 19.0779 12.284Z"
            fill="white"/>
        <path
            d="M20.5714 27.4286C20.5714 23.6415 23.6415 20.5714 27.4286 20.5714H41.1429C44.93 20.5714 48 23.6415 48 27.4286V41.1429C48 44.93 44.93 48 41.1429 48H27.4286C23.6415 48 20.5714 44.93 20.5714 41.1429V27.4286Z"
            fill="#171717"/>
        <path d="M35.853 28.4082V40.1633H32.7183V28.4082H35.853Z" fill="white"/>
      </g>
    </svg>
)

export const InformationalKeywordsCountIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g opacity="0.3">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M48 12.375C48 11.25 47.4375 10.0312 46.5938 9.1875L38.8125 1.3125C37.9688 0.46875 36.75 0 35.625 0H16.5C13.9688 0 12 2.0625 12 4.5V15.4286H15V4.5C15 3.75 15.6562 3 16.5 3H33V12.75C33 14.0625 33.9375 15 35.25 15H45V43.5C45 44.3438 44.25 45 43.5 45H16.5C15.6562 45 15 44.3438 15 43.5V39.4286H12V43.5C12 46.0312 13.9688 48 16.5 48H43.5C45.9375 48 48 46.0312 48 43.5V12.375ZM36.6562 3.46875L44.5312 11.3438C44.6745 11.487 44.763 11.6849 44.8386 11.854C44.862 11.9062 44.8841 11.9557 44.9062 12H36V3.09375C36.0443 3.11588 36.0938 3.13801 36.146 3.16138C36.3151 3.23699 36.513 3.32551 36.6562 3.46875Z"
              fill="#171717"/>
        <path
            d="M24.9643 25.8214C25.4464 26.625 25.7143 27.4286 25.7143 28.5C25.7143 30.8571 23.6786 33.0536 21.1071 33.0536H19.125C19.3929 33.75 19.6071 34.5536 19.6071 35.5714C19.6071 39.4821 17.5714 41.1429 14.5179 41.1429C11.1964 41.1429 11.4107 36.1071 10.6607 35.3571C9.42857 34.125 7.98214 31.7679 6.96429 30.8571H6.58929C6.32143 31.3929 5.73214 31.7143 5.14286 31.7143H1.71429C0.75 31.7143 0 30.9643 0 30V17.1429C0 16.2321 0.75 15.4286 1.71429 15.4286H5.14286C5.57143 15.4286 5.94643 15.6429 6.26786 15.8571H6.85714C9 15.8571 10.7679 13.7679 16.2857 13.7679C16.7143 13.7679 17.1429 13.7143 17.5179 13.7143C21.6429 13.7143 23.4643 15.8571 23.5179 18.8571C24.2679 19.8214 24.6429 21.1607 24.4821 22.4464C25.0179 23.4107 25.1786 24.5893 24.9643 25.8214ZM3.42857 29.5714C4.125 29.5714 4.71429 29.0357 4.71429 28.2857C4.71429 27.5893 4.125 27 3.42857 27C2.67857 27 2.14286 27.5893 2.14286 28.2857C2.14286 29.0357 2.67857 29.5714 3.42857 29.5714ZM21.1071 30.4821C22.2321 30.4821 23.0893 29.4643 23.1429 28.4464C23.1429 27.4821 22.4464 26.4643 21.9107 26.4643C22.6607 25.6607 22.8214 24 21.6429 22.9286C22.3393 21.8036 21.6964 20.3036 20.8929 19.8214C21.3214 17.25 19.9286 16.3393 18.0536 16.3393H16.0179C12.2143 16.3393 9.69643 18.3214 6.85714 18.3214V28.2857H7.39286C8.94643 28.2857 11.0357 32.0893 12.4821 33.5357C13.9821 35.0357 13.5 37.6071 14.5179 38.5714C17.0357 38.5714 17.0357 36.8571 17.0357 35.5714C17.0357 33.4821 15.5357 32.5179 15.5357 30.4821H21.1071Z"
            fill="#171717"/>
      </g>
    </svg>
)

export const PagesServingTooManyKeywordsCountIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="48" viewBox="0 0 49 48" fill="none">
      <g opacity="0.3">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M49 12.375C49 11.25 48.4375 10.0312 47.5938 9.1875L39.8125 1.3125C38.9688 0.46875 37.75 0 36.625 0H17.5C14.9688 0 13 2.0625 13 4.5V15.4286H16V4.5C16 3.75 16.6562 3 17.5 3H34V12.75C34 14.0625 34.9375 15 36.25 15H46V43.5C46 44.3438 45.25 45 44.5 45H17.5C16.6562 45 16 44.3438 16 43.5V37.7143H13V43.5C13 46.0312 14.9688 48 17.5 48H44.5C46.9375 48 49 46.0312 49 43.5V12.375ZM37.6562 3.46875L45.5312 11.3438C45.6745 11.487 45.763 11.6849 45.8386 11.854C45.862 11.9062 45.8841 11.9557 45.9062 12H37V3.09375C37.0443 3.11588 37.0938 3.13801 37.146 3.16138C37.3151 3.23699 37.513 3.32551 37.6562 3.46875ZM40 22.875V22.125C40 21.5625 39.4375 21 38.875 21H23.125C22.4688 21 22 21.5625 22 22.125V22.875C22 23.5312 22.4688 24 23.125 24H38.875C39.4375 24 40 23.5312 40 22.875ZM40 28.875V28.125C40 27.5625 39.4375 27 38.875 27H23.125C22.4688 27 22 27.5625 22 28.125V28.875C22 29.5312 22.4688 30 23.125 30H38.875C39.4375 30 40 29.5312 40 28.875ZM40 34.875V34.125C40 33.5625 39.4375 33 38.875 33H23.125C22.4688 33 22 33.5625 22 34.125V34.875C22 35.5312 22.4688 36 23.125 36H38.875C39.4375 36 40 35.5312 40 34.875Z"
              fill="#171717"/>
        <path
            d="M31.4821 33.9107C32.4464 35.625 31.2143 37.7143 29.2321 37.7143H3.57142C1.58928 37.7143 0.357135 35.5714 1.32142 33.9107L14.1786 11.5714C15.1428 9.85714 17.6607 9.91072 18.625 11.5714L31.4821 33.9107ZM16.4286 29.25C15.0357 29.25 13.9643 30.375 13.9643 31.7143C13.9643 33.1071 15.0357 34.1786 16.4286 34.1786C17.7678 34.1786 18.8928 33.1071 18.8928 31.7143C18.8928 30.375 17.7678 29.25 16.4286 29.25ZM14.0714 20.4107L14.4464 27.6964C14.5 28.0714 14.7678 28.2857 15.0893 28.2857H17.7143C18.0357 28.2857 18.3036 28.0714 18.3571 27.6964L18.7321 20.4107C18.7857 20.0357 18.4643 19.7143 18.0893 19.7143H14.7143C14.3393 19.7143 14.0178 20.0357 14.0714 20.4107Z"
            fill="#171717"/>
      </g>
    </svg>
)

export const CloseIcon = ({onClick}) => (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
          d="M8.17216 6.15322L11.1856 9.19674C11.5773 9.55835 11.5773 10.161 11.1856 10.5226L10.5226 11.1856C10.161 11.5773 9.55832 11.5773 9.19671 11.1856L6.18332 8.17219L3.13979 11.1856C2.77819 11.5773 2.17551 11.5773 1.8139 11.1856L1.15095 10.5226C0.759212 10.161 0.759212 9.55835 1.15095 9.19674L4.16435 6.15322L1.15095 3.13982C0.759212 2.77822 0.759212 2.17554 1.15095 1.81393L1.8139 1.15098C2.17551 0.759242 2.77819 0.759242 3.13979 1.15098L6.18332 4.16438L9.19671 1.15098C9.55832 0.759242 10.161 0.759242 10.5226 1.15098L11.1856 1.81393C11.5773 2.17554 11.5773 2.77822 11.1856 3.13982L8.17216 6.15322Z"
          fill="#171717"/>
    </svg>
)

export const MagnifierMinus = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="47" viewBox="0 0 48 47" fill="none">
      <path
          d="M29.0625 18.2188V20.3884C29.0625 21.0212 28.5201 21.4732 27.9777 21.4732H11.3437C10.7109 21.4732 10.2589 21.0212 10.2589 20.3884V18.2188C10.2589 17.6763 10.7109 17.1339 11.3437 17.1339H27.9777C28.5201 17.1339 29.0625 17.6763 29.0625 18.2188ZM46.7812 44.4353L44.702 46.5145C44.3404 46.9665 43.6172 46.9665 43.1652 46.5145L32.2266 35.5759C32.0458 35.3047 31.9554 35.0335 31.9554 34.7623V33.5871C28.6105 36.3895 24.3616 38.1071 19.6607 38.1071C9.26451 38.1071 0.857141 29.6998 0.857141 19.3036C0.857141 8.99777 9.26451 0.500004 19.6607 0.500004C29.9665 0.500004 38.4643 8.99777 38.4643 19.3036C38.4643 24.0045 36.6562 28.3438 33.8538 31.5982H35.029C35.3002 31.5982 35.5714 31.779 35.8426 31.9598L46.7812 42.8984C47.2333 43.3504 47.2333 44.0737 46.7812 44.4353ZM34.125 19.3036C34.125 11.3482 27.6161 4.83929 19.6607 4.83929C11.615 4.83929 5.19643 11.3482 5.19643 19.3036C5.19643 27.3493 11.615 33.7679 19.6607 33.7679C27.6161 33.7679 34.125 27.3493 34.125 19.3036Z"
          fill="#171717"/>
    </svg>
)
export const ChevronDown = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
      <path
          d="M5.54464 7.76116C5.78571 8.00224 6.1875 8.00224 6.42857 7.76116L11.6518 2.56474C11.8929 2.29688 11.8929 1.89509 11.6518 1.65402L11.0357 1.03795C10.7946 0.796879 10.3929 0.796879 10.125 1.03795L6 5.16295L1.84821 1.03795C1.58036 0.796879 1.17857 0.796879 0.9375 1.03795L0.321429 1.65402C0.0803571 1.89509 0.0803571 2.29688 0.321429 2.56474L5.54464 7.76116Z"
          fill="#171717"/>
    </svg>
)

export const ChevronUp = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
      <path
          d="M7.482 0.667688C7.2108 0.396482 6.75879 0.396482 6.48758 0.667688L0.611467 6.51367C0.340262 6.81501 0.340262 7.26702 0.611467 7.53822L1.30455 8.2313C1.57575 8.50251 2.02776 8.50251 2.3291 8.2313L6.96973 3.59068L11.6405 8.2313C11.9418 8.50251 12.3938 8.50251 12.665 8.2313L13.3581 7.53822C13.6293 7.26702 13.6293 6.81501 13.3581 6.51367L7.482 0.667688Z"
          fill="#171717"/>
    </svg>
)

export const PlusCircle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
          d="M7.99986 0.57143C12.1035 0.57143 15.4284 3.89632 15.4284 8C15.4284 12.1037 12.1035 15.4286 7.99986 15.4286C3.89617 15.4286 0.571289 12.1037 0.571289 8C0.571289 3.89632 3.89617 0.57143 7.99986 0.57143ZM12.3132 8.83871V7.16129C12.3132 6.98157 12.1335 6.80185 11.9538 6.80185H9.19802V4.04608C9.19802 3.86636 9.01829 3.68664 8.83857 3.68664H7.16115C6.95147 3.68664 6.8017 3.86636 6.8017 4.04608V6.80185H4.04594C3.83627 6.80185 3.6865 6.98157 3.6865 7.16129V8.83871C3.6865 9.04839 3.83627 9.19816 4.04594 9.19816H6.8017V11.9539C6.8017 12.1636 6.95147 12.3134 7.16115 12.3134H8.83857C9.01829 12.3134 9.19802 12.1636 9.19802 11.9539V9.19816H11.9538C12.1335 9.19816 12.3132 9.04839 12.3132 8.83871Z"
          fill="#171717"/>
    </svg>
)

export const BackIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
      <path
          d="M0.883092 6.16057L6.72907 0.284459C7.03041 0.0132533 7.48242 0.0132533 7.75363 0.284459L8.44671 0.977539C8.71791 1.24874 8.71791 1.70075 8.44671 2.00209L3.80608 6.64272L8.44671 11.3135C8.71791 11.6148 8.71791 12.0668 8.44671 12.338L7.75363 13.0311C7.48242 13.3023 7.03041 13.3023 6.72907 13.0311L0.883092 7.15499C0.611886 6.88379 0.611886 6.43178 0.883092 6.16057Z"
          fill="black"/>
    </svg>
)

export const CheckCircle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
      <path
          d="M54 27C54 41.9153 41.8064 54 27 54C12.0847 54 0 41.9153 0 27C0 12.1935 12.0847 0 27 0C41.8064 0 54 12.1935 54 27ZM23.8427 41.371L43.875 21.3387C44.5282 20.6855 44.5282 19.4879 43.875 18.8347L41.371 16.4395C40.7177 15.6774 39.629 15.6774 38.9758 16.4395L22.6452 32.7702L14.9153 25.1492C14.2621 24.3871 13.1734 24.3871 12.5202 25.1492L10.0161 27.5444C9.3629 28.1976 9.3629 29.3952 10.0161 30.0484L21.3387 41.371C21.9919 42.0242 23.1895 42.0242 23.8427 41.371Z"
          fill="#171717"/>
    </svg>
)


export const ExclamationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
      <path
          d="M103.427 51.9999C103.427 80.41 80.2017 103.428 51.9989 103.428C23.5887 103.428 0.570312 80.41 0.570312 51.9999C0.570312 23.7971 23.5887 0.571289 51.9989 0.571289C80.2017 0.571289 103.427 23.7971 103.427 51.9999ZM51.9989 62.3685C46.6072 62.3685 42.4597 66.7234 42.4597 71.9077C42.4597 77.2994 46.6072 81.4469 51.9989 81.4469C57.1832 81.4469 61.5381 77.2994 61.5381 71.9077C61.5381 66.7234 57.1832 62.3685 51.9989 62.3685ZM42.8745 28.1519L44.3261 56.3547C44.5335 57.8063 45.5703 58.6358 46.8146 58.6358H56.9758C58.2201 58.6358 59.257 57.8063 59.4643 56.3547L60.9159 28.1519C61.1233 26.7003 59.8791 25.4561 58.4275 25.4561H45.3629C43.9113 25.4561 42.6671 26.7003 42.8745 28.1519Z"
          fill="#171717"/>
    </svg>
)

export const WriterMagicPen = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 120 120" fill="none">
      <path
          d="M53.574 27.857L50.3597 21.4284L43.9312 18.2141L50.3597 14.9999L53.574 8.57129L56.7883 14.9999L63.2169 18.2141L56.7883 21.4284L53.574 27.857ZM24.6454 40.7141L19.2213 30.0668L8.57401 24.6427L19.2213 19.4195L24.6454 8.57129L29.8687 19.4195L40.7169 24.6427L29.8687 30.0668L24.6454 40.7141ZM95.3597 66.4284L100.583 77.2766L111.431 82.4999L100.583 87.924L95.3597 98.5713L89.9356 87.924L79.2883 82.4999L89.9356 77.2766L95.3597 66.4284Z"
          fill="#745ECA"/>
      <path
          d="M108.418 37.299L99.1767 46.54C98.3731 47.3436 96.766 47.3436 95.7615 46.54L73.4624 24.2409C72.6588 23.2365 72.6588 21.6293 73.4624 20.8258L82.7035 11.5847C86.5204 7.76772 92.7481 7.76772 96.3642 11.5847L108.418 23.6383C112.235 27.2543 112.235 33.482 108.418 37.299ZM65.6276 28.6606C66.4311 27.857 68.0383 27.857 69.0428 28.6606L91.3419 50.9597C92.3463 51.9641 92.3463 53.5713 91.3419 54.3749L38.507 107.21L14.199 111.428C10.7838 112.031 7.97133 109.219 8.574 105.803L12.7928 81.4954L65.6276 28.6606ZM26.2526 93.7499V84.107H18.8195L16.6097 97.165L22.8374 103.393L35.8954 101.183V93.7499H26.2526Z"
          fill="#745ECA"/>
    </svg>
)

export const FreshArticleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
      <path
          d="M15.5938 4.59263L12.5804 7.60603C12.4297 7.7567 12.1886 7.7567 12.0681 7.60603L11.4654 7.00335C11.3147 6.88281 11.3147 6.64174 11.4654 6.49107L13.0022 5.04464H0.647321C0.436384 5.04464 0.285714 4.89397 0.285714 4.68304V3.95982C0.285714 3.77902 0.436384 3.59821 0.647321 3.59821H13.0022L11.4654 2.18192C11.3147 2.03125 11.3147 1.79018 11.4654 1.66964L12.0681 1.06696C12.1886 0.916294 12.4297 0.916294 12.5804 1.06696L15.5938 4.08036C15.7444 4.23103 15.7444 4.44196 15.5938 4.59263ZM0.376116 10.3783C0.225446 10.2277 0.225446 10.0167 0.376116 9.86607L3.38951 6.85268C3.54018 6.70201 3.78125 6.70201 3.90179 6.85268L4.50446 7.45536C4.65513 7.57589 4.65513 7.81696 4.50446 7.96763L2.96763 9.38393H15.3527C15.5335 9.38393 15.7143 9.56473 15.7143 9.74554V10.4687C15.7143 10.6797 15.5335 10.8304 15.3527 10.8304H2.96763L4.50446 12.2768C4.65513 12.4275 4.65513 12.6685 4.50446 12.7891L3.90179 13.3917C3.78125 13.5424 3.54018 13.5424 3.38951 13.3917L0.376116 10.3783Z"
          fill="#171717"/>
    </svg>
)

export const SaveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
          d="M13.3996 3.14285C13.6751 3.41836 13.8588 3.78571 13.8588 4.18367V12.3878C13.8588 13.2143 13.1853 13.8571 12.3894 13.8571H1.61392C0.787388 13.8571 0.144531 13.2143 0.144531 12.3878V1.61224C0.144531 0.816322 0.787388 0.142853 1.61392 0.142853H9.818C10.216 0.142853 10.5833 0.326526 10.8588 0.602036L13.3996 3.14285ZM7.00167 11.898C8.0731 11.898 8.96086 11.0408 8.96086 9.93877C8.96086 8.86734 8.0731 7.97959 7.00167 7.97959C5.89963 7.97959 5.04249 8.86734 5.04249 9.93877C5.04249 11.0408 5.89963 11.898 7.00167 11.898ZM9.94045 2.59183C9.94045 2.5 9.87922 2.40816 9.818 2.31632L9.72616 2.22449C9.63433 2.16326 9.54249 2.10204 9.45065 2.10204H2.47106C2.25678 2.10204 2.10371 2.28571 2.10371 2.46938V5.65306C2.10371 5.86734 2.25678 6.0204 2.47106 6.0204H9.5731C9.75678 6.0204 9.94045 5.86734 9.94045 5.65306V2.59183Z"
          fill="#171717"/>
    </svg>
)

export const UserIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
      <path
          d="M5.75 7.25C3.875 7.25 2.375 5.75 2.375 3.875C2.375 2.02344 3.875 0.5 5.75 0.5C7.60156 0.5 9.125 2.02344 9.125 3.875C9.125 5.75 7.60156 7.25 5.75 7.25ZM5.75 1.625C4.50781 1.625 3.5 2.65625 3.5 3.875C3.5 5.11719 4.50781 6.125 5.75 6.125C6.96875 6.125 8 5.11719 8 3.875C8 2.65625 6.96875 1.625 5.75 1.625ZM7.88281 7.64844C9.61719 7.67188 11 9.05469 11 10.7891V11.375C11 12.0078 10.4844 12.5 9.875 12.5H1.625C0.992188 12.5 0.5 12.0078 0.5 11.375V10.7891C0.5 9.05469 1.85938 7.67188 3.59375 7.64844L4.90625 11.2578L5.375 9.3125L4.625 8H6.875L6.125 9.3125L6.57031 11.2578L7.88281 7.64844ZM2.75 11.375H3.75781L2.86719 8.91406C2.11719 9.24219 1.625 9.94531 1.625 10.7891V11.375H2.75ZM9.875 11.375V10.7891C9.875 9.94531 9.35938 9.24219 8.63281 8.91406L7.71875 11.375H9.875Z"
          fill="#171717"/>
    </svg>
)

export const BookIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
      <path
          d="M10.1953 9.875C10.0781 10.1797 10.0781 11.0938 10.1953 11.3984C10.3594 11.4219 10.5 11.5859 10.5 11.75V12.125C10.5 12.3359 10.3125 12.5 10.125 12.5H1.875C0.820312 12.5 0 11.6797 0 10.625V2.375C0 1.34375 0.820312 0.5 1.875 0.5H10.125C10.3125 0.5 10.5 0.6875 10.5 0.875V9.5C10.5 9.6875 10.3594 9.85156 10.1953 9.875ZM9.23438 9.875H1.875C1.45312 9.875 1.125 10.2266 1.125 10.625C1.125 11.0469 1.45312 11.375 1.875 11.375H9.23438C9.16406 10.9766 9.16406 10.2969 9.23438 9.875ZM9.375 1.625H1.875C1.45312 1.625 1.125 1.97656 1.125 2.375V8.91406C1.33594 8.82031 1.59375 8.75 1.875 8.75H9.375V1.625Z"
          fill="#171717"/>
    </svg>
)

export const ChatIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11" fill="none">
      <path
          d="M6.75 0.25C10.0547 0.25 12.75 2.45312 12.75 5.125C12.75 7.82031 10.0547 10 6.75 10C5.97656 10 5.25 9.88281 4.57031 9.67188C3.89062 10.1641 2.74219 10.75 1.3125 10.75C1.07812 10.75 0.867188 10.6328 0.773438 10.4219C0.703125 10.2109 0.726562 9.97656 0.890625 9.8125C0.914062 9.8125 1.64062 9.01562 1.96875 8.10156C1.19531 7.28125 0.75 6.25 0.75 5.125C0.75 2.45312 3.42188 0.25 6.75 0.25ZM6.75 8.875C9.42188 8.875 11.625 7.21094 11.625 5.125C11.625 3.0625 9.42188 1.375 6.75 1.375C4.05469 1.375 1.875 3.0625 1.875 5.125C1.875 6.13281 2.36719 6.88281 2.78906 7.32812L3.28125 7.84375L3.02344 8.5C2.90625 8.82812 2.74219 9.15625 2.55469 9.4375C3.11719 9.25 3.58594 8.99219 3.91406 8.75781L4.35938 8.42969L4.89844 8.59375C5.48438 8.78125 6.11719 8.875 6.75 8.875Z"
          fill="#171717"/>
    </svg>
)

export const MagicIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path
          d="M9.08292 2.92317C9.00272 2.8697 8.94925 2.81623 8.94925 2.73603C8.94925 2.6291 9.00272 2.57563 9.08292 2.52216L10.6869 1.85383L11.3285 0.276551C11.382 0.19635 11.4355 0.142883 11.5157 0.142883C11.6226 0.142883 11.6761 0.19635 11.7295 0.276551L12.3979 1.85383L13.9751 2.52216C14.0553 2.57563 14.1088 2.6291 14.1088 2.7093C14.1088 2.81623 14.0553 2.8697 13.9751 2.92317L12.3979 3.56477L11.7295 5.16878C11.6761 5.24898 11.6226 5.30245 11.5424 5.30245C11.4355 5.30245 11.382 5.24898 11.3285 5.16878L10.6869 3.56477L9.08292 2.92317ZM13.9751 11.0769C14.0553 11.1304 14.1088 11.1838 14.1088 11.264C14.1088 11.371 14.0553 11.4244 13.9751 11.4779L12.3979 12.1195L11.7295 13.7235C11.6761 13.8037 11.6226 13.8572 11.5424 13.8572C11.4355 13.8572 11.382 13.8037 11.3285 13.7235L10.6869 12.1195L9.08292 11.4779C9.00272 11.4244 8.94925 11.371 8.94925 11.2908C8.94925 11.1838 9.00272 11.1304 9.08292 11.0769L10.6869 10.4085L11.3285 8.83127C11.382 8.75107 11.4355 8.6976 11.5157 8.6976C11.6226 8.6976 11.6761 8.75107 11.7295 8.83127L12.3979 10.4085L13.9751 11.0769ZM10.6869 6.98666C10.6602 7.25399 10.5265 7.46786 10.3127 7.5748L7.50564 8.96494L6.1155 11.772C6.00857 11.9858 5.7947 12.1462 5.5541 12.1462C5.28676 12.1462 5.07289 11.9858 4.96596 11.772L3.57582 8.96494L0.7688 7.5748C0.554932 7.46786 0.394531 7.25399 0.394531 7.01339C0.394531 6.74606 0.554932 6.53219 0.7688 6.42526L3.57582 5.03511L4.96596 2.2281C5.17983 1.80036 5.90163 1.80036 6.1155 2.2281L7.50564 5.03511L10.3127 6.42526C10.5265 6.53219 10.6602 6.74606 10.6869 6.98666ZM6.7571 7.92233L8.60172 6.98666L6.7571 6.07772C6.62344 6.02425 6.5165 5.91732 6.46304 5.78365L5.5541 3.93904L4.61842 5.78365C4.56496 5.91732 4.45802 6.02425 4.32436 6.07772L2.47974 6.98666L4.32436 7.92233C4.45802 7.9758 4.56496 8.08273 4.61842 8.2164L5.5541 10.061L6.46304 8.2164C6.5165 8.08273 6.62344 7.9758 6.7571 7.92233Z"
          fill="#171717"/>
    </svg>
)

export const InfoCircleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path
          d="M6.00195 0.865234C9.28906 0.865234 11.9961 3.57227 11.9961 6.85938C11.9961 10.1707 9.28906 12.8535 6.00195 12.8535C2.69067 12.8535 0.0078125 10.1707 0.0078125 6.85938C0.0078125 3.57227 2.69067 0.865234 6.00195 0.865234ZM6.00195 3.52393C5.42188 3.52393 4.98682 3.98315 4.98682 4.53906C4.98682 5.11914 5.42188 5.5542 6.00195 5.5542C6.55786 5.5542 7.01709 5.11914 7.01709 4.53906C7.01709 3.98315 6.55786 3.52393 6.00195 3.52393ZM7.35547 9.66309V9.08301C7.35547 8.93799 7.21045 8.79297 7.06543 8.79297H6.77539V6.37598C6.77539 6.23096 6.63037 6.08594 6.48535 6.08594H4.93848C4.76929 6.08594 4.64844 6.23096 4.64844 6.37598V6.95605C4.64844 7.12524 4.76929 7.24609 4.93848 7.24609H5.22852V8.79297H4.93848C4.76929 8.79297 4.64844 8.93799 4.64844 9.08301V9.66309C4.64844 9.83228 4.76929 9.95312 4.93848 9.95312H7.06543C7.21045 9.95312 7.35547 9.83228 7.35547 9.66309Z"
          fill="currentColor"/>
    </svg>
)

export const InfoCircleLightIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
          d="M7 1C10.2903 1 13 3.70968 13 7C13 10.3145 10.2903 13 7 13C3.68548 13 1 10.3145 1 7C1 3.70968 3.68548 1 7 1ZM7 11.8387C9.66129 11.8387 11.8387 9.68548 11.8387 7C11.8387 4.33871 9.66129 2.16129 7 2.16129C4.31452 2.16129 2.16129 4.33871 2.16129 7C2.16129 9.68548 4.31452 11.8387 7 11.8387ZM7 3.66129C7.55645 3.66129 8.01613 4.12097 8.01613 4.67742C8.01613 5.25806 7.55645 5.69355 7 5.69355C6.41935 5.69355 5.98387 5.25806 5.98387 4.67742C5.98387 4.12097 6.41935 3.66129 7 3.66129ZM8.35484 9.80645C8.35484 9.97581 8.20968 10.0968 8.06452 10.0968H5.93548C5.76613 10.0968 5.64516 9.97581 5.64516 9.80645V9.22581C5.64516 9.08064 5.76613 8.93548 5.93548 8.93548H6.22581V7.3871H5.93548C5.76613 7.3871 5.64516 7.26613 5.64516 7.09677V6.51613C5.64516 6.37097 5.76613 6.22581 5.93548 6.22581H7.48387C7.62903 6.22581 7.77419 6.37097 7.77419 6.51613V8.93548H8.06452C8.20968 8.93548 8.35484 9.08064 8.35484 9.22581V9.80645Z"
          fill="#171717"/>
    </svg>
)

export const ConfigurationIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
      <path d="M13.4263 1.07143C13.6406 1.07143 13.8549 1.28571 13.8549 1.5V1.92857C13.8549 2.16964 13.6406 2.35714 13.4263 2.35714H7.85491V3C7.85491 3.24107 7.64062 3.42857 7.42634 3.42857H6.5692C6.32812 3.42857 6.14062 3.24107 6.14062 3V2.35714H0.569196C0.328125 2.35714 0.140625 2.16964 0.140625 1.92857V1.5C0.140625 1.28571 0.328125 1.07143 0.569196 1.07143H6.14062V0.428571C6.14062 0.214286 6.32812 0 6.5692 0H7.42634C7.64062 0 7.85491 0.214286 7.85491 0.428571V1.07143H13.4263ZM13.4263 9.64286C13.6406 9.64286 13.8549 9.85714 13.8549 10.0714V10.5C13.8549 10.7411 13.6406 10.9286 13.4263 10.9286H4.42634V11.5714C4.42634 11.8125 4.21205 12 3.99777 12H3.14062C2.89955 12 2.71205 11.8125 2.71205 11.5714V10.9286H0.569196C0.328125 10.9286 0.140625 10.7411 0.140625 10.5V10.0714C0.140625 9.85714 0.328125 9.64286 0.569196 9.64286H2.71205V9C2.71205 8.78572 2.89955 8.57143 3.14062 8.57143H3.99777C4.21205 8.57143 4.42634 8.78572 4.42634 9V9.64286H13.4263ZM13.4263 5.35714C13.6406 5.35714 13.8549 5.57143 13.8549 5.78571V6.21429C13.8549 6.45536 13.6406 6.64286 13.4263 6.64286H11.2835V7.28571C11.2835 7.52679 11.0692 7.71429 10.8549 7.71429H9.99777C9.7567 7.71429 9.5692 7.52679 9.5692 7.28571V6.64286H0.569196C0.328125 6.64286 0.140625 6.45536 0.140625 6.21429V5.78571C0.140625 5.57143 0.328125 5.35714 0.569196 5.35714H9.5692V4.71429C9.5692 4.5 9.7567 4.28571 9.99777 4.28571H10.8549C11.0692 4.28571 11.2835 4.5 11.2835 4.71429V5.35714H13.4263Z" fill="#171717"/>
    </svg>
)

export const GlobeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <path
          d="M6.99777 0.142883C10.7858 0.142883 13.8549 3.21201 13.8549 7.00003C13.8549 10.788 10.7858 13.8572 6.99777 13.8572C3.20975 13.8572 0.140625 10.788 0.140625 7.00003C0.140625 3.21201 3.20975 0.142883 6.99777 0.142883ZM11.9471 4.56685C11.3664 3.40556 10.3987 2.49311 9.20975 1.96777C9.59685 2.68666 9.90099 3.57145 10.0945 4.56685H11.9471ZM6.99777 1.47007C6.47242 1.47007 5.64293 2.63136 5.22818 4.56685H8.7397C8.32496 2.63136 7.49546 1.47007 6.99777 1.47007ZM1.46781 7.00003C1.46781 7.38712 1.49546 7.77422 1.57841 8.10602H3.70744C3.6798 7.74657 3.6798 7.38712 3.6798 7.00003C3.6798 6.64058 3.6798 6.28113 3.70744 5.89403H1.57841C1.49546 6.25348 1.46781 6.64058 1.46781 7.00003ZM2.02081 9.4332C2.60145 10.5945 3.5692 11.5346 4.75814 12.0599C4.37104 11.341 4.06689 10.4562 3.87334 9.4332H2.02081ZM3.87334 4.56685C4.06689 3.57145 4.37104 2.68666 4.75814 1.96777C3.5692 2.49311 2.60145 3.40556 2.02081 4.56685H3.87334ZM6.99777 12.53C7.49546 12.53 8.32496 11.3963 8.7397 9.4332H5.22818C5.64293 11.3963 6.47242 12.53 6.99777 12.53ZM8.93325 8.10602C8.9609 7.77422 8.98855 7.38712 8.98855 7.00003C8.98855 6.61293 8.9609 6.25348 8.93325 5.89403H5.03463C5.00698 6.25348 5.00698 6.61293 5.00698 7.00003C5.00698 7.38712 5.00698 7.77422 5.03463 8.10602H8.93325ZM9.20975 12.0599C10.3987 11.5346 11.3664 10.5945 11.9471 9.4332H10.0945C9.90099 10.4562 9.59685 11.341 9.20975 12.0599ZM10.2604 8.10602H12.4171C12.4724 7.77422 12.5277 7.38712 12.5277 7.00003C12.5277 6.64058 12.4724 6.25348 12.4171 5.89403H10.2604C10.2881 6.28113 10.3157 6.64058 10.3157 7.00003C10.3157 7.38712 10.2881 7.74657 10.2604 8.10602Z"
          fill="#171717"/>
    </svg>
)

export const FlashIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
      <path
          d="M6.78906 3.96875C7.1582 3.96875 7.4043 4.39941 7.19922 4.70703L3.58984 10.9414C3.50781 11.1055 3.34375 11.1875 3.15918 11.1875C2.85156 11.1875 2.62598 10.9004 2.6875 10.5928L3.63086 6.59375H1.21094C0.90332 6.59375 0.677734 6.34766 0.71875 6.04004L1.375 1.11816C1.39551 0.87207 1.62109 0.6875 1.86719 0.6875H4.82031C5.12793 0.6875 5.37402 0.995117 5.29199 1.32324L4.41016 3.96875H6.78906Z"
          fill="#171717"/>
    </svg>
)

export const CalendarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
          d="M10.125 1.5C10.7344 1.5 11.25 2.01562 11.25 2.625V10.875C11.25 11.5078 10.7344 12 10.125 12H1.875C1.24219 12 0.75 11.5078 0.75 10.875V2.625C0.75 2.01562 1.24219 1.5 1.875 1.5H3V0.28125C3 0.140625 3.11719 0 3.28125 0H4.21875C4.35938 0 4.5 0.140625 4.5 0.28125V1.5H7.5V0.28125C7.5 0.140625 7.61719 0 7.78125 0H8.71875C8.85938 0 9 0.140625 9 0.28125V1.5H10.125ZM9.98438 10.875C10.0547 10.875 10.125 10.8281 10.125 10.7344V3.75H1.875V10.7344C1.875 10.8281 1.92188 10.875 2.01562 10.875H9.98438Z"
          fill="#171717"/>
    </svg>
)

export const TrendUpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
      <path
          d="M11.1562 7.5C11.3281 7.5 11.5 7.67188 11.5 7.84375V8.53125C11.5 8.72461 11.3281 8.875 11.1562 8.875H1.1875C0.800781 8.875 0.5 8.57422 0.5 8.1875V0.96875C0.5 0.796875 0.650391 0.625 0.84375 0.625H1.53125C1.70312 0.625 1.875 0.796875 1.875 0.96875V7.5H11.1562ZM10.4688 1.3125C10.6406 1.3125 10.8125 1.48438 10.791 1.65625V4.21289C10.791 4.66406 10.2539 4.90039 9.93164 4.57812L9.22266 3.86914L7.16016 5.93164C6.90234 6.21094 6.45117 6.21094 6.19336 5.93164L4.625 4.36328L3.61523 5.35156C3.48633 5.48047 3.27148 5.48047 3.14258 5.35156L2.64844 4.85742C2.51953 4.72852 2.51953 4.51367 2.64844 4.38477L4.13086 2.90234C4.38867 2.62305 4.83984 2.62305 5.09766 2.90234L6.6875 4.4707L8.25586 2.90234L7.54688 2.19336C7.22461 1.87109 7.46094 1.3125 7.91211 1.3125H10.4688Z"
          fill="#171717"/>
    </svg>
)

export const PenIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path
          d="M6.80892 2.18788L9.81212 5.19108L3.28954 11.7137L0.614813 11.9952C0.262875 12.0421 -0.042138 11.7371 0.00478704 11.3852L0.286337 8.71046L6.80892 2.18788ZM11.6657 1.74209C12.1114 2.16442 12.1114 2.89176 11.6657 3.33754L10.3518 4.65144L7.34856 1.64824L8.66246 0.334341C9.10824 -0.111447 9.83558 -0.111447 10.2579 0.334341L11.6657 1.74209Z"
          fill="#171717"/>
    </svg>
)
export const CrownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
      <path
          d="M12.95 2.8C13.5188 2.8 14 3.28125 14 3.85C14 4.44062 13.5188 4.9 12.95 4.9C12.8844 4.9 12.8187 4.9 12.775 4.9L11.1562 10H3.04063L1.20312 4.9C1.15937 4.9 1.09375 4.9 1.05 4.9C0.459375 4.9 0 4.44062 0 3.85C0 3.28125 0.459375 2.8 1.05 2.8C1.61875 2.8 2.1 3.28125 2.1 3.85C2.1 4.00312 2.05625 4.15625 1.99063 4.2875L3.5875 5.25C3.69688 5.31563 3.80625 5.3375 3.9375 5.3375C4.17812 5.3375 4.41875 5.20625 4.55 4.9875L6.32188 1.85938C6.10313 1.68438 5.95 1.37812 5.95 1.05C5.95 0.48125 6.40938 0 7 0C7.56875 0 8.05 0.48125 8.05 1.05C8.05 1.37812 7.89687 1.68438 7.65625 1.85938L9.42813 4.9875C9.55938 5.20625 9.8 5.3375 10.0406 5.3375C10.1719 5.3375 10.2812 5.31563 10.4125 5.25L11.9875 4.2875C11.9219 4.15625 11.9 4.025 11.9 3.85C11.9 3.28125 12.3594 2.8 12.95 2.8ZM10.4125 8.95L11.2437 5.95L10.9375 6.14687C10.675 6.3 10.3687 6.3875 10.0406 6.3875C9.40625 6.3875 8.8375 6.05937 8.53125 5.5125L7 2.82188L5.44688 5.5125C5.14062 6.05937 4.57188 6.3875 3.9375 6.3875C3.63125 6.3875 3.30313 6.3 3.04063 6.14687L2.73438 5.95L3.7625 8.95H10.4125Z"
          fill="#171717"/>
    </svg>
)
export const BooksIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
      <path
          d="M13.49 10.4791C13.5352 10.6824 13.4223 10.8631 13.2415 10.9309L12.8801 11.0213C12.7219 11.0665 12.5412 10.9761 12.4734 10.818C12.1571 10.818 10.9598 11.1342 10.7113 11.2924C10.7113 11.4731 10.6209 11.6312 10.4402 11.6764L10.1013 11.7668C9.89801 11.812 9.69469 11.699 9.6495 11.5183L7.36778 2.82061V11.0439C7.36778 11.4505 7.02891 11.7668 6.46412 11.7668H1.04219C0.816279 11.7668 0.5 11.4505 0.5 11.0439V0.922936C0.5 0.538883 0.816279 0.200012 1.04219 0.200012H6.46412C7.02891 0.200012 7.36778 0.538883 7.36778 0.922936V1.03589L7.61628 0.968119C7.77442 0.922936 7.95515 1.0133 8.02293 1.17144C8.3392 1.17144 9.53655 0.855162 9.78505 0.697022C9.78505 0.516291 9.87542 0.358152 10.0561 0.312969L10.395 0.222604C10.5983 0.177421 10.8017 0.290378 10.8468 0.471109L13.49 10.4791ZM3.3917 10.6824V9.59802H1.58439V10.6824H3.3917ZM3.3917 8.51364V3.45317H1.58439V8.51364H3.3917ZM3.3917 2.36878V1.2844H1.58439V2.36878H3.3917ZM6.28339 10.6824V9.59802H4.47608V10.6824H6.28339ZM6.28339 8.51364V3.45317H4.47608V8.51364H6.28339ZM6.28339 2.36878V1.2844H4.47608V2.36878H6.28339ZM10.4628 10.4113C10.8468 10.2306 11.8409 9.98207 12.2249 9.93689L10.0336 1.57809C9.6495 1.75882 8.65548 2.00732 8.27143 2.0525L10.4628 10.4113Z"
          fill="#171717"/>
    </svg>
)
export const ThumbDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
      <path
          d="M12.332 6.36328C12.5605 6.74414 12.6875 7.125 12.6875 7.63281C12.6875 8.75 11.7227 9.79102 10.5039 9.79102H9.56445C9.69141 10.1211 9.79297 10.502 9.79297 10.9844C9.79297 12.8379 8.82812 13.625 7.38086 13.625C5.80664 13.625 5.9082 11.2383 5.55273 10.8828C4.96875 10.2988 4.2832 9.18164 3.80078 8.75H3.62305C3.49609 9.00391 3.2168 9.15625 2.9375 9.15625H1.3125C0.855469 9.15625 0.5 8.80078 0.5 8.34375V2.25C0.5 1.81836 0.855469 1.4375 1.3125 1.4375H2.9375C3.14062 1.4375 3.31836 1.53906 3.4707 1.64062H3.75C4.76562 1.64062 5.60352 0.650391 8.21875 0.650391C8.42188 0.650391 8.625 0.625 8.80273 0.625C10.7578 0.625 11.6211 1.64062 11.6465 3.0625C12.002 3.51953 12.1797 4.1543 12.1035 4.76367C12.3574 5.2207 12.4336 5.7793 12.332 6.36328ZM2.125 8.14062C2.45508 8.14062 2.73438 7.88672 2.73438 7.53125C2.73438 7.20117 2.45508 6.92188 2.125 6.92188C1.76953 6.92188 1.51562 7.20117 1.51562 7.53125C1.51562 7.88672 1.76953 8.14062 2.125 8.14062ZM10.5039 8.57227C11.0371 8.57227 11.4434 8.08984 11.4688 7.60742C11.4688 7.15039 11.1387 6.66797 10.8848 6.66797C11.2402 6.28711 11.3164 5.5 10.7578 4.99219C11.0879 4.45898 10.7832 3.74805 10.4023 3.51953C10.6055 2.30078 9.94531 1.86914 9.05664 1.86914H8.0918C6.28906 1.86914 5.0957 2.80859 3.75 2.80859V7.53125H4.00391C4.74023 7.53125 5.73047 9.33398 6.41602 10.0195C7.12695 10.7305 6.89844 11.9492 7.38086 12.4062C8.57422 12.4062 8.57422 11.5938 8.57422 10.9844C8.57422 9.99414 7.86328 9.53711 7.86328 8.57227H10.5039Z"
          fill="#171717"/>
    </svg>
)
export const ThumbUpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
      <path
          d="M12.332 7.91211C12.4336 8.49609 12.3574 9.05469 12.1035 9.51172C12.1797 10.1211 12.002 10.7559 11.6465 11.2129C11.6211 12.6348 10.7578 13.625 8.80273 13.625C8.625 13.625 8.42188 13.625 8.21875 13.625C5.62891 13.625 4.8418 12.6602 3.69922 12.6348C3.62305 12.9648 3.29297 13.2188 2.9375 13.2188H1.3125C0.855469 13.2188 0.5 12.8633 0.5 12.4062V6.3125C0.5 5.88086 0.855469 5.5 1.3125 5.5H3.80078C4.2832 5.09375 4.96875 3.97656 5.55273 3.39258C5.9082 3.03711 5.80664 0.625 7.38086 0.625C8.82812 0.625 9.79297 1.4375 9.79297 3.29102C9.79297 3.77344 9.69141 4.1543 9.56445 4.48438H10.5039C11.7227 4.48438 12.6875 5.52539 12.6875 6.64258C12.6875 7.125 12.5605 7.53125 12.332 7.91211ZM10.7578 9.2832C11.3164 8.77539 11.2402 7.98828 10.8848 7.60742C11.1387 7.60742 11.4688 7.125 11.4688 6.66797C11.4434 6.18555 11.0371 5.70312 10.5039 5.70312H7.86328C7.86328 4.73828 8.57422 4.28125 8.57422 3.29102C8.57422 2.68164 8.57422 1.84375 7.38086 1.84375C6.89844 2.32617 7.12695 3.54492 6.41602 4.25586C5.73047 4.94141 4.74023 6.71875 4.00391 6.71875H3.75V11.4668C5.0957 11.4668 6.28906 12.4062 8.0918 12.4062H9.05664C9.94531 12.4062 10.6055 11.9746 10.4023 10.7559C10.7832 10.5273 11.0879 9.81641 10.7578 9.2832ZM2.73438 11.5938C2.73438 11.2637 2.45508 10.9844 2.125 10.9844C1.76953 10.9844 1.51562 11.2637 1.51562 11.5938C1.51562 11.9492 1.76953 12.2031 2.125 12.2031C2.45508 12.2031 2.73438 11.9492 2.73438 11.5938Z"
          fill="#171717"/>
    </svg>
)
export const WrittenFileIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
          d="M10.25 7.8125V8.46875C10.25 8.63281 10.1094 8.75 9.96875 8.75H6.03125C5.86719 8.75 5.75 8.63281 5.75 8.46875V7.8125C5.75 7.67188 5.86719 7.53125 6.03125 7.53125H9.96875C10.1094 7.53125 10.25 7.67188 10.25 7.8125ZM9.96875 9.5C10.1094 9.5 10.25 9.64063 10.25 9.78125V10.4375C10.25 10.6016 10.1094 10.7188 9.96875 10.7188H6.03125C5.86719 10.7188 5.75 10.6016 5.75 10.4375V9.78125C5.75 9.64063 5.86719 9.5 6.03125 9.5H9.96875ZM12.5 5.09375V12.875C12.5 13.5078 11.9844 14 11.375 14H4.625C3.99219 14 3.5 13.5078 3.5 12.875V3.125C3.5 2.51562 3.99219 2 4.625 2H9.40625C9.6875 2 9.99219 2.14062 10.2031 2.35156L12.1484 4.29688C12.3594 4.50781 12.5 4.8125 12.5 5.09375ZM9.5 3.21875V5H11.2813L9.5 3.21875ZM11.375 12.875V6.125H8.9375C8.60938 6.125 8.375 5.89063 8.375 5.5625V3.125H4.625V12.875H11.375Z"
          fill="#171717"/>
    </svg>
)
export const RobotIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
          d="M4.63158 12V10.7368H6.31579V12H4.63158ZM14.7368 6.31578C15.4211 6.31578 16 6.89473 16 7.57894V10.9474C16 11.6579 15.4211 12.2105 14.7368 12.2105H13.8947C13.8947 13.6053 12.7368 14.7368 11.3684 14.7368H4.63158C3.23684 14.7368 2.10526 13.6053 2.10526 12.2105H1.26316C0.552632 12.2105 0 11.6579 0 10.9474V7.57894C0 6.89473 0.552632 6.31578 1.26316 6.31578H2.10526C2.10526 4.94736 3.23684 3.78947 4.63158 3.78947H7.36842V1.89473C7.36842 1.55263 7.63158 1.26315 8 1.26315C8.3421 1.26315 8.63158 1.55263 8.63158 1.89473V3.78947H11.3684C12.7368 3.78947 13.8947 4.94736 13.8947 6.31578H14.7368ZM2.10526 10.9474V7.57894H1.26316V10.9474H2.10526ZM12.6316 12.2105V6.31578C12.6316 5.63157 12.0526 5.05263 11.3684 5.05263H4.63158C3.92105 5.05263 3.36842 5.63157 3.36842 6.31578V12.2105C3.36842 12.921 3.92105 13.4737 4.63158 13.4737H11.3684C12.0526 13.4737 12.6053 12.921 12.6316 12.2105ZM14.7368 10.9474V7.57894H13.8947V10.9474H14.7368ZM5.89474 6.73684C6.57895 6.73684 7.15789 7.31578 7.15789 8C7.15789 8.71052 6.57895 9.26315 5.89474 9.26315C5.18421 9.26315 4.63158 8.71052 4.63158 8C4.63158 7.31578 5.18421 6.73684 5.89474 6.73684ZM10.1053 6.73684C10.7895 6.73684 11.3684 7.31578 11.3684 8C11.3684 8.71052 10.7895 9.26315 10.1053 9.26315C9.39474 9.26315 8.8421 8.71052 8.8421 8C8.8421 7.31578 9.39474 6.73684 10.1053 6.73684ZM9.68421 12V10.7368H11.3684V12H9.68421ZM7.15789 12V10.7368H8.8421V12H7.15789Z"
          fill="#171717"/>
    </svg>
)
export const DraftIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.3631 1.93372C11.5707 1.84767 11.7933 1.80338 12.018 1.80338C12.2427 1.80338 12.4652 1.84767 12.6728 1.93372C12.8804 2.01977 13.0691 2.1459 13.2279 2.30489L13.697 2.77401C13.8558 2.93281 13.9819 3.12139 14.0678 3.32888C14.1538 3.53639 14.198 3.75881 14.198 3.98343C14.198 4.20805 14.1538 4.43047 14.0678 4.63798C13.9819 4.84549 13.8559 5.03404 13.697 5.19285L8.94716 9.9452C8.62621 10.2661 8.19075 10.4464 7.73737 10.4464H6.12612C5.81052 10.4464 5.55469 10.1906 5.55469 9.87499V8.27374C5.55472 7.82048 5.73461 7.38572 6.05488 7.06498L10.808 2.30497C10.9668 2.14599 11.1555 2.01977 11.3631 1.93372ZM12.018 2.94623C11.9434 2.94623 11.8696 2.96093 11.8008 2.98947C11.7319 3.018 11.6694 3.05981 11.6167 3.11251C11.6167 3.11254 11.6167 3.11248 11.6167 3.11251L6.8636 7.87251C6.75728 7.97898 6.69756 8.12328 6.69754 8.27374C6.69754 8.27373 6.69754 8.27375 6.69754 8.27374V9.30356H7.73737C7.8877 9.30356 8.03231 9.24376 8.13885 9.13726C8.13883 9.13729 8.13888 9.13723 8.13885 9.13726L12.8888 4.38479C12.9415 4.33211 12.9835 4.26941 13.012 4.20057C13.0405 4.13173 13.0552 4.05794 13.0552 3.98343C13.0552 3.90892 13.0405 3.83514 13.012 3.7663C12.9835 3.69746 12.9417 3.63491 12.889 3.58223L12.4196 3.1128C12.3669 3.06006 12.3041 3.01802 12.2352 2.98947C12.1664 2.96093 12.0925 2.94623 12.018 2.94623Z"
            fill="black"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.99131 3.04429C4.28871 3.13875 3.89192 3.31481 3.60393 3.6028C3.31591 3.89081 3.13987 4.28748 3.04542 4.98996C2.94876 5.70889 2.94755 6.65789 2.94755 7.99999C2.94755 9.34176 2.94876 10.2908 3.04542 11.0098C3.13987 11.7124 3.31594 12.1092 3.60393 12.3972C3.89194 12.6852 4.28861 12.8612 4.99109 12.9557C5.71002 13.0523 6.65902 13.0536 8.00112 13.0536C9.34289 13.0536 10.2919 13.0523 11.0109 12.9557C11.7135 12.8612 12.1103 12.6852 12.3983 12.3972C12.6863 12.1092 12.8624 11.7125 12.9568 11.01C13.0535 10.2911 13.0547 9.34208 13.0547 7.99999C13.0547 7.6844 13.3105 7.42856 13.6261 7.42856C13.9417 7.42856 14.1975 7.6844 14.1975 7.99999V8.0427C14.1976 9.3324 14.1976 10.3584 14.0895 11.1623C13.978 11.9915 13.7422 12.6696 13.2064 13.2053C12.6707 13.7411 11.9925 13.9769 11.1632 14.0884C10.3592 14.1964 9.3332 14.1964 8.04378 14.1964H7.95841C6.66871 14.1964 5.64274 14.1964 4.8388 14.0884C4.00956 13.9769 3.33154 13.741 2.79581 13.2053C2.26004 12.6695 2.02423 11.9913 1.91275 11.1621C1.80466 10.3581 1.80467 9.33207 1.80469 8.04266V7.9573C1.80467 6.66759 1.80466 5.64161 1.91275 4.83767C2.02424 4.00844 2.26007 3.33041 2.79581 2.79468C3.33157 2.25891 4.00978 2.0231 4.83904 1.91162C5.64306 1.80354 6.66903 1.80355 7.95845 1.80356L8.00112 1.80356C8.31671 1.80356 8.57255 2.0594 8.57255 2.37499C8.57255 2.69058 8.31671 2.94642 8.00112 2.94642C6.65934 2.94642 5.71033 2.94763 4.99131 3.04429Z"
            fill="black"/>
    </svg>
)
export const LinkIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
          d="M9.56194 6.42633C9.2673 6.13169 8.94587 5.91741 8.59766 5.72991C8.46373 5.67633 8.3298 5.70312 8.22266 5.81026L8.11551 5.91741C7.87444 6.13169 7.74051 6.42633 7.71373 6.72098C7.68694 6.85491 7.7673 6.98883 7.87444 7.06919C8.11551 7.17633 8.3298 7.31026 8.49051 7.49776C9.37444 8.38169 9.37444 9.80133 8.49051 10.6853L6.48158 12.6942C5.59766 13.5781 4.17801 13.5781 3.29408 12.6942C2.41016 11.8103 2.41016 10.3906 3.29408 9.50669L4.52623 8.27455C4.60658 8.19419 4.63337 8.08705 4.60658 7.97991C4.52623 7.65848 4.49944 7.31026 4.47266 6.96205C4.47266 6.69419 4.12444 6.56026 3.93694 6.74776C3.61551 7.06919 3.10658 7.57812 2.24944 8.43526C0.776228 9.90848 0.776228 12.2924 2.24944 13.7388C3.69587 15.212 6.0798 15.212 7.55301 13.7388C9.74944 11.5424 9.6423 11.6495 9.80301 11.4353C11.0084 9.98883 10.928 7.79241 9.56194 6.42633ZM13.7405 2.24776C12.2941 0.774549 9.91016 0.774549 8.43694 2.24776C6.24051 4.44419 6.34766 4.33705 6.18694 4.55133C4.98158 5.99776 5.06194 8.19419 6.42801 9.56026C6.72266 9.85491 7.04408 10.0692 7.3923 10.2567C7.52623 10.3103 7.66016 10.2835 7.7673 10.1763L7.87444 10.0692C8.11551 9.85491 8.24944 9.56026 8.27623 9.26562C8.30301 9.13169 8.22266 8.99776 8.11551 8.91741C7.87444 8.81026 7.66016 8.67633 7.49944 8.48883C6.61551 7.60491 6.61551 6.18526 7.49944 5.30133L9.50837 3.29241C10.3923 2.40848 11.8119 2.40848 12.6959 3.29241C13.5798 4.17633 13.5798 5.59598 12.6959 6.47991L11.4637 7.71205C11.3834 7.79241 11.3566 7.89955 11.3834 8.00669C11.4637 8.32812 11.4905 8.67633 11.5173 9.02455C11.5173 9.29241 11.8655 9.42633 12.053 9.23883C12.3744 8.91741 12.8834 8.40848 13.7405 7.55133C15.2137 6.07812 15.2137 3.69419 13.7405 2.24776Z"
          fill="#171717"/>
    </svg>
)
export const SpeedoMeterIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
          d="M8.00042 2C12.2593 2 15.7147 5.45536 15.7147 9.71429C15.7147 11.1339 15.3129 12.4732 14.6433 13.5982C14.5093 13.8661 14.2147 14 13.9201 14H2.05399C1.75935 14 1.4647 13.8661 1.33078 13.5982C0.661133 12.4732 0.286133 11.1339 0.286133 9.71429C0.286133 5.45536 3.7147 2 8.00042 2ZM12.3665 6.6875C12.5808 6.39286 12.5272 5.99107 12.2326 5.77679C11.9379 5.5625 11.5361 5.64286 11.3218 5.91071L8.42899 9.79464C8.29506 9.74107 8.13435 9.71429 8.00042 9.71429C7.03613 9.71429 6.28613 10.4911 6.28613 11.4286C6.28613 11.75 6.36649 12.0446 6.50042 12.2857H9.47363C9.60756 12.0446 9.7147 11.75 9.7147 11.4286C9.7147 11.1071 9.60756 10.8125 9.44685 10.5714L12.3665 6.6875Z"
          fill="#171717"/>
    </svg>
)