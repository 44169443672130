// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.traffic-info-wrapper {
  position: relative;
}

.traffic-info__search-volume {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 146.667% */
  letter-spacing: -0.01em;
  padding: 4px 8px;
  cursor: pointer;
  position: relative;
  background: var(--default-surfaces-ghost-hv);
  color: var(--default-surfaces-anchor);
  border-radius: 50px;
  margin-right: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TrafficInfoButton/TrafficInfoButton.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,aAAa;EAChC,uBAAuB;EACvB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,4CAA4C;EAC5C,qCAAqC;EACrC,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".traffic-info-wrapper {\n  position: relative;\n}\n\n.traffic-info__search-volume {\n  display: flex;\n  align-items: center;\n  font-size: 13px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 16px; /* 146.667% */\n  letter-spacing: -0.01em;\n  padding: 4px 8px;\n  cursor: pointer;\n  position: relative;\n  background: var(--default-surfaces-ghost-hv);\n  color: var(--default-surfaces-anchor);\n  border-radius: 50px;\n  margin-right: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
