import { Tooltip, withStyles } from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useState } from "react";
const defaultStyle = {
  color: "#384359",
  backgroundColor: "white",
  boxShadow: "0px 20px 60px 0px rgba(0, 0, 0, 0.1)",
  transition: "none",
  transform: "none",
  borderRadius: "12px",
  fontFamily: "Inter",
  fontSize: "13px",
  fontWeight: "500",
  lineHeight: "16px",
  letterSpacing: "-0.01em",
  textAlign: "left",
  padding: "8px 12px",
  maxWidth: "220px",
  marginTop: "4px",
  marginBottom: "4px"
}
const blackStyle = { color: "white",
    fontWeight: "bold",
    backgroundColor: "black",}


export const BlackTooltip = withStyles({
  tooltip: { blackStyle },
  arrow: {
    "&:before": {
      color: "black",
    },
  },
})(Tooltip);

export const WhiteTooltip = withStyles({
  tooltip: defaultStyle,
  arrow: {
    "&:before": {
      color: "white",
      boxShadow: "1.3px 0.3px 15px rgba(0, 0, 0, 0.2)",
    },
  },
})(Tooltip);

export const WhiteTooltipAuto = withStyles({
  tooltip: {
    ...defaultStyle,
    maxWidth: '100%'
  },
  arrow: {
    "&:before": {
      color: "white",
      boxShadow: "1.3px 0.3px 15px rgba(0, 0, 0, 0.2)",
    },
  },
})(Tooltip);

const PositionTooltip = ({children, content, delay =1500, ...props}) => {
  const [open, setOpen] = useState(false);
  const [position, setPosition] =useState({x: 0, y: 0 });
  const [callbackId, setCallbackId] = useState(null);

  const disableTooltipOnScroll = useCallback(() => setOpen(false),[])

  const handleClick = (e) => {
    setPosition({x: e.clientX, y: e.clientY });
    setOpen(!open);
    setCallbackId(setTimeout(() => setOpen(false), delay));
    }

  useEffect(() => {
    if(open){
      document.addEventListener("scroll", disableTooltipOnScroll, {capture: true});
    }
    return () => {
      if(callbackId) clearTimeout(callbackId);
      setCallbackId(null);
      document.removeEventListener("scroll", disableTooltipOnScroll, {capture: true});
    }
  },[open])

  return (
    <Fragment>
      <div onClick={handleClick} style={{ display: "inline" }}>
        {children}
      </div>
      {open && ( 
        <Tooltip title={content} open={open} {...props}>
          <div 
            style={{
            position: "fixed",
            left: position.x,
            top: position.y,
            transform: "translate(-50%, -100%)",
          }}
          />
        </Tooltip>
      )}
    </Fragment>
  );
}

export const ClickPositionTooltip = withStyles({
    tooltip: {
       ...defaultStyle,
       maxWidth: "250px",
       boxShadow: "10px 30px 60px 10px rgba(0, 0, 0, 0.2)",
      },
    arrow: {
      "&:before": {
        color: "black",
        boxShadow: "1.3px 0.3px 15px rgba(0, 0, 0, 0.2)",
      },
    },
  })(PositionTooltip);
